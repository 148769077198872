import { useMutation } from "react-query";
import { UserApi } from "../user-api";

interface MutationProps {
  token: string;
}

export const useBELogout = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation("logoutUser", (mutationProps: MutationProps) => {
    const { token } = mutationProps;

    return UserApi.logoutUser({
      token,
    });
  });

  return { logoutFromBE: mutate, isLoading, isSuccess, isError };
};
