// https://codevoweb.com/setup-redux-toolkit-and-rtk-query/

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import appSliceDefault from "./slices/appSlice";
import authSliceDefault from "./slices/authSlice";
import filtersSliceDefault from "./slices/filtersSlice";
import listingsSliceDefault from "./slices/listingsSlice";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    appSlice: appSliceDefault,
    authSlice: authSliceDefault,
    filtersSlice: filtersSliceDefault,
    listingsSlice: listingsSliceDefault,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // .concat([authApi.middleware]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
