import { IconButton, styled } from "@mui/material";
import { ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useBasePathUrl } from "common/hooks";
import { useKeyListener } from "common/hooks/useKeyListener";
import { updateActiveMenuView } from "redux/slices/appSlice";

type PageWrapperProps = {
  children: ReactNode;
  title: string;
  onClose?: () => void;
};

export const PageWrapper = ({ children, title, onClose }: PageWrapperProps) => {
  const { goToBasePath } = useBasePathUrl();
  const dispatch = useAppDispatch();

  const closeFn =
    onClose ||
    (() => {
      dispatch(updateActiveMenuView("none"));
      goToBasePath({});
    });

  useKeyListener("Escape", closeFn);

  return (
    <StyledPageWrapper>
      <div className="page-wrapper-content">
        <div className="page-wrapper-header">
          {title && <h1>{title}</h1>}
          <IconButton
            sx={{
              position: "absolute",
              right: "-6px",
              top: "12px",
            }}
            onClick={closeFn}
          >
            <CloseIcon sx={{ color: "gray" }} />
          </IconButton>
        </div>
        {children}
      </div>
    </StyledPageWrapper>
  );
};

const StyledPageWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: 10,
  height: "100%",
  backgroundColor: "white",
  marginBottom: "20px",
  padding: "60px 20px 30px",
  color: theme.palette.grey[900],
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  overflowY: "auto",
  borderTop: "1px solid #e0e0e0",
  minWidth: "320px",

  "& .page-wrapper-content": {
    maxWidth: "720px",
    width: "100%",
    margin: "0 auto",
    position: "relative",
  },

  "& .page-wrapper-header": {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "42px",

    "& h1": {
      fontSize: "24px",
    },
  },
  "& h4": {
    // fontSize: "18px", // This and below override the default h4 font size on almost entire app
    // marginBottom: "10px",
  },
  "& p": {
    // fontSize: "12px",
    // lineHeight: "1.6",
    // marginBottom: "30px",
  },
}));
