import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, styled } from "@mui/material";
import { theme } from "theme";

interface Props {
  content: string;
}

export const FieldTooltip = (props: Props) => {
  const { content } = props;

  return (
    <Tooltip title={content} placement="right" enterTouchDelay={0} arrow>
      <StyledContent className="field-tooltip">
        <InfoIcon
          sx={
            {
              // opacity: 1,
              // color: "black",
              // "&:hover": {
              //   color: "red",
              // },
              // height: "34px",
              // width: "34px",
              // cursor: "pointer",
            }
          }
        />
      </StyledContent>
    </Tooltip>
  );
};

const StyledContent = styled("div")`
  cursor: pointer;
  color: ${theme.palette.secondary.main};
`;
