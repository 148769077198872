import { AuthPages, setDisplayAuthModal, updateCurrentAuthModalPage } from "redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from ".";

export const useAuthModal = () => {
  const dispatch = useAppDispatch();

  const { isDisplayingAuthModal, currentAuthModalPage } = useAppSelector((state) => state.authSlice);

  return {
    isDisplayingAuthModal,
    currentAuthModalPage,
    onUpdateAuthModalPage: (newPage: AuthPages) => dispatch(updateCurrentAuthModalPage(newPage)),
    onDisplayAuthModal: (newValue: boolean, newPage?: AuthPages) =>
      dispatch(setDisplayAuthModal({ newValue, newPage })),
  };
};
