export const cv = {
  cv_sf_gheorghe: {
    id: "cv_sf_gheorghe",
    lat: 45.86245199578805,
    lng: 25.79516817225711,
    zoom: 10,
    countyId: "cv",
    name: "Sfântu Gheorghe",
    countyName: "Covasna",
  },
};
