import { SelectChangeEvent } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { useBanUser } from "api/admin/hooks/ban-user";
import { useGetActiveListing } from "api/listings/hooks";

export const BanReasons = {
  AGENCY: "Agenție imobiliară",
  INAPPROPRIATE: "Conținut inadecvat",
  FAKE: "Cont fals",
  OTHER: "Altul",
};

export const useBanUserFromUsingTheApp = (props: { onSuccess?: () => void; onError?: () => void }) => {
  const { banUser, isLoading: isBanUserLoading } = useBanUser();
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const queryClient = useQueryClient();
  const { activeListing } = useGetActiveListing();

  const [bannedReason, setBannedReason] = useState<keyof typeof BanReasons>();
  const [bannedMessage, setBannedMessage] = useState<string>();
  const [bannedUserId, setBannedUserId] = useState<string>(activeListing?.ownerId || "");
  useEffect(() => {
    if (activeListing?.ownerId) {
      setBannedUserId(activeListing.ownerId);
    }
  }, [activeListing]);

  const handleBanUserTextFieldChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setBannedUserId(target.value);
  };

  const handleBanUserAdminComment = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setBannedMessage(target.value);
  };

  const handleBanUser = () => {
    if (!bannedUserId || !bannedReason) return;

    addLoadingEl("admin");
    queryClient.refetchQueries("bannedUsers");

    banUser(
      { userId: bannedUserId, reason: bannedReason, adminComment: bannedMessage },
      {
        onSuccess: () => {
          toast.success("User banat cu succes.");
          removeLoadingEl("admin");
          props.onSuccess?.();
        },
        onError: () => {
          toast.error("Nu am putut bana user-ul.");
          removeLoadingEl("admin");
          props.onError?.();
        },
      }
    );
  };

  const handleReasonSelection = (event: SelectChangeEvent<keyof typeof BanReasons>) => {
    const target = event.target as HTMLInputElement;
    const key = target.value as keyof typeof BanReasons;

    setBannedReason(key);
    setBannedMessage(key === "OTHER" ? "" : BanReasons[key]);
  };

  return {
    onUpdateReportedUserId: setBannedUserId,
    onBanUserTextFieldChange: handleBanUserTextFieldChange,
    onBanUserAdminComment: handleBanUserAdminComment,
    onBanUser: handleBanUser,
    onReasonSelection: handleReasonSelection,
    isBanUserLoading,
    bannedUserId,
    bannedReason,
    bannedMessage,
  };
};
