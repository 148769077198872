import React, { ComponentProps } from "react";

export const LocationIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width="50" height="69" viewBox="0 0 50 69" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 61.5037H50V68.2546H0L0 61.5037ZM25 17.6225C21.0714 17.6225 17.8571 20.6604 17.8571 24.3734C17.8571 28.0865 21.0714 31.1244 25 31.1244C26.8944 31.1244 28.7112 30.4131 30.0508 29.1471C31.3903 27.881 32.1429 26.1639 32.1429 24.3734C32.1429 22.583 31.3903 20.8659 30.0508 19.5998C28.7112 18.3338 26.8944 17.6225 25 17.6225ZM25 0.745117C36.6786 0.745117 50 9.04879 50 24.8798C50 35.4113 41.6786 46.5166 25 58.1282C8.32143 46.5166 0 35.4113 0 24.8798C0 9.04879 13.3214 0.745117 25 0.745117Z"
        fill="currentColor"
      />
    </svg>
  );
};
