import { useMutation } from "react-query";
import { UserApi } from "api/user/user-api";

interface MutationProps {
  token: string;
  code: string;
  phoneNumber: string;
}

export const useVerifyPhoneNumberCode = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    "verifyPhoneNumberCode",
    (mutationProps: MutationProps) => {
      const { code, token, phoneNumber } = mutationProps;

      return UserApi.verifyPhoneNumberCode(token, phoneNumber, code);
    }
  );

  return { verifyPhoneNumberCode: mutate, isLoading, isSuccess, isError };
};
