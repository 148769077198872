import Box from "@mui/material/Box";
import { Button, Stack, styled } from "@mui/material";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { formatRelative } from "date-fns";
import { theme } from "theme";
import { useApproveListing } from "api/admin/hooks/useApproveListing";
import { useRejectListing } from "api/admin/hooks/useRejectListing";
import { useAppSelector } from "common/hooks";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { ListingSummary } from "components/ListingSummary";
import { FullListingBEResponseType, convertBEListingToFrontendFormat } from "api/listings/hooks";
import { QueriesKeys } from "common/types/general";
import { createListingDetailsTitle } from "pages/ListingDetails/components/AddressAndCreateDate";

interface Props {
  listing: FullListingBEResponseType;
  index: number;
  length: number;
}

export const PendingListingCard = (props: Props) => {
  const { listing, index, length } = props;

  const { token } = useAppSelector((state) => state.authSlice);
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const queryClient = useQueryClient();

  const { mutate: approveListing } = useApproveListing(token);
  const { mutate: rejectListing } = useRejectListing(token);

  const listingObj = convertBEListingToFrontendFormat(listing);

  const handleApprove = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Ești sigur că vrei să aprobi acest anunț?")) {
      addLoadingEl("admin");
      approveListing(
        { listingId: listing.id },
        {
          onSuccess: () => {
            removeLoadingEl("admin");
            toast.success("Anunț aprobat");
            queryClient.refetchQueries("adminPendingListings" as QueriesKeys);
            queryClient.refetchQueries("basicListingsInCounty" as QueriesKeys);
            queryClient.refetchQueries("countiesMetadata" as QueriesKeys);
          },
          onError: () => {
            removeLoadingEl("admin");
            toast.error("Ceva nu a mers bine");
          },
        }
      );
    }
  };

  const handleReject = async () => {
    // eslint-disable-next-line no-alert
    const reason = prompt("Motivul respingerii:");

    if (!reason) {
      return;
    }

    addLoadingEl("admin");
    rejectListing(
      { listingId: listing.id, reason },
      {
        onSuccess: () => {
          removeLoadingEl("admin");
          toast.success("Anunț respins");
          queryClient.invalidateQueries("adminPendingListings" as QueriesKeys);
        },
        onError: () => {
          removeLoadingEl("admin");
          toast.error("Ceva nu a mers bine");
        },
      }
    );
  };

  return (
    <StyledCard>
      <span className="count">
        {index + 1} / {length}
      </span>
      <Stack width="100%" spacing={2}>
        <StyledDetails>
          <summary>
            {createListingDetailsTitle(listingObj)}
            <br />
            <span>{`Posted ${formatRelative(new Date(listing.createdAt), new Date())} `}</span>
          </summary>
          <Stack>
            <ListingSummary listingObj={listingObj} hideTitle adminView />
            <ImagesCards value={listingObj.images} />
            <ActionButtons
              onSubmit={handleApprove}
              onCancel={handleReject}
              labelSubmit="Aprobă"
              labelCancel="Respinge"
            />
          </Stack>
        </StyledDetails>
      </Stack>

      {/* <ListingSummary listingObj={listingObj} hideTitle adminView /> */}
      {/* <StaticMap lat={listing.coordinates.latitude} lng={listing.coordinates.longitude} zoom={10} /> */}
    </StyledCard>
  );
};

export const ActionButtons = (props: {
  onSubmit: () => void;
  onCancel: () => void;
  labelSubmit: string;
  labelCancel: string;
}) => (
  <Stack width="100%" spacing={2} justifyContent="center" alignItems="center" direction="row" my={5}>
    <Button variant="contained" color="error" onClick={props.onCancel}>
      {props.labelCancel}
    </Button>
    <Button variant="contained" color="primary" onClick={props.onSubmit}>
      {props.labelSubmit}
    </Button>
  </Stack>
);

export const StyledCard = styled(Box)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: 12px;
  box-sizing: border-box;
  border-radius: 12px 12px 0 0;

  .count {
    position: absolute;
    top: -20px;
    left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.grey[500]};
  }
`;

export const StyledDetails = styled("details")`
  padding: 0;

  summary {
    cursor: pointer;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
    // display: inline-flex;
    // justify-content: space-between;
    background-color: ${theme.palette.grey[100]};
    border-radius: 12px 12px 0 0;
    font-size: 16px;
    font-weight: 500;
    color: ${theme.palette.grey[800]};
    padding: 12px 24px 12px 12px;

    & span {
      font-size: 14px;
      color: ${theme.palette.grey[600]};
      margin-top: 8px;
      font-weight: 300;
    }

    &:hover {
      background-color: ${theme.palette.grey[200]};
    }
  }

  &[open] {
    summary {
      background-color: ${theme.palette.grey[400]};
    }
  }
`;

export const ImagesCards = ({ value }: { value: string[] }) => {
  return (
    <Box sx={{ display: "flex", gap: "10px", height: "200px", overflowY: "scroll" }} p={{ xs: 0, sm: "24px 48px" }}>
      {value.map((url) => (
        <img key={url} src={url} alt="Listing" style={{ width: "auto", height: "200px" }} />
      ))}
    </Box>
  );
};

// Not working because of Google Maps API key not being updated (some bug on Google's side)
// const StaticMap = ({ lat, lng, zoom }: { lat: number; lng: number; zoom: number }) => {
//   const src = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=600x300&maptype=roadmap
//   &markers=color:red%7Clabel:A%7C${lat},${lng}
//   &key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

//   return <img src={src} alt="Google Map" />;
// };
