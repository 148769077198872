import { useMutation } from "react-query";
import { AdminApi } from "../admin-api";

interface MutationProps {
  listingId: string;
  reason: string;
}

export const useRejectListing = (token: string | null) => {
  const {
    mutate: originalMutate,
    isLoading,
    isSuccess,
    isError,
  } = useMutation("adminRejectListing", (mutationProps: MutationProps) => {
    const { listingId, reason } = mutationProps;

    return AdminApi.rejectListing({
      listingId,
      token: token!,
      reason,
    });
  });

  const mutate = (mutationProps: MutationProps, ...rest: any) => {
    if (token) {
      originalMutate(mutationProps, ...rest);
    }
  };

  return { mutate, isLoading, isSuccess, isError };
};
