import { ab } from "./ab";
import { ag } from "./ag";
import { ar } from "./ar";
import { b } from "./b";
import { bc } from "./bc";
import { bh } from "./bh";
import { bn } from "./bn";
import { br } from "./br";
import { bt } from "./bt";
import { bv } from "./bv";
import { bz } from "./bz";
import { cj } from "./cj";
import { cl } from "./cl";
import { cs } from "./cs";
import { ct } from "./ct";
import { cv } from "./cv";
import { db } from "./db";
import { dj } from "./dj";
import { gj } from "./gj";
import { gl } from "./gl";
import { gr } from "./gr";
import { hd } from "./hd";
import { hr } from "./hr";
import { il } from "./il";
import { is } from "./is";
import { mh } from "./mh";
import { mm } from "./mm";
import { ms } from "./ms";
import { nt } from "./nt";
import { ot } from "./ot";
import { ph } from "./ph";
import { sb } from "./sb";
import { sj } from "./sj";
import { sm } from "./sm";
import { sv } from "./sv";
import { tl } from "./tl";
import { tm } from "./tm";
import { tr } from "./tr";
import { vl } from "./vl";
import { vn } from "./vn";
import { vs } from "./vs";

const centersInitial = {
  ro_romania: {
    id: "ro_romania",
    lat: 45.69404431014104,
    lng: 24.666355900467778,
    zoom: 7,
    countyId: "ro",
    name: "România",
    countyName: "Întreaga țară",
  },
  ...ab,
  ...ag,
  ...ar,
  ...b,
  ...bc,
  ...bh,
  ...bn,
  ...br,
  ...bt,
  ...bv,
  ...bz,
  ...cj,
  ...cl,
  ...cs,
  ...ct,
  ...cv,
  ...db,
  ...dj,
  ...gj,
  ...gl,
  ...gr,
  ...hd,
  ...hr,
  ...il,
  ...is,
  ...mh,
  ...mm,
  ...ms,
  ...nt,
  ...ot,
  ...ph,
  ...sb,
  ...sj,
  ...sm,
  ...sv,
  ...tl,
  ...tm,
  ...tr,
  ...vl,
  ...vn,
  ...vs,
};

export const DEFAULT_CITY = "ro_romania";

type TCenters = typeof centersInitial;

export const CENTERS: TCenters = centersInitial;

export const MAX = Number.MAX_SAFE_INTEGER;

export const SLIDER_DELAY_MS = 1000;
export const DEBOUNCE_DELAY = 1000;
