/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CENTERS } from "cities";
import { Views } from "common/hooks/useAppParams";
import { BoundsType, CoordsType, SortType } from "common/types/general";

type BBox = [number, number, number, number];

export type OpenedModal =
  | "validate-email"
  | "change-password"
  | "validate-phone"
  | "ban-user"
  | "report-listing"
  | "user-profile"
  | "authenticated-user-profile"
  | "delete-account"
  | "saved-searches"
  | "onboarding"
  | "campaign"
  | "add-listing-success"
  | "add-listing-failure"
  | "auth-success";

export type LoadingElement =
  | "app-initial"
  | "add-listing"
  | "add-dummy-listings"
  | "auth"
  | "user-favorites"
  | "favorites-anonymus"
  | "counties-metadata"
  | "active-listing"
  | "create-listing"
  | "user-favorite-ids"
  | "basic-listings-in-county"
  | "favorites-basic-listings-by-ids"
  | "basic-listings-of-auth-user"
  | "admin"
  | "listing-owner-phone"
  | "save-search";

export type ActiveMenuView = "settings" | "own-listings" | "favorites" | "add-listing" | "none";

export type TApp = {
  mapTypeId: "satellite" | "roadmap" | "terrain";
  mapColor: "dark" | "light";
  bounds: BoundsType | null;
  clustersBounds?: BBox;
  lastCoords?: CoordsType;
  lastUrl?: string;
  lastSeenId?: string;
  lastSeenView?: Views;
  loadingElements: LoadingElement[];
  sorting: SortType;
  imagesFullscreen: boolean;
  sidebarOpen: boolean;
  searchBoxOptionId: keyof typeof CENTERS | null;
  activeMenuView: ActiveMenuView;
  pixelPerLatPointRatioMobile: number;
  isActivatePhoneImplemented: boolean;
  openedModal: OpenedModal | null;
};

const initialState: TApp = {
  mapTypeId: "roadmap",
  mapColor: "light",
  bounds: null,
  lastUrl: "",
  loadingElements: ["app-initial"],
  sorting: "none",
  imagesFullscreen: false,
  sidebarOpen: true,
  searchBoxOptionId: null,
  activeMenuView: "none",
  pixelPerLatPointRatioMobile: 0.00001430358, // Bogdan's iPhone
  isActivatePhoneImplemented: false,
  openedModal: null,
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    // Seems that this is not needed for now, as closing a listing
    // detail window will return us to the initial location/zoom
    updateLastCoords: (state, action) => {
      const { lat, lng, zoom } = action.payload;
      state.lastCoords = { lat, lng, zoom };
    },
    updateBounds: (state, action) => {
      const bounds = action.payload;

      const clustersBounds = [
        bounds?.nw?.lng || 0,
        bounds?.se?.lat || 0,
        bounds?.se?.lng || 0,
        bounds?.nw?.lat || 0,
      ] as BBox;

      if (JSON.stringify(state.bounds) !== JSON.stringify(bounds)) {
        state.bounds = bounds;
        state.clustersBounds = clustersBounds;
      }
    },
    updateMapTypeId: (state, action) => {
      const { mapTypeId } = action.payload;
      state.mapTypeId = mapTypeId;
    },
    saveLastUrl: (state, action) => {
      const { lastUrl } = action.payload;
      state.lastUrl = lastUrl;
    },
    updateLastSeenId: (state, action) => {
      state.lastSeenId = action.payload;
    },
    updateLastSeenView: (state, action) => {
      state.lastSeenView = action.payload;
    },
    setLoadingElements: (
      state,
      {
        payload: { el, op },
      }: {
        payload: { el?: LoadingElement; op: "add" | "remove" | "clear" };
      }
    ) => {
      state.loadingElements =
        op === "clear" || !el
          ? []
          : op === "add"
          ? [...state.loadingElements, el]
          : state.loadingElements.filter((item) => item !== el);
    },
    updateSort: (state, { payload }) => {
      state.sorting = payload;
    },
    updateMapColor: (state, { payload }) => {
      state.mapColor = payload;
    },
    setOpenedModal: (state, { payload }: { payload: OpenedModal | null }) => {
      state.openedModal = payload;
    },
    setImagesFullscreen: (state, { payload }) => {
      state.imagesFullscreen = payload;
    },
    setSidebarOpen: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
    setSearchBoxOptionId: (state, { payload }) => {
      state.searchBoxOptionId = payload;
    },
    updateActiveMenuView: (state, { payload }: { payload: ActiveMenuView }) => {
      state.activeMenuView = payload;
    },
    updatePixelPerLatPointRatioMobile: (state, { payload }: { payload: number }) => {
      state.pixelPerLatPointRatioMobile = payload;
    },
  },
});

export const {
  setOpenedModal,
  updateBounds,
  updateMapTypeId,
  updateLastCoords,
  saveLastUrl,
  updateLastSeenId,
  updateLastSeenView,
  setLoadingElements,
  updateMapColor,
  updateSort,
  setImagesFullscreen,
  setSidebarOpen,
  setSearchBoxOptionId,
  updateActiveMenuView,
  updatePixelPerLatPointRatioMobile,
} = appSlice.actions;

export default appSlice.reducer;
