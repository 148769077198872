import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { ListingsApi } from "api/listings/listings-api";
import { useCreateListing } from "api/listings/hooks/useCreateListing";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { QueriesKeys } from "common/types/general";
import { setOpenedModal } from "redux/slices/appSlice";
import { uploadToS3 } from "./uploadToS3";
import { ImageFile } from "../add-listing-sections/sections-types";

export const useAddListingHandlers = (onWindowClose: () => void) => {
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const { mutate: createListing } = useCreateListing();
  const dispatch = useAppDispatch();

  const appSlice = useAppSelector(({ appSlice }) => appSlice);
  const authSlice = useAppSelector(({ authSlice }) => authSlice);
  const { token } = authSlice || {};
  const { loadingElements } = appSlice || {};

  const queryClient = useQueryClient();

  const [images, setImages] = useState<ImageFile[]>([]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      // TODO: Check if this is really working/needed
      images.forEach((file: ImageFile) => URL.revokeObjectURL(file.preview));
    };
  }, []);

  const handleSubmit = async () => {
    addLoadingEl("add-listing");

    const listingObj = localStorage.getItem("listingObj");

    // Should never happen
    if (!listingObj) {
      removeLoadingEl("add-listing");
      // toast.error("A apărut o eroare. Te rugăm să încerci din nou mai târziu.");
      dispatch(setOpenedModal("add-listing-failure"));
      return;
    }

    const listing = JSON.parse(listingObj);

    if (listing.countryId !== "ro") {
      removeLoadingEl("add-listing");
      toast.error(
        "Ne pare rău, momentan nu putem adăuga anunțuri din afara țării. Modifică adresa și încearcă din nou."
      );
      return;
    }

    // Get the presigned URLs from the server
    const {
      data: { data: imagesUploadURLs },
    } = await ListingsApi.getAWSUploadUrls({
      token: token || "",
      count: images.length,
      countyId: listing.countyId || "ro",
      listingId: listing.id,
    });

    // Upload the images to AWS // TODO: Not sure if the main image is always saved as the first one
    const filePaths = await uploadToS3(images as unknown as File[], imagesUploadURLs || []);

    // Make the request
    createListing(
      {
        listing: {
          ...listing,
          floor: listing.floor || 0,
          images: filePaths,
        },
      },
      {
        onSuccess: () => {
          removeLoadingEl("add-listing");
          dispatch(setOpenedModal("add-listing-success"));
          onWindowClose();
          queryClient.invalidateQueries("userDetails" as QueriesKeys);
          // We dont want to show the campaign modal anymore after the user has added a listing
          localStorage.setItem("showCampaignModal", "false");
        },
        onError: () => {
          removeLoadingEl("add-listing");
          dispatch(setOpenedModal("add-listing-failure"));
        },
      }
    );
  };

  return {
    images,
    loadingElements,
    onSubmitListing: handleSubmit,
    onSetImages: (newImages: ImageFile[]) => setImages(newImages),
  };
};
