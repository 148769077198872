import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ComponentProps } from "react";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ArticleIcon from "@mui/icons-material/Article";
import WidgetsIcon from "@mui/icons-material/Widgets";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useWindowDimensions } from "common/hooks";
import { QontoConnector, QontoStepIcon } from "./Connector";
import {
  BuildingTypeIcon,
  // PartitioningIcon,
  ContactIcon,
  FacilitiesIcon,
  GeneralIcon,
  ImagesIcon,
  LocationIcon,
} from "../utils/stepsIcons";
import { PriceIcon } from "../utils/stepsIcons/price";
import { StepperFieldsType, stepperFields, stepperFieldsKeys } from "../utils/addListingData";

type Props = {
  activeStep: number;
  currentPage: number;
  // Disabled for now. Might not want to use it all.
  // handleStepperClick: (currentPage: number) => void;
};

export const StepperComponent = ({ activeStep, currentPage /* handleStepperClick */ }: Props) => {
  const { isMobile } = useWindowDimensions();

  const activePageKey = stepperFieldsKeys[currentPage];

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
      {Object.values(stepperFields).map(({ label }, index) => (
        <Step
          key={label}
          completed={activeStep > index && currentPage !== index}
          active={currentPage === index}
          sx={{
            minWidth: isMobile ? "10px" : "70px",
            cursor: "default", // index <= activeStep ? "pointer" : "default",
          }}
          // onClick={() => handleStepperClick(index)}
        >
          <StepLabel
            sx={{
              "& .MuiStepLabel-label": {
                fontWeight: currentPage === index ? "normal" : "normal",
                color: currentPage === index ? "primary.main" : "text.secondary",
              },
              "& .MuiStepLabel-alternativeLabel": {
                marginTop: "5px !important",
              },
              whiteSpace: "nowrap",
            }}
            StepIconComponent={QontoStepIcon}
            StepIconProps={{
              icon: <CurrentStepIcon step={activePageKey as StepperFieldsType} width="24px" height="24px" />,
            }}
          >
            {isMobile ? "" : label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

interface CurrentStepIconProps extends ComponentProps<"svg"> {
  step: StepperFieldsType;
  // other props...
}

// Order doesn't matter here
const CurrentStepIcon = ({ step, ...props }: CurrentStepIconProps) => {
  if (step === "buildingType") {
    return <BuildingTypeIcon {...props} />;
  }
  if (step === "partitioning") {
    return <WidgetsIcon />;
  }
  if (step === "extra") {
    return <FacilitiesIcon {...props} />;
  }
  if (step === "general") {
    return <GeneralIcon {...props} />;
  }
  if (step === "description") {
    return <ArticleIcon />;
  }
  if (step === "location") {
    return <LocationIcon {...props} />;
  }
  if (step === "price") {
    return <PriceIcon {...props} />;
  }
  if (step === "contact") {
    return <ContactIcon {...props} />;
  }
  if (step === "images") {
    return <ImagesIcon {...props} />;
  }
  if (step === "summary") {
    return <SummarizeIcon />;
  }

  return <CheckBoxOutlineBlankIcon />;
};
