export const tr = {
  tr_teleorman: {
    id: "tr_teleorman",
    lat: 43.969317516686026,
    lng: 25.333962231870505,
    zoom: 10,
    countyId: "tr",
    name: "Alexandria",
    countyName: "Teleorman",
  },
};
