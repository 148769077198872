import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { useAskResendValidateEmail, useTimeoutDelay } from "api/user/hooks";
import { WAITING_TIME } from "utils/constants";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";

export const AskResendValidateEmailModal = () => {
  const { openedModal } = useAppSelector((state) => state.appSlice);
  const { user } = useAppSelector((state) => state.authSlice);
  const dispatch = useAppDispatch();
  const { timeout, setTimeoutState } = useTimeoutDelay();
  const { mutate: resendEmailVerification } = useAskResendValidateEmail();
  const queryClient = useQueryClient();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // If user is already validated, close the modal
    if (user?.hasVerifiedEmail) {
      dispatch(setOpenedModal(null));
      toast.success("Emailul a fost deja validat.");
    }
  }, [user]);

  const handleSendEmail = () => {
    if (timeout > 0) {
      toast.info(`Poți trimite un email peste ${timeout} secunde.`);
      return;
    }

    resendEmailVerification(undefined, {
      onSuccess: () => {
        setTimeoutState(WAITING_TIME);
        toast.success(
          "Vei primi un email cu un link de validare pe adresa de email asociată contului tău. Te rugăm să urmezi pașii de acolo."
        );
        setSuccess(true);
        setError(false);
        // setTimeout(() => dispatch(setValidateEmail(false)), 1000);
      },
      onError: () => {
        toast.error("Emailul nu a putut fi trimis. Te rugăm să încerci din nou mai târziu.");
        setError(true);
      },
    });
  };

  const handleCloseModal = () => {
    queryClient.refetchQueries("userDetails");
    dispatch(setOpenedModal(null));
  };

  return success ? (
    <ConfirmationDialog
      title="Succes!"
      variant="success"
      labelSecondary="Trimite din nou"
      labelPrimary="Am validat emailul"
      open={openedModal === "validate-email"}
      onCancel={handleCloseModal}
      onPrimary={handleCloseModal}
      onSecondary={handleSendEmail}
    >
      Email trimis cu succes! După validare, apasă pe butonul de mai jos sau{" "}
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          color: "blue",
          fontSize: "inherit",
          cursor: "pointer",
          margin: 0,
          padding: 0,
        }}
        type="button"
        onClick={() => window.location.reload()}
      >
        click aici
      </button>{" "}
      pentru a reîncărca pagina.
    </ConfirmationDialog>
  ) : (
    <ConfirmationDialog
      title="Ai nevoie de o adresă de email validă"
      variant="info"
      labelPrimary="Trimite email de validare"
      labelSecondary="Renunță"
      disabledPrimary={error || success}
      open={openedModal === "validate-email"}
      onCancel={handleCloseModal}
      onPrimary={handleSendEmail}
      onSecondary={handleCloseModal}
    >
      Apasă pe butonul de mai jos pentru a primi un email cu un link de validare pe adresa de email asociată contului
      tău.
    </ConfirmationDialog>
  );
};
