import { useEffect } from "react";
import { getLocalStorageObj } from "utils/helpers";
// import { BuildingTypeFields } from "./addListingData";

export type UserInput = Record<
  string,
  { value: string; error?: boolean; touched?: boolean; required?: boolean; skip?: boolean }
>;

export const updateUserInputFromLocalStorage = <T extends UserInput>({ fields }: { fields: T }) => {
  const localStorageObj = getLocalStorageObj("listingObj");

  if (!localStorageObj) {
    return fields;
  }

  // Loop through fields of userInput and take value from localStorage if it exists
  const newInput = Object.entries(fields).reduce((acc, [key, value]) => {
    const newValue = localStorageObj?.[key] || value.value;

    const skip = getSkippedFields(localStorageObj).includes(key);

    if (skip) {
      // If skip is true, then delete the field from local storage
      delete localStorageObj[key];
      localStorage.setItem("listingObj", JSON.stringify(localStorageObj));
    }

    return {
      ...acc,
      [key]: {
        ...value,
        value: newValue,
        touched: newValue.length > 0 || skip,
        skip,
      },
    };
  }, {} as typeof fields);

  return newInput;
};

// This is the place where we decide which fields to skip based on the user input
const getSkippedFields = (localStorageObj: Record<string, string>) => {
  const isFullHouse = localStorageObj.house === "true";
  const skippedFields = [];

  if (isFullHouse) {
    skippedFields.push("floor");
  }

  return skippedFields;
};

// Use this one directly where there are not going to be another useEffect call made (e.g. BuildingType component)
export const updateLocalStorageWithUserInput = <T extends UserInput>({ userInput }: { userInput: T }) => {
  const localStorageObj = getLocalStorageObj("listingObj") || {};
  const newEntries = Object.entries(userInput).reduce((acc, [key, { value, error, skip }]) => {
    // If a field is skipped, then don't update the local storage with it
    return skip
      ? acc
      : {
          ...acc,
          [key]: error ? "" : value,
        };
  }, {});
  localStorage.setItem("listingObj", JSON.stringify({ ...localStorageObj, ...newEntries }));
};

export const useUpdateLocalStorageWithUserInput = <T extends UserInput>({ userInput }: { userInput: T }) => {
  useEffect(() => {
    updateLocalStorageWithUserInput({ userInput });
  }, [userInput]);
};

export const useValidateUserInput = <T extends UserInput>({
  userInput,
  onSetIsValid,
}: {
  userInput: T;
  onSetIsValid: (isValid: boolean) => void;
}) => {
  useEffect(() => {
    const isValid = Object.values(userInput).every(({ error, required, touched, value, skip }) => {
      return !error && (required && !skip ? touched && String(value).length > 0 : true);
    });

    onSetIsValid(isValid);
  }, [userInput]);
};
