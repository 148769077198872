import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "theme";
import { useBasePathUrl } from "common/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { useAnalyticsEventTracker } from "common/hooks/useAnalyticsEventTracker";
import { AuthPages } from "redux/slices/authSlice";
import { useAuthModal } from "common/hooks/useAuthModal";
import { LoginComponent } from "./components/Login";
import { RegisterComponent } from "./components/Register";
import { PasswordRecoverComponent } from "./components/PasswordRecover";
import { PasswordResetComponent } from "./components/PasswordReset";

interface Props {
  setOpen: (isOpen: boolean) => void;
}

export const AuthModal = ({ setOpen }: Props) => {
  const { goToBasePath } = useBasePathUrl();

  const { currentAuthModalPage, onUpdateAuthModalPage } = useAuthModal();

  const { isPasswordResetMode, isAddListingPage } = useAppParams();

  const gaEventTracker = useAnalyticsEventTracker("Auth Modal");
  useEffect(() => {
    gaEventTracker("Accessed Auth Modal");
  }, []);

  // Auto open modal if user is in reset password mode
  useEffect(() => {
    if (isPasswordResetMode) {
      onUpdateAuthModalPage("password-reset");
      setOpen(true);
    }
  }, [isPasswordResetMode]);

  // Toggle between login, register and forgot password
  const handlePageChange = (event: any) => {
    const eventType = event.target.id as AuthPages;
    onUpdateAuthModalPage(eventType);
  };

  const handleCloseModal = () => {
    onUpdateAuthModalPage("login");

    // If on Add Listing page, skip redirect
    if (isAddListingPage && currentAuthModalPage === "register") {
      return;
    }

    // If on Add Listing page, skip redirect
    if (isAddListingPage && currentAuthModalPage === "login") {
      setOpen(false);
      return;
    }

    setOpen(false);
    goToBasePath({ replace: true });
  };

  return (
    <Modal
      open
      onClose={() => handleCloseModal()}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        position: "absolute",
        overflow: "scroll",
        height: "100%",
        display: "block",
        maxHeight: "100%",
      }}
    >
      <StyledBox>
        <CloseIcon
          sx={(theme) => ({
            position: "absolute",
            right: "25px",
            top: "25px",
            width: "30px",
            height: "30px",
            cursor: "pointer",
            color: theme.palette.grey[500],
          })}
          onClick={() => handleCloseModal()}
        />

        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                mb: 4,
              }}
            >
              {currentAuthModalPage === "login"
                ? "Autentificare"
                : currentAuthModalPage === "register"
                ? "Înregistrare"
                : currentAuthModalPage === "password-recover"
                ? "Recuperare parolă"
                : "Resetare parolă"}
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              {currentAuthModalPage === "register" && <RegisterComponent onCloseModal={handleCloseModal} />}
              {currentAuthModalPage === "login" && <LoginComponent onCloseModal={handleCloseModal} />}
              {currentAuthModalPage === "password-recover" && (
                <PasswordRecoverComponent onCloseModal={handleCloseModal} />
              )}
              {currentAuthModalPage === "password-reset" && <PasswordResetComponent onCloseModal={handleCloseModal} />}
              <Grid container>
                <Grid item xs>
                  {currentAuthModalPage !== "password-recover" && (
                    <StyledButton disableRipple id="password-recover" variant="text" onClick={handlePageChange}>
                      Ai uitat parola?
                    </StyledButton>
                  )}
                </Grid>
                <Grid item>
                  <StyledButton
                    disableRipple
                    id={currentAuthModalPage === "login" ? "register" : "login"}
                    variant="text"
                    onClick={handlePageChange}
                  >
                    {currentAuthModalPage === "login"
                      ? "Nu ai cont? Creează unul aici"
                      : "Ai cont? Autentifică-te aici"}
                  </StyledButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* {(currentPage === "login" || currentPage === "register") && (
            <SocialAuth
              onSocialAuth={(res: any) => handleLogin(res, true)}
              hasAcceptedTerms={userInput.acceptPoC && userInput.acceptTerms}
            />
          )} */}
          {currentAuthModalPage !== "password-reset" && <Copyright />}
        </Container>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: ${theme.palette.background.paper};
  border-radius: 12px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.12);
  padding: 24px;
  overflow-y: scroll;

  main {
    min-width: 450px;

    > div > form {
      width: 100%;
    }
  }

  // Fix for small screens
  ${theme.breakpoints.down(720)} {
    top: 20px;
    transform: translate(-50%, 0);
  }

  .checkbox-container {
    margin: 5px 0 0;

    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0;

      > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > span {
          padding: 0 0.5ch 0 0;
        }

        .checkbox-link {
          color: ${theme.palette.primary.main};
          text-decoration: none;
          line-height: 16px;
          display: inline-flex;
          align-items: center;
          gap: 0.5ch;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  ${theme.breakpoints.down("sm")} {
    top: 0;
    bottom: 0;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;
    padding: 16px 4px;

    main {
      min-width: 250px;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
  text-transform: none;
  font-size: 13px;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const Copyright = () => (
  <Typography
    variant="body2"
    color="text.secondary"
    align="center"
    sx={{
      mt: 6,
      mb: 2,
    }}
  >
    Pentru a adăuga un anunț, trebuie să te autentifici. <br /> Dacă nu ai un cont deja, poți crea unul <b>gratuit</b>.
  </Typography>
);
