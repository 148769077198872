import { useMutation, useQueryClient } from "react-query";
import { useAppSelector } from "common/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { MAX_FILTER_VALUE } from "utils/constants";
import { ListingsApi } from "../../listings-api";
import { SavedSearchBEResponseType } from "../helpers/process-saved-search";
import { priceOptionsMap, surfaceOptionsMap } from "../helpers/process-basic-listings-utils";

export const useSaveSearch = () => {
  const { token = "" } = useAppSelector(({ authSlice }) => authSlice);
  const { countyId } = useAppParams();
  const { bounds } = useAppSelector(({ appSlice }) => appSlice);
  const { filters } = useAppSelector(({ filtersSlice }) => filtersSlice);
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, isError } = useMutation("saveSearchFilters", () => {
    const { buildingType, extra, floors, partitioning, price, rooms, surface } = filters;

    queryClient.invalidateQueries("getSavedSearches");

    const [priceMin] = price.length > 0 ? (priceOptionsMap[price[0]].value as [number, number]) : [0];
    const [, priceMax] =
      price.length > 0 ? (priceOptionsMap[price[price.length - 1]].value as [number, number]) : [0, MAX_FILTER_VALUE];

    const [surfaceMin] = surface.length > 0 ? (surfaceOptionsMap[surface[0]].value as [number, number]) : [0];

    const savedSearchObj: SavedSearchBEResponseType = {
      notifications_enabled: true,
      city_code: countyId,
      county_code: countyId,
      nw_lat: bounds?.nw.lat || 0,
      nw_lng: bounds?.nw.lng || 0,
      se_lat: bounds?.se.lat || 0,
      se_lng: bounds?.se.lng || 0,
      min_price: priceMin || 0,
      max_price: Number.isNaN(priceMax) || priceMax === Infinity ? MAX_FILTER_VALUE : priceMax,
      min_surface: surfaceMin,
      building_type_filters: buildingType.length > 0 ? buildingType : ["block", "house", "villa"],
      partitioning_type_filters:
        partitioning.length > 0 ? partitioning : ["detached", "semidetached", "ordered", "studio"],
      floor_filters: floors.length > 0 ? floors : ["semi_basement"],
      room_filters: rooms.length > 0 ? rooms : ["one", "two", "three", "four", "fiver_or_more"],
      has_air_conditioning: extra.includes("hasAirConditioning"),
      has_elevator: extra.includes("hasElevator"),
      has_balcony: extra.includes("hasBalcony"),
      has_terrace: extra.includes("hasTerrace"),
      has_garden: extra.includes("hasGarden"),
      has_cable_tv: extra.includes("hasCableTv"),
      has_clothes_dryer: extra.includes("hasClothesDryer"),
      has_dishwasher: extra.includes("hasDishwasher"),
      has_dryer: extra.includes("hasDryer"),
      has_fireplace: extra.includes("hasFireplace"),
      has_fridge: extra.includes("hasFridge"),
      has_furniture: extra.includes("hasFurniture"),
      has_garage: extra.includes("hasGarage"),
      has_heating: extra.includes("hasHeating"),
      has_internet: extra.includes("hasInternet"),
      has_oven: extra.includes("hasOven"),
      has_pool: extra.includes("hasPool"),
      has_private_parking: extra.includes("hasPrivateParking"),
      has_security_system: extra.includes("hasSecuritySystem"),
      has_storage: extra.includes("hasStorage"),
      has_street_parking: extra.includes("hasStreetParking"),
      has_tv: extra.includes("hasTv"),
      has_video_surveillance: extra.includes("hasVideoSurveillance"),
      has_washer: extra.includes("hasWasher"),
    };

    return ListingsApi.saveSearch(savedSearchObj, token!);
  });

  return { saveSearch: mutate, isLoading, isSuccess, isError };
};
