import { useMutation } from "react-query";
import { UserApi } from "api/user/user-api";
import { useAppSelector } from "common/hooks";

export const useAskResendValidateEmail = () => {
  const { user } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation("askResendValidateEmail", () => {
    if (!user) {
      return Promise.reject();
    }

    return UserApi.askResendValidateEmail(user.token, user.id);
  });

  return { mutate, isLoading, isSuccess, isError };
};
