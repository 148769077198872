export const bt = {
  bt_bistrita: {
    id: "bt_bistrita",
    lat: 47.74810877695819,
    lng: 26.66343302602745,
    zoom: 10,
    countyId: "bt",
    name: "Botoșani",
    countyName: "Botoșani",
  },
};
