import { useMutation } from "react-query";
import { UserApi } from "api/user/user-api";
import { useAppSelector } from "common/hooks";

export const useDeleteAccount = () => {
  const { token } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation("deleteAccount", () => {
    if (!token) {
      return Promise.reject();
    }

    return UserApi.deleteAccount(token);
  });

  return { deleteAccount: mutate, isLoading, isSuccess, isError };
};
