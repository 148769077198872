import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { PageWrapper } from "components/PageWrapper";
import { useBasePathUrl } from "common/hooks";
import { useContactUs } from "api/user/hooks/useContactUs";
import { useAnalyticsEventTracker } from "common/hooks/useAnalyticsEventTracker";
import { NEUTRAL_RED } from "utils/constants";

interface FormData {
  name: string;
  email: string;
  message: string;
}

export const Contact = () => {
  const { mutate: contactUs } = useContactUs();

  const { goToBasePath } = useBasePathUrl();

  const gaEventTracker = useAnalyticsEventTracker("Contact");
  useEffect(() => {
    gaEventTracker("Accessed Contact Page");
  }, []);

  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    } as FormData);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { name, email, message } = formData;

    contactUs(
      { name, email, message },
      {
        onSuccess: () => {
          toast.success("Mesajul a fost trimis cu succes!");
          goToBasePath({});
        },
        onError: () => {
          toast.error("Eroare la trimiterea mesajului. Dacă eroarea persistă, ne poți contacta la dev@kastel.io");
          setErrorMessage("Ceva a mers prost. Te rugăm să încerci din nou.");
        },
      }
    );
  };
  return (
    <PageWrapper title="Contact">
      <span>
        Trimite un mesaj prin intermediul formularului de mai jos sau scrie-ne la{" "}
        <a href="mailto:dev@kastel.io">dev@kastel.io</a>
      </span>
      <form onSubmit={handleSubmit}>
        <TextField
          size="small"
          required
          label="Nume"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          size="small"
          required
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          size="small"
          required
          label="Mesajul tău"
          name="message"
          value={formData.message}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ marginTop: "20px", alignSelf: "center" }}
          disabled={formData.email.length === 0 || formData.message.length === 0 || formData.name.length === 0}
        >
          Trimite mesaj
        </Button>
        {errorMessage && (
          <Box>
            <Typography variant="body1" style={{ color: NEUTRAL_RED, marginTop: "10px" }}>
              {errorMessage}
            </Typography>
          </Box>
        )}
      </form>
    </PageWrapper>
  );
};
