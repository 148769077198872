import { Typography, styled } from "@mui/material";
import { theme } from "theme";

interface Props {
  title: string;
  subtitle: string;
}

export const AddListingTitle = (props: Props) => {
  const { title, subtitle } = props;

  return (
    <StyledTitle>
      <Typography
        variant="h5"
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          fontWeight: 600,

          [theme.breakpoints.down("md")]: {
            fontSize: "22px",
          },
        }}
      >
        {title}
      </Typography>
      <p className="subtitle">{subtitle}</p>
    </StyledTitle>
  );
};

const StyledTitle = styled("div")`
  color: ${theme.palette.grey[800]};

  ${theme.breakpoints.down("md")} {
  }

  .subtitle {
    max-width: 400px; // This affects the width of the elements below too (e.g. Phone Input field)
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    color: ${theme.palette.grey[700]};

    ${theme.breakpoints.down("md")} {
      font-size: 14px;
    }
  }
`;
