import { ReactElement } from "react";
import { Button, styled } from "@mui/material";
import Badge from "@mui/material/Badge";
import CheckIcon from "@mui/icons-material/Check";

export const StyledButton = styled(Button)`
  font-size: 0.8rem;
  text-transform: none;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: inherit;
`;

export const StyledUserActions = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: "3px 10px",
  "& span": {
    fontSize: "12px",
    padding: "0 5px",
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
    },
  },
}));

export const AuthWrapper = ({ isAuth, children }: { isAuth: boolean; children: ReactElement }) => {
  return isAuth ? (
    <Badge
      badgeContent={<CheckIcon sx={{ fontSize: "12px", color: "green" }} />}
      sx={{ "& .MuiBadge-badge": { color: "green" } }}
    >
      {children}
    </Badge>
  ) : (
    children
  );
};
