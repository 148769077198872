import { PHONE_PREFIX_RO } from "utils/constants";
import {
  emptyValidator,
  validateConfirmPassword,
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
} from "../../../utils/validators";

export type AuthFieldsTypes = keyof typeof authFields;
export type AuthFields = ReturnType<typeof getSpecificAuthFields>;

export const getSpecificAuthFields = (fields: AuthFieldsTypes[]) => {
  return fields.reduce((acc, field) => {
    acc[field] = authFields[field];
    return acc;
  }, {} as typeof authFields);
};

export const authFields = {
  firstName: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
    validator: validateName,
  },
  lastName: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
    validator: validateName,
  },
  email: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
    validator: validateEmail,
  },
  phone: {
    value: PHONE_PREFIX_RO,
    error: false,
    errorMessage: "Numărul de telefon în formatul +xx 0xxx xxx xxx (ex. +40 0722 333 444)",
    touched: false,
    validator: validatePhone,
  },
  loginPassword: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
    validator: emptyValidator,
  },
  password: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
    validator: validatePassword,
  },
  confirmPassword: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
    validator: validateConfirmPassword,
  },
};
