import { useQuery } from "react-query";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { useAppParams } from "common/hooks/useAppParams";
import { QueriesKeys } from "common/types/general";
import { ListingsApi } from "../listings-api";
import { LIMITED_CACHING } from "../../utils";
import { useHandleApiError } from "../../useHandleErrors";

export const useGetCountiesMetadata = () => {
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const { countyId: countryCode, isCountryViewMode } = useAppParams();

  // TODO: Pass filters too in the dependency array, so that the query is refetched on filter change
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["countiesMetadata" as QueriesKeys, countryCode],
    async () => {
      addLoadingEl("counties-metadata");

      const res = await ListingsApi.getCountiesMetadata({ countyId: countryCode! });

      removeLoadingEl("counties-metadata");

      return res.data.data.map((county) => ({
        ...county,
        id: county.code,
      }));
    },
    {
      ...LIMITED_CACHING,
      enabled: isCountryViewMode,
    }
  );

  // Handle errors
  useHandleApiError({
    isError,
    message: "Nu s-au putut încărca datele despre județe. Reîncarcă pagina și încearcă din nou.",
  });

  return { data, isLoading, isSuccess, isError };
};
