import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemText, styled } from "@mui/material";
import clsx from "clsx";
import { Currency } from "common/types/listing";
import { TICK_SYMBOL } from "utils/constants";

interface Props {
  anchorElNav: any;
  selectedItem: Currency;
  onCancel: () => void;
  onSelectNewType: (type: Currency) => void;
}

export const CurrencyDropdown = (props: Props) => {
  const { anchorElNav, selectedItem, onCancel, onSelectNewType } = props;

  return (
    <Menu
      id="listing-type-menu"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(anchorElNav)}
      onClose={onCancel}
      sx={{
        display: {
          xs: "block",
        },
      }}
    >
      <StyledMenuItem
        className={clsx("menu-item", {
          selectedItem: selectedItem === "eur",
        })}
        onClick={() => onSelectNewType("eur")}
        tabIndex={0}
      >
        <ListItemText className="item-text">💶 EUR</ListItemText>
      </StyledMenuItem>
      <StyledMenuItem
        className={clsx("menu-item", {
          selectedItem: selectedItem === "ron",
        })}
        onClick={() => onSelectNewType("ron")}
        tabIndex={0}
        disabled
      >
        <ListItemText className="item-text feature-tbd">🇷🇴 RON</ListItemText>
      </StyledMenuItem>
      <StyledMenuItem
        className={clsx("menu-item", {
          selectedItem: selectedItem === "usd",
        })}
        onClick={() => onSelectNewType("usd")}
        tabIndex={0}
        disabled
      >
        <ListItemText className="item-text  feature-tbd">🇺🇸 USD</ListItemText>
      </StyledMenuItem>
    </Menu>
  );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&.menu-item": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "160px",

      "& .item-text": {
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginLeft: "8px",
        display: "flex",
        alignItems: "center",
      },
    },
  },

  // Selected item before add tick icon in front of text
  "&.selectedItem": {
    "::before": {
      content: `"${TICK_SYMBOL}"`,
      display: "inline-block",
      color: theme.palette.primary.main,
      position: "absolute",
      left: "10px",
    },
  },
}));
