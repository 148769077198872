import { Button, Dialog, IconButton, styled } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";
import clsx from "clsx";
import { theme } from "theme";

interface Props {
  labelPrimary?: string;
  labelSecondary?: string;
  disabledPrimary?: boolean;
  dontCloseOnOutsideClick?: boolean;
  children: ReactNode;
  title: string;
  open: boolean;
  variant: "info" | "warning" | "success" | "error";
  onCancel: () => void;
  onPrimary: () => void;
  onSecondary: () => void;
}

export const ConfirmationDialog = (props: Props) => {
  const {
    labelPrimary,
    labelSecondary,
    disabledPrimary = false,
    dontCloseOnOutsideClick = false,
    children,
    title,
    open,
    variant,
    onCancel,
    onPrimary,
    onSecondary,
  } = props;

  const isConfirmationOnly = !labelSecondary || !labelPrimary;

  return (
    <StyledDialogWrapper
      open={open}
      onClose={dontCloseOnOutsideClick ? () => null : onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={clsx("confirmation-dialog", variant)}
    >
      <div className="top-section">
        <div className="top-section-title">
          {variant === "info" && <InfoIcon />}
          {variant === "success" && <CheckCircleIcon />}
          {variant === "warning" && <WarningIcon />}
          {variant === "error" && <WarningIcon />}
          <span id="alert-dialog-title">{title}</span>
        </div>
        <IconButton className="top-section-close-button" role="button" onClick={onCancel} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <div id="alert-dialog-description" className="description-section">
        {children}
      </div>
      <div className={clsx("buttons-section", { "buttons-section-confirmation-only": isConfirmationOnly })}>
        {labelSecondary && (
          <Button className="button-secondary" onClick={onSecondary} variant="text" size="large" autoFocus>
            {labelSecondary}
          </Button>
        )}
        {labelPrimary && (
          <Button
            className="button-primary"
            onClick={onPrimary}
            variant="contained"
            size="large"
            disabled={disabledPrimary}
          >
            {labelPrimary}
          </Button>
        )}
      </div>
    </StyledDialogWrapper>
  );
};

const StyledDialogWrapper = styled(Dialog)`
  & .MuiDialog-paper {
    width: 100%;
    max-width: 500px;
    padding: 25px 32px;
    min-width: 350px;
    overflow-y: auto;
  }

  & .top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    & .top-section-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      gap: 8px;

      > svg {
        width: 32px;
        height: 32px;
        padding: 0;
        margin: 0 0 0 -4px;
      }
    }
  }

  & .description-section {
    width: 100%;
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 140%;
  }

  & .buttons-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    margin-top: 16px;

    &.buttons-section-confirmation-only {
      justify-content: center;
    }

    & .button-secondary {
      color: ${theme.palette.grey[600]};
      font-weight: 500;

      &:hover {
        color: ${theme.palette.grey[800]};
      }
    }
  }

  &.info {
    & .top-section-title > svg {
      color: ${theme.palette.info.main};
    }
  }

  &.warning {
    & .top-section-title > svg {
      color: ${theme.palette.secondary.main};
    }
  }

  &.error {
    & .top-section-title > svg {
      color: ${theme.palette.error.main};
    }

    .button-primary {
      background-color: red;
      font-weight: 500;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  &.success {
    & .top-section-title > svg {
      color: green;
    }
  }

  ${theme.breakpoints.down("sm")} {
    & .MuiDialog-paper {
      padding: 25px 16px;
      min-width: unset;
    }
  }
`;
