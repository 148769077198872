import { useEffect, useMemo } from "react";
import { z } from "zod";
import { resetFiltersCount } from "redux/slices/filtersSlice";
import { BasicListingType } from "api/listings/listings-types";
import { useAppDispatch, useAppSelector } from "common/hooks";
import {
  filterByBuildingType,
  filterByExtras,
  filterByFloor,
  filterByPartitioning,
  filterByPrice,
  filterByRooms,
  filterBySurface,
  sortListings,
  updateCounts,
} from "./process-basic-listings-utils";

export const basicListingBEResponseSchema = z.array(
  z.object({
    building_type: z.union([z.literal("block"), z.literal("house"), z.literal("villa")]),
    county_id: z.string().optional(),
    county_code: z.string().optional(),
    floor: z.number().nullable().optional(),
    has_air_conditioning: z.boolean().optional(),
    has_elevator: z.boolean().optional(),
    has_balcony: z.boolean().optional(),
    has_terrace: z.boolean().optional(),
    has_garden: z.boolean().optional(),
    has_cable_tv: z.boolean().optional(),
    has_clothes_dryer: z.boolean().optional(),
    has_dishwasher: z.boolean().optional(),
    has_dryer: z.boolean().optional(),
    has_fireplace: z.boolean().optional(),
    has_fridge: z.boolean().optional(),
    has_furniture: z.boolean().optional(),
    has_garage: z.boolean().optional(),
    has_heating: z.boolean().optional(),
    has_internet: z.boolean().optional(),
    has_oven: z.boolean().optional(),
    has_pool: z.boolean().optional(),
    has_private_parking: z.boolean().optional(),
    has_security_system: z.boolean().optional(),
    has_storage: z.boolean().optional(),
    has_street_parking: z.boolean().optional(),
    has_tv: z.boolean().optional(),
    has_video_surveillance: z.boolean().optional(),
    has_washer: z.boolean().optional(),
    id: z.string(),
    image_urls: z.array(z.string()),
    lat: z.number(),
    lng: z.number(),
    partitioning_type: z.union([
      z.literal("detached"),
      z.literal("semidetached"),
      z.literal("ordered"),
      z.literal("studio"),
    ]),
    price: z.number(),
    rooms: z.number().optional(),
    number_of_rooms: z.number().optional(),
    surface: z.number(),
    total_floors: z.number(),
    is_approved: z.boolean().optional(),
  })
);

export type BasicListingBEResponseType = z.infer<typeof basicListingBEResponseSchema>[number];

export const useProcessBasicListings = (initialData: BasicListingBEResponseType[] | undefined) => {
  const data = useMemo(
    () => (initialData ? convertBEBasicListingsToFrontendFormat(initialData) : undefined),
    [initialData]
  );

  const dispatch = useAppDispatch();
  const appSlice = useAppSelector(({ appSlice }) => appSlice);
  const filtersSlice = useAppSelector(({ filtersSlice }) => filtersSlice);
  const { bounds, sorting } = appSlice;
  const { filters, filtersOptions } = filtersSlice;

  const filteredOutListingsSeenHiddenOrOutOfBoundaries = useMemo(() => {
    if (!bounds) return [];

    return data?.filter((listing) => {
      const lat = Number(listing.lat);
      const lng = Number(listing.lng);

      return lat >= bounds.sw.lat && lat <= bounds.ne.lat && lng >= bounds.sw.lng && lng <= bounds.ne.lng;
    });
  }, [bounds, data]);

  const filteredListings = useMemo(() => {
    if (!filteredOutListingsSeenHiddenOrOutOfBoundaries) return undefined;

    const filteredByBuildingType = filteredOutListingsSeenHiddenOrOutOfBoundaries.filter((listing) => {
      return (
        filterByBuildingType(listing, filters.buildingType) &&
        filterByPartitioning(listing, filters.partitioning) &&
        filterByPrice(listing, filters.price) &&
        filterByRooms(listing, filters.rooms) &&
        filterBySurface(listing, filters.surface) &&
        filterByFloor(listing, filters.floors) &&
        filterByExtras(listing, filters.extra)
      );
    });

    return filteredByBuildingType;
  }, [filteredOutListingsSeenHiddenOrOutOfBoundaries, filters]);

  const sortedListings = useMemo(() => {
    if (!filteredListings) return undefined;

    return sortListings(filteredListings || [], sorting);
  }, [filteredListings, sorting]);

  // Update the counts for each filter on every change of the listings
  useEffect(() => {
    if (!sortedListings) return;

    // First reset all counts
    dispatch(resetFiltersCount());

    // Then increment the counts for each filter in one loop
    sortedListings?.forEach((listing) => {
      // updateBuildingTypesCount(listing, filtersOptions, dispatch);
      updateCounts(listing, filtersOptions, dispatch);
    });
  }, [sortedListings]);

  return { processedListings: sortedListings };
};

// Identical to the one from useGetFavoritesBasicListings.ts ; need to find a solution to circular dependecy issue
export const convertBEBasicListingsToFrontendFormat = (listings: BasicListingBEResponseType[]): BasicListingType[] =>
  listings.map((listing) => ({
    buildingType: listing.building_type,
    partitioningType: listing.partitioning_type,
    id: listing.id,
    countyId: listing?.id || listing.county_code!,
    lat: listing.lat,
    lng: listing.lng,
    floor: String(listing.floor),
    price: String(listing.price),
    rooms: listing.rooms ? String(listing.rooms) : String(listing.number_of_rooms),
    surface: String(listing.surface),
    totalFloors: String(listing.total_floors),
    images: listing.image_urls || [],
    isApproved: listing.is_approved,
    hasAirConditioning: String(listing.has_air_conditioning),
    hasElevator: String(listing.has_elevator),
    hasBalcony: String(listing.has_balcony),
    hasTerrace: String(listing.has_terrace),
    hasGarden: String(listing.has_garden),
    hasCableTv: String(listing.has_cable_tv),
    hasClothesDryer: String(listing.has_clothes_dryer),
    hasDishwasher: String(listing.has_dishwasher),
    hasDryer: String(listing.has_dryer),
    hasFireplace: String(listing.has_fireplace),
    hasFridge: String(listing.has_fridge),
    hasFurniture: String(listing.has_furniture),
    hasGarage: String(listing.has_garage),
    hasHeating: String(listing.has_heating),
    hasInternet: String(listing.has_internet),
    hasOven: String(listing.has_oven),
    hasPool: String(listing.has_pool),
    hasPrivateParking: String(listing.has_private_parking),
    hasSecuritySystem: String(listing.has_security_system),
    hasStorage: String(listing.has_storage),
    hasStreetParking: String(listing.has_street_parking),
    hasTv: String(listing.has_tv),
    hasVideoSurveillance: String(listing.has_video_surveillance),
    hasWasher: String(listing.has_washer),
  }));
