import { useQuery } from "react-query";
import { LIMITED_CACHING, logZodError } from "api/utils";
import { QueriesKeys } from "common/types/general";
import { useHandleApiError } from "api/useHandleErrors";
import {
  convertBEPublicUserProfileToFrontendFormat,
  publicUserProfileBEResponseSchema,
} from "api/listings/hooks/helpers/process-public-user-profile";
import { UserApi } from "../../user-api";

// This hook is used to get user details from token
export const useGetPublicUserProfile = (userId: string | undefined) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["publicUserProfile" as QueriesKeys, userId],
    async () => {
      const res = await UserApi.getPublicUserProfile({ userId: userId! });

      const { data } = res.data;
      const parsed = publicUserProfileBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return convertBEPublicUserProfileToFrontendFormat(data);
      }

      const result = convertBEPublicUserProfileToFrontendFormat(parsed.data);

      return result;
    },
    {
      ...LIMITED_CACHING,
      enabled: !!userId,
    }
  );

  // Handle errors
  useHandleApiError({ isError, message: "Nu s-a putut obține profilul utilizatorului." });

  return { publicUserProfile: data, isLoading, isSuccess, isError };
};
