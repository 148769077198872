import { ChangeEvent, FocusEvent, useState } from "react";
import { MuiTelInputInfo } from "mui-tel-input";
import { PHONE_PREFIX_RO } from "utils/constants";
import { AuthFieldsTypes, authFields, getSpecificAuthFields } from "./auth-fields";
import { InputObject } from "./types";

const EMAIL_KEY = "email";
const PASSWORD_KEY = "password";
const CONFIRM_PASSWORD_KEY = "confirmPassword";
const PHONE_KEY = "phone";

export const useUpdateAuthInput = (fields: AuthFieldsTypes[], checkboxes?: Record<string, boolean>) => {
  const initialInput = getSpecificAuthFields(fields);
  const [userInput, setUserInput] = useState({ ...initialInput, ...checkboxes } as typeof authFields);

  // Helper function to update the input value
  const updateInputValue = (key: AuthFieldsTypes, inputObject: InputObject) => {
    const { value, error, errorMessage, touched = false } = inputObject;

    setUserInput((oldInput) => {
      return {
        ...oldInput,
        [key]: {
          value: key === EMAIL_KEY ? value.toLowerCase() : value,
          error: touched && error,
          errorMessage: touched ? errorMessage : "",
          touched,
        },
      };
    });
  };

  // Manage user typing in the input fields
  const handleUpdateInput = (
    key: AuthFieldsTypes,
    value: string,
    touched = false,
    error = false,
    errorMessage = ""
  ) => {
    if (key === PASSWORD_KEY && userInput.confirmPassword.touched) {
      // If the user has already typed in the confirm password field, we need to validate it
      if (value === userInput.confirmPassword.value) {
        updateInputValue(CONFIRM_PASSWORD_KEY, { value, error: false, errorMessage: "" });
        return;
      }

      updateInputValue(CONFIRM_PASSWORD_KEY, {
        value: userInput.confirmPassword.value,
        error: true,
        errorMessage: "Parolele nu coincid",
      });
    }

    updateInputValue(key, { value, touched, error, errorMessage });
  };

  // On focus out, we need to clean the input and update it
  const handleCleanAndUpdateInput = (id: AuthFieldsTypes, value: string) => {
    const key = id as AuthFieldsTypes;

    const { error, errorMessage } =
      key === CONFIRM_PASSWORD_KEY
        ? authFields[key].validator(value, userInput.password.value)
        : authFields[key].validator(value, true);

    if (key === PHONE_KEY) {
      const nationalNumber = value.replaceAll(" ", "").slice(-9);
      return handlePhoneChange(value || PHONE_PREFIX_RO, {
        nationalNumber,
      });
    }

    if (key === PASSWORD_KEY) {
      handleUpdateInput(PASSWORD_KEY, userInput.password.value, true, error, errorMessage);
      return error;
    }

    if (key === CONFIRM_PASSWORD_KEY) {
      handleUpdateInput(CONFIRM_PASSWORD_KEY, userInput.confirmPassword.value, true, error, errorMessage);
      return error;
    }

    handleUpdateInput(key, value.trim(), true, error, errorMessage);

    return error;
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event?.target || {};
    const key = id as AuthFieldsTypes;

    setUserInput((oldInput) => ({
      ...oldInput,
      [key]: checked,
    }));
  };

  const handlePhoneChange = (newPhone: string, options: Partial<MuiTelInputInfo>) => {
    const { error, errorMessage } = authFields.phone.validator(options.nationalNumber || "");
    // Both incoming variants are valid: +40 0712 345 678 & +40 712 345 678
    updateInputValue(PHONE_KEY, { value: newPhone, error, errorMessage, touched: true });

    return error;
  };

  return {
    userInput,
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => {
      handleUpdateInput(event.target.id as AuthFieldsTypes, event.target.value);
    },
    onFocusOut: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      handleCleanAndUpdateInput(event.target.id as AuthFieldsTypes, event.target.value);
    },
    onUpdateInput: handleUpdateInput,
    onCleanAndUpdateInput: handleCleanAndUpdateInput,
    onCheckboxChange: handleCheckboxChange,
    onPhoneChange: handlePhoneChange,
  };
};
