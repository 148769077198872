import { Tooltip, styled } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import clsx from "clsx";
import { useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useAppSelector, useBasePathUrl } from "common/hooks";
import { theme } from "theme";
import { NEUTRAL_RED } from "utils/constants";
import { FRVariants } from "common/types/listing";
import { useAddRemoveFavorites } from "utils/hooks/useAddRemoveFavorites";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useDeleteOwnListing } from "api/user/hooks";
import { QueriesKeys } from "common/types/general";

interface Props {
  listingId: string | undefined;
  countyId: string | undefined;
  variant: FRVariants;
}

export const ListingFavoriteAndEditButtons = (props: Props) => {
  const { listingId = "", countyId = "", variant } = props;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { user } = useAppSelector(({ authSlice }) => authSlice);
  const isOwnListing = !!user?.ownListingsIds?.includes(listingId);
  const queryClient = useQueryClient();

  const { favoritesListingsIdsMap } = useAppSelector(({ listingsSlice }) => listingsSlice);
  const { addRemoveFavorites } = useAddRemoveFavorites();
  const { mutate: removeOwnListing } = useDeleteOwnListing();
  const { goToBasePath } = useBasePathUrl();
  const isFavorite = favoritesListingsIdsMap[listingId];

  const handleAddRemoveFavorite = (event: any) => {
    event?.stopPropagation();
    addRemoveFavorites(listingId);
    event?.currentTarget?.blur();
  };

  const handleOpenDeleteDialog = (event: any) => {
    event?.stopPropagation();
    setShowDeleteDialog(true);
  };

  const handleRemoveOwnListing = () => {
    removeOwnListing(
      { listingId },
      {
        onSuccess: () => {
          toast.success("Anunțul a fost șters cu succes!");
          queryClient.invalidateQueries(["basicListingsInCounty" as QueriesKeys, countyId, true]);
          queryClient.invalidateQueries("userDetails" as QueriesKeys);
          queryClient.invalidateQueries("basicListingsOfAuthUser" as QueriesKeys);
          goToBasePath({});
          setShowDeleteDialog(false);
        },
        onError: () => {
          toast.error("A apărut o eroare. Te rugăm să încerci din nou.");
          setShowDeleteDialog(false);
        },
      }
    );
  };

  return isOwnListing ? (
    <>
      <Tooltip
        title="Funcționalitatea de editare este în curs de dezvoltare. Vă rugăm să ne contactați la dev@kastel.io dacă doriti să editați anunțul."
        enterTouchDelay={0}
      >
        <StyledFavoritesButton className={clsx("admin-button edit", variant)}>
          <EditIcon />
        </StyledFavoritesButton>
      </Tooltip>
      <StyledFavoritesButton className={clsx("admin-button", variant)} onClick={handleOpenDeleteDialog}>
        <DeleteIcon />
      </StyledFavoritesButton>
      <ConfirmationDialog
        title="Ești sigur că dorești să ștergi acest anunț?"
        labelPrimary="Da, șterge"
        labelSecondary="Nu, anulează"
        variant="error"
        open={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onSecondary={() => setShowDeleteDialog(false)}
        onPrimary={handleRemoveOwnListing}
      >
        Anunțul nu va mai putea fi recuperat după ce va fi șters.
      </ConfirmationDialog>
    </>
  ) : (
    <Tooltip
      title={isFavorite ? "Anunțul este în lista ta de favorite" : "Adaugă anunțul la favorite"}
      placement="left"
    >
      <StyledFavoritesButton className={clsx("favorite-button", variant)} onClick={handleAddRemoveFavorite}>
        <FavoriteIcon
          sx={{
            color: isFavorite ? NEUTRAL_RED : "lightgrey",
          }}
        />
      </StyledFavoritesButton>
    </Tooltip>
  );
};

const StyledFavoritesButton = styled("button")`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  background-color: transparent;
  border: none;

  &.admin-button {
    &.feed-card,
    &.marker-card {
      display: none;
    }

    &.edit {
      right: 65px;

      ${theme.breakpoints.down("md")} {
        right: 45px;
      }
    }

    > svg {
      color: ${theme.palette.grey[400]};
    }

    &:hover {
      > svg {
        color: ${theme.palette.grey[800]};
      }
    }
  }

  > svg {
    cursor: pointer;
    stroke: white;
    stroke-width: 2;

    &:hover {
      color: ${NEUTRAL_RED};
    }
  }

  &.feed-card {
    position: absolute;

    top: 8px;
    right: 4px;

    > svg {
      height: 42px;
      width: 42px;
    }
  }

  &.details-card {
    top: 22px;
    right: 18px;

    > svg {
      height: 42px;
      width: 42px;
    }
  }

  &.marker-card {
    top: 7px;
    right: -28px;

    > svg {
      height: 27px;
      width: 27px;
    }
  }

  ${theme.breakpoints.down("md")} {
    &.feed-card {
      top: 8px;
      right: 0px;

      > svg {
        height: 32px;
        width: 32px;
      }
    }

    &.details-card {
      top: 18px;
      right: 10px;

      > svg {
        height: 32px;
        width: 32px;
      }
    }
  }
`;
