import {
  emptyValidator,
  validateDate,
  validateEmail,
  validateName,
  validatePhone,
  validatePositiveNumber,
  validatePositiveNumberNoZeroPrefix,
  validateTotalFloors,
  validateYear,
} from "utils/validators";
import apartamentImg from "assets/buildingTypes/apartment.png";
import houseImg from "assets/buildingTypes/house.png";
import villaImg from "assets/buildingTypes/villa.png";
import decImg from "../../../assets/partitionings/decomandat.png";
import semidecImg from "../../../assets/partitionings/semidecomandat.png";
import studioImg from "../../../assets/partitionings/studio.png";
import vagonImg from "../../../assets/partitionings/vagon.png";

export const DEFAULT_TOTAL_FLOORS = "1";

export const buildingTypeFields = {
  block: {
    label: "Bloc de apartamente",
    value: "false",
    tooltipContent:
      "Un bloc de apartamente este o structură rezidențială mare care găzduiește mai multe locuințe, de obicei pe mai multe niveluri. Acestea pot oferi o varietate de facilități, cum ar fi accesul la transportul în comun și proximitatea față de magazine sau parcuri.",
    icon: apartamentImg,
  },
  house: {
    label: "Casă/Vilă individuală",
    value: "false",
    tooltipContent:
      "O casă sau vilă individuală este o structură rezidențială independentă, proiectată pentru a găzdui o singură familie. Acestea oferă mai multă intimitate și spațiu, incluzând de obicei un teren în jurul casei, ceea ce le face ideale pentru cei care doresc un mediu de viață mai liniștit și privat.",
    icon: houseImg,
  },
  villa: {
    label: "Duplex/Vilă multifamilială",
    value: "false",
    tooltipContent:
      "Un duplex este o structură rezidențială care găzduiește două locuințe separate, fie una deasupra celeilalte, fie una lângă alta. Aceasta oferă avantajele unei case individuale, dar cu costuri de întreținere împărțite, fiind o opțiune atractivă pentru cei care caută o combinație de intimitate și eficiență cost.",
    icon: villaImg,
  },
};

export const partitioningFields = {
  detached: {
    label: "Decomandat",
    value: "false",
    tooltipContent:
      "Un apartament decomandat are camere cu acces separat, fără a fi nevoie să treci prin altă cameră pentru a ajunge într-una anume. Oferă mai multă intimitate și flexibilitate în utilizare.",
    icon: decImg,
  },
  semidetached: {
    label: "Semidecomandat",
    value: "false",
    tooltipContent:
      "Un apartament semidecomandat combină caracteristicile unui apartament decomandat cu cele ale unui apartament nedecomandat. Unele camere au acces separat, în timp ce altele sunt interconectate.",
    icon: semidecImg,
  },
  ordered: {
    label: "Tip vagon",
    value: "false",
    tooltipContent:
      "Într-un apartament tip vagon, camerele sunt dispuse în linie, una după alta, ca într-un tren. Această configurație poate limita intimitatea și accesul la lumină naturală în unele camere.",
    icon: vagonImg,
  },
  studio: {
    label: "Tip studio",
    value: "false",
    tooltipContent:
      "Un apartament de tip studio are un singur spațiu de locuit principal care combină livingul, dormitorul și uneori și bucătăria. Este o opțiune eficientă din punct de vedere al spațiului, ideală pentru o persoană sau un cuplu.",
    icon: studioImg,
  },
};

export const generalFields = {
  rooms: {
    value: "",
    label: "Număr camere",
    placeholder: "",
    required: true,
    type: "number" as const,
    skip: false,
    validator: validatePositiveNumberNoZeroPrefix,
  },
  bathrooms: {
    value: "",
    label: "Număr băi",
    placeholder: "",
    required: true,
    type: "number" as const,
    skip: false,
    validator: validatePositiveNumber,
  },
  floor: {
    value: "",
    label: "Etaj",
    placeholder: "",
    required: true,
    type: "text" as const,
    validator: emptyValidator, // No need to validate select fields
    options: [
      { label: "Subsol", value: -2 },
      { label: "Demisol", value: -1 },
      { label: "Parter", value: 0 },
      { label: "Etaj 1", value: 1 },
      { label: "Etaj 2", value: 2 },
      { label: "Etaj 3", value: 3 },
      { label: "Etaj 4", value: 4 },
      { label: "Etaj 5", value: 5 },
      { label: "Etaj 6", value: 6 },
      { label: "Etaj 7", value: 7 },
      { label: "Etaj 8", value: 8 },
      { label: "Etaj 9", value: 9 },
      { label: "Etaj 10", value: 10 },
      { label: "Etaj 11", value: 11 },
      { label: "Etaj 12", value: 12 },
      { label: "Etaj 13", value: 13 },
      { label: "Etaj 14", value: 14 },
      { label: "Etaj 15", value: 15 },
      { label: "Etaj 16", value: 16 },
      { label: "Etaj 17", value: 17 },
      { label: "Etaj 18", value: 18 },
      { label: "Etaj 19", value: 19 },
      { label: "Etaj 20", value: 20 },
      { label: "Etaj 20+", value: 21 },
    ],
  },
  totalFloors: {
    value: DEFAULT_TOTAL_FLOORS,
    label: "Total etaje",
    placeholder: "",
    required: true,
    type: "number" as const,
    skip: false,
    validator: validateTotalFloors,
  },
  surface: {
    value: "",
    label: "Suprafața utilă în metri pătrați",
    placeholder: "",
    required: true,
    type: "number" as const,
    skip: false,
    validator: validatePositiveNumberNoZeroPrefix,
  },
  constructionYear: {
    value: "",
    label: "An construcție",
    placeholder: "",
    required: true,
    type: "number" as const,
    skip: false,
    validator: validateYear,
  },
};

export const descriptionFields = {
  description: {
    value: "",
    label: "Descriere (opțională)",
    placeholder: "Adaugă mai multe detalii despre proprietate",
    required: false,
    type: "text" as const,
    validator: emptyValidator,
  },
};

export const priceFields = {
  price: {
    value: "",
    label: "Chirie lunară (Euro)",
    placeholder: "",
    required: true,
    type: "number" as const,
    validator: validatePositiveNumberNoZeroPrefix,
  },
  deposit: {
    value: "0",
    label: "Garanție (Euro)",
    placeholder: "",
    required: false,
    type: "number" as const,
    validator: validatePositiveNumber,
  },
  availableFrom: {
    value: "",
    label: "Disponibil din",
    placeholder: "",
    required: true,
    type: "date" as const,
    validator: validateDate,
  },
};

export const contactFields = {
  ownerFirstName: {
    value: "",
    label: "Prenume",
    required: true,
    validator: validateName,
  },
  ownerLastName: {
    value: "",
    label: "Nume",
    required: true,
    validator: validateName,
  },
  ownerPhone: {
    value: "",
    label: "Telefon",
    required: true,
    error: false,
    errorMessage: "",
    validator: validatePhone,
  },
  ownerEmail: {
    // Not used in the AddListing form, but needed in other places
    value: "",
    label: "Email",
    required: true,
    validator: validateEmail,
  },
};

export const extraFields = {
  hasFurniture: {
    label: "Mobilat",
    value: "true",
    group: "main" as const,
  },
  hasFridge: {
    label: "Frigider",
    value: "true",
    group: "main" as const,
  },
  hasWasher: {
    label: "Mașină de spălat",
    value: "true",
    group: "main" as const,
  },
  hasTv: {
    label: "TV",
    value: "true",
    group: "main" as const,
  },
  hasCableTv: {
    label: "Cablu TV",
    value: "true",
    group: "main" as const,
  },
  hasInternet: {
    label: "Internet",
    value: "true",
    group: "main" as const,
  },
  hasAirConditioning: {
    label: "Aer condiționat",
    value: "false",
    group: "extra" as const,
  },
  hasOven: {
    label: "Cuptor",
    value: "false",
    group: "extra" as const,
  },
  hasDishwasher: {
    label: "Mașină de spălat vase",
    value: "false",
    group: "extra" as const,
  },
  hasDryer: {
    label: "Uscător de rufe",
    value: "false",
    group: "extra" as const,
  },
  hasHeating: {
    label: "Încălzire centrală proprie",
    value: "false",
    group: "extra" as const,
  },
  hasStreetParking: {
    label: "Parcare stradală",
    value: "false",
    group: "extra" as const,
  },
  hasPrivateParking: {
    label: "Parcare privată",
    value: "false",
    group: "extra" as const,
  },
  hasClothesDryer: {
    label: "Uscător de haine",
    value: "false",
    group: "extra" as const,
  },
  hasElevator: {
    label: "Lift",
    value: "false",
    group: "extra" as const,
  },
  hasBalcony: {
    label: "Balcon",
    value: "false",
    group: "extra" as const,
  },
  hasTerrace: {
    label: "Terasă",
    value: "false",
    group: "extra" as const,
  },
  hasStorage: {
    label: "Depozit sau boxă",
    value: "false",
    group: "extra" as const,
  },
  hasPool: {
    label: "Piscină",
    value: "false",
    group: "extra" as const,
  },
  hasGarden: {
    label: "Grădină",
    value: "false",
    group: "extra" as const,
  },
  hasGarage: {
    label: "Garaj",
    value: "false",
    group: "extra" as const,
  },
  hasFireplace: {
    label: "Șemineu",
    value: "false",
    group: "extra" as const,
  },
  hasSecuritySystem: {
    label: "Sistem de securitate",
    value: "false",
    group: "extra" as const,
  },
  hasVideoSurveillance: {
    label: "Supraveghere video",
    value: "false",
    group: "extra" as const,
  },
};

export const locationFields = {
  addressFull: { value: "" },
  lat: { value: "" },
  lng: { value: "" },
  streetNumber: { value: "" },
  cityName: { value: "" },
  streetName: { value: "" },
  countyId: { value: "" },
  countyName: { value: "" },
  countryId: { value: "" },
  countryName: { value: "" },
};
