import { Stack } from "@mui/material";
import { formatRelative } from "date-fns";
import { ListingSummary } from "components/ListingSummary";
import { FullListingBEResponseType, convertBEListingToFrontendFormat } from "api/listings/hooks";
import { FullReportedListingBEResponseType } from "api/admin/hooks/get-reported-listings";
import { ImagesCards, StyledCard, StyledDetails } from "./pending-listing-card";

interface Props {
  reportedListing: Omit<FullReportedListingBEResponseType, "listing">[number];
  listing: FullListingBEResponseType;
  index: number;
  length: number;
}

export const ReportedListingCard = (props: Props) => {
  const { listing, reportedListing, index, length } = props;

  const listingObj = convertBEListingToFrontendFormat(listing);

  // const handleApprove = () => {};

  // const handleReject = async () => {};

  return (
    <StyledCard>
      <span className="count">
        {index + 1} / {length}
      </span>
      <Stack width="100%" spacing={2}>
        <StyledDetails>
          <summary>
            {reportedListing.reason} - {reportedListing.message}
            <br />
            <span>{`Reported ${formatRelative(new Date(reportedListing.createdAt), new Date())} `}</span>
          </summary>
          <Stack p={4}>
            <Stack p="16px 8px">
              <strong>Reported by</strong>
              <br />
              <span>
                {reportedListing.user.first_name} {reportedListing.user.last_name}
              </span>
              <span>{reportedListing.user.email}</span>
              <span>{reportedListing.user.id}</span>
              <span>{reportedListing.user.phone_number}</span>
            </Stack>
            <ListingSummary listingObj={listingObj} hideTitle adminView />
            <ImagesCards value={listingObj.images} />
            {/* <ActionButtons onSubmit={handleApprove} onCancel={handleReject} labelCancel="-" labelSubmit="-" /> */}
          </Stack>
        </StyledDetails>
      </Stack>

      {/* <ListingSummary listingObj={listingObj} hideTitle adminView /> */}
      {/* <StaticMap lat={listing.coordinates.latitude} lng={listing.coordinates.longitude} zoom={10} /> */}
    </StyledCard>
  );
};
