import { useMutation } from "react-query";
import { UserApi } from "api/user/user-api";
import { useAppSelector } from "common/hooks";

interface MutationProps {
  firstName: string;
  lastName: string;
  imageUrl: string | null;
}

export const useUpdateUserName = () => {
  const { user, token } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation("updateUserName", (mutationProps: MutationProps) => {
    const { firstName, lastName, imageUrl } = mutationProps;

    if (!user) {
      return Promise.reject();
    }

    return UserApi.updateUserName(token || "", firstName, lastName, imageUrl);
  });

  return { updateUserName: mutate, isLoading, isSuccess, isError };
};
