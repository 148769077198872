import { toast } from "react-toastify";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { useTimeoutDelay } from "api/user/hooks";
import { WAITING_TIME } from "utils/constants";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useRequestPasswordReset } from "api/user/hooks/useRequestPasswordReset";

export const ChangePasswordRequestModal = () => {
  const { openedModal } = useAppSelector((state) => state.appSlice);
  const { user } = useAppSelector((state) => state.authSlice);
  const dispatch = useAppDispatch();
  const { timeout, setTimeoutState } = useTimeoutDelay();
  const { mutate: requestPasswordReset } = useRequestPasswordReset();

  const [sent, setSent] = useState(false);

  const handleChangePassword = () => {
    if (timeout > 0 || !user?.email) {
      toast.info(`Poți cere un email de resetare peste ${timeout} secunde.`);
      return;
    }

    setTimeoutState(WAITING_TIME);
    requestPasswordReset(
      { email: user.email },
      {
        onSuccess: () => {
          toast.success("Un email a fost trimis cu instrucțiuni pentru resetarea parolei.");
          setSent(true);
        },
        onError: () => {
          toast.error("A apărut o eroare. Încearcă mai târziu.");
        },
      }
    );
  };

  const handleCloseModal = () => {
    dispatch(setOpenedModal("authenticated-user-profile"));
  };

  return sent ? (
    <ConfirmationDialog
      title="Succes!"
      labelSecondary="Închide"
      open={openedModal === "change-password"}
      onCancel={handleCloseModal}
      onPrimary={handleChangePassword}
      onSecondary={handleCloseModal}
      variant="info"
    >
      Am trimis un email cu instrucțiuni pentru resetarea parolei pe adresa asociată contului tău. Te rugăm să verifici
      și în folderul de spam.
    </ConfirmationDialog>
  ) : (
    <ConfirmationDialog
      title="Vrei să îți schimbi parola?"
      labelPrimary="Trimite email de resetare"
      labelSecondary="Renunță"
      open={openedModal === "change-password"}
      onCancel={handleCloseModal}
      onPrimary={handleChangePassword}
      onSecondary={handleCloseModal}
      variant="info"
    >
      Dacă dorești să schimbi parola, te rugăm să apeși pe butonul de mai jos. Vei primi un email cu instrucțiuni pe
      adresa asociată contului tău.
    </ConfirmationDialog>
  );
};
