import { Button, styled } from "@mui/material";
import { theme } from "theme";

interface Props {
  disabled: boolean;
  label: string;
  onSubmit: (event: any) => void;
}

export const SubmitButton = (props: Props) => {
  const { disabled, label, onSubmit } = props;

  return (
    <StyledSubmitButton disabled={disabled} type="submit" fullWidth variant="contained" onClick={onSubmit}>
      {label}
    </StyledSubmitButton>
  );
};

const StyledSubmitButton = styled(Button)`
  margin-top: 24px;
  text-transform: none;
  font-size: 16px;
  height: 50px;

  ${theme.breakpoints.down("sm")} {
    font-size: 14px;
    height: 40px;
  }
`;
