export const ag = {
  ag_pitesti: {
    id: "ag_pitesti",
    lat: 44.8527376798879,
    lng: 24.874698325253892,
    zoom: 10,
    countyId: "ag",
    name: "Pitesti",
    countyName: "Argeș",
  },
};
