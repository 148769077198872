import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import WarningIcon from "@mui/icons-material/Warning";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { /* Divider, */ ListItemText, styled } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { useAppDispatch } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";

interface Props {
  anchorElNav: any;
  onCancel: () => void;
  onLogout: () => void;
  onViewOwnProfilePage?: () => void;
  onViewAdminPage: () => void;
  onOpenComponents: () => void;
}

export const UserDetailsDropdown = (props: Props) => {
  const { anchorElNav, onCancel, onLogout, onViewOwnProfilePage, onViewAdminPage, onOpenComponents } = props;
  const { isAdminUser } = useIsAdminUser();

  const dispatch = useAppDispatch();

  return (
    <Menu
      id="menu-auth"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(anchorElNav)}
      onClose={onCancel}
      sx={{
        display: {
          xs: "block",
        },
      }}
    >
      <StyledMenuItem className="menu-item" onClick={onViewOwnProfilePage} tabIndex={0}>
        <ListItemText className="item-text">Detalii cont</ListItemText>
        <ManageAccountsIcon className="menu-icons" />
        {/* <div className="menu-item-warning"> */}
        {/* <WarningIcon className="warning-icon" /> */}
        {/* <ListItemText className="item-text warning">Activează cont</ListItemText> */}
        {/* </div> */}
      </StyledMenuItem>
      {isAdminUser && (
        <StyledMenuItem className="menu-item" onClick={onViewAdminPage} tabIndex={0}>
          <ListItemText className="item-text">Admin panel</ListItemText>
          <SettingsIcon className="menu-icons" />
        </StyledMenuItem>
      )}
      {isAdminUser && (
        <StyledMenuItem className="menu-item" onClick={onOpenComponents} tabIndex={0}>
          <ListItemText className="item-text">Components</ListItemText>
          <SettingsIcon className="menu-icons" />
        </StyledMenuItem>
      )}
      <StyledMenuItem className="menu-item" onClick={() => dispatch(setOpenedModal("saved-searches"))} tabIndex={0}>
        <ListItemText className="item-text">Căutări salvate</ListItemText>
        <BookmarkIcon className="menu-icons" />
      </StyledMenuItem>
      <StyledMenuItem className="menu-item" onClick={onLogout} tabIndex={0}>
        <ListItemText className="item-text">Deconectare</ListItemText>
        <LogoutIcon className="menu-icons" />
      </StyledMenuItem>
    </Menu>
  );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&.menu-item": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "200px",

      "& .menu-item-warning": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      },

      "& .warning-icon": {
        color: theme.palette.warning.main,
        fontSize: "12px",
        padding: 0,
        marginLeft: "8px",
      },

      "& .warning": {
        "& .MuiTypography-root": {
          fontSize: "12px",
        },
      },

      "& .item-text": {
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginLeft: "8px",

        "&.warning": {
          marginLeft: "4px",
          color: theme.palette.warning.main,
        },
      },
      "& .menu-icons": {
        color: theme.palette.text.primary,
      },
    },
  },
}));
