export const hr = {
  hr_miercurea_ciuc: {
    id: "hr_miercurea_ciuc",
    lat: 46.36340497527181,
    lng: 25.7985948718799,
    zoom: 10,
    countyId: "hr",
    name: "Miercurea Ciuc",
    countyName: "Harghita",
  },
};
