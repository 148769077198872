import { useMutation } from "react-query";
import { useAppSelector } from "common/hooks";
import { ListingsApi } from "../../listings-api";

type MutationProps = {
  searchId: string;
  notificationsStatus: boolean;
};

export const useToggleSearchSavedNotification = () => {
  const { token = "" } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    "toggleSavedSearchNotification",
    (props: MutationProps) => {
      const { searchId, notificationsStatus } = props;

      return ListingsApi.toggleSavedSearchNotification(searchId, notificationsStatus, token!);
    }
  );

  return { toggleSavedSearchNotification: mutate, isLoading, isSuccess, isError };
};
