import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { theme, transitions } from "theme";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { updateSort } from "redux/slices/appSlice";

// TODO: Separate in label and value (use shorthand for value)
export const sortingFields = {
  default: { id: "none", label: "Fără sortare" },
  // date: { id: "date", label: "Cele mai noi primele" },
  price: { id: "price", label: "Preț crescător" },
  priceDesc: { id: "priceDesc", label: "Preț descrescător" },
  surface: { id: "surface", label: "Suprafață crescător" },
  surfaceDesc: { id: "surfaceDesc", label: "Suprafață descrescător" },
  rooms: { id: "rooms", label: "Camere crescător" },
  roomsDesc: { id: "roomsDesc", label: "Camere descrescător" },
  floor: { id: "floor", label: "Etaj crescător" },
  floorDesc: { id: "floorDesc", label: "Etaj descrescător" },
  // date: { id: "date", label: "Cele mai noi primele" },
  // dateDesc: { id: "dateDesc", label: "Cele mai vechi primele" },
};

export const SortingDropdown = () => {
  const dispatch = useAppDispatch();
  const { sorting } = useAppSelector(({ appSlice }) => appSlice);

  // TODO: listings.isLoading doesn't seem to work
  const handleChange = (event: SelectChangeEvent) => {
    dispatch(updateSort(event.target.value));
  };

  return (
    <FormControl
      sx={{
        minWidth: "120px",
        // marginLeft: "auto",
      }}
      size="small"
    >
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={sorting}
        variant="outlined"
        label=""
        onChange={handleChange}
        placeholder="Sortează"
        sx={{
          minWidth: "70px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[300],
            borderRadius: "20px",
          },
          "&.MuiInputBase-root, &.MuiOutlinedInput-root, &.MuiSelect-root, &.MuiSelect-outlined, &.Mui-focused, &.Mui-select":
            {
              backgroundColor: "inherit",
            },
          "& .MuiSelect-select, & .MuiSelect-outlined": {
            padding: "5px 10px",
            fontSize: "14px",
            fontWeight: 400,
            color: theme.palette.grey[700],
            transition: transitions.default,
          },
          "& .MuiSelect-outlined": {
            backgroundColor: "white",
            borderRadius: "20px",
          },
          "& .MuiSvgIcon-root": {
            color: theme.palette.grey[400],
          },
        }}
      >
        {Object.values(sortingFields).map((field) => (
          <MenuItem key={field.id} value={field.id}>
            {field.label || <em>{field.label}</em>}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
