import React, { ComponentProps } from "react";

export const ImagesIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width="68" height="66" viewBox="0 0 68 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M54.4 19.623H58.922C58.922 13.643 53.856 8.80055 47.6 8.80055V13.123C51.374 13.123 54.4 16.0155 54.4 19.623ZM63.478 19.623H68C68 8.86555 58.854 0.123047 47.6 0.123047V4.44555C56.372 4.44555 63.478 11.238 63.478 19.623ZM44.2 16.373V6.62305H23.8L17.578 13.123H0V65.123H68V22.873H51C51 19.298 47.94 16.373 44.2 16.373ZM34 55.373C24.616 55.373 17 48.093 17 39.123C17 30.153 24.616 22.873 34 22.873C43.384 22.873 51 30.153 51 39.123C51 48.093 43.384 55.373 34 55.373Z"
        fill="currentColor"
      />
    </svg>
  );
};
