import { useEffect } from "react";
import { toast } from "react-toastify";
import { favsToastMessages, getFavoritesIdsFromLocalStorage } from "utils/hooks/useAddRemoveFavorites";
import { updateFavoritesListingsIds } from "redux/slices";
import { useFavoritesAdd } from "api/user/hooks";
import { updateActiveMenuView } from "redux/slices/appSlice";
import { useAppDispatch, useAppSelector } from "./useRedux";

export const useSyncFavoritesWithLocalStorageOrDb = () => {
  const dispatch = useAppDispatch();
  const { mutate: addUserFavorites } = useFavoritesAdd();
  const { user } = useAppSelector(({ authSlice }) => authSlice);
  const { favoritesListingsIds } = useAppSelector(({ listingsSlice }) => listingsSlice);

  // Exit isFavoritesView if no favoritesListingsIds
  useEffect(() => {
    if (favoritesListingsIds.length === 0) {
      dispatch(updateActiveMenuView("none"));
    }
  }, [favoritesListingsIds]);

  // Sync favoritesListingsIds with db
  useEffect(() => {
    if (!user) {
      const localStorageIds = getFavoritesIdsFromLocalStorage();
      dispatch(updateFavoritesListingsIds(localStorageIds));
    }

    if (user) {
      const localStorageIds = getFavoritesIdsFromLocalStorage();
      const userFavoritesListingsIds = user.favoriteListingsIds || [];
      const mergedFavoritesIds = mergeFavorites(userFavoritesListingsIds, localStorageIds);

      // Skip if there are no favorites
      if (mergedFavoritesIds.length === 0) return;

      // Not necessary to update DB here
      if (JSON.stringify(mergedFavoritesIds) === JSON.stringify(userFavoritesListingsIds)) {
        dispatch(updateFavoritesListingsIds(mergedFavoritesIds));
        return;
      }

      // Update DB
      addUserFavorites(
        { listingIds: mergedFavoritesIds },
        {
          onSuccess: () => {
            dispatch(updateFavoritesListingsIds(mergedFavoritesIds));
            // Remove favorite ids from local storage. We don't need them while user is authenticated
            localStorage.removeItem("favoritesListings");
          },
          onError: () => {
            toast.error(favsToastMessages.syncError);
          },
        }
      );
    }
  }, [user]);
};

const mergeFavorites = (dbFavorites: string[], localStorageFavorites: string[]): string[] => {
  return [...localStorageFavorites, ...dbFavorites].filter((item, index, self) => self.indexOf(item) === index);
};
