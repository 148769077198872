import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { useTimeoutDelay } from "api/user/hooks";
import { WAITING_TIME } from "utils/constants";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useSendPhoneNumberVerificationCode } from "api/user/hooks/send-phone-number-verification-code";
import { useVerifyPhoneNumberCode } from "api/user/hooks/verify-phone-number-code";

export const ValidatePhoneModal = () => {
  const { openedModal } = useAppSelector((state) => state.appSlice);
  const { user, token } = useAppSelector((state) => state.authSlice);
  const dispatch = useAppDispatch();
  const { sendPhoneNumberVerificationCode } = useSendPhoneNumberVerificationCode();
  const { verifyPhoneNumberCode } = useVerifyPhoneNumberCode();

  const [sent, setSent] = useState(false);
  const [value, setValue] = useState("");
  const { timeout, setTimeoutState } = useTimeoutDelay();

  const handleSendPhoneNumberVerificationCode = () => {
    setSent(true);
    if (timeout > 0) {
      toast.info(`Poți trimite un nou mesaj peste ${timeout} secunde.`);
      return;
    }
    setTimeoutState(WAITING_TIME);

    if (!user?.phoneNumber) {
      toast.error("Nu ai un număr de telefon asociat contului tău.");
      return;
    }

    if (!token) {
      toast.error("Nu ești autentificat.");
      return;
    }

    sendPhoneNumberVerificationCode(
      { token, phoneNumber: user.phoneNumber },
      {
        onSuccess: () => {
          toast.success("Mesaj trimis!");
        },
        onError: (err) => {
          console.error(err);
          toast.error("Mesajul nu a putut fi trimis.");
        },
      }
    );
  };

  const handleAskNewCode = () => {
    setSent(false);
    setValue("");
  };

  const handleValidatePhoneNumberCode = () => {
    if (!token) {
      toast.error("Nu ești autentificat.");
      return;
    }

    if (!user?.phoneNumber) {
      toast.error("Nu ai un număr de telefon asociat contului tău.");
      return;
    }

    if (value.length !== 6) {
      toast.error("Codul de validare este invalid.");
      return;
    }

    verifyPhoneNumberCode(
      { token, phoneNumber: user.phoneNumber, code: value },
      {
        onSuccess: () => {
          toast.success("Validare reușită!");
          dispatch(setOpenedModal("validate-phone"));
        },
        onError: (err) => {
          console.error(err);
          toast.error("Validarea nu a putut fi efectuată.");
        },
      }
    );
  };

  const handleCloseModal = () => {
    dispatch(setOpenedModal(null));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return sent ? (
    <ConfirmationDialog
      title="Succes!"
      variant="success"
      labelPrimary="Validează codul primit"
      labelSecondary="Cere un nou cod"
      disabledPrimary={value.length !== 6}
      open={openedModal === "validate-phone"}
      onCancel={handleCloseModal}
      onPrimary={handleValidatePhoneNumberCode}
      onSecondary={handleAskNewCode}
    >
      Vei primi în scurt timp un mesaj cu un cod de validare. Te rugăm să-l introduci în câmpul de mai jos.
      <br />
      <br />
      Dacă nu ai primit mesajul, poți cere un nou cod de validare.
      <TextField
        id="outlined-basic"
        label="Introdu codul de validare"
        variant="outlined"
        style={{ width: "100%", marginTop: "16px" }}
        size="small"
        type="text"
        value={value}
        onChange={handleInputChange}
        autoFocus
      />
    </ConfirmationDialog>
  ) : user?.phoneNumber ? (
    <ConfirmationDialog
      title="Validare număr de telefon"
      variant="info"
      labelPrimary="Trimite mesaj de validare"
      labelSecondary="Renunță"
      open={openedModal === "validate-phone"}
      onCancel={handleCloseModal}
      onPrimary={handleSendPhoneNumberVerificationCode}
      onSecondary={handleCloseModal}
    >
      {`Confirmă că numărul de telefon ${user.phoneNumber} este corect și îți aparține.`}
    </ConfirmationDialog>
  ) : null;
};
