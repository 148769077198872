import { useMutation } from "react-query";
import { UserApi } from "api/user/user-api";

interface MutationProps {
  emailToken: string;
  emailTokenUserId: string;
}

export const useValidateEmail = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation("validateEmail", (mutationProps: MutationProps) => {
    const { emailToken, emailTokenUserId } = mutationProps;

    return UserApi.validateEmail(emailToken, emailTokenUserId);
  });

  return { mutate, isLoading, isSuccess, isError };
};
