import { Checkbox, FormControl, FormControlLabel, styled } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useScrollToTop } from "common/hooks/helpers";
import { AddListingPagePropsType } from "common/types/listing";
import { theme } from "theme";
import { updateUserInputFromLocalStorage, useUpdateLocalStorageWithUserInput } from "../utils/hooks";
import { AddListingTitle } from "../components/AddListingTitle";
import { StyledAddListingWrapper } from "./styles";
import { ExtraFieldsType } from "./sections-types";
import { extraFields } from "./sections-data";

type FieldsType = {
  [K in ExtraFieldsType]: {
    value: string;
    label: string;
    group: "main" | "extra";
  };
};

export const Extra = (props: AddListingPagePropsType) => {
  const { onSetIsValid } = props;

  const [userInput, setUserInput] = useState<FieldsType>(() =>
    updateUserInputFromLocalStorage({ fields: extraFields })
  );
  useUpdateLocalStorageWithUserInput({ userInput });

  // Set validation true by default
  useEffect(() => {
    onSetIsValid(true);
  }, [onSetIsValid]);

  useScrollToTop();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event?.target || {};
    const key = id as keyof FieldsType;

    setUserInput((oldInput) => ({
      ...oldInput,
      [key]: {
        ...oldInput[key],
        value: checked.toString(),
      },
    }));
  };

  return (
    <>
      <AddListingTitle
        title="Facilități și dotări"
        subtitle="Adaugă informații suplimentare despre proprietatea ta. Am bifat câteva opțiuni pentru tine - poți să le modifici sau să adaugi altele."
      />
      <StyledAddListingWrapper className="add-listing-extra-wrapper">
        <StyledExtraWrapper>
          <FormControl className="form-control" component="fieldset">
            {Object.entries(userInput).map(([key, { label, value }]) => {
              return (
                <FormControlLabel
                  className="form-control-label"
                  control={<Checkbox id={key} onChange={handleCheckboxChange} checked={value === "true"} />}
                  key={key}
                  label={label}
                />
              );
            })}
          </FormControl>
        </StyledExtraWrapper>
      </StyledAddListingWrapper>
    </>
  );
};

const StyledExtraWrapper = styled("div")`
  box-sizing: border-box;
  position: relative;
  padding-bottom: 80px;
  background-color: ${theme.palette.grey[100]};

  .form-control {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${theme.breakpoints.down("md")} {
    .form-control {
      grid-template-columns: 1fr 1fr;
    }
  }
`;
