import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import clsx from "clsx";
import { ComponentProps, ReactNode } from "react";
import { theme } from "theme";

interface Props extends ComponentProps<"div"> {
  children: ReactNode;
  type?: "info" | "warning";
}

export const WarningBox = (props: Props) => {
  const { children, type = "info" } = props;

  return (
    <StyledWarningBox {...props} className={clsx(type)}>
      {type === "info" ? <InfoIcon /> : <WarningIcon />}
      <span>{children}</span>
    </StyledWarningBox>
  );
};

const StyledWarningBox = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  // max-width: 500px;
  margin-top: 0.5rem;

  > span {
    text-align: left;
    line-height: 170%;
    font-size: 0.8rem;
    color: ${theme.palette.grey[600]};
  }

  > svg {
    width: 20px;
    height: 20px;
  }

  &.info {
    > svg {
      color: ${theme.palette.primary.main};
    }

    > span {
      color: ${theme.palette.primary.main};
    }
  }

  &.warning {
    > svg {
      color: ${theme.palette.secondary.main};
    }

    > span {
      color: ${theme.palette.secondary.main};
    }
  }

  button {
    padding: 0;
    margin: 0;
    height: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    margin-left: 0.5ch;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
`;
