import { generatePath, useNavigate } from "react-router-dom";
import { CENTERS } from "cities";
import { useAppParams } from "./useAppParams";

interface FnProps {
  view?: string;
  lat?: number | string;
  lng?: number | string;
  zoom?: number | string;
  countyId?: string;
  path?: string;
  suffix?: string;
  goBasePath?: boolean;
  replace?: boolean;
}

const POS_END_BASE_PATH = 6;

export const useBasePathUrl = () => {
  const navigate = useNavigate();
  const { view, countyId, lat, lng, zoom, listingId } = useAppParams();

  const updateBasePath = (props: FnProps) => {
    const { path, suffix, goBasePath = false, replace } = props;

    if (path) {
      navigate(path, { replace: false });
      return;
    }

    // Update URL with coords of the new center (+ zoom)
    const updatedPath =
      path ||
      generatePath("/:view/:countyId/:lat/:lng/:zoom", {
        view: props.view || view || "map",
        countyId: props.countyId || countyId || "b",
        lat: props.lat?.toString() || lat || "",
        lng: props.lng?.toString() || lng || "",
        zoom: props.zoom?.toString() || zoom || "",
      });

    if (goBasePath) {
      navigate(updatedPath, { replace });
      return;
    }

    // What follows after the base path
    const additionalPath = window.location.pathname?.split("/").slice(POS_END_BASE_PATH).join("/") || "";

    // Replace path with updated path
    navigate(`${updatedPath}/${suffix || additionalPath}`, { replace: false });
  };

  const goToCountyCenter = (countyId: string | undefined, goToRoot = false) => {
    const wantedCounty = Object.values(CENTERS).find((el) => el.countyId === countyId);

    if (!countyId || !wantedCounty) {
      navigate("/");
      return;
    }

    if (goToRoot) {
      // Create a new path with the new countyId
      const newPath = generatePath("/:view/:countyId/:lat/:lng/:zoom", {
        view: "map",
        countyId: wantedCounty.countyId,
        lat: wantedCounty.lat.toString(),
        lng: wantedCounty.lng.toString(),
        zoom: wantedCounty.zoom.toString(),
      });
      navigate(newPath);
      return;
    }

    updateBasePath({
      view: "map",
      countyId: wantedCounty.countyId,
      lat: wantedCounty.lat,
      lng: wantedCounty.lng,
      zoom: wantedCounty.zoom,
    });
  };

  const goToBasePath = ({ replace }: { replace?: boolean }) =>
    updateBasePath({ goBasePath: true, replace: replace || false });

  return {
    basePath: `/${view}/${countyId}/${lat}/${lng}/${zoom}`,
    updateBasePath,
    goToCountyCenter,
    goToBasePath,
    listingId,
  };
};
