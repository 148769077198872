import { useEffect, useState } from "react";
import { ClapSpinner } from "react-spinners-kit";
import { toast } from "react-toastify";
import { useAppParams } from "common/hooks/useAppParams";
import { useValidateEmail } from "api/user/hooks";
import { useAppDispatch, useAppSelector, useBasePathUrl } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";

export const ValidateEmailSuccesModal = () => {
  const { emailTokenUserId, emailToken } = useAppParams();
  const { isActivatePhoneImplemented } = useAppSelector((state) => state.appSlice);
  const { mutate: validateEmail } = useValidateEmail();
  const { goToBasePath } = useBasePathUrl();
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (emailToken && emailTokenUserId) {
      setTimeout(() => {
        validateEmail(
          {
            emailToken: emailToken.replaceAll("_", "/"),
            emailTokenUserId,
          },
          {
            onSuccess: () => {
              setSuccess(true);
              setError(false);
              toast.success("Emailul a fost validat cu succes! Vei fi redirecționat către pagina principală...");
            },
            onError: (err: any) => {
              console.error(err);

              // Check if email is already validated
              if (err?.response?.data?.message === "Email already validated") {
                setSuccess(true);
                setError(false);
                toast.success("Emailul a fost deja validat. Vei fi redirecționat în câteva secunde...");
                return;
              }

              setError(true);
              toast.error("Emailul nu a putut fi validat. Vei fi redirecționat în câteva secunde...");
            },
          }
        );
      }, 1000);
    }
  }, [emailToken, emailTokenUserId]);

  return error ? (
    <ConfirmationDialog
      title="Eroare!"
      labelSecondary="Închide"
      labelPrimary="Doresc un nou link de validare"
      open={!!emailToken}
      onCancel={() => goToBasePath({})}
      onPrimary={() => {
        dispatch(setOpenedModal("validate-email"));
        goToBasePath({});
      }}
      onSecondary={() => goToBasePath({})}
      variant="warning"
    >
      Nu am putut valida emailul. E posibil ca linkul să fi fost folosit deja ori să fi expirat.
      <br /> <br />
      Încearcă să te autentifici sau cere un nou link de validare apăsând butonul de mai jos.
    </ConfirmationDialog>
  ) : success ? (
    <ConfirmationDialog
      title="Succes!"
      variant="success"
      labelSecondary={isActivatePhoneImplemented ? "Închide" : ""}
      labelPrimary={isActivatePhoneImplemented ? "Activează numărul de telefon" : "Închide fereastra"}
      open={!!emailToken}
      onCancel={() => goToBasePath({})}
      onPrimary={isActivatePhoneImplemented ? () => dispatch(setOpenedModal("validate-phone")) : () => goToBasePath({})}
      onSecondary={() => goToBasePath({})}
    >
      Emailul a fost validat cu succes.
    </ConfirmationDialog>
  ) : (
    <ConfirmationDialog
      title="Se validează..."
      variant="info"
      open={!!emailToken}
      onCancel={() => goToBasePath({})}
      onPrimary={() => goToBasePath({})}
      onSecondary={() => goToBasePath({})}
    >
      Emailul este în curs de validare. Te rugăm să aștepți.
      <div
        style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "32px" }}
      >
        <ClapSpinner size={60} color="#686769" loading />
      </div>
    </ConfirmationDialog>
  );
};
