import { useQuery } from "react-query";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { QueriesKeys } from "common/types/general";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { useAppSelector } from "common/hooks";
import { AdminApi } from "../admin-api";
import { LIMITED_CACHING } from "../../utils";

// TODO: Add zod schema
export const useGetPendingListings = () => {
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const { token } = useAppSelector(({ authSlice }) => authSlice);
  const { isAdminUser } = useIsAdminUser();

  return useQuery(
    ["adminPendingListings" as QueriesKeys, token],
    async () => {
      addLoadingEl("admin");
      const res = await AdminApi.getPendingListings({ token: token! });
      removeLoadingEl("admin");

      return res.data.data;
    },
    {
      ...LIMITED_CACHING,
      enabled: !!token && isAdminUser,
    }
  );
};
