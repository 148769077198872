import { Autocomplete, TextField } from "@mui/material";
import { ChangeEvent, SyntheticEvent, useState } from "react";
import { useScrollToTop } from "common/hooks/helpers";
import { AddListingPagePropsType } from "common/types/listing";
import {
  updateUserInputFromLocalStorage,
  useUpdateLocalStorageWithUserInput,
  useValidateUserInput,
} from "../utils/hooks";
import { AddListingTitle } from "../components/AddListingTitle";
import { StyledAddListingWrapper } from "./styles";
import { GeneralFieldsType } from "./sections-types";
import { DEFAULT_TOTAL_FLOORS, generalFields } from "./sections-data";

type FieldsType = {
  [K in GeneralFieldsType]: {
    value: string;
    label: string;
    placeholder: string;
    required: boolean;
    validator: (value: string) => { error: boolean; errorMessage: string };
    options?: { label: string; value: number }[];
    touched?: boolean;
    error?: boolean;
    errorMessage?: string;
    // Type of Mui TextField inputProps.inputMode
    type: "text" | "number";
    skip?: boolean;
  };
};

export const General = (props: AddListingPagePropsType) => {
  const { onSetIsValid } = props;

  useScrollToTop();

  const [userInput, setUserInput] = useState<FieldsType>(() =>
    updateUserInputFromLocalStorage({ fields: generalFields })
  );
  useUpdateLocalStorageWithUserInput({ userInput });
  useValidateUserInput({ userInput, onSetIsValid });

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    error = false,
    errorMessage = ""
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FieldsType;

    setUserInput((oldInput) => {
      const newInput = {
        ...oldInput,
        [key]: {
          ...oldInput[key],
          value,
          touched: true,
          error,
          errorMessage,
        },
      };
      return newInput;
    });
  };

  const handleFocusOut = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    const key = id as keyof FieldsType;

    const { validator } = generalFields[key];
    const { error, errorMessage } = validator(value, userInput.floor.value);

    handleInputChange(event, error, errorMessage);
  };

  const floorOptions = generalFields.floor.options;

  const handleFloorSelection = (_event: SyntheticEvent<Element, Event>, newInputValue: string) => {
    const newFloorNumber = floorOptions.find((el) => el.label === newInputValue)?.value;
    const newFloorValue = newFloorNumber ? String(newFloorNumber) : DEFAULT_TOTAL_FLOORS;

    setUserInput((oldInput) => {
      const isWrongSelection = !floorOptions.some((el) => el.label === newInputValue);
      const newMaxFloor = isWrongSelection
        ? oldInput.totalFloors.value
        : String(Math.max(Number(newFloorValue), Number(oldInput.totalFloors.value)));

      const { error, errorMessage } = generalFields.totalFloors.validator(newMaxFloor, String(newFloorValue));

      const newInput = {
        ...oldInput,
        floor: {
          ...oldInput.floor,
          value: newFloorValue,
          touched: true,
          error: isWrongSelection,
        },
        totalFloors: {
          ...oldInput.totalFloors,
          value: newMaxFloor,
          touched: true,
          error,
          errorMessage,
        },
      };

      return newInput;
    });
  };

  return (
    <>
      <AddListingTitle
        title="Informații generale"
        subtitle="Adaugă principalele informații legate de locuința pe care o dai spre închiriere."
      />
      <StyledAddListingWrapper>
        {Object.entries(userInput).map(
          ([id, { label, required, placeholder, error, errorMessage, value, type, skip }]) => {
            if (skip) return null;

            const key = id as keyof FieldsType;
            const floorValue = floorOptions.find((el) => el.value === Number(userInput.floor.value)) || floorOptions[2];

            if (key === "floor") {
              return (
                <Autocomplete
                  value={floorValue}
                  disablePortal
                  id={key}
                  key={key}
                  options={floorOptions}
                  onInputChange={handleFloorSelection}
                  noOptionsText="Nu există opțiuni disponibile"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      size="small"
                      id={key}
                      key={key}
                      variant="outlined"
                      label={label}
                      helperText={errorMessage}
                      value={floorValue}
                      type={type}
                      required
                    />
                  )}
                />
              );
            }

            return (
              <TextField
                error={error}
                size="small"
                id={key}
                key={key}
                variant="outlined"
                label={label}
                helperText={errorMessage}
                value={value}
                type={type}
                fullWidth
                onChange={handleInputChange}
                onBlur={handleFocusOut}
                required={required}
                placeholder={placeholder}
                // Change background color of filled input
                // https://stackoverflow.com/questions/63492293/how-to-change-the-background-color-of-filled-textfield-in-material-ui
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            );
          }
        )}
      </StyledAddListingWrapper>
    </>
  );
};
