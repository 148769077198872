import { useMutation } from "react-query";
import { useAppSelector } from "common/hooks";
import { UserApi } from "../user-api";

interface MutationProps {
  listingId: string;
}

export const useFavoriteRemove = () => {
  const { token } = useAppSelector((state) => state.authSlice);

  const {
    mutate: originalMutate,
    isLoading,
    isSuccess,
    isError,
  } = useMutation("favoritesRemove", (mutationProps: MutationProps) => {
    const { listingId } = mutationProps;

    return UserApi.removeUserFavorite({
      listingId,
      token: token!,
    });
  });

  const mutate = (mutationProps: MutationProps, ...rest: any) => {
    if (token) {
      originalMutate(mutationProps, ...rest);
    }
  };

  return { mutate, isLoading, isSuccess, isError };
};
