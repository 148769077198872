import { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, TileLayer, Tooltip, useMap } from "react-leaflet";
import { Coords } from "google-map-react";

// TODO: Keep track of zoom at all times and change it to 21 when a selection is made from the dropdown
const INITIAL_ZOOM = 18;

type Props = {
  lat: string;
  lng: string;
  onMarkerMovement: (newPosition: Coords) => void;
};

export const DraggableMapContainer = (props: Props) => {
  const { lat, lng, onMarkerMovement } = props;

  const [mapCenter, setMapCenter] = useState({
    lat,
    lng,
  });

  // Necessary to update the map center when the lat/lng props change
  useEffect(() => {
    setMapCenter({
      lat,
      lng,
    });
  }, [lat, lng]);

  return (
    <MapContainer
      // key={new Date().getTime()} // Workaround to prevent the Map already initialized error (https://github.com/PaulLeCam/react-leaflet/issues/936). It causes a flicker on marker move, but nothing we could do for nowß
      center={{
        lat: Number(mapCenter.lat),
        lng: Number(mapCenter.lng),
      }}
      zoom={INITIAL_ZOOM}
      style={{
        border: "1px solid #C4C4C4",
        borderRadius: "4px",
        height: "calc(100vh - 450px)",
        maxWidth: "100%",
        width: "100vw",
        marginTop: 0,
      }}
    >
      <DraggableMap
        mapCenter={{
          lat: Number(mapCenter.lat),
          lng: Number(mapCenter.lng),
        }}
        setMapCenter={setMapCenter}
        onMarkerMovement={onMarkerMovement}
      />
    </MapContainer>
  );
};

type DraggableMapProps = {
  mapCenter: Coords;
  setMapCenter: (newCenter: { lat: string; lng: string }) => void;
  onMarkerMovement: (newPosition: Coords) => void;
};

const DraggableMap = (props: DraggableMapProps) => {
  const { mapCenter, setMapCenter, onMarkerMovement } = props;

  const map = useMap();
  const markerRef = useRef(null);

  const [markerPosition, setMarkerPosition] = useState({
    lat: mapCenter.lat,
    lng: mapCenter.lng,
  });

  const handleMarkerMovement = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;

        if (marker != null) {
          const newPosition: Coords = marker.getLatLng();

          onMarkerMovement(newPosition);
          setMarkerPosition(newPosition);
          setMapCenter({
            lat: String(newPosition.lat),
            lng: String(newPosition.lng),
          });
        }
      },
    }),
    [onMarkerMovement]
  );

  useEffect(() => {
    const marker: any = markerRef.current;

    map.setView(mapCenter, map.getZoom());
    if (marker) {
      marker.setLatLng(mapCenter);
    }
  }, [mapCenter]);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker riseOnHover draggable eventHandlers={handleMarkerMovement} position={markerPosition} ref={markerRef}>
        <Tooltip>
          <span>Mută Pin-ul în poziția dorită</span>
        </Tooltip>
      </Marker>
    </>
  );
};
