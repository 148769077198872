import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { logout, saveUserInfo } from "redux/slices/authSlice";
import { LIMITED_CACHING, logZodError } from "api/utils";
import { UserBasicType } from "common/types/user";
import { QueriesKeys } from "common/types/general";
import { UserApi } from "../user-api";
import { useBELogout } from "./useBELogout";

const userBEResponseSchema = z.object({
  email: z.string(),
  first_name: z.string(),
  has_verified_email: z.boolean(),
  id: z.string(),
  last_name: z.string(),
  phone_number: z.string().nullable(),
  updatedAt: z.string().optional(),
  createdAt: z.string(),
  favorite_listing_ids: z.array(z.string()),
  listing_ids: z.array(z.string()),
  profile_picture_url: z.string().nullable(),
});

type UserBERequestType = z.infer<typeof userBEResponseSchema>;

// This hook is used to get user details from token
export const useGetUserDetails = () => {
  const dispatch = useAppDispatch();
  const { logoutFromBE } = useBELogout();
  const { token, user } = useAppSelector(({ authSlice }) => authSlice);

  // If token is present and user is not present, then get user details
  useQuery("userDetails" as QueriesKeys, async () => UserApi.getOwnDetails({ token: token! }), {
    ...LIMITED_CACHING,
    enabled: !!token && !user,
    onSuccess: (res) => {
      const { data } = res.data;
      const parsed = userBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        dispatch(saveUserInfo({ ...convertBEUserToFrontendFormat(data, token!), token }));
        return;
      }

      dispatch(saveUserInfo({ ...convertBEUserToFrontendFormat(parsed.data, token!), token }));
    },
    onError: () => {
      dispatch(logout());
      logoutFromBE({ token: token! });
      toast.error("Eroare la accesarea contului. Te rugăm să te autentifici din nou.");
    },
  });
};

const convertBEUserToFrontendFormat = (user: UserBERequestType, token: string): UserBasicType => {
  return {
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    createdAt: user.createdAt,
    updatedAt: user.updatedAt,
    phoneNumber: user.phone_number || "",
    hasVerifiedEmail: user.has_verified_email,
    favoriteListingsIds: user.favorite_listing_ids,
    ownListingsIds: user.listing_ids,
    profilePictureUrl: user.profile_picture_url,
    token,
  };
};
