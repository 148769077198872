import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { GoogleMap } from "components/Map/GoogleMap";
import useIsMounted from "common/hooks/is-mounted";
import { AuthModal } from "pages/Auth/AuthModal";
import { useAuthModal } from "common/hooks/useAuthModal";
import { initializeUser } from "redux/slices/authSlice";
import {
  useBasePathUrl,
  useUpdateViewOnDesktop,
  useWindowDimensions,
  useAppDispatch,
  useAppSelector,
} from "common/hooks";
import { useSyncFavoritesWithLocalStorageOrDb } from "common/hooks/use-sync-favorites";
import { useAppParams } from "common/hooks/useAppParams";
import { useGetUserDetails } from "api/user/hooks";
import { useAddRemoveFavorites } from "utils/hooks/useAddRemoveFavorites";
import { ReportListingModal } from "components/modals/report-listing-modal";
import { UserProfileModal } from "components/modals/public-user-profile-modal";
import { AuthenticatedUserProfileModal } from "components/modals/authenticated-user-profile-modal";
import { DeleteAccountModal } from "components/modals/delete-account-modal";
import { SavedSearchesModal } from "components/modals/saved-searches-modal";
import { OnboardingModal } from "components/modals/onboarding-modal";
import { setOpenedModal } from "redux/slices/appSlice";
import { CampaignModal } from "components/modals/campaign-modal";
import { CookieBannerConsent } from "components/cookie-banner-consent";
import { AddListingFailureModal } from "components/modals/add-listing-failure";
import { AddListingSuccessModal } from "components/modals/add-listing-success";
import { AuthSuccessModal } from "components/modals/auth-success";
import { Footer } from "./Footer";
import { SidePanel } from "./SidePanel";
import { AskResendValidateEmailModal } from "../components/modals/validate-email-resend-modal";
import { ValidatePhoneModal } from "../components/modals/validate-phone-modal";
import { FiltersSidePanel } from "./FiltersSidePanel";
import { ValidateEmailSuccesModal } from "../components/modals/validate-email-succes-modal";
import { ChangePasswordRequestModal } from "../components/modals/change-password-request-modal";
import { BanUserModal } from "../components/modals/ban-user-modal";

type MainComponentProps = {
  heightWithoutNavbarAndFooter: string;
};

export const MainComponent = (props: MainComponentProps) => {
  const { heightWithoutNavbarAndFooter } = props;
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const { goToCountyCenter } = useBasePathUrl();
  const { isVerifyEmailMode, lat, lng, zoom, countyId } = useAppParams();
  const { openedModal } = useAppSelector(({ appSlice }) => appSlice);

  const { isDisplayingAuthModal, onDisplayAuthModal } = useAuthModal();

  useGetUserDetails();
  useUpdateViewOnDesktop();
  useAddRemoveFavorites();
  useSyncFavoritesWithLocalStorageOrDb();

  const hasCoords = lat && lng && zoom;

  const { isMobile } = useWindowDimensions();

  // Initialize user on mount
  useEffect(() => {
    if (isMounted()) {
      dispatch(initializeUser());
    }
  }, [isMounted]);

  // Redirect to county coords
  useEffect(() => {
    if (!lat || !lng || !zoom) {
      goToCountyCenter(countyId);
    }
  }, [countyId, goToCountyCenter, hasCoords, isMounted, lat, lng, zoom]);

  // Open onboarding modal on first visit
  useEffect(() => {
    const onboardingModalSeen = localStorage.getItem("onboardingModal");
    const campaignModal = localStorage.getItem("showCampaignModal");

    // Show onboarding modal
    if (!onboardingModalSeen) {
      dispatch(setOpenedModal("onboarding"));
      return;
    }

    // Show campaign modal
    if (campaignModal !== "false") {
      setTimeout(() => {
        dispatch(setOpenedModal("campaign"));
      }, 3500);
    }
  }, []);

  // Disable map update behind the scenes whenever it's not necessary
  // const isOnAddPage = location.pathname.endsWith("/add");
  // LE: We need to have the map running behind the scenes, otherwise the Autocomplete location search won't work
  // when we open the Add Listing page on a new session

  return (
    <>
      {/* {theme.breakpoints && process.env.NODE_ENV === "development" && <ShowBreakpoints />} */}
      {hasCoords && <GoogleMap heightWithoutNavbarAndFooter={heightWithoutNavbarAndFooter} />}
      <SidePanel isMobile={isMobile} isMounted={isMounted() || false} />
      {isMobile && <FiltersSidePanel />}
      <CookieBannerConsent />
      <Footer />
      <Outlet />
      {isDisplayingAuthModal && <AuthModal setOpen={onDisplayAuthModal} />}
      {isVerifyEmailMode && <ValidateEmailSuccesModal />}
      {openedModal === "validate-email" && <AskResendValidateEmailModal />}
      {openedModal === "change-password" && <ChangePasswordRequestModal />}
      {openedModal === "validate-phone" && <ValidatePhoneModal />}
      {openedModal === "ban-user" && <BanUserModal />}
      {openedModal === "report-listing" && <ReportListingModal />}
      {openedModal === "user-profile" && <UserProfileModal />}
      {openedModal === "authenticated-user-profile" && <AuthenticatedUserProfileModal />}
      {openedModal === "delete-account" && <DeleteAccountModal />}
      {openedModal === "saved-searches" && <SavedSearchesModal />}
      {openedModal === "onboarding" && <OnboardingModal />}
      {openedModal === "campaign" && <CampaignModal />}
      {openedModal === "add-listing-success" && <AddListingSuccessModal />}
      {openedModal === "add-listing-failure" && <AddListingFailureModal />}
      {openedModal === "auth-success" && <AuthSuccessModal />}
    </>
  );
};
