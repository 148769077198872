import { styled } from "@mui/system";
import { Link } from "@mui/material";
import { useBasePathUrl, useWindowDimensions } from "common/hooks";

export const Footer = () => {
  const { isMobile } = useWindowDimensions();
  const { basePath } = useBasePathUrl();

  return (
    <StyledFooter>
      {!isMobile && <span className="element">{`${new Date().getFullYear()}\u00A0©\u00A0Kastel.io`}</span>}
      <Link href={`${basePath}/privacy`}>Politica de confidențialitate</Link>
      <Link href={`${basePath}/terms`}>Termeni și condiții</Link>
      <Link href={`${basePath}/contact`}>Contact</Link>
    </StyledFooter>
  );
};

export const StyledFooter = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "-18px",
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  height: "18px",
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  zIndex: 2,
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  overflowX: "auto",
  overflowY: "hidden",
  whiteSpace: "nowrap",

  "& a, & span": {
    fontSize: "12px",
    textDecoration: "none",
    padding: "0 10px",
    color: theme.palette.grey[600],
    fontWeight: 500,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  "& a:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
  "& span": {
    borderLeft: "none",
    whitespace: "nowrap",
  },
}));
