/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import {
  partitioningFields,
  buildingTypeFields,
  extraFields,
} from "pages/AddListing/add-listing-sections/sections-data";

export type FiltersOptions = "buildingType" | "partitioning" | "price" | "rooms" | "floors" | "surface" | "extra";
export type FiltersOptionsAllKeys =
  | keyof typeof extraFields
  | keyof typeof partitioningFields
  | keyof typeof buildingTypeFields
  | "price-min"
  | "price-300"
  | "price-400"
  | "price-500"
  | "price-750"
  | "price-max"
  | "one"
  | "two"
  | "three"
  | "four"
  | "fiver_or_more"
  | "semi_basement"
  | "ground_floor"
  | "intermediary"
  | "top_floor"
  | "surface-min"
  | "surface-medium"
  | "surface-max";

export type FiltersOptionsType = {
  key: FiltersOptionsAllKeys;
  label: string;
  count: number;
  value?: [number, number] | string;
};

export type FiltersType = {
  filtersOpen: boolean;
  filters: Record<FiltersOptions, FiltersOptionsAllKeys[]>;
  filtersOptions: Record<FiltersOptions, FiltersOptionsType[]>;
  filtersCount: Record<FiltersOptionsAllKeys, number>;
};

const initialFilters = {
  buildingType: [],
  partitioning: [],
  price: [],
  rooms: [],
  floors: [],
  surface: [],
  extra: [],
};

export const priceOptions: FiltersOptionsType[] = [
  { key: "price-min", label: "Sub 200€", value: [0, 200], count: 0 },
  { key: "price-300", label: "200€ - 300€", value: [200, 300], count: 0 },
  { key: "price-400", label: "300€ - 400€", value: [300, 400], count: 0 },
  { key: "price-500", label: "400€ - 500€", value: [400, 500], count: 0 },
  { key: "price-750", label: "500€ - 700€", value: [500, 700], count: 0 },
  { key: "price-max", label: "Peste 700€", value: [700, Infinity], count: 0 },
];

export const roomsOptions: FiltersOptionsType[] = [
  { key: "one", label: "1 cameră", value: [1, 1], count: 0 },
  { key: "two", label: "2 camere", value: [2, 2], count: 0 },
  { key: "three", label: "3 camere", value: [3, 3], count: 0 },
  { key: "four", label: "4 camere", value: [4, 4], count: 0 },
  { key: "fiver_or_more", label: "5+ camere", value: [5, Infinity], count: 0 },
];

export const floorsOptions: FiltersOptionsType[] = [
  { key: "semi_basement", label: "Demisol sau subsol", value: "semi_basement", count: 0 },
  { key: "ground_floor", label: "Parter", value: "ground_floor", count: 0 },
  { key: "intermediary", label: "Etaj intermediar", value: "intermediary", count: 0 },
  { key: "top_floor", label: "Mansardă sau ultimul etaj", value: "top_floor", count: 0 },
];

export const surfaceOptions: FiltersOptionsType[] = [
  { key: "surface-min", label: "Sub 50 mp", value: [0, 50], count: 0 },
  { key: "surface-medium", label: "50 mp - 100 mp", value: [50, 100], count: 0 },
  { key: "surface-max", label: "Peste 100 mp", value: [100, Infinity], count: 0 },
];

const extraOptions: FiltersOptionsType[] = Object.entries(extraFields).map(([key, { label }]) => ({
  key: key as keyof typeof extraFields,
  label,
  count: 0,
}));

const filtersOptions = {
  buildingType: Object.entries(buildingTypeFields).map(([key, { label }]) => ({
    key,
    label,
    count: 0,
  })),
  partitioning: Object.entries(partitioningFields).map(([key, { label }]) => ({
    key,
    label,
    count: 0,
  })),
  price: priceOptions,
  rooms: roomsOptions,
  floors: floorsOptions,
  surface: surfaceOptions,
  extra: extraOptions,
} as Record<FiltersOptions, FiltersOptionsType[]>;

const getFiltersCount = () => {
  let filtersCount = {} as Record<FiltersOptionsAllKeys, number>;

  Object.values(filtersOptions).forEach((value) => {
    filtersCount = { ...filtersCount, ...mapValues(keyBy(value, "key"), "count") };
  });

  return filtersCount;
};

const initialState: FiltersType = {
  filtersOpen: false,
  filtersOptions,
  filtersCount: getFiltersCount(),
  filters: initialFilters,
};

export const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState,
  reducers: {
    setFiltersOpen(state, action) {
      state.filtersOpen = action.payload;
    },
    updateFilters(state, action) {
      const { fieldKey, id, checked } = action.payload as {
        fieldKey: FiltersOptions;
        id: FiltersOptionsAllKeys;
        checked: boolean;
      };

      state.filters[fieldKey] = checked
        ? [...state.filters[fieldKey], id]
        : state.filters[fieldKey].filter((v) => v !== id);
    },
    resetFilters(state) {
      state.filters = initialFilters;
    },
    resetFiltersCount(state) {
      state.filtersCount = initialState.filtersCount;
    },
    incrementFilterCount(state, action) {
      const ids = action.payload as FiltersOptionsAllKeys[];

      ids.forEach((id) => {
        state.filtersCount[id] += 1;
      });
    },
  },
});

export const { setFiltersOpen, updateFilters, resetFilters, resetFiltersCount, incrementFilterCount } =
  filtersSlice.actions;

export default filtersSlice.reducer;
