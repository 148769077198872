export const gl = {
  gl_galati: {
    id: "gl_galati",
    lat: 45.43440089699928,
    lng: 28.023118582066346,
    zoom: 10,
    countyId: "gl",
    name: "Galați",
    countyName: "Galați",
  },
};
