import { AppBar, Toolbar, styled, Button } from "@mui/material";
import { boxShadows, theme } from "theme";
import { useBasePathUrl, useAppDispatch } from "common/hooks";
import { setSearchBoxOptionId, updateActiveMenuView } from "redux/slices/appSlice";
import { NAVBAR_HEIGHT, NEUTRAL_RED } from "utils/constants";
import LogoPng from "assets/logo.png";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { SearchBox } from "../Search";
import { LeftMenu } from "./left-menu";
import { LoadingBar } from "../LoadingBar";
import { RightMenu } from "./right-menu";

export const Navbar = () => {
  const { goToCountyCenter } = useBasePathUrl();
  const dispatch = useAppDispatch();
  const { clearLoadingState } = useUpdateAppLoadingState();

  return (
    <StyledAppBar position="relative">
      <Toolbar className="toolbar">
        <Button
          className="logo-button"
          onClick={() => {
            goToCountyCenter("ro", true);
            dispatch(setSearchBoxOptionId(null));
            dispatch(updateActiveMenuView("none"));
            clearLoadingState();
          }}
        >
          <img src={LogoPng} alt="logo" />
          <span>Beta</span>
        </Button>
        <LeftMenu />
        <SearchBox />
        <RightMenu />
      </Toolbar>
      <LoadingBar />
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  box-shadow: ${boxShadows.toolbar};
  min-width: 320px;

  & .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    min-width: 320px;
    overflow: auto;
    box-shadow: ${boxShadows.toolbar};
    max-height: ${NAVBAR_HEIGHT.DESKTOP}px;
    background-color: ${theme.palette.grey[50]};
    z-index: 14;
    padding: 0 10px;

    & .logo-button {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0;

      > img {
        max-height: 42px;
        max-width: 100%;
        width: auto;
        padding: 2px;
        box-sizing: border-box;
      }

      > span {
        color: ${NEUTRAL_RED};
        position: absolute;
        font-size: 9px;
        font-weight: 400;
        bottom: 4px;
        left: 2px;
      }
    }

    ${theme.breakpoints.down("sm")} {
      gap: 4px;
      max-height: ${NAVBAR_HEIGHT.MOBILE}px;

      & .logo-button {
        > img {
          height: 32px;
          max-height: 32px;
        }
      }
    }
  }
`;
