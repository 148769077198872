import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useAppParams } from "./useAppParams";
import { useWindowDimensions } from "./window-dimensions";

export const useUpdateViewOnDesktop = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();
  const { listingId } = useAppParams();

  useEffect(() => {
    if (isMobile) return;

    // Replace view in path with "map"
    const [, view, paramCountyId, paramLat, paramLng, paramZoom, ...rest] = window.location.pathname?.split("/") || [];
    const updatedPath = generatePath("/:view/:countyId/:lat/:lng/:zoom", {
      view: view === "feed" ? "map" : view === "map" && listingId ? "details" : view,
      countyId: paramCountyId || "b",
      lat: paramLat || "",
      lng: paramLng || "",
      zoom: paramZoom || "",
    });

    const newPath = `${updatedPath}/${rest.join("/")}`;
    navigate(newPath, { replace: true });
  }, [isMobile]);
};
