import { ChangeEvent, useEffect } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useRegisterUser } from "api/user/hooks";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { WARNING_MESSAGE_REGISTER } from "utils/constants";
import { PhoneInputComponent } from "components/PhoneInputComponent";
import { PasswordTextField } from "components/TextFields/PasswordTextField";
import { AuthPages } from "redux/slices/authSlice";
import { useAppDispatch } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { SubmitButton } from "./SubmitButton";
import { CheckboxEl } from "./CheckboxComponent";
import { AuthFields } from "../utils/auth-fields";
import { useUpdateAuthInput } from "../utils/update-auth-input";

interface Props {
  onCloseModal: (newPage?: AuthPages) => void;
}

export const RegisterComponent = (props: Props) => {
  const { onCloseModal } = props;

  const dispatch = useAppDispatch();
  const { mutate: registerUser } = useRegisterUser();
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();

  const {
    userInput: incomingUserInput,
    onInputChange,
    onUpdateInput,
    onCleanAndUpdateInput,
    onCheckboxChange,
    onPhoneChange,
  } = useUpdateAuthInput(["firstName", "lastName", "phone", "email", "password", "confirmPassword"], {
    acceptTerms: false,
    acceptPoC: false,
  });
  const userInput = incomingUserInput as AuthFields & { acceptTerms: boolean; acceptPoC: boolean };

  // Load existing fields from local storage in case they were added during Add Listing flow
  useEffect(() => {
    const existingFields = getAuthFieldsFromLocalStorage();

    if (Object.keys(existingFields).length === 0) {
      return;
    }
    Object.entries(existingFields).forEach(([key, value]: any) => {
      if (!value) {
        return;
      }

      if (key === "phone") {
        onPhoneChange(value, {
          nationalNumber: value.replaceAll(" ", ""),
        });
        return;
      }

      onUpdateInput(key, value, true);
    });
  }, []);

  let hasInvalidCheckboxes = false;

  const handleSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    let hasInvalidFields = false;
    hasInvalidCheckboxes = false;

    Object.entries(userInput).forEach(([key, input]) => {
      let localError = false;

      if (typeof input === "boolean") {
        localError = !input;

        if (localError) {
          hasInvalidCheckboxes = true;
        }
      } else {
        localError = onCleanAndUpdateInput(key as any, input.value);
      }

      if (localError) {
        hasInvalidFields = true;
      }
    });

    if (hasInvalidFields) {
      console.log("invalid");
      toast.error(
        hasInvalidCheckboxes
          ? "Pentru a continua, trebuie să accepți termenii și condițiile și politica de confidențialitate."
          : "A apărut o eroare. Verifică câmpurile completate."
      );
      return;
    }

    // Need to add this check to BE as well, otherwise someone might bypass it
    if (!userInput.acceptPoC || !userInput.acceptTerms) {
      removeLoadingEl("auth");
      toast.error(WARNING_MESSAGE_REGISTER);
      return;
    }

    addLoadingEl("auth");

    registerUser(
      {
        firstName: userInput.firstName.value,
        lastName: userInput.lastName.value,
        phone: userInput.phone.value.replaceAll(" ", ""),
        email: userInput.email.value,
        password: userInput.password.value,
      },
      {
        onSuccess: () => {
          removeLoadingEl("auth");
          onCloseModal();
          dispatch(setOpenedModal("auth-success"));
        },
        onError: (err: any) => {
          let errorMessage = "A apărut o eroare. Încearcă mai târziu.";
          if (err?.response?.data?.message === "Email already registered") {
            errorMessage = "Adresă de email a fost folosită deja. Încearcă să te autentifici.";
          }

          removeLoadingEl("auth");
          toast.error(errorMessage);
        },
      }
    );
  };

  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        id="firstName"
        label="Prenume"
        name="firstName"
        autoComplete="firstName"
        autoFocus
        size="small"
        onChange={onInputChange}
        // onBlur={onFocusOut}
        value={userInput.firstName.value}
        error={userInput.firstName.error}
        helperText={userInput.firstName.errorMessage}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Nume de familie"
        name="lastName"
        autoComplete="lastName"
        autoFocus
        size="small"
        onChange={onInputChange}
        // onBlur={onFocusOut}
        value={userInput.lastName.value}
        error={userInput.lastName.error}
        helperText={userInput.lastName.errorMessage}
        sx={{
          marginBottom: "22px",
        }}
      />
      <PhoneInputComponent
        value={userInput.phone.value}
        isError={userInput.phone.error}
        errorMessage={userInput.phone.errorMessage}
        touched={userInput.phone.touched}
        onChange={onPhoneChange}
        // onBlur={() => onFocusOut({ target: { id: "phone", value: userInput.phone.value } } as any)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Adresă de email"
        name="email"
        autoComplete="email"
        autoFocus
        size="small"
        onChange={onInputChange}
        // onBlur={onFocusOut}
        value={userInput.email.value}
        error={userInput.email.error}
        helperText={userInput.email.errorMessage}
        sx={{
          marginTop: "22px",
        }}
      />
      <PasswordTextField
        id="password"
        name="password"
        onChange={onInputChange}
        // onBlur={onFocusOut}
        label="Parolă"
        value={userInput.password.value}
        error={userInput.password.error}
        helperText={userInput.password.errorMessage}
      />
      <PasswordTextField
        id="confirmPassword"
        name="confirmPassword"
        onChange={onInputChange}
        // onBlur={onFocusOut}
        label="Repetă parola"
        value={userInput.confirmPassword.value}
        error={userInput.confirmPassword.error}
        helperText={userInput.confirmPassword.errorMessage}
      />
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <div>
              <Checkbox
                id="acceptTerms"
                className="checkbox"
                color="secondary"
                onChange={onCheckboxChange}
                checked={userInput.acceptTerms}
              />
              <CheckboxEl label="Am citit și sunt de acord cu " href="/terms" hrefText="Termenii și Condițiile" />
            </div>
          }
          label=""
        />
        <FormControlLabel
          control={
            <div>
              <Checkbox
                id="acceptPoC"
                className="checkbox"
                color="secondary"
                onChange={onCheckboxChange}
                checked={userInput.acceptPoC}
              />
              <CheckboxEl
                label="Am citit și sunt de acord cu "
                href="/privacy"
                hrefText="Politica de Confidențialitate și GDPR"
              />
            </div>
          }
          label=""
        />
      </div>
      <SubmitButton disabled={false} label="Creează un cont nou" onSubmit={handleSubmit} />
    </>
  );
};

export default RegisterComponent;

const getAuthFieldsFromLocalStorage = () => {
  const listingObjInitial = localStorage.getItem("listingObj");

  const listingObj = listingObjInitial ? JSON.parse(listingObjInitial) : {};

  if (!listingObj) {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    };
  }

  return {
    firstName: listingObj.ownerFirstName,
    lastName: listingObj.ownerLastName,
    phone: listingObj.ownerPhone,
    email: listingObj.ownerEmail,
  };
};
