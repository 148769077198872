import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledMain = styled("main")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const StyledAppLayout = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));
