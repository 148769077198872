import { useEffect } from "react";

export const useKeyListener = (key: string, callback: (ev?: any) => any) => {
  useEffect(() => {
    document.addEventListener("keydown", (pressedKey: any) => {
      if (pressedKey.code === key) {
        callback(pressedKey);
      }
    });

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);
};
