export const sm = {
  sm_satu_mare: {
    id: "sm_satu_mare",
    lat: 47.78741106465754,
    lng: 22.875743600008917,
    zoom: 10,
    countyId: "sm",
    name: "Satu Mare",
    countyName: "Satu Mare",
  },
};
