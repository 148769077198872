import { compareDateWithToday } from "./helpers";

export const emptyValidator = (_value: string, ..._: any) => {
  const isValid = true;
  return { isValid, error: !isValid, errorMessage: isValid ? "" : "Câmp obligatoriu" };
};

export const validateEmail = (value: string, ..._: any) => {
  const regexEmail = /^[\w-\+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const isValid = !!value.match(regexEmail); // || !value;

  return { isValid, error: !isValid, errorMessage: isValid ? "" : "Adresă de email invalidă" };
};

export const validateName = (value: string, ..._: any) => {
  const regexName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  const isValid = !!value.match(regexName); // || !value;
  return { isValid, error: !isValid, errorMessage: isValid ? "" : "Nume invalid" };
};

export const validatePassword = (value: string, ..._: any) => {
  // Minimum 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number
  const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;

  const isValid = !!value.match(regexPassword); // || !value;

  const has8CharsOrLonger = value.length >= 8 && value.length <= 32;
  const hasUppercaseLetter = /[A-Z]/.test(value);
  const hasLowercaseLetter = /[a-z]/.test(value);
  const hasNumber = /\d/.test(value);

  return {
    isValid,
    error: !isValid,
    errorMessage: isValid
      ? ""
      : `Parola trebuie să conțină ${has8CharsOrLonger ? "" : "între 8 și 32 de caractere"}${
          hasUppercaseLetter ? "" : ", o literă mare"
        }${hasLowercaseLetter ? "" : ", o literă mică"}${hasNumber ? "" : ", un număr"}`.replaceAll(" , ", " "),
  };
};

export const validateConfirmPassword = (value: string, ...rest: any) => {
  const confirmPassword = rest[0];
  const isValid = value === confirmPassword || !confirmPassword;
  return { isValid, error: !isValid, errorMessage: isValid ? "" : "Parolele nu coincid" };
};

export const validatePhone = (value: string, ..._: any) => {
  // Ends with 9 digits
  // const regexPhone = /^d{9}$/;
  const regexPhone = /^\d{9}$/;
  const isValid = !!value.match(regexPhone);

  return {
    isValid,
    error: !isValid,
    errorMessage: isValid ? "" : "Numărul de telefon în formatul +xx 0xxx xxx xxx (ex. +40 0722 333 444)",
  };
};

export const validateNumber = (value: string, ..._: any) => {
  const regexNumber = /^\d+$/;
  const isValid = !!value.match(regexNumber); // || !value;
  return { isValid, error: !isValid, errorMessage: isValid ? "" : "Număr invalid" };
};

export const validatePositiveNumber = (value: string, ..._: any) => {
  const regexNumber = /^\d+$/;
  const hasLeadingZero = value.length > 1 && value[0] === "0";
  const isValid = !!value.match(regexNumber) && !hasLeadingZero; // || !value;
  return { isValid, error: !isValid, errorMessage: isValid ? "" : "Număr invalid" };
};

export const validatePositiveNumberNoZeroPrefix = (value: string, ..._: any) => {
  const regexNumber = /^[1-9]\d*$/;
  const isValid = !!value.match(regexNumber); // || !value;
  return { isValid, error: !isValid, errorMessage: isValid ? "" : "Număr invalid" };
};

export const validateYear = (value: string, ..._: any) => {
  const regexYear = /^(18|19|20)\d{2}$/;

  const validatedNumber = validatePositiveNumberNoZeroPrefix(value);
  if (validatedNumber.error) {
    return validatedNumber;
  }

  const currentYear = new Date().getFullYear();
  const isBiggerThanCurrentYear = parseInt(value, 10) > currentYear;
  const isInNearFuture = isBiggerThanCurrentYear && parseInt(value, 10) < 2200;
  const isTooLow = parseInt(value, 10) < 1800;

  const isValid = !!value.match(regexYear) && !isBiggerThanCurrentYear && !isTooLow; // || !value;

  return {
    isValid,
    error: !isValid,
    errorMessage: isValid
      ? ""
      : isTooLow
      ? "Introdu un an între 1800 și prezent"
      : isBiggerThanCurrentYear
      ? isInNearFuture
        ? "Anul nu poate fi în viitor"
        : "An invalid"
      : "An invalid",
  };
};

// export const validateDescription = (value: string, ..._: any) => {
//   const regexDescription = /^.{0,500}$/;
//   const isValid = !!value.match(regexDescription); // || !value;
//   return { isValid, error: !isValid, errorMessage: isValid ? "" : "Maxim 500 de caractere" };
// };

export const validateTotalFloors = (value: string, ...rest: any) => {
  const floor = rest[0];

  const validatedNumber = validatePositiveNumberNoZeroPrefix(value);
  if (validatedNumber.error) {
    return validatedNumber;
  }

  const isValid = Number(value) >= Number(floor); // || !value;
  const validatedTotalFloors = {
    isValid,
    error: !isValid,
    errorMessage: isValid ? "" : "Numărul de etaje nu poate fi mai mic decât etajul selectat",
  };

  return validatedTotalFloors;
};

export const validateDate = (value: string, ..._: any) => {
  // Validate date of format DD/MM/YYYY to be a valid date and not in the past
  const regexDate = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

  // Convert value to date in format DD/MM/YYYY
  const valueDate = new Date(value).toLocaleDateString("us-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  if (value === "Invalid Date") {
    return {
      isValid: false,
      error: true,
      errorMessage: "Dată invalidă",
    };
  }

  const isInPast = compareDateWithToday(value) === "smaller";
  const isValid = valueDate.match(regexDate);
  const isValidDate = !isInPast && isValid;

  return {
    isValid,
    error: !isValidDate,
    errorMessage: isInPast
      ? "Data nu poate fi în trecut. Introdu o dată în formatul ll/zz/aaaa"
      : isValid
      ? ""
      : "Dată invalidă. Introdu o dată în formatul ll/zz/aaaa",
  };
};
