import InfoIcon from "@mui/icons-material/Info";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { MuiTelInputInfo } from "mui-tel-input";
import { PHONE_PREFIX_RO } from "utils/constants";
import { useScrollToTop } from "common/hooks/helpers";
import { useAppSelector } from "common/hooks";
import { theme } from "theme";
import { PhoneInputComponent } from "components/PhoneInputComponent";
import { AddListingPagePropsType } from "common/types/listing";
import {
  updateUserInputFromLocalStorage,
  useUpdateLocalStorageWithUserInput,
  useValidateUserInput,
} from "../utils/hooks";
import { AddListingTitle } from "../components/AddListingTitle";
import { StyledAddListingWrapper } from "./styles";
import { ContactFieldsType } from "./sections-types";
import { contactFields } from "./sections-data";

type FieldsType = {
  [K in ContactFieldsType]: {
    value: string;
    label: string;
    required: boolean;
    validator: (value: string) => { error: boolean; errorMessage: string };
    touched?: boolean;
    error?: boolean;
    errorMessage?: string;
  };
};

export const ContactDetails = (props: AddListingPagePropsType) => {
  const { onSetIsValid } = props;

  useScrollToTop();
  const { user } = useAppSelector((state) => state.authSlice);

  const [userInput, setUserInput] = useState<FieldsType>(() =>
    updateUserInputFromLocalStorage({ fields: contactFields })
  );

  useEffect(() => {
    if (userInput?.ownerPhone.value === "") {
      setUserInput((oldInput) => ({
        ...oldInput,
        ownerPhone: {
          ...oldInput.ownerPhone,
          value: PHONE_PREFIX_RO,
        },
      }));
    }
  }, [userInput]);

  useUpdateLocalStorageWithUserInput({ userInput });
  useValidateUserInput({ userInput, onSetIsValid });
  useUpdateUserFromGlobalStore({ setUserInput });

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | null>,
    error = false,
    errorMessage = ""
  ) => {
    const { id, value } = event?.target || {};
    const key = id as keyof FieldsType;

    setUserInput((oldInput) => ({
      ...oldInput,
      [key]: {
        ...oldInput[key],
        value,
        touched: true,
        error,
        errorMessage,
      },
    }));
  };

  const handleFocusOut = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    const key = id as keyof FieldsType;

    const { validator } = contactFields[key];
    const { error, errorMessage } = validator(value);

    handleInputChange(event, error, errorMessage);
  };

  const handlePhoneChange = (newPhone: string, { nationalNumber }: MuiTelInputInfo) => {
    const { error, errorMessage } = contactFields.ownerPhone.validator(nationalNumber || "");

    setUserInput((oldInput) => ({
      ...oldInput,
      ownerPhone: {
        ...oldInput.ownerPhone,
        value: newPhone,
        touched: true,
        error,
        errorMessage,
      },
    }));
  };

  return (
    <>
      <AddListingTitle
        title="Contact"
        subtitle="Numele și numărul de telefon vor fi afișate pe site pentru a putea fi contactat de către cei interesați de anunțul tău. Emailul nu va fi afișat pe site."
      />
      <StyledAddListingWrapper>
        {Object.entries(userInput).map(
          ([id, { value, label, required, error = false, errorMessage = "", touched }]) => {
            const key = id as keyof FieldsType;

            if (key === "ownerPhone") {
              return (
                <PhoneInputComponent
                  key={key}
                  value={String(value)}
                  isError={error}
                  errorMessage={errorMessage}
                  touched={touched}
                  onChange={handlePhoneChange}
                  disabled={!!user}
                />
              );
            }

            return (
              <TextField
                error={error}
                size="small"
                key={key}
                id={key}
                variant="outlined"
                label={label}
                helperText={errorMessage}
                value={value}
                type="text"
                required={required}
                fullWidth
                onChange={handleInputChange}
                onBlur={handleFocusOut}
                disabled={!!user && (key === "ownerFirstName" || key === "ownerLastName" || key === "ownerEmail")}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: theme.palette.grey[400],
                  },
                }}
              />
            );
          }
        )}
        {user && <InfoSection />}
      </StyledAddListingWrapper>
    </>
  );
};

const InfoSection = () => (
  <div
    style={{
      fontSize: "12px",
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      gap: "5px",
    }}
  >
    <InfoIcon />
    Datele de contact au fost completate automat din contul tău.
  </div>
);

const getPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return PHONE_PREFIX_RO;

  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength === 12) return phoneNumber;

  // Add PHONE_PREFIX_RO and take last 10 digits from phone number (in case it's incomplete)
  return `${PHONE_PREFIX_RO}${phoneNumber.substring(phoneNumberLength - 10, phoneNumberLength)}`;
};

const useUpdateUserFromGlobalStore = ({ setUserInput }: { setUserInput: Dispatch<SetStateAction<FieldsType>> }) => {
  const { user } = useAppSelector((state) => state.authSlice);

  useEffect(() => {
    if (user) {
      setUserInput((oldInput) => ({
        ownerFirstName: {
          ...oldInput.ownerFirstName,
          value: user.firstName,
          touched: true,
        },
        ownerLastName: {
          ...oldInput.ownerLastName,
          value: user.lastName,
          touched: true,
        },
        ownerEmail: {
          ...oldInput.ownerEmail,
          value: user.email,
          touched: true,
        },
        ownerPhone: {
          ...oldInput.ownerPhone,
          value: getPhoneNumber(user.phoneNumber),
          touched: true,
          error: false,
          errorMessage: "",
        },
      }));
    }
  }, [user]);
};
