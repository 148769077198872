export const ar = {
  ar_ar: {
    id: "ar_ar",
    lat: 46.17728759862162,
    lng: 21.31143301899221,
    zoom: 10,
    countyId: "ar",
    name: "Arad",
    countyName: "Arad",
  },
};
