import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import Hotjar from "@hotjar/browser";
import App from "./App";
import { store } from "./redux/store";

const siteId = 3731662;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

// Can't use strict mode with GoogleMapReact because it causes lots of errors
// Need to use ReactDOM.render because of flickering bug https://github.com/google-map-react/google-map-react/issues/1117
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root") as HTMLElement
);

// Should switch to the approach below whenever the flickering bug with GoogleMapReact is fixed
// import { createRoot } from "react-dom/client";
// import "./index.css";
// import { Provider } from "react-redux";
// import App from "./App";
// import { store } from "./redux/store";

// const container = document.getElementById("root");
// const root = createRoot(container!); // createRoot(container!) if you use TypeScript
// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>
// );
