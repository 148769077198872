import { useQuery } from "react-query";
import { z } from "zod";
import { QueriesKeys } from "common/types/general";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { AdminApi } from "api/admin/admin-api";
import { useAppSelector } from "common/hooks";
import { LIMITED_CACHING, logZodError } from "../../../utils";
import { useHandleApiError } from "../../../useHandleErrors";

const userBEResponseSchema = z.array(
  z.object({
    createdAt: z.string(), // Date on which the ban entry was created
    email: z.string(),
    first_name: z.string(),
    has_verified_email: z.boolean(),
    id: z.string(),
    last_name: z.string(),
    phone_number: z.string().nullable(),
    updatedAt: z.string(),
    is_banned: z.boolean(),
  })
);

type UserBERequestType = z.infer<typeof userBEResponseSchema>;

export const useGetBannedUsers = () => {
  const { isAdminUser } = useIsAdminUser();
  const { token } = useAppSelector(({ authSlice }) => authSlice);

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["bannedUsers" as QueriesKeys],
    async () => {
      const res = await AdminApi.getBannedUsers(token!);

      const { data } = res.data;
      const parsed = userBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return data;
      }

      return parsed.data;
    },
    {
      ...LIMITED_CACHING,
      enabled: isAdminUser && !!token,
    }
  );

  // Handle errors
  useHandleApiError({ isError, message: "Nu s-a putut obține lista de utilizatori blocați." });

  return { bannedUsers: data as UserBERequestType, isLoading, isSuccess, isError };
};
