import { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { useLoginUser } from "api/user/hooks";
import { saveUserInfo, setToken } from "redux/slices/authSlice";
import { useAppDispatch } from "common/hooks";
import { PasswordTextField } from "components/TextFields/PasswordTextField";
import { SubmitButton } from "./SubmitButton";
import { CheckboxEl } from "./CheckboxComponent";
import { AuthFields } from "../utils/auth-fields";
import { useUpdateAuthInput } from "../utils/update-auth-input";

interface Props {
  onCloseModal: () => void;
}

export const LoginComponent = (props: Props) => {
  const { onCloseModal } = props;

  const { mutate: loginUser } = useLoginUser();
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const dispatch = useAppDispatch();

  const {
    userInput: incomingUserInput,
    onInputChange,
    onFocusOut,
    onCheckboxChange,
  } = useUpdateAuthInput(["email", "loginPassword"], { rememberMe: true });
  const userInput = incomingUserInput as AuthFields & { rememberMe: boolean };

  const handleSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    addLoadingEl("auth");

    loginUser(
      {
        email: userInput.email.value,
        password: userInput.loginPassword.value,
      },
      {
        onSuccess: (res) => {
          removeLoadingEl("auth");
          dispatch(
            setToken({
              token: res.data.data.token,
              rememberMe: userInput.rememberMe,
            })
          );
          dispatch(saveUserInfo({ ...res.data.data.user, token: res.data.data.token }));
          toast.success("Bine ai revenit!");
          onCloseModal();
        },
        onError: (err: any) => {
          let errorMessage = "A apărut o eroare. Încearcă mai târziu.";
          if (err?.response?.status === 401) {
            errorMessage = "Emailul sau parola sunt incorecte.";
          }

          removeLoadingEl("auth");
          toast.error(errorMessage);
        },
      }
    );
  };

  const isDisabled = Object.values(userInput).some((input) => {
    if (typeof input === "boolean") {
      return false;
    }

    return input.error || input.value === "";
  });

  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Adresă de email"
        name="email"
        autoComplete="email"
        autoFocus
        size="small"
        onChange={onInputChange}
        onBlur={onFocusOut}
        value={userInput.email.value}
        error={userInput.email.error}
        helperText={userInput.email.errorMessage}
      />
      <PasswordTextField
        id="loginPassword"
        name="loginPassword"
        label="Parolă"
        onChange={onInputChange}
        onBlur={onFocusOut}
        value={userInput.loginPassword.value}
        error={userInput.loginPassword.error}
        helperText={userInput.loginPassword.errorMessage}
      />
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <div>
              <Checkbox
                id="rememberMe"
                className="checkbox"
                color="secondary"
                onChange={onCheckboxChange}
                checked={userInput.rememberMe}
              />
              <CheckboxEl label="Ține-mă minte" />
            </div>
          }
          label=""
        />
      </div>
      <SubmitButton disabled={isDisabled} label="Intră în cont" onSubmit={handleSubmit} />
    </>
  );
};

export default LoginComponent;
