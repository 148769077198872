import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { theme } from "theme";
import { ConfirmationDialog } from "./confirmation-dialog/confirmation-dialog";

export const CookieBannerConsent = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showCookieNotice, setShowCookieNotice] = useState(false);

  const closeCookieNotice = () => {
    const nowDate = new Date();
    const expireDate = new Date(nowDate.setDate(nowDate.getDate() + 30)).toUTCString();
    document.cookie = `cookie_notice=0;path=/;expires=${expireDate};SameSite=Lax;`;

    if (document.getElementById("cookie-notice")) {
      // document.getElementById("cookie-notice")!.style.display = "none";
      setShowCookieNotice(false);
    }

    localStorage.setItem("cookie_notice", "1");
  };

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      const cookieNotice = `; ${document.cookie}`.split("; cookie_notice=").pop()?.split(";")[0];

      if (cookieNotice !== "0") {
        // document.getElementById("cookie-notice")!.style.display = "flex";
        setShowCookieNotice(true);
      }
    });
  }, []);

  return (
    <>
      <Container
        id="cookie-notice"
        style={{
          display: showCookieNotice ? "flex" : "none",
        }}
      >
        <div>
          Folosim cookie-uri pentru a vă oferi cea mai bună experiență pe site-ul nostru. Prin continuarea navigării,
          sunteți de acord cu utilizarea cookie-urilor.
          <button type="button" onClick={() => setShowDetails(true)} className="cookie-notice-more">
            Ce sunt cookie-urile?
          </button>
        </div>
        <button type="button" className="cookie-notice-close" onClick={closeCookieNotice}>
          OK
        </button>
      </Container>
      <ConfirmationDialog
        title="Cookie-urile HTTP"
        labelPrimary="Închide"
        open={showDetails}
        onCancel={() => setShowDetails(false)}
        onPrimary={() => setShowDetails(false)}
        onSecondary={() => setShowDetails(false)}
        variant="info"
      >
        <h2>Ce sunt cookie-urile HTTP</h2>
        <p>
          Cookie-urile HTTP (cunoscute și ca cookie-uri web, cookie-uri Internet, cookie-uri pentru browser sau simplu
          cookie-uri) sunt mici blocuri de date create de un server web în timp ce un utilizator navighează pe un site
          și plasate pe computerul sau dispozitivul utilizatorului de către browserul web al acestuia. Cookie-urile sunt
          plasate pe dispozitivul utilizat pentru a accesa un site web, și mai mult de un cookie poate fi plasat pe
          dispozitivul unui utilizator în timpul unei sesiuni.
        </p>

        <p>
          Cookie-urile îndeplinesc funcții utile și uneori esențiale pe web. Ele permit serverelor web să stocheze
          informații cu stare (cum ar fi articolele adăugate în coșul de cumpărături într-un magazin online) pe
          dispozitivul utilizatorului sau să urmărească activitatea de navigare a utilizatorului (inclusiv apăsarea
          anumitor butoane, autentificarea sau înregistrarea paginilor vizitate anterior). Ele pot fi folosite de
          asemenea pentru a salva pentru utilizare ulterioară informații pe care utilizatorul le-a introdus anterior în
          câmpurile de formular, cum ar fi numele, adresele, parolele și numerele cardurilor de plată.
        </p>

        <h3>Tipuri de cookie-uri</h3>
        <p>
          Cookie-urile de autentificare sunt utilizate frecvent de serverele web pentru a autentifica faptul că un
          utilizator este logat și cu ce cont este logat. Fără cookie, utilizatorii ar trebui să se autentifice
          logându-se pe fiecare pagină care conține informații sensibile pe care doresc să le acceseze. Securitatea unui
          cookie de autentificare depinde în general de securitatea site-ului web emitent și de browserul web al
          utilizatorului, și de faptul că datele cookie sunt criptate sau nu.
        </p>

        <p>
          Când utilizatorul vizitează pagina de logare a unui site web, serverul web trimite în mod tipic clientului un
          cookie ce conține un identificator unic de sesiune. Când utilizatorul se loghează cu succes, serverul își
          amintește că acel identificator de sesiune a fost autentificat și oferă utilizatorului acces la serviciile
          sale.
        </p>

        <p>
          Deoarece cookie-urile de sesiune conțin doar un identificator unic de sesiune, acest lucru face ca cantitatea
          de informații personale pe care un site web le poate salva despre fiecare utilizator să fie practic nelimitată
          — site-ul web nu este limitat de restricțiile referitoare la cât de mare poate fi un cookie. Cookie-urile de
          sesiune ajută de asemenea la îmbunătățirea timpilor de încărcare a paginilor, deoarece cantitatea de
          informații dintr-un cookie de sesiune este mică și necesită puțină lățime de bandă.
        </p>

        <p>
          Cookie-uri de personalizare. Cookie-urile pot fi utilizate pentru a reține informații despre utilizator în
          scopul de a-i afișa conținut relevant acestuia în timp. De exemplu, un server web ar putea trimite un cookie
          ce conține numele de utilizator care a fost folosit ultima dată pentru a se loga pe un site web, astfel încât
          acesta să fie completat automat data viitoare când utilizatorul se loghează.
        </p>

        <p>
          Multe site-uri web utilizează cookie-uri pentru personalizare pe baza preferințelor utilizatorului.
          Utilizatorii își selectează preferințele introducându-le într-un formular web și trimițând formularul către
          server. Serverul codifică preferințele într-un cookie și trimite cookie-ul înapoi către browser. În acest mod,
          de fiecare dată când utilizatorul accesează o pagină pe site web, serverul poate personaliza pagina conform
          preferințelor utilizatorului.
        </p>

        <p>
          Cookie-urile de urmărire, și în special cookie-urile de urmărire terță parte, sunt utilizate frecvent ca
          metode de a compila înregistrări pe termen lung ale istoriilor de navigare ale indivizilor.
        </p>

        <p>
          Cookie-urile de urmărire sunt utilizate pentru a urmări obiceiurile de navigare web ale utilizatorilor. Acest
          lucru poate fi realizat și într-o oarecare măsură folosind adresa IP a computerului care solicită pagina sau
          câmpul referer al antetului de cerere HTTP, dar cookie-urile permit o mai mare precizie. Acest lucru poate fi
          demonstrat astfel:
        </p>

        <ul>
          <li>
            Dacă utilizatorul solicită o pagină a site-ului, dar cererea nu conține niciun cookie, serverul presupune că
            aceasta este prima pagină vizitată de utilizator. Astfel, serverul creează un identificator unic (de obicei
            un șir de litere și numere aleatoare) și îl trimite ca un cookie înapoi la browser împreună cu pagina
            solicitată.
          </li>
          <li>
            De la acest punct, cookie-ul va fi trimis automat de browser către server de fiecare dată când o nouă pagină
            de pe site este solicitată. Serverul nu doar trimite pagina ca de obicei, dar de asemenea stochează URL-ul
            paginii solicitate, data/ora cererii, și cookie-ul într-un fișier log.
          </li>
          <li>
            Analizând acest fișier log, este atunci posibil de a afla ce pagini a vizitat utilizatorul, în ce secvență
            și pentru cât timp.
          </li>
        </ul>

        <h3>Gestionarea cookie-urilor</h3>
        <p>
          Cele mai multe browsere permit să gestionați cum sunt setate și utilizate cookie-urile în timp ce navigați, și
          să ștergeți cookie-urile și datele de navigare. De asemenea, browserul dvs. poate avea setări care vă permit
          să gestionați cookie-urile pe bază de site cu site.
        </p>

        <p>
          De exemplu, setările Google Chrome (și alte browsere bazate pe Chrome) și Mozilla Firefox vă permit să
          ștergeți cookie-urile existente, să permiteți sau blocați toate cookie-urile, și să setați preferințele pentru
          cookie-uri pentru site-uri web.
        </p>

        <p>
          Cele mai multe dintre browserele moderne au modul Incognito, care nu stochează istoricul browserului de
          site-uri vizitate sau cookie-urile pe dispozitivul dvs. după ce închideți toate ferestrele
          &quot;Incognito&quot;.
        </p>
        <span>Sursa: https://about-cookies.eu.org/</span>
      </ConfirmationDialog>
    </>
  );
};

const Container = styled("div")`
  border-radius: 0.5rem;
  align-items: center;
  position: fixed;
  gap: 16px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  margin: auto;
  border: 1px solid #eee;
  background-color: #fefefe;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.05);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 22px;
  font-size: 15px;
  text-align: center;
  color: ${theme.palette.grey[700]};
  box-shadow: ${theme.shadows[1]};

  width: 100%;
  padding: 0.75rem 1.25rem;
  box-sizing: border-box;

  .cookie-notice-more {
    margin: 0 0.25rem;
    text-decoration: underline;
    text-decoration-style: dashed;
    color: inherit;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    font-size: inherit;

    &:hover {
      color: #000;
      border-color: #000;
    }
  }

  .cookie-notice-close {
    padding: 0 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.125rem;
    line-height: 20px;
    text-decoration: none;
    color: #888;
    cursor: pointer;
  }

  ${theme.breakpoints.up("md")} {
    max-width: 500px;
    margin-bottom: 1rem;
  }
`;
