import { Button, Dialog, IconButton, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-material-ui-carousel";
import { useState } from "react";
import { useAppDispatch, useAppSelector, useBasePathUrl, useWindowDimensions } from "common/hooks";
import { theme } from "theme";
import { setOpenedModal } from "redux/slices/appSlice";
import { CampaignTermsModal } from "./campaign-terms-modal";

type UserType = "owner" | "tenant";

export const OnboardingModal = () => {
  const dispatch = useAppDispatch();
  const { goToBasePath, updateBasePath } = useBasePathUrl();

  const { isMobile } = useWindowDimensions();

  const getUserType = () => localStorage.getItem("userType") as UserType | null;

  const [termsOpen, setTermsOpen] = useState(false);
  const [userType, setUserType] = useState<UserType | null>(() => getUserType());

  const { openedModal } = useAppSelector((state) => state.appSlice);

  const handleCloseModal = () => {
    goToBasePath({});
    dispatch(setOpenedModal(null));
    localStorage.setItem("onboardingModal", "true");

    if (userType === "owner") {
      setTimeout(() => {
        dispatch(setOpenedModal("campaign"));
      }, 5000);
    }
  };

  const handleAddListing = () => {
    updateBasePath({
      suffix: "add",
    });
    dispatch(setOpenedModal(null));
  };

  const toggleUserType = () => {
    setUserType((prev) => {
      const newUserType = prev === "owner" ? "tenant" : "owner";

      localStorage.setItem("userType", newUserType);

      return newUserType;
    });
  };

  return (
    <>
      <StyledUserProfileDialog open={openedModal === "onboarding"} onClose={handleCloseModal}>
        <Stack alignItems="flex-end">
          <IconButton onClick={handleCloseModal}>
            <CloseIcon sx={{ color: "gray" }} />
          </IconButton>
        </Stack>
        {/* <Stack spacing={10} direction={{ xs: "column", md: "row" }} alignItems={{ xs: "center", md: "flex-start" }}> */}
        {userType === "owner" ? (
          <StyledCarousel navButtonsAlwaysVisible={!isMobile} autoPlay={!termsOpen} swipe>
            <div className="onboarding-entry">
              <img src="/images/onboarding/1.svg" alt="" />
              <h1>Am o locuință de închiriat</h1>
              <p>Platformă specializată în conectarea proprietarilor cu potențialii chiriași.</p>
              <div className="onboarding-buttons">
                <Button variant="outlined" color="secondary" onClick={toggleUserType}>
                  Sunt chiriaș
                </Button>
                <Button variant="contained" onClick={handleAddListing}>
                  Adaugă un anunț
                </Button>
              </div>
            </div>
            <div className="onboarding-entry">
              <img src="/images/onboarding/2.svg" alt="" />
              <h1>Am o locuință de închiriat</h1>
              <p>Fără comisioane, fără intermediari!</p>
              <div className="onboarding-buttons">
                <Button variant="outlined" color="secondary" onClick={toggleUserType}>
                  Sunt chiriaș
                </Button>
                <Button variant="contained" onClick={handleAddListing}>
                  Adaugă un anunț
                </Button>
              </div>
            </div>
            <div className="onboarding-entry">
              <img src="/images/onboarding/3.svg" alt="" />
              <h1>Am o locuință de închiriat</h1>
              <p>
                Postează o singură dată, iar anunțul tău vă apărea în topul rezultatelor conform criteriilor de căutare
                ale chiriașilor indiferent de vechimea lui. Gata cu anunțurile duplicate pentru a te menține relevant.
              </p>
              <div className="onboarding-buttons">
                <Button variant="outlined" color="secondary" onClick={toggleUserType}>
                  Sunt chiriaș
                </Button>
                <Button variant="contained" onClick={handleAddListing}>
                  Adaugă un anunț
                </Button>
              </div>
            </div>
            <div className="onboarding-entry">
              <img src="/images/campaign/1.svg" alt="" />
              <img className="medal" src="/images/campaign/medal.svg" alt="" />
              <h1>Am o locuință de închiriat</h1>
              <p>
                La adăugarea unui anunț, te înscrii automat la tombola pentru a câștiga <b>500 RON</b> dacă postezi
                anunțul până pe 24 Decembrie 2023. Se aplică următorii{" "}
                <button className="terms" type="button" onClick={() => setTermsOpen(true)}>
                  termeni și condiții
                </button>
                .
              </p>
              <div className="onboarding-buttons">
                <Button variant="outlined" color="secondary" onClick={toggleUserType}>
                  Sunt chiriaș
                </Button>
                <Button variant="contained" onClick={handleAddListing}>
                  Adaugă un anunț
                </Button>
              </div>
            </div>
          </StyledCarousel>
        ) : (
          <StyledCarousel navButtonsAlwaysVisible={!isMobile} autoPlay={!termsOpen} swipe>
            <div className="onboarding-entry">
              <img src="/images/onboarding/4.svg" alt="" />
              <h1>Îmi caut o chirie</h1>
              <p>Platformă specializată în găsirea următoarei tale chirii!</p>
              <div className="onboarding-buttons">
                <Button variant="outlined" color="secondary" onClick={toggleUserType}>
                  Sunt proprietar
                </Button>
                <Button variant="contained" onClick={handleCloseModal}>
                  Vezi toate anunțurile
                </Button>
              </div>
            </div>
            <div className="onboarding-entry">
              <img src="/images/onboarding/5.svg" alt="" />
              <h1>Îmi caut o chirie</h1>
              <p>Găsește chiria potrivită pentru tine. Direct de la proprietar, fără comisioane, fără intermediari!</p>
              <div className="onboarding-buttons">
                <Button variant="outlined" color="secondary" onClick={toggleUserType}>
                  Sunt proprietar
                </Button>
                <Button variant="contained" onClick={handleCloseModal}>
                  Vezi toate anunțurile
                </Button>
              </div>
            </div>
            <div className="onboarding-entry">
              <img src="/images/onboarding/6.svg" alt="" />
              <h1>Îmi caut o chirie</h1>
              <p>
                Primești notificări de îndată ce apar anunțuri conform criteriilor alese de tine. Fără anunțuri
                duplicate.
              </p>
              <div className="onboarding-buttons">
                <Button variant="outlined" color="secondary" onClick={toggleUserType}>
                  Sunt proprietar
                </Button>
                <Button variant="contained" onClick={handleCloseModal}>
                  Vezi toate anunțurile
                </Button>
              </div>
            </div>
          </StyledCarousel>
        )}
        <div className="fading-gradient" />
      </StyledUserProfileDialog>
      <CampaignTermsModal open={termsOpen} onClose={() => setTermsOpen(false)} />
    </>
  );
};

const StyledCarousel = styled(Carousel)`
  overflow: auto;
  height: 100%;

  > div {
    height: 100%;

    .onboarding-entry {
      // background-image: url("/images/onboarding/1.svg");
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: center;
      text-align: center;
      position: relative;
      height: 100%;
      min-height: 450px;

      > img {
        max-height: 175px;
        height: auto;
        margin-top: 0;
      }

      & .medal {
        position: absolute;
        top: 0;
        left: 10%;
        transform: rotate(-10deg);
        width: 150px;
      }

      > h1,
      > p {
        color: ${theme.palette.grey[700]};
      }

      > p {
        line-height: 170%;

        > b {
          color: green;
        }
      }

      > h1 {
        margin: 0 0 1.5rem 0;
        font-size: 1.5rem;
      }

      .onboarding-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 2rem;

        > button {
          width: 100%;
        }

        ${theme.breakpoints.up("md")} {
          flex-direction: row;

          > button {
            width: auto;
          }
        }
      }

      & .terms {
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        color: ${theme.palette.primary.main};
        text-decoration: underline;
        font-size: inherit;
        font-weight: inherit;
      }

      ${theme.breakpoints.up("md")} {
        > img {
          max-height: 300px;
        }

        > h1 {
          // margin: 2rem 0 1rem 0;
        }
      }
    }
  }
`;

const StyledUserProfileDialog = styled(Dialog)`
  margin: auto 5ch;

  .fading-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #c3c3c3 100%);
  }

  & .MuiDialog-paper {
    height: auto;
    padding: 1rem 1rem 2rem 1rem;
    overflow-y: auto;
    // min-width: 840px;
    z-index: 999999;
    width: 100%;
    border-radius: 8px;
    margin: 0;

    ${theme.breakpoints.down("lg")} {
      // min-width: 600px;
    }

    ${theme.breakpoints.up("sm")} {
      padding: 24px;
      // min-width: 300px;
      // margin-bottom: -20px;
      padding: 32px 32px 64px 32px;
      margin: auto 5vw;
    }
  }

  & .left-side {
    position: relative;
    max-width: 200px;
    color: ${theme.palette.grey[600]};

    & img {
      border-radius: 50%;
      width: 180px;
      height: 180px;
      object-fit: cover;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    }

    & .user-metadata-container {
      padding: 0 11px;

      & .user-metadata-name {
        color: black;
        font-size: 18px;
        font-weight: 500;
      }

      & .user-metadata-member-since {
        font-size: 13px;
        font-weight: 400;
      }

      & .user-metadata-phone {
        font-size: 14px;
        font-weight: 500;
        gap: 4px;

        a {
          color: ${theme.palette.primary.main};
          padding: 0;
          margin: 0;
        }
      }
    }

    & .user-profile-side-menu {
      & .MuiButtonBase-root {
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: ${theme.palette.grey[600]};
      }
    }
  }

  & .right-side {
    // width: 100%;
    // height: 100%;
    display: none; // TODO: Remove this when the right side is ready

    & .user-profile-listings {
      .user-profile-listings-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        > div {
          position: relative;
          margin-bottom: 20px;

          & .price-label {
            color: ${theme.palette.grey[600]};
            background-color: white;
            border-radius: 8px;
            position: absolute;
            z-index: 9999999;
            top: -14px;
            right: 0;
            font-size: 12px;
            padding: 4px 8px;
            font-weight: 500;
          }

          & .own-listing-chip {
            font-size: 12px;
          }

          .from-marker {
            max-width: 135px;
            min-width: 135px;

            & .image-gallery {
              img {
                max-height: 100px;
              }
            }

            & .image-gallery-icon {
              padding: 0;
            }

            & .image-gallery-svg {
              width: 20px;
              height: 20px;
            }

            & .image-gallery-bullets {
              display: none;
            }

            & .image-gallery-content:not(.fullscreen) img {
              height: 300px;
              border-radius: 8px 8px 0 0;
            }

            .listing-card-info-button-wrapper {
              margin: 0;
              background-color: transparent;
              white-space: nowrap;

              > svg {
                padding: 0;
              }

              .listing-card-info {
                grid-template-columns: 1fr;
              }
            }
          }
        }
      }
    }

    & .user-profile-rating {
      > div {
        color: ${theme.palette.grey[400]};
      }
    }
  }

  ${theme.breakpoints.down("lg")} {
    .right-side {
    }
  }
`;
