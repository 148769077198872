import * as React from "react";
import { Helmet } from "react-helmet";
import { PageWrapper } from "components/PageWrapper";

export const TermsAndConditions = () => {
  return (
    <PageWrapper title="Termeni și Condiții">
      <Helmet>
        <title>Termeni si Conditii</title>
        <meta
          name="description"
          content="Termenii si conditiile de utilizare a platformei de inchirieri imobiliare direct de la proprietari."
        />
      </Helmet>
      <p>
        1. Utilizarea acestei platforme implică acceptarea acestor Termeni și Condiții. Dacă nu sunteți de acord cu
        aceste Termeni și Condiții, vă rugăm să nu utilizați această platformă.
      </p>
      <p>
        2. Platforma noastră oferă acces la informații despre proprietăți imobiliare disponibile pentru închiriere sau
        vânzare. Utilizatorii pot căuta, vizualiza și contacta proprietarii pentru a obține informații suplimentare sau
        pentru a face o ofertă.
      </p>
      <p>
        3. Informațiile prezentate pe platforma noastră sunt furnizate de către proprietari și pot fi modificate sau
        actualizate în orice moment. Platforma noastră nu poate garanta acuratețea sau completitudinea acestor
        informații.
      </p>
      <p>
        4. Utilizatorii sunt responsabili pentru verificarea preciziei și completitudinii informațiilor furnizate de
        către proprietari . Platforma noastră nu poate fi considerată responsabilă pentru orice erori sau omisiuni în
        informațiile prezentate.
      </p>
      <p>
        5. Utilizatorii sunt responsabili pentru conținutul acestor anunțuri și sunt de acord să nu posteze conținut
        nepotrivit, dăunător sau ilegal. Platforma noastră își rezervă dreptul de a șterge orice conținut considerat
        nepotrivit sau ilegal.
      </p>
      <p>
        6. Utilizatorii sunt responsabili pentru orice negociere sau tranzacție efectuată cu proprietarii prin
        intermediul platformei noastre. Platforma noastră nu poate fi considerată responsabilă pentru orice dispută sau
        problemă care ar putea să apară în urma unei tranzacții.
      </p>
      <p>
        7. Utilizatorii sunt de acord să nu copieze, reproducă, vândă sau distribuie conținutul platformei noastre fără
        permisiunea noastră explicită. Utilizatorii sunt de acord să respecte drepturile de autor și proprietate
        intelectuală ale platformei și ale proprietarilor care au furnizat informațiile prezentate pe platformă.
      </p>
      <p>
        8. Platforma noastră poate furniza link-uri către alte site-uri web sau resurse. Utilizatorii sunt de acord că
        platforma noastră nu poate fi considerată responsabilă pentru conținutul sau disponibilitatea acestor site-uri
        sau resurse.
      </p>
      <p>
        9. Platforma noastră își rezervă dreptul de a modifica sau actualiza acești Termeni și Condiții oricând, fără
        notificare prealabilă. Pentru utilizatorii înregistrați, modificările vor fi anunțate la accesarea platformei
        ori prin intermediul unui email trimis pe adresa folosită la creearea contului. Utilizatorii sunt de acord să se
        conformeze cu orice modificări sau actualizări efectuate.
      </p>
      <p>
        10. Dacă orice clauză din acesti Termeni și Condiții este considerată nulă sau neexecutabilă, acea clauză va fi
        eliminată din acești Termeni și Condiții fără a afecta valabilitatea celorlalte clauze.
      </p>
      <p>
        11. Proprietarii sunt obligați să furnizeze cu exactitate adresa imobilului în cauză atunci când îl listează pe
        platforma noastră pentru închiriere sau vânzare. Proprietarii sunt responsabili pentru verificarea preciziei
        informațiilor furnizate și pentru actualizarea acestora în cazul în care există modificări.
      </p>
      <p>
        12. Platforma noastră nu poate fi considerată responsabilă pentru orice erori sau inexactități în informațiile
        furnizate de către proprietari, cum ar fi adresa imobilului, și nu poate fi trasă la răspundere pentru orice
        consecințe negative care ar putea să apară din acest motiv.
      </p>
      <p>
        13. Proprietarii care furnizează informații inexacte sau false despre adresa imobilului pot fi eliminați de pe
        platforma noastră.
      </p>
      <p>
        14. Utilizarea acestei platforme este supusă legilor din Romania. Orice dispută care ar putea să apară în
        legătură cu utilizarea acestei platforme va fi rezolvată prin intermediul instanțelor din Romania.
      </p>
    </PageWrapper>
  );
};
