const CITY_ZOOM = 13;

export const b = {
  b_bucharest: {
    id: "b_bucharest",
    lat: 44.42809351726233,
    lng: 26.10252656566445,
    zoom: 10,
    countyId: "b",
    name: "București",
    countyName: "București",
  },
  b_sector1: {
    id: "b_sector1",
    lat: 44.4880501,
    lng: 26.0122289,
    zoom: CITY_ZOOM,
    countyId: "b",
    name: "Sectorul 1",
    countyName: "București",
  },
  b_sector2: {
    id: "b_sector2",
    lat: 44.4596413,
    lng: 26.1316022,
    zoom: CITY_ZOOM,
    countyId: "b",
    name: "Sectorul 2",
    countyName: "București",
  },
  b_sector3: {
    id: "b_sector3",
    lat: 44.4182226,
    lng: 26.1255851,
    zoom: CITY_ZOOM,
    countyId: "b",
    name: "Sectorul 3",
    countyName: "București",
  },
  b_sector4: {
    id: "b_sector4",
    lat: 44.3819546,
    lng: 26.0902114,
    zoom: CITY_ZOOM,
    countyId: "b",
    name: "Sectorul 4",
    countyName: "București",
  },
  b_sector5: {
    id: "b_sector5",
    lat: 44.4037531,
    lng: 26.0122368,
    zoom: CITY_ZOOM,
    countyId: "b",
    name: "Sectorul 5",
    countyName: "București",
  },
  b_sector6: {
    id: "b_sector6",
    lat: 44.4429521,
    lng: 25.9834716,
    zoom: CITY_ZOOM,
    countyId: "b",
    name: "Sectorul 6",
    countyName: "București",
  },
};
