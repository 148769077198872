import { useAppDispatch, useAppSelector } from "common/hooks";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useAddListingStepper } from "pages/AddListing/utils/useStepper";
import { setOpenedModal } from "redux/slices/appSlice";

export const AddListingFailureModal = () => {
  const dispatch = useAppDispatch();
  const { onWindowClose } = useAddListingStepper();
  const { openedModal } = useAppSelector((state) => state.appSlice);

  const handleClose = () => {
    dispatch(setOpenedModal(null));
    onWindowClose();
  };

  return (
    <ConfirmationDialog
      title="Anunțul nu a putut fi adăugat!"
      variant="warning"
      open={openedModal === "add-listing-failure"}
      onPrimary={handleClose}
      onCancel={handleClose}
      onSecondary={handleClose}
      labelPrimary="Închide"
    >
      A apărut o eroare la adăugarea anunțului. Te rugăm să încerci din nou mai târziu sau să ne contactezi la{" "}
      <a href="mailto:contact@kastel.io" target="_blank" rel="noreferrer noopener" style={{ color: "inherit" }}>
        contact@kastel.io
      </a>{" "}
      dacă problema persistă.
    </ConfirmationDialog>
  );
};
