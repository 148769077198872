import { useEffect, useState } from "react";

export const useTimeoutDelay = () => {
  const [timeout, setTimeoutState] = useState(0);
  useEffect(() => {
    let interval: any;

    if (timeout > 0) {
      interval = setInterval(() => {
        setTimeoutState((prevTimeout) => prevTimeout - 1);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timeout]);

  return { timeout, setTimeoutState };
};
