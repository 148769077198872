import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const useTodaysDate = () => {
  const today = new Date();
  // This line sets the time of the current date to the
  // last millisecond, so the comparison returns `true` only if
  // date is at least tomorrow
  today.setHours(23, 59, 59, 998);
  return { today };
};

export const useYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  return { yesterday };
};

// Prevent keeping scroll position when navigating between pages
export function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
