import { useEffect, useState } from "react";
import { Box, Button, styled } from "@mui/material";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { theme } from "theme";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "common/hooks";
import { useAuthModal } from "common/hooks/useAuthModal";
import { ListingSummary } from "components/ListingSummary";
import { useAnalyticsEventTracker } from "common/hooks/useAnalyticsEventTracker";
import { NAVBAR_HEIGHT, NEUTRAL_RED } from "utils/constants";
import { setOpenedModal } from "redux/slices/appSlice";
import {
  BuildingType,
  Partitioning,
  ContactDetails,
  Extra,
  General,
  Images,
  Location,
  Price,
} from "./add-listing-sections";
import { stepperFieldsKeys } from "./utils/addListingData";
import { useAddListingHandlers } from "./utils/useAddListingHandlers";
import { useAddListingStepper } from "./utils/useStepper";
import { AddListingHeader } from "./AddListingHeader";
import { NavigationButtons } from "./components/NavigationButtons";
import { Description } from "./add-listing-sections/Description";

export const AddListing = () => {
  const { isMobile } = useWindowDimensions();
  const { onDisplayAuthModal } = useAuthModal();
  const { activeStep, currentPage, onStepperNext, onStepperPrev, onStepperReset, onWindowClose } =
    useAddListingStepper();
  const { images, onSubmitListing, onSetImages } = useAddListingHandlers(onWindowClose);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [firstTimeOpen, setIsFirstTimeOpen] = useState(() => !localStorage.getItem("listingObj"));

  const gaEventTracker = useAnalyticsEventTracker("Add Listing");
  useEffect(() => {
    gaEventTracker("Accessed Add Listing Page", "Add Listing");
  }, []);

  const appSlice = useAppSelector(({ appSlice }) => appSlice);
  const authSlice = useAppSelector(({ authSlice }) => authSlice);
  const { loadingElements } = appSlice || {};
  const { user } = authSlice || {};

  const [showNeedAccountToPost, setShowNeedAcountToPost] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const isLastPage = currentPage === stepperFieldsKeys.length - 1;

  const handleRefetchUserDetails = () => {
    queryClient.refetchQueries("userDetails");
  };

  const handleListingSubmit = () => {
    if (!user) {
      onDisplayAuthModal(true);
      return;
    }

    // TODO: This won't work for now. Described here: https://github.com/Kastel-io/kastel-backend/issues/175
    // const myProfilePage = `${basePath}/profile/${user.id}`;

    // This should never be reached; we changed the approach to this problem.
    if (user.hasVerifiedEmail === false) {
      toast.error(
        <span>
          {`Nu poți adăuga un anunț dacă nu ai adresa de email confirmată! Cere un cod de verificare accesând "Contul tău -> Detalii cont" din bara de navigare.`}
        </span>
      );
      return;
    }

    // Post listing. All data except for the images is in the localStorage
    onSubmitListing();
  };

  const showFirstTimeOpenMessage = firstTimeOpen; // && isMobile;

  return (
    <StyledAddListingWrapper mobile={isMobile}>
      <AddListingHeader
        activeStep={activeStep}
        currentPage={showFirstTimeOpenMessage ? -1 : currentPage}
        onStepperReset={onStepperReset}
        onShowConfirmationDialog={() => setShowConfirmationDialog(true)}
      />
      <StyledAddListingContainer className="add-listing-container">
        <StyledBodyContentWrapper
          className="body-content-wrapper"
          sx={{
            "& .MuiInputBase-root": {
              width: "450px",

              // Other than md will break the input width
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            },
            "& .MuiFormHelperText-root": {
              margin: 0,
              paddingLeft: theme.spacing(1),
              color: NEUTRAL_RED,
            },
          }}
        >
          {currentPage === 0 && showFirstTimeOpenMessage && (
            <StyledFirstTimeOpenMessage>
              <h1>Adaugă proprietatea ta pe Kastel.io!</h1>
              {!user ? (
                <>
                  <span>Pentru a putea adăuga o proprietate, trebuie să fii autentificat.</span>
                  <Button variant="outlined" color="primary" onClick={() => onDisplayAuthModal(true)}>
                    Intră în cont sau creează unul nou
                  </Button>
                  {/* <WarningBox type="warning">Doar utilizatorii autentificați pot adăuga anunțuri.</WarningBox> */}
                </>
              ) : !user.hasVerifiedEmail ? (
                <>
                  <span>Este necesar să îți validezi adresa de email pentru a putea adăuga un anunț.</span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleRefetchUserDetails();
                      dispatch(setOpenedModal("validate-email"));
                    }}
                  >
                    Validează adresa de email
                  </Button>
                </>
              ) : (
                <>
                  <span>
                    Avem nevoie de cât mai multe informații pentru a permite viitorului chiriaș să ia o decizie în
                    cunoștință de cauză, pentru a nu vă irosi timpul reciproc cu vizionări inutile.
                    <br />
                    <br />
                    Adăugarea unei proprietăți presupune să trecem prin toți pașii listați în partea de sus a paginii și
                    durează aproximatix 10 minute.{" "}
                    <b>
                      Vestea bună este că o dată ce proprietatea a fost adăugată, nu va mai trebui să repeți procesul
                      niciodată.
                    </b>
                  </span>
                  <Button variant="contained" color="primary" onClick={() => setIsFirstTimeOpen(false)}>
                    Adaugă proprietatea ta acum
                  </Button>
                </>
              )}
            </StyledFirstTimeOpenMessage>
          )}
          {currentPage === 0 && !showFirstTimeOpenMessage && (
            <BuildingType isValid={isValid} onSetIsValid={setIsValid} onGoToNextPage={onStepperNext} />
          )}
          {currentPage === 1 && (
            <Partitioning isValid={isValid} onSetIsValid={setIsValid} onGoToNextPage={onStepperNext} />
          )}
          {currentPage === 2 && <General isValid={isValid} onSetIsValid={setIsValid} />}
          {currentPage === 3 && <Price isValid={isValid} onSetIsValid={setIsValid} />}
          {currentPage === 4 && <ContactDetails isValid={isValid} onSetIsValid={setIsValid} />}
          {currentPage === 5 && <Extra isValid={isValid} onSetIsValid={setIsValid} />}
          {currentPage === 6 && <Description isValid={isValid} onSetIsValid={setIsValid} />}
          {currentPage === 7 && <Location isValid={isValid} onSetIsValid={setIsValid} />}
          {currentPage === 8 && (
            <Images isValid={isValid} onSetIsValid={setIsValid} images={images} onSetImages={onSetImages} />
          )}
          {currentPage === 9 && <ListingSummary listingObj={JSON.parse(localStorage.getItem("listingObj")!)} />}
        </StyledBodyContentWrapper>
        {!showFirstTimeOpenMessage && (
          <NavigationButtons
            {...{
              onStepperNext,
              onStepperPrev,
              currentPage,
              isValid,
              loadingElements,
              isLastPage,
              handleListingSubmit: user ? handleListingSubmit : () => setShowNeedAcountToPost(true),
            }}
          />
        )}
      </StyledAddListingContainer>
      <ConfirmationDialog
        title="Salvezi datele și ieși?"
        variant="info"
        labelPrimary="Salvează și ieși"
        labelSecondary="Nu, ieși fără să salvezi"
        open={showConfirmationDialog}
        onCancel={() => setShowConfirmationDialog(false)}
        onSecondary={onStepperReset}
        onPrimary={onWindowClose}
      >
        Datele introduse până acum pot fi salvate pentru a se putea relua procesul de adăugare a anunțului mai târziu.
      </ConfirmationDialog>
      <ConfirmationDialog
        title="Dorești să te autentifici?"
        variant="info"
        labelPrimary="Da, mă autentific"
        labelSecondary="Nu acum"
        open={showNeedAccountToPost}
        onCancel={() => setShowNeedAcountToPost(false)}
        onSecondary={() => setShowNeedAcountToPost(false)}
        onPrimary={() => {
          setShowNeedAcountToPost(false);
          onDisplayAuthModal(true);
        }}
      >
        E necesar să intri în cont pentru a putea publica anunțul. Datele introduse până acum vor fi salvate și vei
        putea relua procesul de adăugare a anunțului numai după autentificare.
      </ConfirmationDialog>
    </StyledAddListingWrapper>
  );
};

const StyledFirstTimeOpenMessage = styled("div")`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;

  h1 {
    font-size: 24px;
    color: ${theme.palette.grey[800]};
    line-height: 150%;
  }

  span {
    color: ${theme.palette.grey[700]};
    line-height: 150%;
    letter-spacing: 0.65px;
  }

  button {
    margin: 32px auto 12px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
  }

  .no-account-warning {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    > svg {
      color: ${NEUTRAL_RED};
    }

    > span {
      text-align: left;
      line-height: 170%;
      font-size: 15px;
      color: ${theme.palette.grey[600]};
    }
  }
`;

const StyledAddListingWrapper = styled("div")<{ mobile: boolean }>`
  position: absolute;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background-color: white;
  margin-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #e0e0e0;
  box-sizing: border-box;
  top: ${({ mobile }) => (mobile ? `-${NAVBAR_HEIGHT.MOBILE}px` : `-${NAVBAR_HEIGHT.DESKTOP}px`)};
  background-color: ${theme.palette.grey[100]};
`;

const StyledAddListingContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto 100px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0 20px 20px;
  background-color: ${theme.palette.grey[100]};

  ${theme.breakpoints.down("md")} {
    padding-top: 0;
  }
`;

const StyledBodyContentWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  // overflow: auto;
  height: auto;
  width: 100%;
  margin: 20px auto;

  > div {
    margin: 0 auto;
  }

  ${theme.breakpoints.down("md")} {
    width: 100%;
  }
`;
