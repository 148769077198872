export const bn = {
  bn_bistrita: {
    id: "bn_bistrita",
    lat: 47.138037711956805,
    lng: 24.49222677653083,
    zoom: 10,
    countyId: "bn",
    name: "Bistrița",
    countyName: "Bistrița-Năsăud",
  },
};
