import { RefObject, useEffect, useRef } from "react";

export const useOnClickOutside = (elementRef: RefObject<HTMLElement>, callback: (event?: any) => void) => {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Put ids to elements that should not trigger the callback
      const exceptionId = "no-outside-click";
      const idExceptedFromOutsideClick = exceptionId ? (event.target as HTMLElement).id.startsWith(exceptionId) : false;

      // Do nothing if clicking the element or one of its descendents
      if (!elementRef.current || elementRef.current.contains(event.target as Node) || idExceptedFromOutsideClick) {
        return;
      }
      callbackRef.current(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [elementRef]);
};

export const useOnClickOutsideMarkerAndCard = (elementRef: RefObject<HTMLElement>, callback: (event?: any) => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  const hasParentWithIdStart = (_element: HTMLElement | null, idStart: string): boolean => {
    let element = _element;
    while (element) {
      if (element.id.startsWith(idStart)) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  };

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const targetElement = event.target as HTMLElement;

      // Check if the target element or any of its parents has the desired id
      const idExceptedFromOutsideClick = hasParentWithIdStart(targetElement, "marker-basic-listings");

      // Do nothing if clicking the element or one of its descendents
      if (!elementRef.current || elementRef.current.contains(targetElement) || idExceptedFromOutsideClick) {
        return;
      }

      callbackRef.current(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [elementRef]);
};
