import { SyntheticEvent, useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { Coords } from "google-map-react";
import { useScrollToTop } from "common/hooks/helpers";
import { useWindowDimensions } from "common/hooks";
import { updateUserInputFromLocalStorage, useUpdateLocalStorageWithUserInput } from "pages/AddListing/utils/hooks";
import { AddListingTitle } from "pages/AddListing/components/AddListingTitle";
import { useAppParams } from "common/hooks/useAppParams";
import { AddListingPagePropsType } from "common/types/listing";
import { WarningBox } from "components/warning-box";
import { GeocodeComponent, TGeocodeDetails } from "./Geocode";
import { parseSearchResult } from "./parseAddress";
import { DraggableMapContainer } from "./DraggableMap";
import { LocationFieldsType } from "../sections-types";
import { locationFields } from "../sections-data";

type FieldsType = {
  [K in LocationFieldsType]: {
    value: string;
  };
};

export const Location = (props: AddListingPagePropsType) => {
  const { onSetIsValid } = props;
  const { lat: paramsLat, lng: paramsLng } = useAppParams();

  useScrollToTop();

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({});

  const [locationError, setLocationError] = useState(false);
  const [userInput, setUserInput] = useState<FieldsType>(() =>
    updateUserInputFromLocalStorage({
      fields: {
        ...locationFields,
        lat: { value: paramsLat || "" },
        lng: { value: paramsLng || "" },
      },
    })
  );
  useUpdateLocalStorageWithUserInput({ userInput });

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    onSetIsValid(!!userInput.addressFull.value);
  }, [userInput, getPlacePredictions]);

  const resetInput = () => {
    setUserInput(locationFields);
  };

  const handleReset = () => {
    resetInput();
    getPlacePredictions({ input: "" });
  };

  const updateUserInput = (locationDetails: TGeocodeDetails, lat: number, lng: number) => {
    if (Object.keys(locationDetails).length === 0) {
      resetInput();
      return;
    }

    // TODO: Convert county name to county id
    // TODO: Get city name from 'sublocality'
    const { address, streetNumber, cityName, streetName, countyId, countyName, countryId, countryName } =
      locationDetails;

    setUserInput({
      addressFull: { value: parseSearchResult(address) },
      lat: { value: String(lat) },
      lng: { value: String(lng) },
      streetNumber: { value: streetNumber },
      cityName: { value: cityName },
      streetName: { value: streetName },
      countyId: { value: countyId },
      countyName: { value: countyName },
      countryId: { value: countryId },
      countryName: { value: countryName },
    });
  };

  return (
    <>
      <AddListingTitle
        title="Localizare"
        subtitle="Caută adresa dorită și mută markerul pe hartă pentru a preciza locația exactă."
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: isMobile ? "0" : "0 10%",
          height: "100%",
          width: "100%",
          position: "relative",
          boxSizing: "border-box",
        }}
      >
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <Autocomplete
            id="search-address"
            placeholder="Debounce 500 ms"
            disableClearable
            options={placePredictions.map((option) => {
              return option.description;
            })}
            noOptionsText="Nu există opțiuni disponibile"
            loading={isPlacePredictionsLoading}
            loadingText="Se încarcă..."
            onSelect={(event: SyntheticEvent) => {
              handleSelectPredictionFromList({
                event,
                placePredictions,
                placesService,
                onUpdateUserInput: updateUserInput,
                onLocationError: (status: boolean) => setLocationError(status),
              });
            }}
            value={userInput.addressFull?.value?.toString()}
            onChange={(event, newValue, reason) => {
              if (reason === "clear") {
                handleReset();
              }
            }}
            renderInput={(params) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                <TextField
                  {...params}
                  label="Adresa completă"
                  placeholder="Introdu adresa și selectează rezultatul din lista de sugestii"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  fullWidth
                  size="small"
                  onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                  }}
                  sx={{
                    width: isMobile ? "100%" : "auto",
                  }}
                />
                {/* <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    // Make text stay on same line
                    whiteSpace: "nowrap",
                    // marginRight: "auto",
                    marginLeft: "12px",
                  }}
                  onClick={handleReset}
                >
                  Resetează
                </Button> */}
              </div>
            )}
          />
        </FormControl>
        {locationError ? (
          <WarningBox type="warning">
            Ai probleme cu markerul pe hartă? Încearcă o poziție apropiată sau să introduci adresa manual în căsuța de
            mai sus. Dacă problema persistă, ne poți scrie la contact@kastel.io.
          </WarningBox>
        ) : !userInput.addressFull.value ? (
          <WarningBox type="info">
            Introdu adresa în căsuța de mai sus și selectează rezultatul din lista de sugestii. Mută markerul pe hartă
            pentru o localizare mai precisă.
          </WarningBox>
        ) : null}
        <div
          style={{
            marginTop: "12px",
            width: "100%",
          }}
        >
          <DraggableMapContainer
            lat={userInput.lat.value}
            lng={userInput.lng.value}
            onMarkerMovement={(newPos: Coords) =>
              handleMarkerMovement({
                ...newPos,
                onUpdateUserInput: updateUserInput,
                onLocationError: (status) => setLocationError(status),
              })
            }
          />
        </div>
      </div>
    </>
  );
};
interface MarkerMovement {
  lat: number;
  lng: number;
  onLocationError: (status: boolean) => void;
  onUpdateUserInput: (locationDetails: TGeocodeDetails, lat: number, lng: number) => void;
}

// Get new address based on coordinates received from Marker movement
const handleMarkerMovement = async (props: MarkerMovement) => {
  const { lat, lng, onLocationError, onUpdateUserInput } = props;

  const locationDetails = await GeocodeComponent.getAddressFromLatAndLng(String(lat), String(lng));

  if (!locationDetails) {
    onUpdateUserInput({} as TGeocodeDetails, lat, lng);
    return;
  }

  if (locationDetails.address === "" || locationDetails.cityName === "") {
    onLocationError(true);
    return;
  }

  onLocationError(false);

  onUpdateUserInput(locationDetails as TGeocodeDetails, lat, lng);
};

interface SelectPredictions {
  event: any;
  placePredictions: google.maps.places.AutocompletePrediction[];
  placesService: google.maps.places.PlacesService | null;
  onLocationError: (status: boolean) => void;
  onUpdateUserInput: (locationDetails: TGeocodeDetails, lat: number, lng: number) => void;
}

const handleSelectPredictionFromList = (props: SelectPredictions) => {
  const { event, placePredictions, placesService, onLocationError, onUpdateUserInput } = props;

  const addressFull = event.target.value;
  const selectedPlace = placePredictions.find((place) => place.description === addressFull) || null;
  if (selectedPlace) {
    placesService?.getDetails({ placeId: selectedPlace.place_id }, (placeDetails: any) => {
      const { lat, lng } = placeDetails?.geometry?.location || {};
      if (lat && lng) {
        handleMarkerMovement({ lat: lat(), lng: lng(), onLocationError, onUpdateUserInput });
      }
    });
  }
};
