import { ZodError } from "zod";

const FIVE_MINUTES = 5 * 60 * 1000;
const ONE_MINUTE = 1 * 60 * 1000;

export const INFINITE_CACHING = { cacheTime: Infinity, staleTime: Infinity };
export const LIMITED_CACHING = { cacheTime: FIVE_MINUTES, staleTime: FIVE_MINUTES };
export const SHORT_CACHING = { cacheTime: ONE_MINUTE, staleTime: ONE_MINUTE };
export const NO_CACHING = { cacheTime: 0, staleTime: 0 };

export const DEFAULT_REQ_TIMEOUT = 15 * 1000;

export const logZodError = (error: ZodError) => {
  if (process.env.NODE_ENV === "development") {
    console.error(error);
    throw error;
  }

  // TODO: Configure Sentry.captureException(error);
  console.warn(error);
};
