import React, { memo, useRef } from "react";
import { styled } from "@mui/material";
import { FavoriteOutlined } from "@mui/icons-material";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import clsx from "clsx";
import { useAppSelector, useBasePathUrl, useWindowDimensions } from "common/hooks";
import { boxShadows, theme } from "theme";
import { useOpenListingDetailsPage } from "common/hooks/useOpenListingDetailsPage";
import { useAppParams } from "common/hooks/useAppParams";
import { useOnClickOutsideMarkerAndCard } from "utils/hooks/useOnClickOutside";
import { MarkerBasicListingType } from "api/listings/listings-types";
import { ListingCard } from "components/ListingCard";
import { NEUTRAL_BLUE, NEUTRAL_RED } from "utils/constants";

// Do this for TS to allow the passing of lat and lng to the Marker div
declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    lat?: number;
    lng?: number;
  }
}

type MarkerProps = {
  lat: number;
  lng: number;
  isActiveListing?: boolean;
  basicListing: MarkerBasicListingType;
  hoveredMarkerId: string | undefined;
  onSetMarkerHoverId: (markerId: string | undefined) => void;
};

// Use memo to prevent reloading marker on every render
export const MarkerBasicListings = memo((props: MarkerProps) => {
  const { isActiveListing, basicListing, hoveredMarkerId, onSetMarkerHoverId } = props;

  const { updateBasePath } = useBasePathUrl();
  const { onOpenListingDetailsPage } = useOpenListingDetailsPage();
  const { isMobile } = useWindowDimensions();
  const { listingId } = useAppParams();
  const appSlice = useAppSelector(({ appSlice }) => appSlice);
  const authSlice = useAppSelector(({ authSlice }) => authSlice);
  const listingsSlice = useAppSelector(({ listingsSlice }) => listingsSlice);

  const isHovered = hoveredMarkerId === basicListing.id;
  const isFavorite = !!listingsSlice.favoritesListingsIdsMap[basicListing.id];
  const isOwnListing = authSlice.user?.ownListingsIds?.includes(basicListing.id) || false;
  const isSeen = !isOwnListing && !!listingsSlice.seenListings[basicListing.id];
  const isLastListingSeen = appSlice.lastSeenId === basicListing?.id && listingId !== basicListing?.id;
  const isOwnListingView = appSlice.activeMenuView === "own-listings";

  const handleHoverIn = (): void => {
    onSetMarkerHoverId(basicListing?.id);
  };

  const handleHoverOut = (): void => {
    onSetMarkerHoverId(undefined);
  };

  const handleTouchStart = (): void => {
    const { pixelPerLatPointRatioMobile } = appSlice;

    if (isMobile) {
      handleHoverIn();
      updateBasePath({
        lat: basicListing.lat + 200 * pixelPerLatPointRatioMobile,
        lng: basicListing.lng,
        replace: true,
      });
    }
  };

  const handleMarkerClick = (_event: any) => {
    if (!isMobile) {
      onOpenListingDetailsPage({ listing: basicListing! });
    }
  };

  const priceStr = basicListing?.price?.toLocaleString() || "-";

  const markerRef = useRef(null);
  const cardRef = useRef(null);
  useOnClickOutsideMarkerAndCard(markerRef, handleHoverOut);
  useOnClickOutsideMarkerAndCard(cardRef, handleHoverOut);

  return (
    <>
      <StyledMarkerWrapper
        ref={markerRef}
        id={`marker-basic-listings-${basicListing?.id}`}
        data-testid="basic-listing-marker"
        className={clsx({
          "marker-basic-active": isActiveListing,
          "marker-basic-favorite": isFavorite,
          "marker-basic-hovered": isHovered,
          "marker-basic-seen": isSeen,
          "marker-basic-last-seen": isLastListingSeen,
        })}
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
        onClick={handleMarkerClick}
        onTouchStart={handleTouchStart}
      >
        {`${priceStr}€`}
        {isFavorite && !isOwnListingView && <FavoriteOutlined className="basic-marker-icon favorite" />}
        {isOwnListingView && <RoomPreferencesIcon className="basic-marker-icon" />}
      </StyledMarkerWrapper>
      <StyledListingCardWrapper
        ref={cardRef}
        id={`marker-basic-listings-${basicListing?.id}`}
        className={clsx({
          "card-wrapper-hovered": isHovered,
        })}
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
      >
        <ListingCard listing={basicListing!} fromMarker />
      </StyledListingCardWrapper>
    </>
  );
});

const StyledMarkerWrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 10px;
  border-radius: 25px;
  box-shadow: ${boxShadows.mapButtons};
  gap: 2px;
  font-weight: 500;
  border: 2px solid;
  padding: 12px 32px;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  & .basic-marker-icon {
    font-size: 13px;
    padding: 0;
    margin: 0;

    &.favorite {
      color: ${NEUTRAL_RED} !important;
    }
  }

  > svg {
    color: white;
  }

  &:hover {
    > svg {
      color: white;
    }
  }

  background-color: ${theme.palette.primary.main};
  color: white;
  border-color: white;

  &.marker-basic-seen {
    // Lighter-medium blue
    background-color: ${NEUTRAL_BLUE};
    color: white;
    border-color: white;
  }

  &.marker-basic-favorite {
    background-color: ${theme.palette.primary.main};
    color: white;
    border-color: white;
  }

  &.marker-basic-last-seen {
    background-color: white;
    color: ${theme.palette.secondary.main};
    border-color: ${theme.palette.secondary.main};

    > svg {
      color: ${theme.palette.secondary.main};
    }

    &:hover {
      > svg {
        color: ${theme.palette.secondary.main};
      }
    }
  }

  &.marker-basic-active {
    background-color: ${theme.palette.secondary.main};
    color: white;
    border-color: white;
  }

  &.marker-basic-hovered {
    background-color: ${theme.palette.primary.main};
    color: white;
    border-color: white;
    width: 45px;
    font-weight: 500;
    font-size: 15px;
    border-radius: 0 0 16px 16px;
    border-top: none;
    transition: width 0.2s ease-in-out;
  }
`;

const StyledListingCardWrapper = styled("div")`
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 200px;
  max-height: 300px;

  &.card-wrapper-hovered {
    display: flex;
    position: absolute;
    transform: translate(-50%, -115%);
    z-index: 99;
  }
`;
