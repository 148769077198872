import { Navigate } from "react-router-dom";
import { ReactNode, SyntheticEvent, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, InputLabel, MenuItem, Select, Skeleton, Stack, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { useGetPendingListings } from "api/admin/hooks/useGetPendingListings";
import { PageWrapper } from "components/PageWrapper";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { useGenerateRandomInserts } from "common/hooks/generate-random-inserts";
import { useGetBannedUsers } from "api/admin/hooks/get-banned-users";
import { BanReasons, useBanUserFromUsingTheApp } from "utils/hooks/use-ban-user-from-using-app";
import { useGetReportedListings } from "api/admin/hooks/get-reported-listings";
import { theme } from "theme";
import { PendingListingCard } from "./pending-listing-card";
import { ReportedListingCard } from "./reported-listing-card";

export const AdminPage = () => {
  const { isAdminUser } = useIsAdminUser();
  const [value, setValue] = useState(0);

  const {
    onBanUserTextFieldChange,
    onBanUserAdminComment,
    onBanUser,
    onReasonSelection,
    isBanUserLoading,
    bannedUserId,
    bannedReason,
    bannedMessage,
  } = useBanUserFromUsingTheApp({});

  const { bannedUsers } = useGetBannedUsers();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const RandomGenerator = useGenerateRandomInserts();

  const { data: pendingListings, isLoading: pendingListingsLoading } = useGetPendingListings();

  const { data: reportedListings, isLoading: reportedListingsLoading } = useGetReportedListings();

  if (!isAdminUser) {
    return <Navigate to="/" replace />;
  }

  return (
    <PageWrapper title="Admin panel">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab
            key="pending"
            label={<TabLabel label="Pending" count={pendingListings?.length || 0} />}
            {...a11yProps(0)}
          />
          <StyledTab
            key="reported"
            label={<TabLabel label="Reported" count={reportedListings?.length || 0} />}
            {...a11yProps(1)}
          />
          <StyledTab key="utils" label={<TabLabel label="Utils" />} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {pendingListings && !pendingListingsLoading ? (
          pendingListings.map((listing, index) => (
            <PendingListingCard key={listing.id} index={index} length={pendingListings.length} listing={listing} />
          ))
        ) : (
          <LoadingSkeleton />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {reportedListings && !reportedListingsLoading ? (
          reportedListings.map((reportedListing, index) => (
            <ReportedListingCard
              key={reportedListing.id}
              index={index}
              length={reportedListings.length}
              reportedListing={reportedListing}
              listing={{
                ...reportedListing.listing,
                user: reportedListing.user,
                coordinates: { latitude: 45.747464, longitude: 21.227219 },
              }}
            />
          ))
        ) : (
          <LoadingSkeleton />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StyledDetails key="random-inserts">
          <summary>Generate random inserts</summary>
          <section>
            <RandomGenerator />
          </section>
        </StyledDetails>
        <StyledDetails key="ban-user">
          <summary>Ban user</summary>
          <section>
            <Stack direction="row" spacing={5}>
              <TextField label="User id" variant="outlined" fullWidth onChange={onBanUserTextFieldChange} />
              <Button
                variant="contained"
                disabled={
                  !bannedUserId ||
                  isBanUserLoading ||
                  !bannedReason ||
                  (bannedReason === "OTHER" && !bannedMessage) ||
                  bannedUserId === "admin"
                }
                onClick={onBanUser}
                color="warning"
              >
                Ban user
              </Button>
            </Stack>
            <Stack>
              <InputLabel id="select-label">Motivul banării</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={bannedReason}
                label="Motivul banării"
                onChange={onReasonSelection}
              >
                {Object.entries(BanReasons).map(([reason, key]) => (
                  <MenuItem key={key} value={reason}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing={5}>
              <TextField
                multiline
                rows={4}
                label="Mesaj admin"
                variant="outlined"
                fullWidth
                onChange={onBanUserAdminComment}
              />
            </Stack>
          </section>
        </StyledDetails>
        <StyledDetails key="banned-users">
          <summary>Banned users</summary>
          <section>
            <StyledBannedUsersTable>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nume</th>
                  <th>Prenume</th>
                  <th>Data banării</th>
                </tr>
              </thead>
              <tbody>
                {bannedUsers?.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.last_name}</td>
                    <td>{user.first_name}</td>
                    <td>{user.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </StyledBannedUsersTable>
          </section>
        </StyledDetails>
      </TabPanel>
    </PageWrapper>
  );
};

const StyledTab = styled(Tab)`
  text-transform: none;
  font-size: 20px;
  font-weight: 400;
`;

const TabLabel = (props: { label: string; count?: number }) => (
  <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
    <span>{props.label}</span>
    {typeof props.count === "number" && (
      <span style={{ fontWeight: 500, color: theme.palette.secondary.main }}>({props.count})</span>
    )}
  </Stack>
);

const StyledBannedUsersTable = styled("table")`
  width: 100%;
  border-collapse: collapse;

  & th,
  td {
    border: 1px solid black;
    padding: 8px;
  }
`;

const LoadingSkeleton = () => <Skeleton variant="rectangular" height={118} />;

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <StyledTabContent>{children}</StyledTabContent>}
    </div>
  );
};

const StyledDetails = styled("details")`
  summary {
    font-size: 18px;
    font-weight: 500;
    padding: 8px 0;
    cursor: pointer;
  }

  > section {
    padding: 24px 12px;
    background-color: #f5f5f5;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .ban-user-container {
    }
  }
`;

const StyledTabContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 12px;
  margin: 48px 0;
`;
