import { useQuery } from "react-query";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { QueriesKeys } from "common/types/general";
import { useAppSelector } from "common/hooks";
import { ListingsApi } from "../../listings-api";
import { LIMITED_CACHING, logZodError } from "../../../utils";
import { useHandleApiError } from "../../../useHandleErrors";
import { convertBESavedSearchToFrontendFormat, savedSearchBESchema } from "../helpers/process-saved-search";

export const useGetSavedSearches = () => {
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();

  const { token } = useAppSelector(({ authSlice }) => authSlice);

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["getSavedSearches" as QueriesKeys, token],
    async () => {
      addLoadingEl("save-search");
      const res = await ListingsApi.getSavedSearches(token!);
      removeLoadingEl("save-search");

      const { data } = res.data;
      const parsed = savedSearchBESchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return convertBESavedSearchToFrontendFormat(data);
      }

      return convertBESavedSearchToFrontendFormat(parsed.data);
    },
    {
      ...LIMITED_CACHING,
      enabled: !!token,
    }
  );

  // Handle errors
  useHandleApiError({ isError, message: "Nu s-au putut încărca căutările salvate. Încearcă din nou mai târziu." });

  return { savedSearches: data, isLoading, isSuccess, isError };
};
