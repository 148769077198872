export const bz = {
  bz_buzau: {
    id: "bz_buzau",
    lat: 45.15195933484733,
    lng: 26.818700403220088,
    zoom: 10,
    countyId: "bz",
    name: "Buzău",
    countyName: "Buzău",
  },
};
