import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer } from "react-toastify";
import { use100vh } from "react-div-100vh";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from "react-ga4";
import { useEffect, useRef } from "react";
import { AdminPage } from "pages/Admin";
import { MuiV5Components } from "components/all-components";
import { NAVBAR_HEIGHT } from "utils/constants";
import { Navbar } from "./components/navbar";
import { StyledAppLayout, StyledMain } from "./AppStyles";
import { routes } from "./AppFallbackRoutes";
import { getFallbackRoute } from "./utils/helpers";
import { useWindowDimensions } from "./common/hooks";
import { Privacy } from "./pages/Privacy";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { MainComponent } from "./pages/Main";
import { theme } from "./theme";
import { Contact } from "./pages/Contact";
import { NotFound } from "./pages/NotFound";
import { AddListing } from "./pages/AddListing/AddListing";

import "react-toastify/dist/ReactToastify.css";

// https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics/
if (process.env.REACT_APP_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
} else {
  console.warn("REACT_APP_TRACKING_ID is not set");
}

const queryClient = new QueryClient();

const App = () => {
  // NOTE: This is a hack to make the app work on mobile. The problem is that
  // the height of the app is not 100vh on mobile. This is because of the
  // address bar which covers the bottom part of the viewport. This hook fixes that.
  // (explained here: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/)
  const height = use100vh();

  const stagingPasswordPassRef = useRef(localStorage.getItem("stagingPasswordPass") === "true");

  // Keep track of the current page for analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname} ${window.location.search}`,
      title: "Opened app",
    });
  }, []);

  useEffect(() => {
    if (window.location.hostname === "staging.kastel.io" && !stagingPasswordPassRef.current) {
      // Prevent unauthorized access. Ask for a password
      let password = "";

      while (password !== process.env.REACT_APP_STAGING_PASSWORD) {
        password = prompt("Enter password") || "";
      }

      stagingPasswordPassRef.current = true;
      localStorage.setItem("stagingPasswordPass", "true");
    }
  });

  // Use this to fit the map and the side panel to the available height (without the navbar)
  const { isMobile } = useWindowDimensions();

  const heightWithoutNavbarAndFooter = `calc(100% - 18px - ${
    isMobile ? `${NAVBAR_HEIGHT.MOBILE}px` : `${NAVBAR_HEIGHT.DESKTOP}px`
  })`;

  // eslint-disable-next-line no-underscore-dangle
  const _hideDuringCypress = !window.Cypress;

  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <Router>
            <ToastContainer limit={3} position={isMobile ? "bottom-center" : "top-right"} autoClose={2000} />
            <StyledMain
              style={{
                position: "relative",
                height: height || "100vh",
              }}
            >
              <Navbar />
              {/* {isMobile && activeListing.data && (
              <ActiveListingAddressChip activeListing={activeListing?.data || undefined} />
            )} */}
              <StyledAppLayout
                style={{
                  position: "relative",
                  height: heightWithoutNavbarAndFooter,
                  overflowX: "clip",
                }}
              >
                <Routes>
                  <Route path="/:view/:countyId/:lat/:lng/:zoom/admin" element={<AdminPage />} />
                  <Route
                    path="/:view/:countyId/:lat/:lng/:zoom/listing/:listingId"
                    element={<MainComponent heightWithoutNavbarAndFooter={heightWithoutNavbarAndFooter} />}
                  >
                    <Route path="image/:id" element={<Contact />} />
                  </Route>
                  {Object.values(routes(getFallbackRoute({ isMobile }))).map((route) => (
                    <Route key={route.path} {...route} />
                  ))}
                  <Route
                    path="/:view/:countyId/:lat/:lng/:zoom"
                    element={<MainComponent heightWithoutNavbarAndFooter={heightWithoutNavbarAndFooter} />}
                  >
                    <Route path="contact" element={<Contact />} />
                    <Route path="terms" element={<TermsAndConditions />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="about" element={<Contact />} />
                    <Route path="add" element={<AddListing />} />
                    <Route path="components" element={<MuiV5Components />} />
                    <Route path="password-reset/:token/:userId" element={<Outlet />} />
                    <Route path="verify-email/:verifyEmailToken/:userId" element={<Outlet />} />
                  </Route>
                  <Route
                    path="/:view/:countyId"
                    element={<MainComponent heightWithoutNavbarAndFooter={heightWithoutNavbarAndFooter} />}
                  />
                  <Route path="/404" element={<NotFound />} />
                </Routes>
              </StyledAppLayout>
            </StyledMain>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};

export default App;
