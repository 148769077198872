import { useAppSelector } from "common/hooks";

const ADMINS_EMAILS = [
  "biasinovschi@gmail.com",
  "biasinovschi+12@gmail.com",
  "emil.micu24@gmail.com",
  "micu.ioana2002@gmail.com",
];

export const useIsAdminUser = () => {
  const { user } = useAppSelector(({ authSlice }) => authSlice);
  const isAdminUser = Boolean(user?.email && ADMINS_EMAILS.includes(user.email));

  return { isAdminUser };
};
