import React, { ComponentProps } from "react";

export const BuildingTypeIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M53.6667 30.9742V0.578125H15.3333V15.7761H0V68.9692H30.6667V53.7712H38.3333V68.9692H69V30.9742H53.6667ZM15.3333 61.3702H7.66667V53.7712H15.3333V61.3702ZM15.3333 46.1722H7.66667V38.5732H15.3333V46.1722ZM15.3333 30.9742H7.66667V23.3752H15.3333V30.9742ZM30.6667 46.1722H23V38.5732H30.6667V46.1722ZM30.6667 30.9742H23V23.3752H30.6667V30.9742ZM30.6667 15.7761H23V8.17714H30.6667V15.7761ZM46 46.1722H38.3333V38.5732H46V46.1722ZM46 30.9742H38.3333V23.3752H46V30.9742ZM46 15.7761H38.3333V8.17714H46V15.7761ZM61.3333 61.3702H53.6667V53.7712H61.3333V61.3702ZM61.3333 46.1722H53.6667V38.5732H61.3333V46.1722Z"
        fill="currentColor"
      />
    </svg>
  );
};
