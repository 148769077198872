/* eslint-disable camelcase */
import Geocode from "react-geocode";
import { toast } from "react-toastify";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
// aGeocode.setApiKey('disabled');
Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY as string);

// set response language. Defaults to english.
Geocode.setLanguage("ro");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("ro");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

type TAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

const parseCountyId = (_countyId = "ro") => {
  const countyId = _countyId?.toLowerCase();
  const isBucharest = countyId === "bucurești" || countyId === "if";

  return isBucharest ? "b" : countyId;
};

export type TGeocodeDetails = {
  address: string;
  streetName: string;
  streetNumber: string;
  cityName: string;
  countyId: string;
  countyName: string;
  countryId: string;
  countryName: string;
};

export const GeocodeComponent = {
  // Get address from latitude & longitude.
  getAddressFromLatAndLng: async (lat: string, lng: string) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng);

      if (!response) {
        return null;
      }

      // Extract address components from response
      const address = response.results.find((el: any) => el.types.includes("street_address")) as {
        address_components: TAddressComponent[];
        formatted_address: string;
      };

      const { address_components, formatted_address } = address || {};

      // e.g. '12A' or '5'
      const streetNumber = address_components.find((el) => el.types.includes("street_number"))?.long_name || "";
      // e.g. 'Strada Ștefan cel Mare'
      const streetName = address_components.find((el) => el.types.includes("route"))?.long_name || "";
      // e.g. 'Pitești'
      const cityName = address_components.find((el) => el.types.includes("locality"))?.long_name || "";
      // e.g. 'Argeș' and 'ag'
      const { long_name: countyName, short_name: countyId } =
        address_components.find((el) => el.types.includes("administrative_area_level_1")) || {};
      // e.g. 'România' and 'ro'
      const { long_name: countryName, short_name: countryId } =
        address_components.find((el) => el.types.includes("country")) || {};

      return {
        address: formatted_address,
        streetName,
        streetNumber,
        cityName,
        countyId: parseCountyId(countyId),
        countyName,
        countryId: countryId?.toLowerCase(),
        countryName,
      };
    } catch (error) {
      toast.error("N-a fost găsită o adresă validă pentru locația indicată.", {
        toastId: "geocode-error",
      });
      return {
        address: "",
        streetName: "",
        streetNumber: "",
        cityName: "",
        countyId: "",
        countyName: "",
        countryId: "",
        countryName: "",
      };
    }
  },

  // Get formatted address, city, state, country from latitude & longitude when
  // Geocode.setLocationType("ROOFTOP") enabled
  // the below parser will work for most of the countries
  getFormattedAddressFromLatAndLng: (lat: string, lng: string) =>
    Geocode.fromLatLng(lat, lng).then(
      (response: any) => {
        // TODO: What is happening here - do we even need this method?

        const address = response.results[0].formatted_address;
        let city;
        let state;
        let country;
        for (let i = 0; i < response.results[0].address_components.length; i += 1) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j += 1) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }
        return { address, city, state, country };
      },
      (error: Error) => {
        console.error(error);
      }
    ),

  // Get latitude & longitude from address.
  getLatAndLngFromAddress: (address: string) => {
    return Geocode.fromAddress(address).then(
      (response: any) => {
        const { formatted_address } = response.results[0];
        const { lat, lng } = response.results[0].geometry.location;
        return { formatted_address, lat, lng };
      },
      (error: Error) => {
        console.error(error);
      }
    );
  },
};
