import orderBy from "lodash/orderBy";
import keyBy from "lodash/keyBy";
import {
  FiltersOptions,
  FiltersOptionsAllKeys,
  FiltersOptionsType,
  floorsOptions,
  incrementFilterCount,
  priceOptions,
  roomsOptions,
  surfaceOptions,
} from "redux/slices/filtersSlice";
import { SortType } from "common/types/general";
import { BasicListingType } from "api/listings/listings-types";

// Create maps using lodash keyby
export const priceOptionsMap = keyBy(priceOptions, "key");
export const roomsOptionsMap = keyBy(roomsOptions, "key");
export const surfaceOptionsMap = keyBy(surfaceOptions, "key");
export const floorOptionsMap = keyBy(floorsOptions, "key");

export const sortListings = (listings: BasicListingType[], sortType: SortType): BasicListingType[] => {
  if (sortType === "price") {
    return orderBy(listings, ["price"], ["asc"]);
  }
  if (sortType === "priceDesc") {
    return orderBy(listings, ["price"], ["desc"]);
  }
  if (sortType === "surface") {
    return orderBy(listings, ["surface"], ["asc"]);
  }
  if (sortType === "surfaceDesc") {
    return orderBy(listings, ["surface"], ["desc"]);
  }
  if (sortType === "rooms") {
    return orderBy(listings, ["rooms"], ["asc"]);
  }
  if (sortType === "roomsDesc") {
    return orderBy(listings, ["rooms"], ["desc"]);
  }
  if (sortType === "floor") {
    return orderBy(listings, ["floor"], ["asc"]);
  }
  if (sortType === "floorDesc") {
    return orderBy(listings, ["floor"], ["desc"]);
  }
  // You will need to add a 'date' field in your Listing type if you want to sort by date
  // if (sortType === "date") {
  //   return orderBy(listings, ["date"], ["asc"]);
  // }
  // if (sortType === "dateDesc") {
  //   return orderBy(listings, ["date"], ["desc"]);
  // }

  return listings;
};

export const filterByBuildingType = (listing: BasicListingType, buildingTypeFilters: string[]) => {
  if (buildingTypeFilters.length === 0) return true;

  return buildingTypeFilters.includes(listing.buildingType);
};

export const filterByPartitioning = (listing: BasicListingType, partitioningFilters: string[]) => {
  if (partitioningFilters.length === 0) return true;

  return partitioningFilters.includes(listing.partitioningType);
};

export const filterByPrice = (listing: BasicListingType, priceFilters: string[]) => {
  if (priceFilters.length === 0) return true;

  return priceFilters.some((priceFilter) => {
    const [min, max] = priceOptionsMap[priceFilter].value! as [number, number];
    const price = Number(listing.price);

    return price >= min && price <= max;
  });
};

export const filterByRooms = (listing: BasicListingType, roomsFilters: string[]) => {
  if (roomsFilters.length === 0) return true;

  return roomsFilters.some((roomsFilter) => {
    const [min, max] = roomsOptionsMap[roomsFilter].value! as [number, number];
    const rooms = Number(listing.rooms);

    return rooms >= min && rooms <= max;
  });
};

export const filterBySurface = (listing: BasicListingType, surfaceFilters: string[]) => {
  if (surfaceFilters.length === 0) return true;

  return surfaceFilters.some((surfaceFilter) => {
    const [min, max] = surfaceOptionsMap[surfaceFilter].value! as [number, number];
    const surface = Number(listing.surface);

    return surface >= min && surface <= max;
  });
};

export const filterByFloor = (listing: BasicListingType, floorFilters: string[]) => {
  if (floorFilters.length === 0 || listing.buildingType === "house") return true;

  const floor = Number(listing.floor);
  const totalFloors = Number(listing.totalFloors);

  return floorFilters.some((floorFilter) => {
    const value = floorOptionsMap[floorFilter].value as FiltersOptionsAllKeys;

    if (value === "semi_basement") {
      return floor < 0;
    }
    if (value === "ground_floor") {
      return floor === 0;
    }
    if (value === "intermediary") {
      return floor > 0 && floor < totalFloors;
    }
    if (value === "top_floor") {
      return floor === totalFloors;
    }

    // This should never be reached
    return false;
  });
};

export const filterByExtras = (listing: BasicListingType, extrasFilters: string[]) => {
  if (extrasFilters.length === 0) return true;

  return extrasFilters.every((extraFilter) => {
    const value = extraFilter as keyof typeof listing;

    return listing[value] === "true";
  });
};

export const updateCounts = (
  listing: BasicListingType,
  filtersOptions: Record<FiltersOptions, FiltersOptionsType[]>,
  dispatch: any
) => {
  const optionsToIncrease = [] as FiltersOptionsAllKeys[];

  // Count buildingType
  optionsToIncrease.push(listing.buildingType);

  // Count partitioning
  optionsToIncrease.push(listing.partitioningType);

  // Count price
  Object.keys(priceOptionsMap).forEach((key) => {
    const [min, max] = priceOptionsMap[key].value! as [number, number];

    const price = Number(listing.price);

    if (price >= min && price <= max) {
      optionsToIncrease.push(key as FiltersOptionsAllKeys);
    }
  });

  // Count rooms
  Object.keys(roomsOptionsMap).forEach((key) => {
    const [min, max] = roomsOptionsMap[key].value! as [number, number];
    const rooms = Number(listing.rooms);

    if (rooms >= min && rooms <= max) {
      optionsToIncrease.push(key as FiltersOptionsAllKeys);
    }
  });

  // Count surface
  Object.keys(surfaceOptionsMap).forEach((key) => {
    const [min, max] = surfaceOptionsMap[key].value! as [number, number];
    const surface = Number(listing.surface);

    if (surface >= min && surface <= max) {
      optionsToIncrease.push(key as FiltersOptionsAllKeys);
    }
  });

  const floor = Number(listing.floor);
  const totalFloors = Number(listing.totalFloors);

  // Count floor
  if (listing.buildingType !== "house") {
    if (floor <= 0) {
      optionsToIncrease.push("ground_floor");
    } else if (floor > 0 && floor < totalFloors) {
      optionsToIncrease.push("intermediary");
    } else if (floor === totalFloors) {
      optionsToIncrease.push("top_floor");
    }
  }

  // Count extras
  filtersOptions.extra.forEach(({ key }) => {
    if (listing[key as keyof typeof listing] === "true") {
      optionsToIncrease.push(key as FiltersOptionsAllKeys);
    }
  });

  dispatch(incrementFilterCount(optionsToIncrease));
};
