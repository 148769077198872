export const cl = {
  cl_calarasi: {
    id: "cl_calarasi",
    lat: 44.2018878031782,
    lng: 27.3178626638966,
    zoom: 10,
    countyId: "cl",
    name: "Călărași",
    countyName: "Călărași",
  },
};
