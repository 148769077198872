import { styled } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { ComponentProps } from "react";
import { theme } from "theme";

interface Props extends ComponentProps<typeof MuiTelInput> {
  value: string;
  isError: boolean;
  errorMessage: string;
  disabled?: boolean;
  touched?: boolean;
}

export const PhoneInputComponent = (props: Props) => {
  const { value, isError, errorMessage, disabled, touched: _touched, ...rest } = props;

  return (
    <StyledMuiTelInput
      error={isError}
      helperText={errorMessage}
      value={value}
      disabled={disabled}
      sx={{
        "& .MuiInputBase-root": {
          padding: "2px 4px",
        },
        "& .MuiOutlinedInput-input": {
          padding: "8px 0 8px",
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: disabled ? theme.palette.grey[400] : "inherit",
        },
        "& .MuiFormHelperText-root": {
          color: !disabled ? (isError ? "red" : theme.palette.grey[500]) : "inherit",
        },
      }}
      {...rest}
    />
  );
};

const StyledMuiTelInput = styled(MuiTelInput)`
  width: 100%;

  & .MuiOutlinedInput-root {
    width: 100%;
  }
`;
