import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { InputLabel, MenuItem, Select, Stack, TextField, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { QueriesKeys } from "common/types/general";
import { BanReasons, useBanUserFromUsingTheApp } from "utils/hooks/use-ban-user-from-using-app";
import { useGetActiveListing } from "api/listings/hooks";

export const BanUserModal = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { activeListing } = useGetActiveListing();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      setError(false);
      setSuccess(false);
    };
  }, []);

  // Ban user
  const { openedModal } = useAppSelector((state) => state.appSlice);
  const { isAdminUser } = useIsAdminUser();
  const {
    onBanUserAdminComment,
    onBanUser,
    onReasonSelection,
    isBanUserLoading,
    bannedUserId,
    bannedReason,
    bannedMessage,
  } = useBanUserFromUsingTheApp({ onSuccess: () => setSuccess(true), onError: () => setError(true) });

  const handleCloseModal = () => {
    dispatch(setOpenedModal(null));
    queryClient.refetchQueries("bannedUsers" as QueriesKeys);
  };

  if (!isAdminUser) {
    return null;
  }

  if (success) {
    return (
      <ConfirmationDialog
        title="Raportul a fost trimis cu succes!"
        variant="success"
        labelPrimary="Închide"
        open={openedModal === "ban-user"}
        onCancel={handleCloseModal}
        onPrimary={handleCloseModal}
        onSecondary={handleCloseModal}
      >
        Utilizatorul a fost blocat cu succes!
      </ConfirmationDialog>
    );
  }

  if (error) {
    return (
      <ConfirmationDialog
        title="Eroare"
        variant="error"
        labelPrimary="Închide"
        open={openedModal === "ban-user"}
        onCancel={handleCloseModal}
        onPrimary={handleCloseModal}
        onSecondary={handleCloseModal}
      >
        A apărut o eroare. Te rugăm să încerci din nou.
      </ConfirmationDialog>
    );
  }

  return (
    <ConfirmationDialog
      title="Ești sigur că vrei să blochezi utilizatorul?"
      variant="warning"
      labelSecondary="Anulează"
      labelPrimary="Blochează utilizatorul"
      disabledPrimary={!bannedUserId || isBanUserLoading || !bannedReason}
      open={openedModal === "ban-user"}
      onCancel={handleCloseModal}
      onPrimary={onBanUser}
      onSecondary={handleCloseModal}
    >
      Introdu motivul pentru care dorești să blochezi utilizatorul{" "}
      <strong>{activeListing ? `${activeListing.ownerFirstName} ${activeListing.ownerLastName}` : ""}</strong>
      <StyledSection>
        <Stack>
          <InputLabel id="select-label">Motivul banării</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={bannedReason}
            label="Motivul banării"
            onChange={onReasonSelection}
          >
            {Object.entries(BanReasons).map(([reason, key]) => (
              <MenuItem key={key} value={reason}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        {bannedReason === "OTHER" && (
          <Stack direction="row" spacing={5}>
            <TextField
              multiline
              rows={4}
              label="Mesaj admin"
              variant="outlined"
              fullWidth
              onChange={onBanUserAdminComment}
              value={bannedMessage}
            />
          </Stack>
        )}
      </StyledSection>
    </ConfirmationDialog>
  );
};

const StyledSection = styled("section")`
  padding: 24px 0;
  background-color: #f5f5f5;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
