import { styled } from "@mui/system";
import capitalize from "lodash/capitalize";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import Switch from "@mui/material/Switch";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { theme } from "theme";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useGetSavedSearches } from "api/listings/hooks/search-saving/get-saved-searches";
import { CENTERS } from "cities";
import { allLabels, getGenericLabel } from "utils/labels";
import { floorOptionsMap } from "api/listings/hooks/helpers/process-basic-listings-utils";
import { MAX_FILTER_VALUE } from "utils/constants";
import { useDeleteSearch } from "api/listings/hooks/search-saving/delete-saved-search";
import { useToggleSearchSavedNotification } from "api/listings/hooks/search-saving/toggle-search-saved-notification";
import { SavedSearchObjType } from "api/listings/listings-types";
import { updateFilters } from "redux/slices/filtersSlice";

export const SavedSearchesModal = () => {
  const { openedModal } = useAppSelector((state) => state.appSlice);
  const dispatch = useAppDispatch();

  const { savedSearches } = useGetSavedSearches();
  const { deleteSearch } = useDeleteSearch();
  const { toggleSavedSearchNotification } = useToggleSearchSavedNotification();

  const [localSavedSearches, setLocalSavedSearches] = useState<SavedSearchObjType[]>([]);
  useEffect(() => {
    if (!savedSearches) {
      return;
    }
    setLocalSavedSearches(savedSearches);
  }, [savedSearches]);

  const queryClient = useQueryClient();

  const handleEnableNotifications = (savedSearchId: string, notificationsEnabled: boolean) => {
    toggleSavedSearchNotification(
      {
        searchId: savedSearchId,
        notificationsStatus: notificationsEnabled,
      },
      {
        onSuccess: () => {
          setLocalSavedSearches((prev) => {
            return prev.map((item) => {
              if (item.id === savedSearchId) {
                return {
                  ...item,
                  notificationsEnabled,
                };
              }

              return item;
            });
          });
        },
        onError: () => {
          toast.error("A apărut o eroare. Vă rugăm să încercați din nou.");
        },
      }
    );
  };

  const handleDeleteSearch = (savedSearchId: string) => {
    deleteSearch(
      { searchId: savedSearchId },
      {
        onSuccess: () => {
          toast.success("Căutarea a fost ștearsă cu succes!");
          queryClient.invalidateQueries("getSavedSearches");
        },
        onError: () => {
          toast.error("A apărut o eroare. Vă rugăm să încercați din nou.");
        },
      }
    );
  };

  // TODO: handle filters
  // eslint-disable-next-line no-underscore-dangle
  const _handleApplyFilters = (savedSearchItem: SavedSearchObjType) => {
    const {
      countyCode,
      buildingTypeFilters,
      partitioningTypeFilters,
      minPrice,
      maxPrice,
      floorFilters,
      roomFilters,
      ...rest
    } = savedSearchItem;

    const filters = {
      ...rest,
      countyId: countyCode,
      buildingType: buildingTypeFilters,
      partitioning: partitioningTypeFilters,
      price: {
        min: minPrice,
        max: maxPrice,
      },
      floors: floorFilters,
      rooms: roomFilters,
    };

    dispatch(updateFilters(filters));
  };

  return (
    <ConfirmationDialog
      title="Căutări salvate"
      open={openedModal === "saved-searches"}
      variant="info"
      labelPrimary="Închide fereastra"
      // disabledPrimary={disabled}
      // labelSecondary="Anulează"
      onCancel={() => dispatch(setOpenedModal(null))}
      onPrimary={() => dispatch(setOpenedModal(null))}
      onSecondary={() => dispatch(setOpenedModal(null))}
    >
      <Container>
        <StyledList>
          {!localSavedSearches?.length && (
            <p>
              {`Nu ai salvat nicio căutare. Pentru a salva o căutare, aplică filtrele dorite și apasă butonul de "Salvează
              căutarea".`}
            </p>
          )}
          {localSavedSearches &&
            localSavedSearches.map((savedSearchItem) => {
              const {
                countyCode,
                notificationsEnabled,
                buildingTypeFilters,
                partitioningTypeFilters,
                minPrice,
                maxPrice,
                floorFilters,
              } = savedSearchItem;

              const countyName = Object.values(CENTERS).find(
                ({ countyId: optionsCountyId }) => optionsCountyId === countyCode
              )?.countyName;

              const buildingTypes = capitalize(
                buildingTypeFilters
                  .map((item) => {
                    return getGenericLabel(item as any);
                  })
                  .join(", ")
                  .toLocaleLowerCase()
              );

              const partitioningTypes = partitioningTypeFilters
                .map((item) => {
                  return getGenericLabel(item as any);
                })
                .join(", ");

              const floors = capitalize(
                floorFilters
                  .map((item) => {
                    return floorOptionsMap[item as any].label;
                  })
                  .join(", ")
                  .toLowerCase()
              );

              const roomsInitial = savedSearchItem.roomFilters.map((item) => {
                const numeral =
                  item === "one"
                    ? 1
                    : item === "two"
                    ? 2
                    : item === "three"
                    ? 3
                    : item === "four"
                    ? 4
                    : "5 sau mai multe";

                return numeral === 1 ? "1 cameră" : `${numeral} camere`;
              });

              const extrasInitial = Object.entries(savedSearchItem)
                .map(([key, value]) => {
                  if (key.startsWith("has") && value === true) {
                    const label = (allLabels as any)[key];
                    return label;
                  }

                  return "";
                })
                .filter((item) => item);
              const extras = capitalize(extrasInitial.join(", ").toLocaleLowerCase());

              const rooms =
                roomsInitial.length === 5
                  ? "Toate opțiunile"
                  : capitalize(roomsInitial.sort().join(", ").toLocaleLowerCase());

              return (
                <li key={savedSearchItem.id}>
                  <div className="saved-searches-fields">
                    <h3>
                      {countyName} | {buildingTypes || partitioningTypes}
                    </h3>
                    <p>
                      <b>Preț:</b>{" "}
                      {maxPrice === MAX_FILTER_VALUE
                        ? minPrice === 0
                          ? "Orice preț"
                          : `Începând de la ${minPrice} EUR`
                        : minPrice === 0
                        ? `Sub ${maxPrice} EUR`
                        : `${minPrice}-${maxPrice} EUR`}
                    </p>
                    <p>
                      <b>Etaj:</b> {floors}
                    </p>
                    <p>
                      <b>Camere:</b> {rooms}
                    </p>
                    <p>
                      <b>Facilități:</b> {extras || "Niciuna"}
                    </p>
                  </div>
                  <div className="saved-searches-action-buttons">
                    <button
                      className="notify-me"
                      type="button"
                      onClick={() => handleEnableNotifications(savedSearchItem.id!, !notificationsEnabled)}
                    >
                      <Switch checked={notificationsEnabled} size="small" /> Doresc să primesc notificări pe email
                    </button>
                    <button className="delete" type="button" onClick={() => handleDeleteSearch(savedSearchItem.id!)}>
                      <ClearIcon /> Șterge căutarea
                    </button>
                    {/* <button className="see-all" type="button" onClick={() => _handleApplyFilters(savedSearchItem)}>
                    Vezi anunțurile
                  </button> */}
                  </div>
                </li>
              );
            })}
        </StyledList>
      </Container>
    </ConfirmationDialog>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
  gap: 32px;
`;

const StyledList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid ${theme.palette.grey[300]};
    padding: 1rem;
    box-shadow: 0 0 4px 0 ${theme.palette.grey[300]};
    box-sizing: border-box;

    .saved-searches-fields {
      display: flex;
      flex-direction: column;
      width: 100%;

      h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        color: ${theme.palette.primary.main};
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 14px;
        margin: 0;
        color: ${theme.palette.grey[600]};
      }
    }

    .saved-searches-action-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        margin-top: 12px;
        background-color: transparent;
        border: none;
        color: ${theme.palette.error.main};
        font-size: 12px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .disabled-fields * {
        cursor: not-allowed;
      }

      > .notify-me {
        color: ${theme.palette.primary.main};
      }

      > .delete {
        color: ${theme.palette.grey[600]};

        svg {
          margin: 0;
          padding: 0;
          width: 14px;
          height: 14px;
          margin-right: 2px;
          color: red;
        }
      }

      > .see-all {
        color: ${theme.palette.secondary.main};
      }
    }
  }
`;
