/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";

type TListings = {
  hoveredListingId: string | null;
  favoritesListingsIds: string[];
  favoritesListingsIdsMap: Record<string, string>;
  hiddenListings: Record<string, string>;
  seenListings: Record<string, string>;
  showSeenListings: boolean;
};

const initialState: TListings = {
  hoveredListingId: null,
  favoritesListingsIds: [],
  favoritesListingsIdsMap: {},
  hiddenListings: {},
  seenListings: {},
  showSeenListings: true,
};

export const listingsSlice = createSlice({
  name: "listingsSlice",
  initialState,
  reducers: {
    updateHoveredListingId: (state, action) => {
      state.hoveredListingId = action.payload;
    },
    updateHiddenListings: (state, action) => {
      const { hiddenListings } = action.payload;
      state.hiddenListings = keyBy(hiddenListings);
    },
    updateFavoritesListingsIds: (state, action) => {
      const newFavoritesIdsMap = keyBy(action.payload.filter((favoriteId: string) => favoriteId));

      if (JSON.stringify(state.favoritesListingsIdsMap) === JSON.stringify(newFavoritesIdsMap)) return;

      state.favoritesListingsIdsMap = newFavoritesIdsMap;
      state.favoritesListingsIds = action.payload;
    },
    updateSeenListings: (state, action) => {
      const { seenListings } = action.payload;
      state.seenListings = keyBy(seenListings);
    },

    toggleShowSeenListings: (state) => {
      state.showSeenListings = !state.showSeenListings;
    },
  },
});

export const {
  updateHoveredListingId,
  updateFavoritesListingsIds,
  updateHiddenListings,
  updateSeenListings,
  toggleShowSeenListings,
} = listingsSlice.actions;

export default listingsSlice.reducer;
