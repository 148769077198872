export const db = {
  db_targoviste: {
    id: "db_targoviste",
    lat: 44.92822096178463,
    lng: 25.46010682707607,
    zoom: 10,
    countyId: "db",
    name: "Târgoviște",
    countyName: "Dâmbovița",
  },
};
