export const cj = {
  cj_cluj: {
    id: "cj_cluj",
    lat: 46.769789614028824,
    lng: 23.592423144319223,
    zoom: 10,
    countyId: "cj",
    name: "Cluj-Napoca",
    countyName: "Cluj",
  },
  cj_floresti: {
    id: "cj_floresti",
    lat: 46.7422595,
    lng: 23.4871469,
    zoom: 14,
    countyId: "cj",
    name: "Floresti",
    countyName: "Cluj",
  },
};
