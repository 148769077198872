import { useParams } from "react-router-dom";

type PageType = "listing" | "add" | "profile" | "listings" | "password-reset" | "admin" | "verify-email";

export type Views = "map" | "feed" | "details";

export const useAppParams = () => {
  const {
    view: pView,
    countyId: pCountyId,
    lat: pLat,
    lng: pLng,
    zoom: pZoom,
    listingId: pListingId,
    userProfileId: pUserProfileId,
    token: pToken,
    userId: pUserId,
    verifyEmailToken: pVerifyEmailToken,
    emailToken: pEmailToken,
  } = useParams();

  const fallbackParams = window.location.pathname?.split("/");
  const [
    ,
    fallbackView,
    fallbackCountyId,
    fallbackLat,
    fallbackLng,
    fallbackZoom,
    _pageType,
    fallbackListingOrUserProfileIdOrTokenId,
    fallbackUserId,
  ] = fallbackParams;

  const pageType = _pageType as PageType;

  const isListingDetailsMode = pageType === "listing";
  const isAddListingPage = pageType === "add";
  const isUserProfilePage = pageType === "profile";
  const isPasswordResetMode = pageType === "password-reset";
  const isAdminView = pageType === "admin";
  const isVerifyEmailMode = pageType === "verify-email";

  const listingId = pageType === "listing" ? pListingId || fallbackListingOrUserProfileIdOrTokenId : undefined;
  const userProfileId = pageType === "profile" ? pUserProfileId || fallbackListingOrUserProfileIdOrTokenId : undefined;
  const passwordResetToken =
    pageType === "password-reset" ? pToken || fallbackListingOrUserProfileIdOrTokenId : undefined;
  const passwordResetUserId = pageType === "password-reset" ? pUserId || fallbackUserId : undefined;
  const emailToken =
    pageType === "verify-email" ? pVerifyEmailToken || fallbackListingOrUserProfileIdOrTokenId : undefined;
  const emailTokenUserId = pageType === "verify-email" ? pEmailToken || fallbackUserId : undefined;

  const view = (pView || fallbackView) as Views;
  const countyId = pCountyId || fallbackCountyId;

  return {
    view,
    countyId,
    lat: pLat || fallbackLat,
    lng: pLng || fallbackLng,
    zoom: pZoom || fallbackZoom,
    listingId,
    userProfileId,
    passwordResetToken,
    passwordResetUserId,
    isListingDetailsMode,
    isAddListingPage,
    isUserProfilePage,
    isPasswordResetMode,
    isAdminView,
    isCountryViewMode: countyId === "ro",
    emailToken,
    isVerifyEmailMode,
    emailTokenUserId,
  };
};
