import { styled } from "@mui/material";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { theme } from "theme";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CampaignTermsModal = (props: Props) => {
  const { open, onClose } = props;

  return (
    <ConfirmationDialog
      open={open}
      title='Campania "Postează și Câștigă"'
      variant="info"
      onCancel={onClose}
      onPrimary={onClose}
      onSecondary={onClose}
      labelPrimary="Închide"
      labelSecondary=""
    >
      <StyledTerms>
        <h1>Termeni și condiții</h1>
        <br />
        <div>
          Pentru revendicarea premiului este necesară dovedirea validității anunțului și a existenței proprietății
          oferite spre închiriere. Aceasta se va face prin extrasul de carte funciară, care va trebui să corespundă cu
          detaliile expuse în anunț.{" "}
        </div>
        <div>
          <h3>1. Organizatorul Campaniei</h3>
          <b>1.1</b> Campania este organizată de Kastelio SRL, proprietarul site-ului kastel.io, denumită în continuare
          Organizator.
        </div>
        <div>
          <h3>2. Durata Campaniei</h3>
          <b>2.1</b> Campania începe pe 01.10.2023 și se încheie pe 24.12.2023, ora 23:59.
        </div>
        <div>
          <h3>3. Eligibilitate</h3>
          <b>3.1</b> Sunt eligibile pentru această campanie toate persoanele fizice care îndeplinesc cumulativ
          următoarele condiții: - au vârsta de minimum 18 ani împliniți la data începerii campaniei. - dețin un cont pe
          platforma kastel.io - postează un anunț valid* pe platforma kastel.io în perioada campaniei.
        </div>
        <div>
          <h3>4. Despre premiu</h3>
          <b>4.1</b> Premiul constă în <b>500 RON</b>, bani care vor fi oferiți prin transfer bancar în contul
          câștigătorului. 4.2 Premiul nu poate fi schimbat în alte produse/servicii.
        </div>
        <div>
          <h3>5. Desfășurarea campaniei</h3>
          <b>5.1</b> Fiecare anunț valid* postat pe kastel.io în perioada campaniei reprezintă o intrare în tragerea la
          sorți.
          <br />
          <b>5.2</b> Tragerea la sorți va fi realizată electronic pe data de 24 decembrie.
          <br />
          <b>5.3</b> Câștigătorul va fi notificat prin e-mail sau telefon în termen de 48 de ore de la extragere.
          <br />
          <b>5.4</b> Participantul trebuie să dețină un cont pe platforma kastel.io și cel puțin un anunț aferent
          acestui cont în momentul extragerii.
          <br />
          <b>5.5</b> Anunțurile trebuie să fie în una din stările &quot;activ&quot; sau &quot;inactiv&quot;. Ștergerea
          anunțurilor și/sau a contului prin care s-a realizat înscrierea în tombolă duce la eliminarea participantului
          de la extragere.
        </div>
        <div>
          <h3>6. Obligații ale câștigătorului</h3>
          <b>6.1</b> Câștigătorul trebuie să furnizeze toate informațiile necesare pentru transferul premiului în termen
          de 7 zile de la notificare.
          <br />
          <b>6.2</b> Dacă câștigătorul nu poate fi contactat sau nu furnizează informațiile solicitate în termenul
          menționat, premiul va fi considerat nerevendicat și va fi organizată o nouă extragere.
        </div>
        <div>
          <h3>7. Limitarea răspunderii</h3>
          <b>7.1</b> Organizatorul este îndreptățit să ia toate măsurile necesare în caz de tentativă de fraudă a
          sistemului, abuz sau orice alte tentative care ar putea afecta imaginea acestei Campanii, precum și a
          Organizatorului.
          <br />
          <b>7.2</b> În eventualitatea unei dispute asupra validității unei înscrieri în cadrul acestei Campanii,
          decizia Organizatorului este definitivă.
        </div>
        <div>
          <h3>8. Confidențialitate</h3>
          <b>8.1</b> Informațiile personale ale participanților vor fi utilizate exclusiv în scopul acestei campanii și
          nu vor fi divulgate terților.
        </div>
        <div>
          <h3>9. Alte mențiuni</h3>
          <b>9.1</b> Organizatorul își rezervă dreptul de a modifica sau încheia campania în orice moment, cu informarea
          prealabilă a participanților.
        </div>
        <p className="antet">
          *Prin anunț valid, se înțelege un anunț cu informații reale, a unei proprietăți reale cu adresa pe teritoriul
          României, disponibilă pentru închiriat la momentul postării anunțului. Dovedirea autenticității proprietății
          se va face prin extrasul de carte funciară furnizat de către câștigător. Detaliile extrasului de carte
          funciară vor trebui să corespundă cu detaliile expuse în anunț.
        </p>
      </StyledTerms>
    </ConfirmationDialog>
  );
};

const StyledTerms = styled("div")`
  & .antet {
    font-size: 12px;
    color: ${theme.palette.grey[600]};
    margin-top: 2rem;
  }
`;
