import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { useFavoritesAdd, useFavoriteRemove } from "api/user/hooks";
import { updateFavoritesListingsIds } from "redux/slices";

export const favsToastMessages = {
  add: "Anunțul a fost adăugat în lista de favorite.",
  addError: "Nu s-a putut adăuga anunțul în lista de favorite. Vă rugăm să reîncărcați pagina.",
  remove: "Anunțul a fost șters din lista de favorite.",
  removeError: "Nu s-a putut șterge anunțul din lista de favorite. Vă rugăm să reîncărcați pagina.",
  sync: "Lista de favorite a fost sincronizată.",
  syncError: "Nu s-a putut sincroniza lista de favorite. Vă rugăm să reîncărcați pagina.",
};

export const useAddRemoveFavorites = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ authSlice }) => authSlice);
  const { favoritesListingsIds } = useAppSelector(({ listingsSlice }) => listingsSlice);

  const { mutate: addUserFavorites } = useFavoritesAdd();
  const { mutate: removeUserFavorite } = useFavoriteRemove();

  /*
   * If user is not authenticated, we use the localStorage as the source of truth
   * In parallel, we also use the global state to keep track of the favorites ids
   */
  const handleAnonymous = (listingId: string) => {
    const isRemoveOperation = favoritesListingsIds.includes(listingId);
    const newFavoritesIds = isRemoveOperation
      ? favoritesListingsIds.filter((id) => id !== listingId)
      : [...favoritesListingsIds, listingId];

    // Update local storage
    localStorage.setItem("favoritesListings", newFavoritesIds.join(","));

    // Update state
    dispatch(updateFavoritesListingsIds(newFavoritesIds));

    if (isRemoveOperation) {
      toast.success(favsToastMessages.remove);
      return;
    }

    toast.success(favsToastMessages.add);
  };

  /*
   * If user is authenticated, we update the db and the global state.
   */
  const handleAuthenticatedUser = (listingId: string) => {
    // Get new favorites ids
    const isRemoveOperation = favoritesListingsIds.includes(listingId);
    const newFavoritesIds = isRemoveOperation
      ? favoritesListingsIds.filter((id) => id !== listingId)
      : [...favoritesListingsIds, listingId];

    // Update db
    if (isRemoveOperation) {
      removeUserFavorite(
        { listingId },
        {
          onSuccess: () => {
            dispatch(updateFavoritesListingsIds(newFavoritesIds));
            toast.success(favsToastMessages.remove);
          },
          onError: () => {
            toast.error(favsToastMessages.removeError);
          },
        }
      );
    } else {
      addUserFavorites(
        { listingIds: newFavoritesIds },
        {
          onSuccess: () => {
            toast.success(favsToastMessages.add);
            dispatch(updateFavoritesListingsIds(newFavoritesIds));
          },
          onError: () => {
            toast.error(favsToastMessages.addError);
          },
        }
      );
    }
  };

  return {
    addRemoveFavorites: user ? handleAuthenticatedUser : handleAnonymous,
  };
};

export const getFavoritesIdsFromLocalStorage = () => {
  const localStorageObj = localStorage.getItem("favoritesListings");
  if (!localStorageObj) return [];

  return localStorageObj.split(",").filter((el) => el);
};
