import { CENTERS, DEFAULT_CITY } from "cities";

export const getCityId = (city: string) => city.trim().toLowerCase().replaceAll(" ", "-");

type Props = {
  code?: string;
  suffix?: string;
  isMobile?: boolean;
};

export const getFallbackRoute = ({ code, suffix, isMobile = false }: Props) => {
  if (code) {
    const wantedCounty = Object.values(CENTERS).find((el) => el.countyId === code);

    if (!wantedCounty) return "/404";

    const { lat, lng, zoom } = wantedCounty;
    return `/map/${code}/${lat}/${lng}/${zoom}`;
  }

  // If no customCountyId, return default map route
  const { countyId, lat, lng, zoom } = CENTERS[DEFAULT_CITY];

  const countryZoomMobile = isMobile ? zoom - 1 : zoom;

  return `/map/${countyId}/${lat}/${lng}/${countryZoomMobile}${suffix ? `/${suffix}` : ""}`;
};

export const isNullish = (el: any) => el === null || el === undefined;

export const createFullAddress = (address?: {
  streetName: string;
  streetNumber: string;
  cityName: string;
  neighborhood?: string;
}) =>
  address
    ? `${address.streetName} ${address.streetNumber}, ${address.cityName}${
        address.neighborhood ? ` (${address.neighborhood})` : ""
      }`
    : "";

export const getLocalStorageObj = (objName: string) => {
  const localStorageObj = localStorage.getItem(objName);

  if (localStorageObj) {
    return JSON.parse(localStorageObj);
  }

  return null;
};

export const compareDateWithToday = (date: string): "smaller" | "bigger" | "equal" => {
  const date1Obj = new Date(date).setHours(0, 0, 0, 0);
  const date2Obj = new Date().setHours(0, 0, 0, 0);

  if (date1Obj < date2Obj) {
    return "smaller";
  }

  if (date1Obj > date2Obj) {
    return "bigger";
  }

  return "equal";
};

export const displayDescription = (description: string | undefined) => {
  if (!description || description === "") return "";

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: description }} />;
};

export const formatPhoneNumber = (phone: string | number | undefined) => {
  if (!phone) return "";

  const phoneNumberString = phone.toString();

  if (phoneNumberString.startsWith("07")) {
    return `${phoneNumberString.substring(0, 4)} ${phoneNumberString.substring(4, 7)} ${phoneNumberString.substring(
      7,
      10
    )}`;
  }

  return `${phoneNumberString.substring(0, 3)} ${phoneNumberString.substring(3, 6)} ${phoneNumberString.substring(6)}`;
};
