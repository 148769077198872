import { Button, TextField, styled } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import { useScrollToTop } from "common/hooks/helpers";
import { AddListingPagePropsType } from "common/types/listing";
import {
  updateUserInputFromLocalStorage,
  useUpdateLocalStorageWithUserInput,
  useValidateUserInput,
} from "../utils/hooks";
import { AddListingTitle } from "../components/AddListingTitle";
import { StyledAddListingWrapper } from "./styles";
import { priceFields } from "./sections-data";
import { PriceFieldsType } from "./sections-types";

type FieldsType = {
  [K in PriceFieldsType]: {
    value: string;
    label: string;
    required: boolean;
    validator: (value: string) => { error: boolean; errorMessage: string };
    touched?: boolean;
    error?: boolean;
    errorMessage?: string;
    type?: "text" | "number" | "date";
  };
};

// Will use translation later
const ROMANIAN_TEMP = true;

export const Price = (props: AddListingPagePropsType) => {
  const { onSetIsValid } = props;

  useScrollToTop();

  const [focusedInput, setFocusedInput] = useState<string | null>(null);
  const [userInput, setUserInput] = useState<FieldsType>(() =>
    updateUserInputFromLocalStorage({ fields: priceFields })
  );
  useUpdateLocalStorageWithUserInput({ userInput });
  useValidateUserInput({ userInput, onSetIsValid });

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    error = false,
    errorMessage = ""
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FieldsType;

    setUserInput((oldInput) => {
      const newInput = {
        ...oldInput,
        [key]: {
          ...oldInput[key],
          value,
          touched: true,
          error,
          errorMessage,
        },
      };
      return newInput;
    });
  };

  const handleFocusOut = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    const key = id as keyof FieldsType;

    const { validator } = priceFields[key];
    const { error, errorMessage } = validator(value);

    handleInputChange(event, error, errorMessage);
  };

  const handleDepositChange = (option: "none" | "single") => {
    const priceValue = userInput.price.value;
    const multiplier = option === "single" ? 1 : 0;

    const isPriceValid = !Number.isNaN(userInput.price.value);

    if (!isPriceValid) {
      return;
    }

    handleInputChange({
      target: {
        id: "deposit",
        value: String(Number(priceValue) * multiplier),
      },
    } as any);
  };

  const handleAvailableDateChange = (option: "today" | "beginning-next-month") => {
    // New date in format 2021-10-01T00:00:00.000Z
    const newDate = new Date();

    if (option === "beginning-next-month") {
      newDate.setMonth(newDate.getMonth() + 1);
      newDate.setDate(1);
    }

    const value = newDate.toISOString();

    handleInputChange({
      target: {
        id: "availableFrom",
        value,
      },
    } as any);
  };

  return (
    <>
      <AddListingTitle
        title="Preț și disponibilitate"
        subtitle="Adaugă informații despre prețul și disponibilitatea locuinței. Garanția este opțională și se plătește o singură dată, la începutul închirierii."
      />
      <StyledAddListingWrapper>
        {Object.entries(userInput).map(([id, { label, required, error, value, errorMessage, type }]) => {
          const key = id as keyof FieldsType;

          if (type === "date") {
            return (
              <DatePicker
                key={key}
                renderInput={(props) => (
                  <>
                    <TextField
                      {...props}
                      error={error}
                      size="small"
                      id={key}
                      fullWidth
                      variant="outlined"
                      required={required}
                      label={label}
                      helperText={errorMessage}
                      onBlur={handleFocusOut}
                      onFocus={(event: any) => {
                        const { id } = event.target;
                        setFocusedInput(id);
                      }}
                      inputProps={{
                        ...props.inputProps,
                        placeholder: ROMANIAN_TEMP ? "ll/zz/aaaa" : "mm/dd/yyyy",
                      }}
                    />
                    <StyledDepositCalculator
                      className={clsx({
                        "focus-availability": focusedInput === "availableFrom",
                      })}
                    >
                      <Button variant="text" size="small" onClick={() => handleAvailableDateChange("today")}>
                        Începând de azi
                      </Button>
                      {" | "}
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => handleAvailableDateChange("beginning-next-month")}
                      >
                        Prima zi a lunii viitoare
                      </Button>
                    </StyledDepositCalculator>
                  </>
                )}
                value={value}
                onChange={(value: any) => {
                  handleInputChange({
                    target: {
                      id: key,
                      value,
                    },
                  } as any);
                }}
              />
            );
          }

          return (
            <React.Fragment key={key}>
              <TextField
                error={error}
                size="small"
                key={key}
                id={key}
                fullWidth
                variant="outlined"
                required={required}
                label={label}
                helperText={errorMessage}
                value={value}
                type={type}
                onBlur={handleFocusOut}
                onChange={handleInputChange}
                onFocus={(event: any) => {
                  const { id } = event.target;
                  setFocusedInput(id);
                }}
              />
              <StyledDepositCalculator
                className={clsx({
                  "focus-deposit": key === "deposit" && focusedInput === "deposit",
                })}
              >
                <Button variant="text" size="small" onClick={() => handleDepositChange("none")}>
                  Nu se percepe garanție
                </Button>
                {" | "}
                <Button variant="text" size="small" onClick={() => handleDepositChange("single")}>
                  Garanția este chiria pe o lună
                </Button>
              </StyledDepositCalculator>
            </React.Fragment>
          );
        })}
      </StyledAddListingWrapper>
    </>
  );
};

const StyledDepositCalculator = styled("div")`
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 100%;
  font-size: 0px;
  margin-top: -18px;
  position: relative;

  button {
    font-size: 0;
    font-weight: 400;
  }

  &.focus-deposit {
    display: flex;

    button {
      font-size: 12px;
    }
  }

  &.focus-availability {
    display: flex;

    button {
      font-size: 12px;
    }
  }
`;
