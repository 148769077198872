import React, { ComponentProps } from "react";

export const FacilitiesIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width="75" height="65" viewBox="0 0 75 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M75 22.8975L37.5 0.492188L0 22.8975H33.75V64.5073H41.25V22.8975H75Z" fill="currentColor" />
      <path
        d="M8.02383 32.499L0.673828 33.6833L3.74883 47.6706V64.5066H11.2488L11.3238 51.7036H18.7488V64.5066H26.2488V45.3021H10.8738L8.02383 32.499Z"
        fill="currentColor"
      />
      <path
        d="M64.1172 45.3021H48.7422V64.5066H56.2422V51.7036H63.6672L63.7422 64.5066H71.2422V47.6706L74.3172 33.6833L66.9672 32.499L64.1172 45.3021Z"
        fill="currentColor"
      />
    </svg>
  );
};
