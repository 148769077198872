import { BuildingTypeType } from "pages/AddListing/add-listing-sections/sections-types";
import { compareDateWithToday, isNullish } from "./helpers";

export const allLabels = {
  block: "Apartament la bloc",
  house: "Casă",
  villa: "Vilă",
  detached: "Decomandat",
  semidetached: "Semidecomandat",
  ordered: "Comandat",
  studio: "Studio",
  municipal: "Centrală de cartier",
  electric: "Centrală electrică",
  gaz: "Centrală pe gaz",
  constructionYear: "Construit în {}",
  surface: "{} metri pătrați utili",
  bathroom: "{} baie",
  bathrooms: "{} băi",
  room: "{} cameră",
  rooms: "{} camere",
  hasAirConditioning: "Aer condiționat",
  hasBalcony: "Balcon",
  hasCableTv: "Cablu TV",
  hasClothesDryer: "Uscător de rufe",
  hasDiswasher: "Mașină de spălat vase",
  hasDryer: "Uscător de rufe",
  hasElevator: "Lift",
  hasFireplace: "Șemineu",
  hasFridge: "Frigider",
  hasFurniture: "Mobilat",
  hasGarage: "Garaj",
  hasGarden: "Grădină",
  hasHeating: "Încălzire",
  hasInternet: "Internet",
  hasOven: "Cuptor",
  hasPool: "Piscină",
  hasPrivateParking: "Parcare privată",
  hasSecuritySystem: "Sistem de securitate",
  hasStorage: "Depozitare",
  hasStreetParking: "Parcare pe stradă",
  hasTerrace: "Terasă",
  hasTv: "TV",
  hasVideoSurveillance: "Supraveghere video",
  hasWasher: "Mașină de spălat",
};

export const getGenericLabel = (key: keyof typeof allLabels | undefined, value?: string | number) => {
  if (!key) return undefined;

  return allLabels[key].replace("{}", String(value || ""));
};

// Custom labels
export const getFloors = (floor: string, totalFloors?: string) => {
  const firstPart = floor === "-2" ? "Subsol" : floor === "-1" ? "Demisol" : floor === "0" ? "Parter" : `Etaj ${floor}`;
  const secondPart = totalFloors ? ` din ${totalFloors}` : "";

  if (floor === "undefined" || floor === "null" || isNullish(floor))
    return `${totalFloors} ${totalFloors === "1" ? "etaj" : "etaje"}`;

  return `${firstPart}${secondPart}`;
};

export const getOwnerName = (ownerName = "") => {
  const [firstName, lastName] = ownerName.split(" ");
  return `${firstName} ${lastName?.[0]}.`;
};

export const getPrice = ({
  amount,
  currency = "€",
  interval = "lună",
}: {
  amount: number | string;
  currency?: string;
  interval?: string;
}) => `Chirie ${amount}${currency} pe ${interval}`;

// General
export const getDeposit = ({ amount, currency = "€" }: { amount: number | string; currency?: string }) =>
  `Garanție ${amount}${currency}`;

export const getAddress = (address: { cityName: string; streetName: string; streetNumber: string }) =>
  `${address.cityName}, ${address.streetName} ${address.streetNumber}`;

// Date stuff
export const getAvailableFrom = (date: string, buildingType: BuildingTypeType) => {
  const isTodayOrPast = compareDateWithToday(date) !== "bigger";
  const availableLabel = buildingType === "block" ? "disponibil" : "disponibilă";

  if (isTodayOrPast) return `${availableLabel}\u00A0acum`;
  return `${availableLabel} din ${formatDateRomanian(date)}`;
};

export const getAddedAt = (date: string) => `Adăugat în ${formatDateRomanian(date)}`;

export const getAddedBy = (name: string) => `Adăugat de ${name}`;

const padTo2Digits = (num: number) => num.toString().padStart(2, "0");

export const formatDate = (unformattedDate: string | Date) => {
  const date = new Date(unformattedDate);
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
};

export const formatDateRomanian = (unformattedDate: string | Date, includeYear = false) => {
  const date = new Date(unformattedDate);

  // Return e.g. 1 decembrie, 15 februarie, 31 martie
  return `${date.getDate()} ${getMonthNameRomanian(date.getMonth())} ${includeYear ? date.getFullYear() : ""}`.trim();
};

const getMonthNameRomanian = (month: number) => {
  const months = [
    "ianuarie",
    "februarie",
    "martie",
    "aprilie",
    "mai",
    "iunie",
    "iulie",
    "august",
    "septembrie",
    "octombrie",
    "noiembrie",
    "decembrie",
  ];

  return months[month];
};
