import SearchIcon from "@mui/icons-material/Search";
import { ClickAwayListener, alpha, styled } from "@mui/material";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { CENTERS } from "cities";
import { useBasePathUrl } from "common/hooks/useBasePathUrl";
import { theme } from "theme";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "common/hooks";
import { setSearchBoxOptionId } from "redux/slices/appSlice";
import { useAppParams } from "common/hooks/useAppParams";

interface CountyObj {
  id: string;
  lat: number;
  lng: number;
  zoom: number;
  countyId: string;
  name: string;
  countyName: string;
}

const options = Object.values(CENTERS).map((obj) => ({
  ...obj,
  name: obj.id === "ro_romania" ? "Toată România" : `${obj.name}, ${obj.countyName}`, // We need this combined name for the search
})) as CountyObj[];

export const SearchBox = () => {
  const { updateBasePath } = useBasePathUrl();
  const { isAddListingPage } = useAppParams();

  const dispatch = useAppDispatch();
  const { searchBoxOptionId } = useAppSelector(({ appSlice }) => appSlice);
  const { isXs } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  const [currentOption, setCurrentOption] = useState<(typeof options)[number] | null>(null);
  useEffect(() => {
    const newOption = options.find(({ id }) => id === searchBoxOptionId) || null;

    setCurrentOption(newOption || null);
    handleSelect(null, newOption, { skipUpdate: true });
  }, [searchBoxOptionId]);

  const splitName = (name: string) => name.split(",")[0];

  const handleSelect = (_event: any, newValue: any, options?: { skipUpdate: boolean }) => {
    if (!newValue) return;

    dispatch(setSearchBoxOptionId(newValue.id));

    if (options?.skipUpdate) return;

    updateBasePath({
      lat: newValue.lat,
      lng: newValue.lng,
      zoom: newValue.zoom,
      countyId: newValue.countyId,
      suffix: "#",
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <StyledSearch className="search-wrapper">
        <div className="icon-wrapper">
          <SearchIcon />
        </div>
        <StyledAutocomplete
          className="search-autocomplete"
          id="grouped-search"
          options={options}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={currentOption}
          PopperComponent={StyledPopper}
          getOptionLabel={(option: any) => option.name}
          disabled={isAddListingPage} // Workaround for the autocomplete not opening when we fill the add listing contact form
          renderOption={(props, renderOption: unknown) => {
            const option = renderOption as CountyObj;

            return (
              <StyledListElement
                {...props}
                className="search-autocomplete-option"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <span className="county-name">{splitName(option.name)}</span>
                {isXs ? (
                  <span className="name">{option.countyId.toUpperCase()}</span>
                ) : (
                  <span className="name">{option.countyName}</span>
                )}
              </StyledListElement>
            );
          }}
          onHighlightChange={(e, newValue, reason) => {
            if (reason === "keyboard") {
              handleSelect(e, newValue);
              // Highlight the selected option when pressing the arrow keys.
              // Autocomplete will handle the other keys.
              e.preventDefault();
            }
          }}
          onInputChange={(_e, _newValue, reason) => {
            if (reason === "input") {
              dispatch(setSearchBoxOptionId(null));
            }
          }}
          onChange={(e, newValue) => handleSelect(e, newValue)}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              autoComplete="off"
              id="search-autocomplete-input"
              value={currentOption ? splitName(currentOption.countyName) : ""}
              variant="outlined"
              placeholder="Caută județ sau localitate"
            />
          )}
        />
      </StyledSearch>
    </ClickAwayListener>
  );
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.grey[50],
    overflow: "none",
  },
  [`& .${autocompleteClasses.popper}`]: {
    width: "fit-content",
    minWidth: "100%",
    overflow: "none",
  },
});

const StyledSearch = styled("div")`
  position: relative;
  border-radius: 20px;
  margin: 0 0 0 auto;
  width: 100%;
  flex-grow: 1;
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.text.disabled};

  &:hover {
    background-color: ${theme.palette.background.paper};
  }

  .icon-wrapper {
    padding: ${theme.spacing(0, 2)};
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledListElement = styled("li")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-radius: ${theme.shape.borderRadius};
  color: ${theme.palette.grey[600]};
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:first-child {
    border-bottom: 1px solid ${theme.palette.grey[300]};

    .name {
      display: none;
    }
  }

  &:hover,
  &:focus,
  &.Mui-focused {
    background-color: ${alpha(theme.palette.primary.main, 0.1)};
  }

  .name {
    font-weight: 500;
  }

  .county-name {
    font-size: 0.8em;
    color: ${theme.palette.grey[600]};
  }
`;

const StyledAutocomplete = styled(Autocomplete)(() => ({
  "&.MuiAutocomplete-root": {
    width: "100%",
    border: "none",
    outline: "none",
    margin: 0,
    position: "relative",

    "& .MuiFormControl-root": {
      outline: "none",
      width: "100%",
      position: "relative",
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
      outline: "none",
      padding: 0,
      backgroundColor: "transparent",
    },
  },

  "& .MuiAutocomplete-popper": {
    width: "fit-content",

    "&.MuiAutocomplete-listbox": {
      padding: 0,

      "& .MuiAutocomplete-input": {
        padding: 0,
        paddingLeft: "1em",
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.disabled,
        border: "1px solid red",
        borderRadius: theme.shape.borderRadius,
      },
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  border: "none",
  outline: "none",
  color: "inherit",
  "&.MuiTextField-root": {
    width: "100%",
  },
  "& .MuiAutocomplete-input": {
    padding: theme.spacing(2, 2, 2, 0),
    marginLeft: `calc(1em + ${theme.spacing(6)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.disabled,
    borderRadius: theme.shape.borderRadius,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    outline: "none",
  },
}));
