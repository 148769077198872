import { Button, styled } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWindowDimensions } from "common/hooks";
import { theme } from "theme";
import { LoadingElement } from "redux/slices/appSlice";
import { buttons } from "../utils/addListingData";

type NavigationButtonsProps = {
  onStepperNext: () => void;
  onStepperPrev: () => void;
  currentPage: number;
  isValid: boolean;
  loadingElements: LoadingElement[];
  isLastPage: boolean;
  handleListingSubmit: () => void;
};

export const NavigationButtons = (props: NavigationButtonsProps) => {
  const { onStepperNext, onStepperPrev, currentPage, isValid, loadingElements, isLastPage, handleListingSubmit } =
    props;

  const { isMobile } = useWindowDimensions();

  const isLoading = loadingElements.includes("add-listing");

  return (
    <StyledButtons>
      <Button
        size={isMobile ? "small" : "large"}
        variant="text"
        onClick={onStepperPrev}
        disabled={currentPage === 0 || loadingElements.length > 0}
        sx={{
          "&.Mui-disabled": { backgroundColor: "#e0e0e0", visibility: currentPage === 0 ? "hidden" : "visible" },
          width: isMobile ? "150px" : "250px",
        }}
        startIcon={<ArrowBackIcon />}
      >
        {buttons.back}
      </Button>
      <LoadingButton
        size={isMobile ? "small" : "large"}
        variant="contained"
        onClick={isLastPage ? handleListingSubmit : onStepperNext}
        disabled={!isValid || isLoading}
        sx={{
          "&.Mui-disabled": { backgroundColor: "#e0e0e0" },
          "&.MuiButton-root:hover": {
            backgroundColor: theme.palette.primary.main,
          },
          width: isMobile ? "150px" : "250px",
        }}
        endIcon={isLastPage ? <SaveIcon /> : <ArrowForwardIcon />}
        loading={isLoading}
        loadingPosition={isLastPage ? "end" : undefined}
      >
        {isLastPage ? buttons.submit : buttons.next}
      </LoadingButton>
    </StyledButtons>
  );
};

const StyledButtons = styled("div")`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(4)};
  background-color: white;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
  z-index: 9999;
  box-sizing: border-box;
`;
