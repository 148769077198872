import { styled } from "@mui/material";
import clsx from "clsx";
import ImageGallery from "react-image-gallery";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InfoIcon from "@mui/icons-material/Info";
import Crop54Icon from "@mui/icons-material/Crop54";
import SettingsIcon from "@mui/icons-material/Settings";
import HeightIcon from "@mui/icons-material/Height";
import LivingIcon from "@mui/icons-material/Living";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SellIcon from "@mui/icons-material/Sell";
import { boxShadows, theme } from "theme";
import { useAppSelector, useWindowDimensions } from "common/hooks";
import { getFloors, getGenericLabel } from "utils/labels";
import { useOpenListingDetailsPage } from "common/hooks/useOpenListingDetailsPage";
import { BasicListingType } from "api/listings/listings-types";
import { ListingFavoriteAndEditButtons } from "./listing-favorite-and-edit-buttons";

type ListingCardProps = {
  listing: Pick<
    BasicListingType,
    | "buildingType"
    | "id"
    | "rooms"
    | "price"
    | "floor"
    | "surface"
    | "images"
    | "totalFloors"
    | "countyId"
    | "lat"
    | "lng"
  >;
  fromMarker?: boolean;
  onUserProfileCallback?: (listingId: string) => void;
};

export const ListingCard = (props: ListingCardProps) => {
  const { listing, fromMarker = false, onUserProfileCallback } = props;

  const { buildingType, id, rooms, price, floor, surface, images, totalFloors } = listing;

  const { onOpenListingDetailsPage } = useOpenListingDetailsPage();
  const { isMobile } = useWindowDimensions();
  const { lastSeenId } = useAppSelector(({ appSlice }) => appSlice);

  // TODO: Move inside a hook and include admin user too (which should be allowed to modify all users)
  const { user } = useAppSelector(({ authSlice }) => authSlice);
  const isOwnListing = user?.ownListingsIds?.includes(id) || false;

  const handleOpenListingDetailsPage = (event: any) => {
    event?.stopPropagation?.();
    event?.stopImmediatePropagation?.();

    if (typeof onUserProfileCallback === "function") {
      onUserProfileCallback(id);
      return;
    }

    onOpenListingDetailsPage({ listing });
  };

  return (
    <StyledListingCard
      id={fromMarker ? `card-basic-listings-${id}` : `feed-card-basic-listings-${id}`}
      className={clsx("listing-card-wrapper", {
        "last-seen": lastSeenId === id,
        "from-marker": fromMarker,
        "from-feed-or-details": !fromMarker,
        "is-mobile": isMobile,
      })}
    >
      <p
        className={clsx("own-listing-chip", {
          "own-listing": isOwnListing,
        })}
      >
        <InfoIcon />
        Anunțul tău
      </p>
      <ImageGallery
        key={`listing-card-image-gallery-${id}`}
        items={images?.map((img) => ({ original: img, thumbnail: img })) || []}
        infinite
        showFullscreenButton={false}
        showPlayButton={false}
        showNav
        showBullets={images?.length > 1}
        showThumbnails={false}
        onClick={handleOpenListingDetailsPage}
      />
      <button type="button" className="listing-card-info-button-wrapper" onClick={handleOpenListingDetailsPage}>
        <div className="listing-card-info">
          {!fromMarker && (
            <div className="price-tag">
              <SellIcon />
              <span>{`${price}€`}</span>
            </div>
          )}
          <div>
            <ApartmentIcon />
            <span>
              {buildingType === "house"
                ? "Casă"
                : buildingType === "block"
                ? "Bloc"
                : buildingType === "villa"
                ? "Vilă"
                : "Altceva"}
            </span>
          </div>
          <div>
            <LivingIcon />
            <span>{getGenericLabel(rooms === "1" ? "room" : "rooms", rooms)}</span>
          </div>
          <div>
            <HeightIcon />
            <span>{getFloors(floor, totalFloors)}</span>
          </div>
          <div>
            <Crop54Icon />
            <span>{`${surface}mp`}</span>
          </div>
        </div>
        <div className="listing-card-info-button">
          {isOwnListing && <SettingsIcon className="prefix-icon" />}
          <span>{isOwnListing ? "Editează anunțul" : "Deschide anunțul"}</span>
          <ArrowRightAltIcon className="arrow-icon" />
        </div>
      </button>
      <ListingFavoriteAndEditButtons
        countyId={listing.countyId}
        listingId={id}
        variant={fromMarker ? "marker-card" : "feed-card"}
      />
    </StyledListingCard>
  );
};

const StyledListingCard = styled("div")`
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${theme.palette.grey[400]};
  background-color: ${theme.palette.grey[100]};
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: ${boxShadows.cards};
  cursor: pointer;

  & .image-gallery-svg {
    width: 50px;
    height: 50px;
  }

  & .image-gallery-content:not(.fullscreen) img {
    height: 300px;
    border-radius: 8px 8px 0 0;
  }

  & .own-listing-chip {
    display: none;
    pointer-events: none;

    &.own-listing {
      display: flex;
      align-items: center;
      gap: 4px;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      font-size: 16px;
      font-weight: 500;
      top: -16px;
      left: 0;
      right: 0;
      padding: 8px 16px;
      box-sizing: border-box;
      width: 100%;
      z-index: 10;
      border-radius: 8px 8px 0 0;
    }
  }

  .listing-card-info-button-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 0 0 8px 8px;
    background-color: #f5f5f5; // rgba(0, 0, 0, 0.3);
    box-shadow: ${boxShadows.cards};
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;

    .listing-card-info {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      height: 24px;
      padding: 18px 24px;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid ${theme.palette.grey[300]};
      background-color: ${theme.palette.grey[200]};
      flex-wrap: wrap;
      height: unset;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 4px;
        white-space: nowrap;

        > svg {
          width: 20px;
          height: 20px;
          color: ${theme.palette.grey[500]};
        }

        > span {
          font-size: 14px;
          font-weight: 500;
          color: ${theme.palette.grey[800]};
        }

        &.price-tag {
          > svg {
          }

          > span {
          }
        }
      }

      ${theme.breakpoints.down("sm")} {
        padding: 12px 12px;
        gap: 4px;

        > div {
          > svg {
            width: 16px;
            height: 16px;
          }
          > span {
            font-size: 12px;
          }
        }
      }
    }

    .listing-card-info-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      margin: 12px 0;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      color: ${theme.palette.grey[900]};

      > .prefix-icon {
        width: 20px;
        height: 20px;
      }

      > .arrow-icon {
        height: 0;
        width: 0;
        margin: 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &.last-seen {
    border: 2px solid ${theme.palette.secondary.main};
  }

  &.from-marker {
    border: 2px solid white;
    max-width: 260px;
    min-width: 260px;
    width: 100%;
    margin: 0 0 -3px 0;

    & .image-gallery {
      img {
        max-height: 170px;
      }
    }

    & .image-gallery-svg {
      width: 30px;
      height: 30px;
    }

    & .image-gallery-bullets {
      bottom: 8px;

      .image-gallery-bullet {
        width: 8px;
        height: 8px;
      }
    }

    & .image-gallery-content:not(.fullscreen) img {
      height: 300px;
      border-radius: 8px 8px 0 0;
    }

    .listing-card-info-button-wrapper {
      margin: 0;

      .listing-card-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 6px;
        height: unset;
        padding: 8px 0 8px 12px;
        margin: 0 0 4px 0;
        box-sizing: border-box;

        > div {
          justify-content: flex-start;
          padding: 0;
          margin: 0;

          > svg {
            width: 13px;
            height: 13px;
          }

          > span {
            font-size: 13px;
            font-weight: 500;
            color: ${theme.palette.grey[800]};
          }
        }
      }

      .listing-card-info-button {
        margin: 6px 0 12px 0;
        font-size: 13px;

        > .arrow-icon {
          width: 0px;
          height: 0px;
        }
      }
    }

    ${theme.breakpoints.down("sm")} {
      .listing-card-info-button-wrapper {
        gap: 0;

        .listing-card-info {
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 6px;
          height: unset;
        }
      }
    }
  }

  &:hover,
  &.is-mobile {
    transition: filter 0.3s ease-in-out;

    &.from-feed-or-details:not(.last-seen) {
      border: 1px solid rgba(232, 129, 23, 0.5);
    }

    .listing-card-info-button-wrapper {
      .listing-card-info-button {
        color: ${theme.palette.secondary.main};

        > .arrow-icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &.from-marker:hover {
    .listing-card-info-button-wrapper {
      .listing-card-info-button {
        > .arrow-icon {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
`;
