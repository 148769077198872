export const ab = {
  ab_ab: {
    id: "ab_ab",
    lat: 46.06936111466607,
    lng: 23.573108552338997,
    zoom: 10,
    countyId: "ab",
    name: "Alba-Iulia",
    countyName: "Alba",
  },
};
