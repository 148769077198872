import { LoadingElement, setLoadingElements } from "redux/slices/appSlice";
import { useAppDispatch } from ".";

// React on isLoading flags across the app (where used).
// Update global state so that the loading bar is displayed
export const useUpdateAppLoadingState = () => {
  const dispatch = useAppDispatch();

  return {
    addLoadingEl: (el: LoadingElement) => {
      dispatch(setLoadingElements({ el, op: "add" }));
    },
    removeLoadingEl: (el: LoadingElement) => {
      dispatch(setLoadingElements({ el, op: "remove" }));
    },
    clearLoadingState: () => {
      dispatch(setLoadingElements({ op: "clear" }));
    },
  };
};
