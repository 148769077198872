import { Button, Dialog, IconButton, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useAppDispatch, useAppSelector, useBasePathUrl } from "common/hooks";
import { theme } from "theme";
import { setOpenedModal } from "redux/slices/appSlice";
import { CampaignTermsModal } from "./campaign-terms-modal";

export const CampaignModal = () => {
  const dispatch = useAppDispatch();
  const { goToBasePath, updateBasePath } = useBasePathUrl();

  const [termsOpen, setTermsOpen] = useState(false);
  const { openedModal } = useAppSelector((state) => state.appSlice);

  const [showCampaignModal, setShowCampaignModal] = useState(() => sessionStorage.getItem("showCampaignModal"));
  useEffect(() => {
    if (showCampaignModal === null) {
      setShowCampaignModal(localStorage.getItem("showCampaignModal"));
    }
  }, [showCampaignModal]);

  const handleCloseModal = () => {
    goToBasePath({});
    dispatch(setOpenedModal(null));
    setShowCampaignModal("false");
    sessionStorage.setItem("showCampaignModal", "false");
  };

  const handleNeverShow = () => {
    localStorage.setItem("showCampaignModal", "false");
    handleCloseModal();
  };

  const handleAddListing = () => {
    updateBasePath({
      suffix: "add",
    });
    dispatch(setOpenedModal(null));
  };

  return (
    <>
      <StyledUserProfileDialog
        open={openedModal === "campaign" && showCampaignModal === null}
        onClose={handleCloseModal}
      >
        <Stack alignItems="flex-end">
          <IconButton onClick={handleCloseModal}>
            <CloseIcon sx={{ color: "gray" }} />
          </IconButton>
        </Stack>

        <Container>
          <div className="onboarding-entry">
            <img src="/images/campaign/1.svg" alt="" />
            <img className="medal" src="/images/campaign/medal.svg" alt="" />
            <h1>Participă în campania &quot;Postează și Câștigă&quot;!</h1>
            <p>
              La adăugarea unui anunț, te înscrii automat la tombola pentru a câștiga <b>500 RON</b> dacă postezi
              anunțul până pe 24 Decembrie 2023. Se aplică următorii{" "}
              <button className="terms" type="button" onClick={() => setTermsOpen(true)}>
                termeni și condiții
              </button>
              .
            </p>
            <p className="antet">
              <InfoIcon /> Dacă ai deja un anunț activ adăugat în perioada campaniei, ești automat înscris în tombolă.
            </p>
            <div className="onboarding-buttons">
              <Button variant="outlined" color="secondary" onClick={handleNeverShow}>
                Nu mai doresc să văd acest mesaj
              </Button>
              <Button variant="contained" onClick={handleAddListing}>
                Adaugă un anunț acum
              </Button>
            </div>
          </div>
        </Container>
        {/* <div className="fading-gradient" /> */}
      </StyledUserProfileDialog>
      <CampaignTermsModal open={termsOpen} onClose={() => setTermsOpen(false)} />
    </>
  );
};

const Container = styled("div")`
  overflow: auto;
  height: 100%;

  .onboarding-entry {
    // background-image: url("/images/onboarding/1.svg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    text-align: center;
    position: relative;
    height: 100%;
    min-height: 450px;

    > img {
      max-height: 175px;
      height: auto;
      margin-top: 0;
    }

    & .medal {
      position: absolute;
      top: 0;
      left: 10%;
      transform: rotate(-10deg);
      width: 150px;
    }

    > h1,
    > p {
      color: ${theme.palette.grey[700]};
    }

    > p {
      line-height: 170%;

      > b {
        color: green;
      }
    }

    > h1 {
      margin: 0 0 1.5rem 0;
      font-size: 1.5rem;
    }

    & .antet {
      font-size: 12px;
      color: ${theme.palette.grey[600]};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5ch;
    }

    .onboarding-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: 2rem;

      > button {
        width: 100%;
      }

      ${theme.breakpoints.up("md")} {
        flex-direction: row;

        > button {
          width: auto;
        }
      }
    }

    & .terms {
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      color: ${theme.palette.primary.main};
      text-decoration: underline;
      font-size: inherit;
      font-weight: inherit;
    }

    ${theme.breakpoints.up("md")} {
      > img {
        max-height: 300px;
      }

      > h1 {
        // margin: 2rem 0 1rem 0;
      }
    }
  }
`;

const StyledUserProfileDialog = styled(Dialog)`
  margin: auto 5ch;

  .fading-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #c3c3c3 100%);
  }

  & .MuiDialog-paper {
    height: auto;
    padding: 1rem 1rem 2rem 1rem;
    overflow-y: auto;
    // min-width: 840px;
    z-index: 999999;
    width: 100%;
    border-radius: 8px;
    margin: 0;

    ${theme.breakpoints.up("md")} {
      position: absolute;
      right: 20px;
      bottom: 20px;
      padding: 24px;
      // min-width: 300px;
      // margin-bottom: -20px;
    }
  }
`;
