import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import Fab from "@mui/material/Fab";
import Grow from "@mui/material/Grow";
import ListItem from "@mui/material/ListItem";
import { Chip, Tooltip } from "@mui/material";
import clsx from "clsx";
import { Check } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { motion, useAnimation } from "framer-motion";
import { useWindowDimensions } from "common/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { useOnClickOutside } from "utils/hooks/useOnClickOutside";
import { boxShadows } from "theme";

export type Pois =
  | "attraction"
  | "business"
  | "government"
  | "medical"
  | "park"
  | "place_of_worship"
  | "school"
  | "sports_complex"
  | "transit";

// eslint-disable-next-line no-underscore-dangle
const _allPois: Pois[] = [
  "attraction",
  "business",
  "government",
  "medical",
  "park",
  "place_of_worship",
  "school",
  "sports_complex",
  "transit",
];

interface Props {
  visiblePois: Pois[];
  setVisiblePois: (pois: Pois[]) => void;
}

export const FloatingButtonPointsOfInterest = (props: Props) => {
  const { visiblePois, setVisiblePois } = props;
  const { countyId } = useAppParams();
  const { isMobile } = useWindowDimensions();
  const animationControls = useAnimation();

  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isCountryViewMode = countyId === "ro";

  useEffect(() => {
    const poisClicked = localStorage.getItem("poisClicked");

    if (poisClicked === "true") {
      setTooltipOpen(false);
      return;
    }
    let timer: any = null;

    if (!isCountryViewMode) {
      setTooltipOpen(true);

      timer = setTimeout(() => {
        setTooltipOpen(false);
        // Do this to avoid showing the tooltip again
        // (on top of user clicking the button)
        localStorage.setItem("poisClicked", "true");
      }, 5000);
    }

    // Clean up the timer on unmount
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const listAnimationVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  const handleMouseLeave = () => {
    setOpen(false);
    animationControls.start({ rotate: 0 });
  };

  const handleClickFabButton = () => {
    localStorage.setItem("poisClicked", "true");
    setTooltipOpen(false);

    setOpen(!open);
    animationControls.start({ rotate: open ? 0 : 0 });
  };

  const detailsRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(detailsRef, handleMouseLeave);

  const handleClick = (id: Pois) => {
    let newVisiblePois = [...visiblePois];

    if (visiblePois.includes(id)) {
      newVisiblePois = visiblePois.filter((poi) => poi !== id);
    } else {
      newVisiblePois.push(id);
    }
    setVisiblePois(newVisiblePois);
  };

  return (
    <FabsContainer isMobile={isMobile} ref={detailsRef}>
      <Grow in={open} mountOnEnter unmountOnExit>
        <StyledList initial="hidden" animate={open ? "visible" : "hidden"} variants={listAnimationVariants}>
          {poisChips.map(({ id, label }) => {
            const isSelected = visiblePois.includes(id);
            return (
              <StyledListItem key={id} onClick={() => handleClick(id)}>
                <StyledChip
                  label={label}
                  className={clsx(isSelected && "selected")}
                  icon={isSelected ? <Check fontSize="small" /> : undefined}
                />
              </StyledListItem>
            );
          })}
        </StyledList>
      </Grow>
      <MotionWrapper animate={animationControls} whileTap={{ rotate: 0 }} onClick={handleClickFabButton}>
        <Tooltip
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: "white",
                padding: "15px",
                fontSize: "12px",
                fontWeight: 300,
                boxShadow: "0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28)",
                color: "grey",
                width: "140px",
                textAlign: "center",
              },
              "& .MuiTooltip-arrow": {
                color: "white",
              },
            },
          }}
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Alege punctele de interes pe care dorești să le vezi pe hartă</span>
            </div>
          }
          placement="left"
          open={tooltipOpen}
          arrow
          enterDelay={500}
          enterTouchDelay={500}
          leaveDelay={0}
          leaveTouchDelay={0}
        >
          <StyledFab size="small">
            <MoreHorizIcon />
          </StyledFab>
        </Tooltip>
      </MotionWrapper>
    </FabsContainer>
  );
};

export const StyledSelectAll = styled("span")(({ theme }) => ({
  borderRadius: theme.spacing(5),
  marginTop: "10px",
  cursor: "pointer",
  textDecoration: "underline",
  "&:hover": {
    fontWeight: "bold",
  },
  width: "auto",
  fontSize: "12px",
  display: "flex",
  color: theme.palette.grey[600],
}));

const poisChips: Array<{
  id: Pois;
  label: string;
}> = [
  {
    id: "business",
    label: "Restaurante, magazine",
  },
  {
    id: "transit",
    label: "Transport în comun",
  },
  {
    id: "school",
    label: "Instituții de învățământ",
  },
  {
    id: "sports_complex",
    label: "Zone de agreement",
  },
  {
    id: "attraction",
    label: "Atracții turistice",
  },
  {
    id: "medical",
    label: "Spitale și clinici",
  },
  {
    id: "government",
    label: "Instituții publice",
  },
  {
    id: "place_of_worship",
    label: "Lăcașuri de cult",
  },
];

export const FabsContainer = styled("div")<{ isMobile: boolean }>(
  ({ isMobile }) => `
  position: absolute;
  bottom:  20px;
  right: ${isMobile ? "-5px" : "0px"};
  margin: 1em;
  z-index: 2;
`
);

const MotionWrapper = styled(motion.div)``;
const StyledFab = styled(Fab)`
  transform: rotate(0deg);
  background-color: #fff;
  box-shadow: ${() => boxShadows.mapButtons};
`;

const StyledList = styled(motion.ul)`
  position: absolute;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: transparent;
  padding: 0;
`;

const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px 0;
  border: none !important;
  width: auto;
  margin-left: auto;
  text-align: right;
  padding: 0;
`;

const StyledChip = styled(Chip)(
  ({ theme }) => `
  cursor: pointer;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  font-weight: 300;

  padding:  10px;
  background-color: #fff;
  color: black;

  &:hover,
  &:active,
  &:focus,
  &.selected {
    filter: brightness(1.1);
    font-weight: 400;
  }
  &.selected {
    background-color: #a7c7fa;
    border: 1px solid ${theme.palette.primary.main};

    & svg {
      color: ${theme.palette.secondary.main}
    }
  }
`
);
