export const tm = {
  tm_timisoara: {
    id: "tm_timisoara",
    lat: 45.75615232061579,
    lng: 21.22809071131744,
    zoom: 10,
    countyId: "tm",
    name: "Timișoara",
    countyName: "Timiș",
  },
};
