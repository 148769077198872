// Remove unnecessary elements from address string
export const parseSearchResult = (address: string) => {
  let parsedAddress = address;

  parsedAddress = parsedAddress.replace(", România", "");

  // TODO: Remove postal code (with regex not working for some reason)
  if (!Number.isNaN(Number(parsedAddress.substring(parsedAddress.length - 7)))) {
    parsedAddress = parsedAddress.substring(0, parsedAddress.length - 7);
  }

  return parsedAddress;
};
