import { Dialog, IconButton, Stack, Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import { useAppDispatch, useAppSelector, useBasePathUrl } from "common/hooks";
import { useGetActiveListing } from "api/listings/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { theme } from "theme";
import { formatDateRomanian, getPrice } from "utils/labels";
import { useGetListingOwnerPhone } from "api/listings/hooks/get-listing-owner-phone";
import { ListingCard } from "components/ListingCard";
import { formatPhoneNumber } from "utils/helpers";
import { useGetPublicUserProfile } from "api/user/hooks/get-public-user-profile";

export const UserProfileModal = () => {
  const dispatch = useAppDispatch();
  const { basePath } = useBasePathUrl();

  const { activeListing } = useGetActiveListing();
  const { publicUserProfile } = useGetPublicUserProfile(activeListing?.ownerId);
  const { openedModal } = useAppSelector((state) => state.appSlice);
  const { ownerPhoneNumber } = useGetListingOwnerPhone({ listingId: activeListing?.id, enabled: true });

  if (!activeListing) return null;

  const handleCloseModal = () => {
    dispatch(setOpenedModal(null));
  };

  const handleOpenListingDetailsPage = (listingId: string) => {
    const listingAddress = `${window.location.origin}${basePath}/listing/${listingId}`;
    window.open(listingAddress, "_blank");
  };

  return (
    <StyledUserProfileDialog open={openedModal === "user-profile"} onClose={handleCloseModal}>
      <Stack alignItems="flex-end">
        <IconButton onClick={handleCloseModal}>
          <CloseIcon sx={{ color: "gray" }} />
        </IconButton>
      </Stack>
      {/* <Stack spacing={10} direction={{ xs: "column", md: "row" }} alignItems={{ xs: "center", md: "flex-start" }}> */}
      <Stack spacing={10} direction={{ xs: "column", md: "column" }} alignItems={{ xs: "center", md: "center" }}>
        <Stack
          className="left-side"
          spacing={5}
          direction="column"
          justifyContent={{ xs: "flex-start", md: "flex-start" }}
        >
          <img
            src={publicUserProfile?.profilePictureUrl || "/placeholder.jpeg"}
            alt=""
            aria-label="no background image"
          />
          <Stack
            className="user-metadata-container"
            direction="column"
            alignItems={{ xs: "center", md: "center" }}
            justifyContent={{ xs: "center", md: "center" }}
            spacing={1}
          >
            <Typography className="user-metadata-name" variant="h4">
              {activeListing.ownerFirstName} {activeListing.ownerLastName}
            </Typography>
            <Typography className="user-metadata-member-since" variant="h6">
              Membru din <strong>{formatDateRomanian(activeListing.createdAt, true)}</strong>
            </Typography>
            <Typography className="user-metadata-phone" variant="h6" display="flex" alignItems="center">
              <PhoneIcon fontSize="small" />
              <a href={`tel:${ownerPhoneNumber}`}>{formatPhoneNumber(ownerPhoneNumber)}</a>
            </Typography>
          </Stack>
        </Stack>
        {publicUserProfile && (
          <Stack spacing={15} className="right-side">
            <Stack id="user-profile-listings" spacing={6} className="user-profile-listings">
              <Typography variant="h6">
                Anunțurile proprietarului{" "}
                <span
                  style={{
                    fontSize: "14px",
                    color: theme.palette.grey[600],
                  }}
                >
                  ({publicUserProfile.listings.length})
                </span>
              </Typography>
              <Stack direction="row" className="user-profile-listings-list">
                {publicUserProfile.listings.map((listing) => (
                  <div key={listing.id}>
                    <span className="price-label">{getPrice({ amount: listing.price }).replace("Chirie ", "")}</span>
                    <ListingCard fromMarker listing={listing} onUserProfileCallback={handleOpenListingDetailsPage} />
                  </div>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </StyledUserProfileDialog>
  );
};

const StyledUserProfileDialog = styled(Dialog)`
  & .MuiDialog-paper {
    padding: 24px;
    margin: 0;
    width: 90vw;
    box-sizing: border-box;

    overflow-y: auto;
    z-index: 999999;

    ${theme.breakpoints.up("sm")} {
      padding: 32px 32px 64px 32px;
      min-width: 420px;
    }

    ${theme.breakpoints.up("lg")} {
      min-width: 700px;
    }
  }

  & .left-side {
    position: relative;
    max-width: 200px;
    color: ${theme.palette.grey[600]};

    & img {
      border-radius: 50%;
      width: 180px;
      height: 180px;
      object-fit: cover;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
      margin: 0 auto;
    }

    & .user-metadata-container {
      padding: 0 11px;

      & .user-metadata-name {
        color: black;
        font-size: 18px;
        font-weight: 500;
      }

      & .user-metadata-member-since {
        font-size: 13px;
        font-weight: 400;
        white-space: nowrap;
      }

      & .user-metadata-phone {
        font-size: 14px;
        font-weight: 500;
        gap: 4px;

        a {
          color: ${theme.palette.primary.main};
          padding: 0;
          margin: 0;
        }
      }
    }

    & .user-profile-side-menu {
      & .MuiButtonBase-root {
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: ${theme.palette.grey[600]};
      }
    }
  }

  & .right-side {
    width: 100%;

    & .user-profile-listings {
      .user-profile-listings-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        > div {
          position: relative;
          margin-bottom: 20px;
          width: 100%;

          & .price-label {
            color: ${theme.palette.secondary.main};
            background-color: white;
            border-radius: 8px;
            position: absolute;
            z-index: 9999999;
            top: -8px;
            right: 0;
            font-size: 12px;
            padding: 4px 8px;
            font-weight: 600;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
          }

          & .own-listing-chip {
            font-size: 12px;
          }

          .listing-card-wrapper.from-marker {
            // min-width: 200px;
            width: 100%;
            min-width: 100%;

            & .image-gallery {
              img {
                max-height: 100px;
              }
            }

            & .image-gallery-icon {
              padding: 0;
            }

            & .image-gallery-svg {
              width: 20px;
              height: 20px;
            }

            & .image-gallery-bullets {
              display: none;
            }

            & .image-gallery-content:not(.fullscreen) img {
              height: 300px;
              border-radius: 8px 8px 0 0;
            }

            .listing-card-info-button-wrapper {
              margin: 0;
              background-color: transparent;
              white-space: nowrap;

              > svg {
                padding: 0;
              }

              .listing-card-info {
                grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
              }
            }
          }
        }
      }
    }
  }

  ${theme.breakpoints.down("lg")} {
    .right-side {
    }
  }

  ${theme.breakpoints.down("sm")} {
    border: 1px solid red;
  }
`;
