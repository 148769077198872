export const bv = {
  bv_brasov: {
    id: "bv_brasov",
    lat: 45.65443938498519,
    lng: 25.605713353920418,
    zoom: 10,
    countyId: "bv",
    name: "Brașov",
    countyName: "Brașov",
  },
};
