import { useMutation } from "react-query";
import { useAppSelector } from "common/hooks";
import { UserApi } from "../user-api";

interface MutationProps {
  listingIds: string[];
}

export const useFavoritesAdd = () => {
  const { token } = useAppSelector((state) => state.authSlice);

  const {
    mutate: originalMutate,
    isLoading,
    isSuccess,
    isError,
  } = useMutation("favoritesAdd", (mutationProps: MutationProps) => {
    const { listingIds } = mutationProps;

    return UserApi.addUserFavorites({
      listingIds,
      token: token!,
    });
  });

  const mutate = (mutationProps: MutationProps, ...rest: any) => {
    if (token) {
      originalMutate(mutationProps, ...rest);
    }
  };

  return { mutate, isLoading, isSuccess, isError };
};
