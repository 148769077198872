import { useMutation } from "react-query";
import { useAppSelector } from "common/hooks";
import { UserApi } from "../user-api";

interface MutationProps {
  email?: string;
  password?: string;
}

export const useLoginUser = () => {
  // If there is a token, we are logged in
  const { token } = useAppSelector((state) => state.authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation("loginUser", (mutationProps: MutationProps) => {
    const { email, password } = mutationProps || {};

    return UserApi.loginUser({
      email,
      password,
      token,
    });
  });

  return { mutate, isLoading, isSuccess, isError };
};
