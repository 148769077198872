import { Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { PageWrapper } from "./PageWrapper";

export const MuiV5Components = () => {
  const { isAdminUser } = useIsAdminUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminUser) {
      navigate("/");
    }
  }, [isAdminUser, navigate]);

  return (
    <PageWrapper title="Contact">
      <Stack spacing={10} direction="column" mb={20}>
        <TypographyComponent />
        <ButtonsComponent />
      </Stack>
    </PageWrapper>
  );
};

const Title = (props: { text: string }) => (
  <Typography variant="h5" gutterBottom borderBottom="1px solid" borderColor="lightgray">
    {props.text}
  </Typography>
);

const Subtitle = (props: { text: string }) => <Typography variant="subtitle1">{props.text}</Typography>;

const TypographyComponent = () => (
  <Stack spacing={2} direction="column">
    <Title text="Typography" />
    <Stack spacing={1} direction="column">
      <Typography variant="h1">Header 1</Typography>
      <Typography variant="h2">Header 2</Typography>
      <Typography variant="h3">Header 3</Typography>
      <Typography variant="h4">Header 4</Typography>
      <Typography variant="h5">Header 5</Typography>
      <Typography variant="h6">Header 6</Typography>
      <Typography variant="subtitle1">Subtitle 1</Typography>
      <Typography variant="subtitle2">Subtitle 2</Typography>
      <Typography variant="body1">Body 1</Typography>
      <Typography variant="body2">Body 2</Typography>
      <Typography variant="button">Button</Typography>
      <Typography variant="caption">Caption</Typography>
      <Typography variant="overline">Overline</Typography>
    </Stack>
  </Stack>
);

const ButtonsComponent = () => (
  <Stack spacing={2} direction="column">
    <Title text="Buttons" />
    <Stack spacing={2} direction="column">
      <Stack spacing={2} direction="column">
        <Subtitle text="Contained" />
        <Stack display="block" spacing={1} direction="row">
          <Button variant="contained" color="primary">
            Primary
          </Button>
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
          <Button variant="contained" color="success">
            Success
          </Button>
          <Button variant="contained" color="error">
            Error
          </Button>
          <Button variant="contained" color="warning">
            Warning
          </Button>
          <Button variant="contained" color="info">
            Info
          </Button>
          <Button variant="contained" color="info" disableElevation>
            Disabled elevation
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2} direction="column">
        <Subtitle text="Outlined" />
        <Stack spacing={1} direction="row">
          <Button variant="outlined" color="primary">
            Primary
          </Button>
          <Button variant="outlined" color="secondary">
            Secondary
          </Button>
          <Button variant="outlined" color="success">
            Success
          </Button>
          <Button variant="outlined" color="error">
            Error
          </Button>
          <Button variant="outlined" color="warning">
            Warning
          </Button>
          <Button variant="outlined" color="info">
            Info
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2} direction="column">
        <Subtitle text="Text" />
        <Stack spacing={1} direction="row">
          <Button variant="text" color="primary">
            Primary
          </Button>
          <Button variant="text" color="secondary">
            Secondary
          </Button>
          <Button variant="text" color="success">
            Success
          </Button>
          <Button variant="text" color="error">
            Error
          </Button>
          <Button variant="text" color="warning">
            Warning
          </Button>
          <Button variant="text" color="info">
            Info
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2} direction="column">
        <Subtitle text="Sizes" />
        <Stack display="block" spacing={1} direction="row">
          <Button variant="contained" size="small">
            Small
          </Button>
          <Button variant="contained" size="medium">
            Medium
          </Button>
          <Button variant="contained" size="large">
            Large
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2} direction="column">
        <Subtitle text="Icons" />
        <Stack display="block" spacing={1} direction="row">
          <Button variant="contained" startIcon={<SendIcon />}>
            Start
          </Button>
          <Button variant="contained" endIcon={<SendIcon />}>
            End
          </Button>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);
