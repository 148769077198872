// import WipImage from "assets/wip.png";
// import NotFoundImage from "assets/notfound.png";
import { useBasePathUrl } from "common/hooks";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";

export const NotFound = () => {
  const { goToBasePath } = useBasePathUrl();

  return (
    <ConfirmationDialog
      labelPrimary="Mergi la pagina principală"
      open
      onCancel={() => goToBasePath({})}
      onPrimary={() => goToBasePath({})}
      onSecondary={() => goToBasePath({})}
      title="Nu am găsit pagina pe care o cauți"
      variant="warning"
    >
      E posibil să fi fost ștearsă sau să se fi mutat la o altă adresă. Te rugăm să verifici link-ul și să încerci din
      nou.
    </ConfirmationDialog>
  );
};

// const PageNotFoundImage = () => {
//   return (
//     <img
//       src={NotFoundImage}
//       alt="Not Found"
//       style={{
//         maxWidth: "20vw",
//         maxHeight: "20vh",
//         position: "relative",
//       }}
//     />
//   );
// };

// const UnderConstructionImage = () => {
//   return (
//     <img
//       src={WipImage}
//       alt="Work in progress"
//       style={{
//         maxWidth: "50vw",
//         maxHeight: "50vh",
//         position: "relative",
//       }}
//     />
//   );
// };
