import { Button, Checkbox, Divider, FormControlLabel, styled } from "@mui/material";
import { ChangeEvent, useEffect, useRef } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import clsx from "clsx";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "common/hooks";
import {
  FiltersOptions,
  FiltersOptionsAllKeys,
  resetFilters,
  setFiltersOpen,
  updateFilters,
} from "redux/slices/filtersSlice";
import { boxShadows, theme } from "theme";
import { FILTERS_WIDTH } from "utils/constants";
import { StyledButton, StyledContainer } from "components/SidebarViewsToggleButtons";
import { useSaveSearch } from "api/listings/hooks/search-saving/save-search";

export const FiltersSidePanel = () => {
  const { filtersOpen, filtersOptions, filters } = useAppSelector(({ filtersSlice }) => filtersSlice);
  const { sidebarOpen } = useAppSelector(({ appSlice }) => appSlice);
  const { token } = useAppSelector(({ authSlice }) => authSlice);
  const { isMobile } = useWindowDimensions();
  const filtersRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const { saveSearch } = useSaveSearch();

  const saved = useRef(false);
  useEffect(() => {
    saved.current = false;
  }, [filters]);

  const appliedFiltersNumber = Object.values(filters).reduce((acc, curr) => acc + curr.length, 0);
  const hasActiveFilters = Object.values(filters).some((filter) => filter.length > 0);

  useEffect(() => {
    if (!isMobile) {
      dispatch(setFiltersOpen(true));
    } else {
      dispatch(setFiltersOpen(false));
    }
  }, [isMobile]);

  const handleCloseModal = () => {
    dispatch(setFiltersOpen(false));
  };

  const handleReset = () => {
    dispatch(resetFilters());
  };

  const handleSaveSearch = (_event: any) => {
    if (!hasActiveFilters || saved.current || !token) {
      return;
    }

    saveSearch(undefined, {
      onSuccess: () => {
        toast.success("Căutarea a fost salvată cu succes!");
        handleCloseModal();
        saved.current = true;
      },
      onError: () => {
        toast.error("Căutarea nu a putut fi salvată. Te rugăm să încerci din nou.");
      },
    });
  };

  // useOnClickOutside(filtersRef, handleCloseModal);

  return (
    <Container ref={filtersRef} className={clsx({ "close-filters": !filtersOpen, "box-shadow": !sidebarOpen })}>
      <StyledTitleAndCloseButton>
        <div className="title-and-close">
          <h3>{hasActiveFilters ? "Filtre active" : "Filtrează anunțurile"}</h3>
          <StyledCloseFilters onClick={() => dispatch(setFiltersOpen(false))}>
            <ArrowForwardIosIcon />
          </StyledCloseFilters>
        </div>
        {hasActiveFilters && (
          <div className="save-and-reset">
            <Button type="button" variant="text" onClick={handleReset} className="reset">
              Resetează toate filtrele
            </Button>
            {isMobile && (
              <Button type="button" variant="text" onClick={handleSaveSearch} className="save">
                Salvează căutarea
              </Button>
            )}
          </div>
        )}
      </StyledTitleAndCloseButton>
      <Divider />
      <FilterGroupElement fields={filtersOptions.price} title="Preț închiriere" id="price" />
      <FilterGroupElement fields={filtersOptions.rooms} title="Camere" id="rooms" />
      <FilterGroupElement fields={filtersOptions.buildingType} title="Tip clădire" id="buildingType" />
      <FilterGroupElement fields={filtersOptions.partitioning} title="Compartimentare" id="partitioning" />
      <FilterGroupElement fields={filtersOptions.floors} title="Etaj" id="floors" />
      <FilterGroupElement fields={filtersOptions.surface} title="Suprafață (mp utili)" id="surface" />
      <FilterGroupElement fields={filtersOptions.extra} title="Facilități și dotări" id="extra" />
      {isMobile && (
        <StyledContainer>
          <StyledButton
            endIcon={<CheckIcon />}
            size="small"
            variant="contained"
            onClick={() => handleCloseModal()}
            sx={{
              zIndex: 1000,
              width: "100%",
              "&:hover": {
                fontWeight: "bold",
              },
              position: "fixed",
              bottom: "32px",
            }}
          >
            {appliedFiltersNumber === 0
              ? "Înapoi la anunțuri"
              : appliedFiltersNumber === 1
              ? "Aplică 1 filtru"
              : `Aplică ${appliedFiltersNumber} filtre`}
          </StyledButton>
        </StyledContainer>
      )}
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: ${FILTERS_WIDTH}px;
  width: 100%;
  height: 100%;
  padding: 0 12px 100px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
  position: relative;
  z-index: 999;
  background-color: white;

  transition: all 0.3s ease-in-out;

  &.box-shadow {
    box-shadow: ${() => boxShadows.filters};
  }

  ${theme.breakpoints.up("md")} {
    &.close-filters {
      max-width: 0;
      width: 0;
      padding: 0;
    }
  }

  ${theme.breakpoints.down("md")} {
    position: absolute;
    width: 100%;
    max-width: 100%;
    min-width: unset;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    z-index: 9999999;

    &.close-filters {
      border: 1px solid red;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
    }
  }
`;

const StyledCloseFilters = styled("button")`
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: ${theme.palette.grey[500]};
  margin: 6px -2px 8px 6px; // Make it match the Filters button from the map
  width: 24px;
  height: 24px;
  margin-left: auto;
`;

interface FilterGroupElementProps {
  fields: {
    key: FiltersOptionsAllKeys;
    label: string;
  }[];
  id: FiltersOptions;
  title: string;
}

const FilterGroupElement = (props: FilterGroupElementProps) => {
  const { fields, id: fieldKey, title } = props;
  const { filters, filtersCount } = useAppSelector(({ filtersSlice }) => filtersSlice);

  const dispatch = useAppDispatch();

  const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    dispatch(updateFilters({ fieldKey, id, checked }));
  };

  return (
    <StyledFiltersGroup>
      <h5>{title}</h5>
      {Object.values(fields).map(({ key, label }) => {
        return (
          <StyledFilterLine key={key}>
            <FormControlLabel
              className="label"
              control={
                <Checkbox
                  id={key}
                  className="checkbox"
                  color="primary"
                  onChange={handleCheckboxClick}
                  checked={Object.values(filters).some((filter) => filter.includes(key))}
                  size="small"
                />
              }
              label={label}
            />
            <span>{`(${filtersCount[key]})`}</span>
          </StyledFilterLine>
        );
      })}
    </StyledFiltersGroup>
  );
};

const StyledTitleAndCloseButton = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0;
  box-sizing: border-box;
  gap: 0.25rem;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: white;

  .title-and-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    > h3 {
      font-weight: 500;
      margin: 0;
    }
  }

  .save-and-reset {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;

    > button {
      font-size: 12px;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }

    & .reset {
      color: ${theme.palette.secondary.main};
    }

    & .save {
      color: ${theme.palette.primary.main};
    }
  }

  ${theme.breakpoints.down("sm")} {
    .title-and-close {
      > h3 {
        font-size: 22px;
      }
    }
  }
`;

const StyledFiltersGroup = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 8px;
  display: flex;
  background-color: ${theme.palette.grey[50]};

  > h5 {
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    padding: 12px 0 4px 0;
  }

  .label {
    margin: 0;
    color: ${theme.palette.grey[800]};
    margin-left: -2px;
    padding: 6px 0;

    > span {
      font-weight: 400;
      font-size: 13px;
    }

    &:last-child {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .checkbox {
    padding: 0 2px 0 0;
  }

  ${theme.breakpoints.down("sm")} {
    > h5 {
      font-size: 18px;
    }

    .label {
      > span {
        font-size: 16px;
      }
    }
  }
`;

const StyledFilterLine = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > span {
    font-weight: 400;
    font-size: 13px;
    margin: 0 8px 0 4px;
    color: ${theme.palette.grey[600]};
  }

  ${theme.breakpoints.down("md")} {
    > span {
      font-size: 16px;
    }
  }
`;
