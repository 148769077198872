import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { QueriesKeys } from "common/types/general";
import { useDeleteAccount } from "api/user/hooks/delete-account";
import { logout } from "redux/slices/authSlice";

export const DeleteAccountModal = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { deleteAccount } = useDeleteAccount();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      setError(false);
      setSuccess(false);
    };
  }, []);

  // Ban user
  const { openedModal } = useAppSelector((state) => state.appSlice);

  const handleCloseModal = () => {
    dispatch(setOpenedModal("authenticated-user-profile"));
    queryClient.refetchQueries("user" as QueriesKeys);
  };

  const handleDeleteAccount = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Ești sigur că vrei să ștergi contul?")) {
      deleteAccount(undefined, {
        onSuccess: () => {
          toast.success("Contul a fost șters cu succes!");
          setSuccess(true);
          dispatch(logout());
          dispatch(setOpenedModal(null));
        },
        onError: () => {
          toast.error("A apărut o eroare. Te rugăm să încerci din nou.");
          setError(true);
        },
      });
    }
  };

  if (success) {
    return (
      <ConfirmationDialog
        title="Contul a fost șters cu succes!"
        variant="success"
        labelPrimary="Închide"
        open={openedModal === "delete-account"}
        onCancel={handleCloseModal}
        onPrimary={handleCloseModal}
        onSecondary={handleCloseModal}
      >
        Contul și toate datele asociate acestuia au fost șterse cu succes.
      </ConfirmationDialog>
    );
  }

  if (error) {
    return (
      <ConfirmationDialog
        title="Eroare"
        variant="info"
        labelPrimary="Închide"
        open={openedModal === "delete-account"}
        onCancel={handleCloseModal}
        onPrimary={handleCloseModal}
        onSecondary={handleCloseModal}
      >
        A apărut o eroare. Te rugăm să încerci din nou mai târziu.
      </ConfirmationDialog>
    );
  }

  return (
    <ConfirmationDialog
      title="Ești sigur că vrei să ștergi contul?"
      variant="error"
      labelSecondary="Anulează"
      labelPrimary="Da, șterge contul"
      // disabledPrimary={!bannedUserId || isBanUserLoading || !bannedReason}
      open={openedModal === "delete-account"}
      onCancel={handleCloseModal}
      onPrimary={handleDeleteAccount}
      onSecondary={handleCloseModal}
    >
      Atenție! Toate anunțurile și datele asociate contului vor fi șterse definitiv. Odată ce contul este șters, nu mai
      poate fi recuperat.
    </ConfirmationDialog>
  );
};
