export const vl = {
  vl_ramnicu_valcea: {
    id: "vl_ramnicu_valcea",
    lat: 45.09903861265682,
    lng: 24.363298057515106,
    zoom: 10,
    countyId: "vl",
    name: "Râmnicu Vâlcea",
    countyName: "Vâlcea",
  },
};
