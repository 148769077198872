export const cs = {
  cs_resita: {
    id: "cs_resita",
    lat: 45.29981175930616,
    lng: 21.891331304824483,
    zoom: 10,
    countyId: "cs",
    name: "Reșița",
    countyName: "Caraș-Severin",
  },
};
