import { useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";

export const useHandleApiError = ({ isError, message }: { isError: boolean; message?: string }) => {
  const { clearLoadingState } = useUpdateAppLoadingState();

  useEffect(() => {
    if (isError) {
      clearLoadingState();
      toast.error(message || "Eroare. Te rugăm să încerci din nou.", { toastId: message || "error-message" });
    }
  }, [isError, message]);
};
