import { ChangeEvent, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { theme } from "theme";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { uploadToS3 } from "pages/AddListing/utils/uploadToS3";
import { useUpdateUserName } from "api/user/hooks";
import { v4 as uuidv4 } from "uuid";
import { validateName } from "utils/validators";
import { UserApi } from "api/user/user-api";

type UserInput = {
  firstName?: string;
  lastName?: string;
};

export const AuthenticatedUserProfileModal = () => {
  const { user, token } = useAppSelector((state) => state.authSlice);
  const { openedModal } = useAppSelector((state) => state.appSlice);
  const { updateUserName } = useUpdateUserName();
  const dispatch = useAppDispatch();

  const [hasChanged, setHasChanged] = useState(false);
  const [imagePreview, setImagePreview] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();
  const [userInput, setUserInput] = useState<UserInput>();
  const [invalid, setInvalid] = useState({
    firstName: false,
    lastName: false,
  });

  // Update the user input when the user is fetched
  useEffect(() => {
    if (user && !userInput) {
      setUserInput({
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  }, [user]);

  // Handle the image selection
  const handleImageSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const imagePreview = file ? URL.createObjectURL(file) : undefined;

    setImagePreview(imagePreview);
    setImageFile(file);

    // if (imagePreview !== user.imageUrl) {
    setHasChanged(true);
    // }
  };

  // Handle the user input change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setUserInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle the user input validation
  const handleValidation = (field: "firstName" | "lastName", value: string) => {
    const { isValid } = validateName(value);

    if (isValid) {
      if (userInput?.firstName !== user?.firstName || userInput?.lastName !== user?.lastName) {
        setHasChanged(true);
      } else {
        // setHasChanged(imagePreview !== user?.imageUrl);
        setHasChanged(false);
      }
    }

    setInvalid((prev) => ({
      ...prev,
      [field]: !isValid,
    }));
  };

  // Discard the image selection
  const handleCancelSelection = () => {
    setImagePreview(undefined);
    setImageFile(undefined);
    URL.revokeObjectURL(imagePreview!);
  };

  // Handle the changes submission
  const handleSubmit = async () => {
    let filePath;

    // Get the presigned URL from the server if an image was selected
    if (imageFile) {
      // Get the presigned URL from the server
      const {
        data: { data: imageUploadURL },
      } = await UserApi.getAWSProfilePictureUploadUrl({
        token: token!,
      });

      // Upload the images to AWS // TODO: Not sure if the main image is always saved as the first one
      filePath = await uploadToS3(
        [imageFile],
        [
          {
            url: imageUploadURL,
            filename: `${uuidv4()}.jpeg`,
          },
        ]
      );
    }

    // Update the user
    updateUserName(
      {
        firstName: userInput?.firstName || user!.firstName,
        lastName: userInput?.lastName || user!.lastName,
        imageUrl: filePath?.[0] || user?.profilePictureUrl || null,
      },
      {
        onSuccess: () => {
          toast.success("Profilul a fost actualizat cu succes!");
          dispatch(setOpenedModal(null));
        },
        onError: () => {
          toast.error("A apărut o eroare la salvarea modificărilor. Vă rugăm să încercați din nou mai târziu.");
        },
      }
    );
  };

  const disabled = !hasChanged || invalid.firstName || invalid.lastName;

  return (
    <ConfirmationDialog
      title="Editare Profil"
      open={openedModal === "authenticated-user-profile"}
      variant="info"
      labelPrimary="Salvează modificările"
      disabledPrimary={disabled}
      labelSecondary={hasChanged ? "Anulează" : "Înapoi"}
      onCancel={() => dispatch(setOpenedModal(null))}
      onPrimary={() => handleSubmit()}
      onSecondary={() => dispatch(setOpenedModal(null))}
    >
      <Container>
        <div className="user-profile-header">
          <img src={imagePreview || user?.profilePictureUrl || "/placeholder.jpeg"} alt="user" />

          <form>
            {imagePreview && (
              <button className="user-profile-delete-image" type="button" onClick={handleCancelSelection}>
                Șterge
              </button>
            )}
            <label htmlFor="user-image-upload">
              {imagePreview || user?.profilePictureUrl ? "Schimbă imaginea" : "Adaugă o imagine"}
              <input id="user-image-upload" type="file" accept="image/*" onChange={handleImageSelection} />
            </label>
          </form>
        </div>
        <StyledList>
          <li>
            <TextField
              id="lastName"
              name="lastName"
              label="Nume"
              error={invalid.lastName}
              helperText={invalid.lastName && "Nume greșit"}
              fullWidth
              variant="standard"
              value={userInput?.lastName || ""}
              onChange={handleInputChange}
              onBlur={() => handleValidation("lastName", userInput?.lastName || "")}
            />
          </li>
          <li>
            <TextField
              id="firstName"
              name="firstName"
              label="Prenume"
              error={invalid.firstName}
              helperText={invalid.firstName && "Nume greșit"}
              fullWidth
              variant="standard"
              value={userInput?.firstName || ""}
              onChange={handleInputChange}
              onBlur={() => handleValidation("firstName", userInput?.firstName || "")}
            />
          </li>
          <li>
            <TextField
              className="disabled-fields"
              label="Email"
              fullWidth
              variant="standard"
              value={user?.email || ""}
              disabled
            />
          </li>
          <li>
            <TextField
              className="disabled-fields"
              label="Telefon"
              fullWidth
              variant="standard"
              value={user?.phoneNumber || ""}
              disabled
            />
          </li>
          <li>
            <button
              className="user-profile-action-button"
              type="button"
              onClick={() => dispatch(setOpenedModal("change-password"))}
            >
              Schimbă parola
            </button>
            <button
              className="user-profile-action-button"
              type="button"
              onClick={() => dispatch(setOpenedModal("delete-account"))}
            >
              Șterge contul
            </button>
          </li>
        </StyledList>
      </Container>
    </ConfirmationDialog>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
  gap: 32px;

  .user-profile-delete-image {
    margin-top: 12px;
    background-color: transparent;
    border: none;
    color: ${theme.palette.grey[600]};
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  > h1 {
    color: ${theme.palette.grey[800]};
  }

  .user-profile-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & img {
      border-radius: 50%;
      width: 180px;
      height: 180px;
      object-fit: cover;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    }

    label {
      position: relative;
      cursor: pointer;
      color: ${theme.palette.grey[600]};
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }

      input {
        display: none;
      }
    }
  }
`;

const StyledList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .user-profile-action-button {
      margin-top: 12px;
      background-color: transparent;
      border: none;
      color: ${theme.palette.error.main};
      font-size: 12px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .disabled-fields * {
      cursor: not-allowed;
    }
  }
`;
