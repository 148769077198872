import { useQuery } from "react-query";
import { z } from "zod";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { QueriesKeys } from "common/types/general";
import { AdminApi } from "api/admin/admin-api";
import { useAppSelector } from "common/hooks";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { LIMITED_CACHING, logZodError } from "../../../utils";

const reportedListingBEResponseSchema = z.array(
  z.object({
    createdAt: z.string(),
    id: z.number(),
    listing_id: z.string(),
    message: z.string(),
    reason: z.string(),
    updatedAt: z.string(),
    user_id: z.string(),
    user: z.object({
      id: z.string(),
      first_name: z.string(),
      last_name: z.string(),
      email: z.string().email().optional(),
      phone_number: z.string().optional(),
    }),
    listing: z.object({
      id: z.string(),
      title: z.string(),
      description: z.string(),
      price: z.number(),
      deposit: z.number().nullable(),
      construction_year: z.number(),
      floor: z.number().nullable().optional(),
      total_floors: z.number(),
      available_parking_lots: z.number().nullable(),
      available_from: z.string().optional(), // TODO: Should not be optional
      garden_surface: z.number().nullable(),
      number_of_rooms: z.number(),
      number_of_bathrooms: z.number(),
      surface: z.number(),
      has_air_conditioning: z.boolean(),
      has_elevator: z.boolean(),
      has_balcony: z.boolean(),
      has_terrace: z.boolean(),
      has_garden: z.boolean(),
      has_cable_tv: z.boolean(),
      has_clothes_dryer: z.boolean(),
      has_dishwasher: z.boolean(),
      has_dryer: z.boolean(),
      has_fireplace: z.boolean(),
      has_fridge: z.boolean(),
      has_furniture: z.boolean(),
      has_garage: z.boolean(),
      has_heating: z.boolean(),
      has_internet: z.boolean(),
      has_oven: z.boolean(),
      has_pool: z.boolean(),
      has_private_parking: z.boolean(),
      has_security_system: z.boolean(),
      has_storage: z.boolean(),
      has_street_parking: z.boolean(),
      has_tv: z.boolean(),
      has_video_surveillance: z.boolean(),
      has_washer: z.boolean(),
      image_urls: z.array(z.string()),
      images_thumbnail_urls: z.array(z.string()),
      main_image_url: z.string(),
      is_active: z.boolean(),
      is_approved: z.boolean(),
      building_type: z.union([z.literal("block"), z.literal("house"), z.literal("villa")]),
      parking_type: z.string().nullable(),
      partitioning_type: z.union([
        z.literal("detached"),
        z.literal("semidetached"),
        z.literal("ordered"),
        z.literal("studio"),
      ]),
      heating_type: z.union([z.literal("municipal"), z.literal("electric"), z.literal("gaz")]).nullable(),
      country_code: z.string(),
      country_name: z.string(),
      county_code: z.string(),
      county_name: z.string(),
      city_code: z.string(),
      city_name: z.string(),
      street_name: z.string(),
      street_number: z.string(),
      user_id: z.string(),
      createdAt: z.string(),
      updatedAt: z.string(),
      coordinates: z
        .object({
          latitude: z.number(),
          longitude: z.number(),
        })
        .optional(), // TODO: Should not be optional
    }),
  })
);

export type FullReportedListingBEResponseType = z.infer<typeof reportedListingBEResponseSchema>;

export const useGetReportedListings = () => {
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const { token } = useAppSelector(({ authSlice }) => authSlice);
  const { isAdminUser } = useIsAdminUser();

  return useQuery(
    ["adminReportedListings" as QueriesKeys, token],
    async () => {
      addLoadingEl("admin");
      const res = await AdminApi.getReportedListings(token!);
      removeLoadingEl("admin");

      const { data } = res.data;
      const parsed = reportedListingBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return data as FullReportedListingBEResponseType;
      }

      return parsed.data;
    },
    {
      ...LIMITED_CACHING,
      enabled: !!token && isAdminUser,
    }
  );
};
