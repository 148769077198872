import { useMutation } from "react-query";
import { UserApi } from "../user-api";

interface MutationProps {
  email: string;
}

export const useRequestPasswordReset = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    "requestPasswordReset",
    (mutationProps: MutationProps) => {
      const { email } = mutationProps;

      return UserApi.requestPasswordReset({
        email,
      });
    }
  );

  return { mutate, isLoading, isSuccess, isError };
};
