export const ot = {
  ot_slatina: {
    id: "ot_slatina",
    lat: 44.42811352310695,
    lng: 24.370419636659456,
    zoom: 10,
    countyId: "ot",
    name: "Slatina",
    countyName: "Olt",
  },
};
