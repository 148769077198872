import axios from "axios";
import {
  ListingsInCountyParamsType,
  CountiesMetadataBEResponseType,
  AWSUploadURLsType,
  AWSUploadURLsBEResponseType,
} from "./listings-types";
import { DEFAULT_REQ_TIMEOUT } from "../utils";
import { SavedSearchBEResponseType } from "./hooks/helpers/process-saved-search";

const baseUrl =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_SERVER_URL_LOCAL : process.env.REACT_APP_SERVER_URL;

export class ListingsApi {
  public static async getBasicListingsInCounty({ countyId }: ListingsInCountyParamsType) {
    return axios.get(`${baseUrl}/basic-listings`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      params: {
        county_code: countyId,
      },
    });
  }

  public static async getCountiesMetadata({ countyId }: ListingsInCountyParamsType) {
    return axios.get<{ data: CountiesMetadataBEResponseType[] }>(`${baseUrl}/listings-metadata/${countyId}`, {
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async getActiveListing(listingId: string) {
    return axios.get(`${baseUrl}/listing/${listingId}`, {
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async getActiveOwnListing(listingId: string, token?: string) {
    return axios.get(`${baseUrl}/user/listing/${listingId}`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async getAWSUploadUrls({ count, countyId, token, listingId }: AWSUploadURLsType) {
    return axios.get<{ data: AWSUploadURLsBEResponseType[] }>(`${baseUrl}/user/listing/upload-urls`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      params: {
        count,
        county_id: countyId,
        listing_id: listingId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async createListing(listing: any, token: string) {
    return axios.post(
      `${baseUrl}/user/listing`,
      {
        ...listing,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async getFavoritesListingsByIds({ listingIds }: { listingIds: string[] }) {
    return axios.post(
      `${baseUrl}/listing/favorites-by-ids`,
      {
        favorite_ids: listingIds,
      },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async getBasicListingsOfAuthUser(token: string | undefined) {
    return axios.get(`${baseUrl}/user/listings`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async getPhoneNumberOfListingOwner(listingId: string) {
    return axios.get(`${baseUrl}/listing/${listingId}/contact`, {
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async reportListing(listingId: string, reason: string, message: string, token: string) {
    return axios.post(
      `${baseUrl}/user/listing/${listingId}/report`,
      {
        reason,
        message,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async saveSearch(searchObject: SavedSearchBEResponseType, token: string) {
    return axios.post(`${baseUrl?.replace(".io/api", ".io/v1")}/user/listing-search`, searchObject, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async deleteSavedSearch(savedSearchId: string, token: string) {
    return axios.delete(`${baseUrl?.replace(".io/api", ".io/v1")}/user/listing-search/${savedSearchId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async getSavedSearches(token: string) {
    return axios.get(`${baseUrl?.replace(".io/api", ".io/v1")}/user/listing-search`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: DEFAULT_REQ_TIMEOUT,
    });
  }

  public static async toggleSavedSearchNotification(
    savedSearchId: string,
    notificationsStatus: boolean,
    token: string
  ) {
    return axios.put(
      `${baseUrl?.replace(".io/api", ".io/v1")}/user/listing-search/${savedSearchId}`,
      {
        notifications_enabled: notificationsStatus,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }
}
