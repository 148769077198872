export const ms = {
  ms_targu_mures: {
    id: "ms_targu_mures",
    lat: 46.53660345826646,
    lng: 24.555172474650433,
    zoom: 10,
    countyId: "ms",
    name: "Târgu Mureș",
    countyName: "Mureș",
  },
};
