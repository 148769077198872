import { useEffect, useRef, useState } from "react";
import { Box, Fab, Tooltip, Typography, styled } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { toast } from "react-toastify";
import { SidebarViewsToggleButtons } from "components/SidebarViewsToggleButtons";
import { theme, transitions } from "theme";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "common/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { setFiltersOpen } from "redux/slices/filtersSlice";
import { ListingsCount } from "components/ListingsCountChip";
import { BasicListingType } from "api/listings/listings-types";
import { useGetGenericBasicListings } from "api/use-generic-basic-listings";
import { useSaveSearch } from "api/listings/hooks/search-saving/save-search";
import { ListingCard } from "../../components/ListingCard";
import { SortingDropdown } from "./SortingDropdown";

const PAGE_SIZE = 10;

export const ListingsFeed = () => {
  const { view } = useAppParams();
  const { listings, isLoading } = useGetGenericBasicListings();
  const { filters, filtersOpen } = useAppSelector(({ filtersSlice }) => filtersSlice);
  const { token } = useAppSelector(({ authSlice }) => authSlice);

  const { saveSearch } = useSaveSearch();

  const saved = useRef(false);
  useEffect(() => {
    saved.current = false;
  }, [filters]);

  const hasActiveFilters = Object.values(filters).some((filter) => filter.length > 0);

  const { isMobile } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const [isScrolled, setIsScrolled] = useState(false);
  const [infiniteScrollCurrentPage, setInfiniteScrollCurrentPage] = useState(1);

  // Handle infinite scroll
  const listInnerRef = useRef(null);
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 500 >= scrollHeight) {
        setInfiniteScrollCurrentPage((prev) => prev + 1);
      }

      if (!isScrolled && scrollTop > 0) setIsScrolled(true);
      else if (isScrolled && scrollTop === 0) setIsScrolled(false);
    }
  };

  const handleSaveSearch = (_event: any) => {
    if (!hasActiveFilters || saved.current || !token) {
      return;
    }

    saveSearch(undefined, {
      onSuccess: () => {
        toast.success("Căutarea a fost salvată cu succes!");
        saved.current = true;
      },
      onError: () => {
        toast.error("Căutarea nu a putut fi salvată. Te rugăm să încerci din nou.");
      },
    });
  };

  return (
    <StyledListingsFeed className="listings-feed-wrapper">
      <div className="listings-feed-container" ref={listInnerRef} onScroll={onScroll}>
        <div style={{ position: "relative" }}>
          <div className="listings-feed-header">
            <ListingsCount />
            <SortingDropdown />
            {isMobile || (!isMobile && !filtersOpen) ? (
              <StyledFiltersButton type="button" onClick={() => dispatch(setFiltersOpen(true))}>
                <FilterAltIcon fontSize="small" sx={{ color: "black" }} />
              </StyledFiltersButton>
            ) : (
              <Tooltip title={token ? "Salvează căutarea" : "Autentică-te pentru a salva căutarea"} enterTouchDelay={0}>
                <StyledBookmarkButton>
                  <button
                    className={saved.current ? "saved" : ""}
                    type="button"
                    onClick={handleSaveSearch}
                    disabled={!hasActiveFilters || !token || saved.current}
                  >
                    {saved.current ? <BookmarkAddedIcon /> : <BookmarkAddIcon />}
                  </button>
                </StyledBookmarkButton>
              </Tooltip>
            )}
          </div>
          <Box className="listings-feed">
            {listings &&
              Object.values(listings)
                .slice(0, infiniteScrollCurrentPage * PAGE_SIZE)
                .map((listing: BasicListingType, index: number) => (
                  <ListingCard key={listing.id + index} listing={listing} />
                ))}
            {listings && infiniteScrollCurrentPage * PAGE_SIZE > listings.length && (
              <Box className="listings-feed-end">
                <Typography sx={{}}>
                  {isLoading
                    ? "Se încarcă"
                    : listings.length > 0
                    ? "Nu mai sunt anunțuri de afișat pentru zona selectată."
                    : "Nu sunt anunțuri de afișat pentru zona selectată."}
                </Typography>
                <Typography>Extinde zona de căutare de pe hartă sau schimbă filtrele.</Typography>
                {/* <SentimentVeryDissatisfiedIcon sx={{ fontSize: 56, mt: 2, color: theme.palette.grey[200] }} /> */}
              </Box>
            )}
          </Box>
        </div>
      </div>
      {view === "feed" && <SidebarViewsToggleButtons />}
    </StyledListingsFeed>
  );
};

const StyledListingsFeed = styled("div")`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .listings-feed-container {
    position: relative;
    height: auto;
    width: 100%;
    overflow-y: auto;
    margin: 0;
    z-index: 9;

    .listings-feed-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 12px;
      position: sticky;
      gap: 8px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      background-color: ${theme.palette.grey[100]};
      transition: ${transitions.default};
      font-size: 12px;
    }

    .listings-feed {
      position: relative;
      padding: 12px;
      column-count: 1;
      column-gap: 12px;
    }

    .listings-feed-end {
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      align-items: center;
      height: 200px;
      color: ${theme.palette.grey[500]};
      margin-bottom: 42px;
    }
  }
`;

const StyledFiltersButton = styled(Fab)`
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin: 6px -2px 8px 6px; // Make it match the Filters button from the map
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-left: auto;
`;

const StyledBookmarkButton = styled("span")`
  margin: 0;
  padding: 0;
  margin-left: auto;

  > button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    background-color: transparent;
    color: ${theme.palette.secondary.main};
    box-shadow: none;

    & svg {
      :hover {
        filter: brightness(0.8);
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: ${theme.palette.grey[500]};
      pointer-events: none;

      &.saved {
        color: green;
      }
    }
  }
`;
