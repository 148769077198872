export const gr = {
  gr_giurgiu: {
    id: "gr_giurgiu",
    lat: 43.8940515703657,
    lng: 25.96062856319993,
    zoom: 10,
    countyId: "gr",
    name: "Giurgiu",
    countyName: "Giurgiu",
  },
};
