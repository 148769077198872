import { Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { CENTERS } from "cities";

type Cities = "b" | "cj" | "is" | "gj" | "tm" | "bv" | "ct" | "sv" | "mm" | "gl" | "bh";

export const useGenerateRandomInserts = () => {
  const handleGenerateRandomInserts = (city: Cities, user: "emil" | "bogdan") => {
    // Number of queries to generate
    const numQueries = 1000;
    const queries = [];
    for (let i = 0; i < numQueries; i += 1) {
      queries.push(generateRandomListingSQL(city, user));
    }
    console.log(queries.join("\n"));
  };

  return () => (
    <div style={{ marginTop: "-13px" }}>
      <b>Will generate random listings insert statements (printed in console)</b>
      <br />
      <span>biasinovschi+5</span>
      <div style={{ display: "flex", gap: "12px", marginTop: "5px" }}>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("cj", "bogdan")}>
          CJ
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("b", "bogdan")}>
          B
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("is", "bogdan")}>
          IS
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("gj", "bogdan")}>
          GJ
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("tm", "bogdan")}>
          TM
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("bv", "bogdan")}>
          BV
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("ct", "bogdan")}>
          CT
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("sv", "bogdan")}>
          SV
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("mm", "bogdan")}>
          MM
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("gl", "bogdan")}>
          GL
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("bh", "bogdan")}>
          BH
        </Button>
      </div>
      <span>emil.micu24</span>
      <div style={{ display: "flex", gap: "12px", marginTop: "5px" }}>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("cj", "emil")}>
          CJ
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("b", "emil")}>
          B
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("is", "emil")}>
          IS
        </Button>
        <Button size="small" variant="contained" onClick={() => handleGenerateRandomInserts("gj", "emil")}>
          GJ
        </Button>
      </div>
    </div>
  );
};

// Generate a random listing SQL
const generateRandomListingSQL = (city: Cities, user: "emil" | "bogdan"): string => {
  // Generate data for each field
  const id = uuidv4();
  const title = `Anunț nou - Random Title ${getRandomInt(1, 1000)}`;
  const description = "Random generated description";
  const price = getRandomIntMultipleOf50(100, 1500);
  const constructionYear = getRandomInt(1900, 2021);
  const floor = getRandomInt(0, 10);
  const totalFloors = Math.max(floor, getRandomInt(0, 10));
  // const availableParkingLots = null;
  // const gardenSurface = null;
  const numberOfRooms = getRandomInt(1, 10);
  const numberOfBathrooms = getRandomInt(1, 10);
  const surface = getRandomInt(20, 250);
  const imageUrls = getRandomImages();
  const imagesThumbnailUrls = "{}";
  const mainImageUrl = "";
  const isActive = true;
  const hasAirConditioning = getRandomBoolean();
  const hasElevator = getRandomBoolean();
  const hasBalcony = getRandomBoolean();
  const hasTerrace = getRandomBoolean();
  const hasGarden = getRandomBoolean();
  const isApproved = true;
  const buildingType = getRandomArrayElement(["block", "house", "villa"]);
  // const parkingType = null;
  const partitioningType = getRandomArrayElement(["detached", "semidetached", "ordered", "studio"]);
  // const heatingType = null;
  const geometryCoordinates = getRandomCoordinates(city);
  const { countryCode, countryName, countyCode, countyName, cityCode, cityName } = {
    ...cityDetails[city],
    countryCode: "ro",
    countryName: "Romania",
  };
  const userId = user === "emil" ? "3d25ee96-8714-4269-9817-029e08dd1cc5" : "5e04308d-3e1e-411a-a56b-8203a6fc2f14";
  const createdAt = "2021-10-10 10:10:10";
  const updatedAt = "2021-10-10 10:10:10";
  const hasCableTv = getRandomBoolean();
  const hasClothesDryer = getRandomBoolean();
  const hasDishwasher = getRandomBoolean();
  const hasDryer = getRandomBoolean();
  const hasFireplace = getRandomBoolean();
  const hasFridge = getRandomBoolean();
  const hasFurniture = getRandomBoolean();
  const hasGarage = getRandomBoolean();
  const hasHeating = getRandomBoolean();
  const hasInternet = getRandomBoolean();
  const hasOven = getRandomBoolean();
  const hasPool = getRandomBoolean();
  const hasPrivateParking = getRandomBoolean();
  const hasSecuritySystem = getRandomBoolean();
  const hasStorage = getRandomBoolean();
  const hasStreetParking = getRandomBoolean();
  const hasTv = getRandomBoolean();
  const hasVideoSurveillance = getRandomBoolean();
  const hasWasher = getRandomBoolean();
  const streetName = "Random street name";
  const streetNumber = getRandomInt(1, 100);
  const deposit = price;
  const isDeactivated = false;
  const availableFrom = "2021-10-10 10:10:10";

  // Construct the SQL
  return `INSERT INTO listings (id,title,description,price,construction_year,floor,total_floors,number_of_rooms,number_of_bathrooms,surface,image_urls,images_thumbnail_urls,main_image_url,is_active,has_air_conditioning,has_elevator,has_balcony,has_terrace,has_garden,is_approved,building_type,partitioning_type,geometry_coordinates,country_code,country_name,county_code,county_name,city_code,city_name,user_id,created_at,updated_at,has_cable_tv,has_clothes_dryer,has_dishwasher,has_dryer,has_fireplace,has_fridge,has_furniture,has_garage,has_heating,has_internet,has_oven,has_pool,has_private_parking,has_security_system,has_storage,has_street_parking,has_tv,has_video_surveillance,has_washer,street_name,street_number,deposit,is_deactivated,available_from) VALUES ('${id}', '${title}', '${description}', ${price}, ${constructionYear}, ${floor}, ${totalFloors}, ${numberOfRooms}, ${numberOfBathrooms}, ${surface}, '${imageUrls}', '${imagesThumbnailUrls}', '${mainImageUrl}', ${isActive}, ${hasAirConditioning}, ${hasElevator}, ${hasBalcony}, ${hasTerrace}, ${hasGarden}, ${isApproved}, '${buildingType}', '${partitioningType}', ${geometryCoordinates}, '${countryCode}', '${countryName}', '${countyCode}', '${countyName}', '${cityCode}', '${cityName}', '${userId}', '${createdAt}', '${updatedAt}', ${hasCableTv}, ${hasClothesDryer}, ${hasDishwasher}, ${hasDryer}, ${hasFireplace}, ${hasFridge}, ${hasFurniture}, ${hasGarage}, ${hasHeating}, ${hasInternet}, ${hasOven}, ${hasPool}, ${hasPrivateParking}, ${hasSecuritySystem}, ${hasStorage}, ${hasStreetParking}, ${hasTv}, ${hasVideoSurveillance}, ${hasWasher}, '${streetName}', ${streetNumber}, ${deposit}, ${isDeactivated}, '${availableFrom}');`;
};

const images = [
  "https://kastel-production.s3.eu-central-1.amazonaws.com/b/undefined/image-38799b72-ddb3-45d2-9595-c3ce692862f9.jpg",
  "https://kastel-production.s3.eu-central-1.amazonaws.com/b/undefined/image-3cbfef1a-ee78-4123-a88b-9882f1fd4cc7.jpg",
  "https://kastel-production.s3.eu-central-1.amazonaws.com/b/undefined/image-4cf01103-1b09-406f-b89b-c5d16d9b65f9.jpg",
  "https://kastel-production.s3.eu-central-1.amazonaws.com/b/undefined/image-9a31a357-68d6-498e-b626-ff391ff16cb8.jpg",
];

const getRandomImages = () => {
  const result = [];
  for (let i = 0; i < 5; i += 1) {
    result.push(images[getRandomInt(0, images.length - 1)]);
  }
  return `{${result.join(",")}}`;
};

// Mock some random data generators for brevity
const getRandomIntMultipleOf50 = (min: number, max: number) => {
  const randomInt = getRandomInt(min, max);
  return randomInt - (randomInt % 50);
};
const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
const getRandomFloat = (min: number, max: number) => Math.random() * (max - min) + min;
const getRandomBoolean = () => Math.random() < 0.5;
const getRandomArrayElement = (arr: string[]) => arr[getRandomInt(0, arr.length - 1)];

const getRandomCoordinates = (city: Cities) => {
  let { lat, lng } = CENTERS.ro_romania;

  if (city === "b") {
    lat = getRandomFloat(44.356310772609355, 44.5259730841814);
    lng = getRandomFloat(26.237198515312958, 25.954891135781622);
  }

  if (city === "cj") {
    lat = getRandomFloat(46.7467725122978, 46.803864056789436);
    lng = getRandomFloat(23.51892572839571, 23.652830418051487);
  }

  if (city === "is") {
    lat = getRandomFloat(47.09097956234772, 47.21421609265884);
    lng = getRandomFloat(27.48081148233841, 27.67865142310748);
  }

  if (city === "gj") {
    lat = getRandomFloat(45.02288947758804, 45.04801462268793);
    lng = getRandomFloat(23.254927205524954, 23.29048153087834);
  }

  if (city === "tm") {
    lat = getRandomFloat(45.7274119338665, 45.76805911679158);
    lng = getRandomFloat(21.1973922093942, 21.268114854218258);
  }

  if (city === "bv") {
    lat = getRandomFloat(45.64045741557636, 45.66874872722751);
    lng = getRandomFloat(25.562543232383675, 25.637483331384185);
  }

  if (city === "ct") {
    lat = getRandomFloat(44.14389600329377, 44.18384633469984);
    lng = getRandomFloat(28.59539831330466, 28.634334173875708);
  }

  if (city === "sv") {
    lat = getRandomFloat(47.64425684194056, 47.68010068516181);
    lng = getRandomFloat(26.240972570208918, 26.26557144571407);
  }

  if (city === "mm") {
    lat = getRandomFloat(47.63896636780626, 47.66411782077201);
    lng = getRandomFloat(23.52487402079517, 23.60058466791068);
  }

  if (city === "gl") {
    lat = getRandomFloat(45.42259261032421, 45.4475273624372);
    lng = getRandomFloat(28.008698765839952, 28.039082826678);
  }

  if (city === "bh") {
    lat = getRandomFloat(47.03058923130316, 47.07877382350665);
    lng = getRandomFloat(21.880450097406438, 21.96353419596923);
  }

  return `ST_GeomFromText('POINT(${lng} ${lat})')`;
};

const cityDetails = {
  b: {
    countyCode: "b",
    countyName: "București",
    cityCode: "b",
    cityName: "București",
  },
  cj: {
    countyCode: "cj",
    countyName: "Cluj",
    cityCode: "cj",
    cityName: "Cluj-Napoca",
  },
  is: {
    countyCode: "is",
    countyName: "Iași",
    cityCode: "is",
    cityName: "Iași",
  },
  gj: {
    countyCode: "gj",
    countyName: "Gorj",
    cityCode: "gj",
    cityName: "Târgu Jiu",
  },
  tm: {
    countyCode: "tm",
    countyName: "Timiș",
    cityCode: "tm",
    cityName: "Timișoara",
  },
  bv: {
    countyCode: "bv",
    countyName: "Brașov",
    cityCode: "bv",
    cityName: "Brașov",
  },
  ct: {
    countyCode: "ct",
    countyName: "Constanța",
    cityCode: "ct",
    cityName: "Constanța",
  },
  sv: {
    countyCode: "sv",
    countyName: "Suceava",
    cityCode: "sv",
    cityName: "Suceava",
  },
  mm: {
    countyCode: "mm",
    countyName: "Maramureș",
    cityCode: "mm",
    cityName: "Baia Mare",
  },
  gl: {
    countyCode: "gl",
    countyName: "Galați",
    cityCode: "gl",
    cityName: "Galați",
  },
  bh: {
    countyCode: "bh",
    countyName: "Bihor",
    cityCode: "bh",
    cityName: "Oradea",
  },
};
