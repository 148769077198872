import { styled } from "@mui/material";
import { useAppSelector } from "common/hooks";

export const LoadingBar = () => {
  const { loadingElements } = useAppSelector(({ appSlice }) => appSlice);

  return (
    <StyledLoadingBar
      className="animated-gradient loading-horizontal-bar"
      style={{
        display: loadingElements.length > 0 ? "block" : "none",
      }}
    />
  );
};

const StyledLoadingBar = styled("div")`
  background: repeating-linear-gradient(to right, #e9d8c8 0%, #e88117 33%, #e9d8c8 66%, #e88117 100%);
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 10s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
`;
