import { CENTERS } from "cities";

type City = { lat: number; lng: number; zoom: number; countyId: string; name: string; countyName: string };

const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371; // Earth's radius in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};

export const findClosestCity = (lat: number, lng: number, _cities?: Record<string, City>): City => {
  const cities = _cities || CENTERS;

  let closestCity = cities.b_bucharest;
  let minDistance = Infinity;

  Object.values(cities).forEach((city) => {
    const distance = getDistance(lat, lng, city.lat, city.lng);
    if (distance < minDistance) {
      minDistance = distance;
      closestCity = city;
    }
  });

  return closestCity;
};
