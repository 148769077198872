export const vn = {
  vn_focsani: {
    id: "vn_focsani",
    lat: 45.69481473796399,
    lng: 27.184914950810086,
    zoom: 10,
    countyId: "vn",
    name: "Focșani",
    countyName: "Vrancea",
  },
};
