import { useMutation } from "react-query";
import { useAppSelector } from "common/hooks";
import { ListingsApi } from "../../listings-api";

type MutationProps = {
  searchId: string;
};

export const useDeleteSearch = () => {
  const { token = "" } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation("deleteSavedSearch", (props: MutationProps) => {
    const { searchId } = props;

    return ListingsApi.deleteSavedSearch(searchId, token!);
  });

  return { deleteSearch: mutate, isLoading, isSuccess, isError };
};
