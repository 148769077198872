import { useState } from "react";
import { useAppDispatch, useBasePathUrl } from "common/hooks";
import { updateActiveMenuView } from "redux/slices/appSlice";
import { useQueryClient } from "react-query";
import { QueriesKeys } from "common/types/general";
import { stepperFields, stepperFieldsKeys } from "./addListingData";

const STARTING_STEP = 0; // We used to have General as the first step, but now we have BuildingType

export const useAddListingStepper = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(localStorage?.getItem("currentPage") !== null ? STARTING_STEP : 0);
  const [activeStep, setActiveStep] = useState(
    localStorage?.getItem("activeStep") !== null ? Number.parseInt(localStorage.getItem("activeStep")!, 10) : 0
  );
  const queryClient = useQueryClient();

  const { goToBasePath } = useBasePathUrl();

  const handleSubmitted = () => {
    handleStepperReset();
    queryClient.refetchQueries("userDetails" as QueriesKeys);
  };

  const handleClose = () => {
    dispatch(updateActiveMenuView("none"));
    goToBasePath({});
  };

  const handleStepperReset = () => {
    setActiveStep(0);
    setCurrentPage(0);
    localStorage.removeItem("listingObj");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("activeStep");
    handleClose();
  };

  // Handle click on label text
  const handleStepperClick = (index: number) => {
    if (index <= activeStep) {
      setCurrentPage(index);
    }
  };

  // Handle prev button click
  const handleStepperPrev = () => {
    const newCurrentPage = Math.max(0, currentPage - 1);
    setCurrentPage(newCurrentPage);
    localStorage.setItem("currentPage", newCurrentPage.toString());
  };

  // Handle next button click
  const handleStepperNext = () => {
    const newCurrentPage = Math.min(stepperFieldsKeys.length, currentPage + 1);
    const newActiveStep = Math.max(newCurrentPage, activeStep);
    setCurrentPage(newCurrentPage);
    setActiveStep(newActiveStep);
    localStorage.setItem("currentPage", newCurrentPage.toString());
    localStorage.setItem("activeStep", newActiveStep.toString());
  };

  return {
    activePageLabel: Object.values(stepperFields)[currentPage].label,
    activeStep,
    currentPage,
    onWindowClose: handleClose,
    onSubmitted: handleSubmitted,
    onStepperClick: handleStepperClick,
    onStepperPrev: handleStepperPrev,
    onStepperNext: handleStepperNext,
    onStepperReset: handleStepperReset,
  };
};
