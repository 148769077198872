/* eslint-disable camelcase */
import { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import { Fab, styled } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { ClapSpinner } from "react-spinners-kit";
import { BoundsType, CoordsType } from "common/types/general";
import { useAppDispatch, useBasePathUrl, useAppSelector, useWindowDimensions } from "common/hooks";
import {
  setSearchBoxOptionId,
  setSidebarOpen,
  updateBounds,
  updateMapTypeId,
  updatePixelPerLatPointRatioMobile,
} from "redux/slices/appSlice";
import { boxShadows, theme } from "theme";
import { useGetCountiesMetadata, useGetActiveListing } from "api/listings/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { setFiltersOpen } from "redux/slices/filtersSlice";
import { CENTERS } from "cities";
import { ListingsCount } from "components/ListingsCountChip";
import { useGetGenericBasicListings } from "api/use-generic-basic-listings";
import { findClosestCity } from "./utils/getClosestCity";
import { SidebarViewsToggleButtons } from "../SidebarViewsToggleButtons";
import { MarkerBasicListings } from "./Markers/MarkerBasicListings";
import { FloatingButtonPointsOfInterest, Pois } from "./FloatingButtonPointsOfInterest";
import { createClusterPoints, darkStyles, mapPointsOfInterestVisibility } from "./mapUtils";
import { MarkerClusterAndMetadata } from "./Markers/MarkerClusterAndMetadata";

type GoogleMapProps = {
  heightWithoutNavbarAndFooter: string;
};

const MAX_ZOOM = 22;

// const LARGE_ZOOM = 15;
// const CLUSTER_FRQ_ON_LARGE_ZOOM = 30;
// const CLUSTER_FRQ_ON_SMALL_ZOOM_AND_POIS_NOT_VISIBLE = 120;
// const CLUSTER_FRQ_ON_SMALL_ZOOM_AND_POIS_VISIBLE = 200;

interface GoogleMapRefs {
  map: google.maps.Map | null;
  maps: typeof google.maps | null;
}

// console.time("Listings arrived");

export const GoogleMap = (props: GoogleMapProps) => {
  const { heightWithoutNavbarAndFooter } = props;

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<GoogleMapRefs>({ map: null, maps: null });

  const [visiblePois, setVisiblePois] = useState<Pois[]>([]);
  const [hoveredMarkerId, setMarkerHoveredId] = useState<string>();

  const dispatch = useAppDispatch();
  const appSlice = useAppSelector(({ appSlice }) => appSlice);
  const filtersSlice = useAppSelector(({ filtersSlice }) => filtersSlice);
  const listingsSlice = useAppSelector(({ listingsSlice }) => listingsSlice);
  const { filtersOpen } = filtersSlice;
  const { activeMenuView, sidebarOpen } = appSlice;
  const { mapTypeId, clustersBounds, mapColor } = appSlice;
  const { seenListings } = listingsSlice;

  const isFavoritesView = activeMenuView === "favorites";
  const isOwnListingsView = activeMenuView === "own-listings";
  const isFavoritesOrOwnListingsView = isFavoritesView || isOwnListingsView;

  const { isMobile } = useWindowDimensions();
  const { updateBasePath } = useBasePathUrl();
  const { lat: paramsLat, lng: paramsLng, zoom: paramsZoom, listingId, countyId, view } = useAppParams();

  const isCountryViewMode = countyId === "ro";

  const { activeListing } = useGetActiveListing();
  const { data: listingsMetadata } = useGetCountiesMetadata();
  const { listings } = useGetGenericBasicListings();

  // Flashlight and poi buttons are displayed too early so we delay them a bit
  const [displayIconsWithAShortDelay, setDisplayIconsWithAShortDelay] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDisplayIconsWithAShortDelay(true);
    }, 1000);
  }, []);

  // Default map values
  const lat = parseFloat(paramsLat!);
  const lng = parseFloat(paramsLng!);
  const zoom = parseInt(paramsZoom!, 10);

  const getClusterSeenStatus = (clusterId: any) => {
    const leaves = supercluster.getLeaves(clusterId);
    return leaves.every((leaf: any) => leaf.properties.seen);
  };

  // Create clusters. Adjust frequency from options
  const { clusters, supercluster } = useSupercluster({
    points: createClusterPoints(listings, seenListings),
    bounds: clustersBounds || undefined,
    zoom,
    options: {
      // If map is zoomed out or pois are selected, show clusters with more points
      maxZoom: MAX_ZOOM,
      radius: 200,
      // zoom >= LARGE_ZOOM
      //   ? CLUSTER_FRQ_ON_LARGE_ZOOM
      //   : visiblePois.length === 0
      //   ? CLUSTER_FRQ_ON_SMALL_ZOOM_AND_POIS_VISIBLE
      //   : CLUSTER_FRQ_ON_SMALL_ZOOM_AND_POIS_VISIBLE,
    },
  });

  useEffect(() => {
    if (listings && listings.length > 0) {
      if (clusters.length === 0) {
        // console.time("Clusters created");
        if (process.env.NODE_ENV === "development") {
          // console.timeEnd("Listings arrived");
        }
        return;
      }

      if (process.env.NODE_ENV === "development") {
        // console.timeEnd("Clusters created");
      }
    }
  }, [listings, clusters]);

  const handleGoogleMapApiLoaded = (mapDetails: { map: any; maps: any }) => {
    const { map, maps } = mapDetails;
    mapRef.current = { map, maps };

    map.mapTypes.roadmap.name = "Hartă";
    map.mapTypes.terrain.name = "Arată teren";
    map.mapTypes.satellite.name = "Satelit";

    // Update bounds in listings hook and fetch new data
    const { east, north, south, west } = map.getBounds().toJSON();

    dispatch(
      updateBounds({
        nw: { lat: north, lng: west },
        se: { lat: south, lng: east },
        ne: { lat: north, lng: east },
        sw: { lat: south, lng: west },
      })
    );
  };

  const handleMapClick = (/* ...props: any */) => {};

  // const toggleMapColor = () => {
  //   dispatch(updateMapColor(mapColor === "light" ? "dark" : "light"));
  // };

  // Smooth zoom function. Not used for now
  const animateMapZoomTo = useCallback((map: google.maps.Map, targetZoom: number, commandedZoom: number) => {
    const currentZoom = commandedZoom;

    if (currentZoom !== targetZoom) {
      google.maps.event.addListenerOnce(map, "zoom_changed", () => {
        animateMapZoomTo(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1));
      });
      setTimeout(() => {
        map.setZoom(currentZoom + (targetZoom > currentZoom ? 1 : -1));
      }, 1250);
    }
  }, []);

  const handleMapChange = (mapChangeProps: { bounds: BoundsType; center: CoordsType; zoom: number }) => {
    const { bounds, center, zoom } = mapChangeProps;

    const closestCity = findClosestCity(center.lat, center.lng);
    const shouldBeInCountryViewMode = zoom < 10;

    // Get the map element's dimensions and calculate the pixel per lat point ratio
    const mapHeight = mapContainerRef.current!.offsetHeight;
    const latPPR = (bounds.nw.lat - bounds.se.lat) / mapHeight;

    mapRef.current.map?.panTo(center);
    // if (!listingId) {
    dispatch(updateBounds(bounds));
    dispatch(updatePixelPerLatPointRatioMobile(latPPR));
    // }

    updateBasePath({
      lat: center.lat,
      lng: center.lng,
      zoom,
      countyId: shouldBeInCountryViewMode ? "ro" : closestCity.countyId,
    });

    const closestCityId = Object.values(CENTERS).find(
      ({ countyId: optionsCountyId }) => optionsCountyId === closestCity.countyId
    )?.id;

    if (closestCityId) {
      // Regardless of where we are, we want the search box to be in sync with the map
      dispatch(setSearchBoxOptionId(shouldBeInCountryViewMode ? CENTERS.ro_romania.id : closestCityId));
    }
  };

  const isLoadingClusters = (!listings || (listings.length > 0 && clusters.length === 0)) && !isCountryViewMode;

  return (
    <>
      {isLoadingClusters && <LoadingMapOverlay />}

      <StyledGoogleMap
        ref={mapContainerRef}
        style={{
          height: heightWithoutNavbarAndFooter,
        }}
      >
        <>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
              language: "ro",
              region: "ro",
              libraries: ["places"],
            }}
            center={{ lat, lng }}
            zoom={zoom}
            onGoogleApiLoaded={handleGoogleMapApiLoaded}
            onClick={handleMapClick}
            onMapTypeIdChange={(newMapTypeId) => {
              if (newMapTypeId && newMapTypeId !== mapTypeId) {
                dispatch(updateMapTypeId({ mapTypeId: newMapTypeId }));
              }
            }}
            options={(options) => {
              return {
                disableDoubleClickZoom: false, // TODO: Keep state global (?) | globalState.triggers.mapDoubleClickZoom,
                fullscreenControl: false,
                id: "google-map",
                mapTypeControl: true,
                mapTypeId,
                gestureHandling: "greedy",
                // gestureHandling: hoveredMarkerId ? "none" : "greedy",
                clickableIcons: visiblePois.length > 0,
                zoomControl: true,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                language: "ro",
                styles: [...(mapColor === "dark" ? darkStyles : []), ...mapPointsOfInterestVisibility(visiblePois)],
                mapTypeControlOptions: {
                  mapTypeIds: ["roadmap", "satellite", "terrain", "hybrid", "styled_map"],
                  position: options.ControlPosition.LEFT_TOP,
                  style: options.MapTypeControlStyle.HORIZONTAL_BAR,
                },
                zoomControlOptions: {
                  position: options.ControlPosition.LEFT_BOTTOM,
                  style: options.ZoomControlStyle.DEFAULT,
                },
              };
            }}
            onChange={handleMapChange}
            yesIWantToUseGoogleMapApiInternals
          >
            {/* Listings metadata on Country view */}
            {!listingId &&
              isCountryViewMode &&
              !isFavoritesOrOwnListingsView &&
              listingsMetadata &&
              // !isFavoritesView &&
              listingsMetadata
                ?.filter((listingMetadata) => listingMetadata.count > 0)
                .map((listingMetadata, index) => (
                  <MarkerClusterAndMetadata
                    isMetadata
                    listing={listingMetadata}
                    key={index}
                    lat={listingMetadata.lat}
                    lng={listingMetadata.lng}
                  />
                ))}
            {/* Listings on county view */}
            {(isFavoritesOrOwnListingsView || !isCountryViewMode) &&
              listings &&
              clusters.map((clusterObj) => {
                const [longitude, latitude] = clusterObj.geometry.coordinates;
                const { cluster: isCluster, point_count, listing, cluster_id } = clusterObj.properties;

                const clusterSeen = isCluster ? getClusterSeenStatus(cluster_id) : false;

                if (isCluster || !listing) {
                  return (
                    <MarkerClusterAndMetadata
                      isCluster
                      isClusterSeen={clusterSeen}
                      onClusterClick={() => {
                        const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterObj.id), MAX_ZOOM);
                        mapRef.current.map?.setZoom(expansionZoom);
                        mapRef.current.map?.panTo({ lat: latitude, lng: longitude });
                      }}
                      key={clusterObj.properties.cluster_id}
                      listing={{
                        id: clusterObj.properties.cluster_id,
                        count: point_count,
                        lat: latitude,
                        lng: longitude,
                      }}
                      lat={latitude}
                      lng={longitude}
                    />
                  );
                }

                return (
                  <MarkerBasicListings
                    key={listing.id}
                    basicListing={listing}
                    lat={latitude}
                    lng={longitude}
                    hoveredMarkerId={hoveredMarkerId}
                    onSetMarkerHoverId={(newId) => setMarkerHoveredId(newId)}
                  />
                );
              })}
            {/* Active listing */}
            {listingId && activeListing && (
              <MarkerBasicListings
                isActiveListing
                key="active-listing"
                basicListing={{
                  ...activeListing,
                  lat: Number(activeListing.lat),
                  lng: Number(activeListing.lng),
                }}
                lat={Number(activeListing.lat)}
                lng={Number(activeListing.lng)}
                hoveredMarkerId={hoveredMarkerId}
                onSetMarkerHoverId={(newId) => setMarkerHoveredId(newId)}
              />
            )}
          </GoogleMapReact>
          {/* Toggle button. Shown only on mobile  */}
          {view === "map" && !isCountryViewMode && <SidebarViewsToggleButtons />}
          {/* {process.env.NODE_ENV === "development" && <AddDummyListing />} */}

          {/* {mapRef.current.map && displayIconsWithAShortDelay && (
        <Tooltip
          title={mapColor === "light" ? "Mod de vizualizare întunecat" : "Mod de vizualizare luminos"}
          placement="bottom"
          arrow
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: "white",
                padding: "10px",
                fontSize: "12px",
                fontWeight: 300,
                boxShadow: "0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28)",
                color: "grey",
                width: "120px",
                textAlign: "center",
              },
              "& .MuiTooltip-arrow": {
                color: "white",
              },
            },
          }}
        >
          <StyledFiltersFab
            tabIndex={0}
            size="small"
            onClick={toggleMapColor}
            sx={{
              zIndex: 2,
            }}
          >
            {mapColor === "light" ? <FlashlightOffIcon /> : <FlashlightOnIcon />}
          </StyledFiltersFab>
        </Tooltip>
      )} */}

          <ListingsCount className="map-count" fromMap />

          {!isMobile && (!isCountryViewMode || isFavoritesOrOwnListingsView) && (
            <StyledHideOrCloseSidebarButton
              className={clsx(!sidebarOpen && "reversed")}
              onClick={() => {
                if (listingId) {
                  updateBasePath({ view: "map", goBasePath: true });
                  return;
                }
                dispatch(setSidebarOpen(!sidebarOpen));
              }}
            >
              {listingId ? (
                <CloseIcon fontSize="small" />
              ) : sidebarOpen ? (
                <ArrowForwardIosIcon fontSize="small" />
              ) : (
                <div className="show-sidebar-button">
                  <LocalLibraryIcon fontSize="small" />
                  <span>
                    Lista
                    <br />
                    anunțuri
                  </span>
                </div>
              )}
            </StyledHideOrCloseSidebarButton>
          )}

          {isMobile && !filtersOpen && !listingId && view === "map" && !isCountryViewMode && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <StyledFiltersFab tabIndex={0} size="small" onClick={() => dispatch(setFiltersOpen(true))}>
                <FilterAltIcon fontSize="small" />
              </StyledFiltersFab>
            </div>
          )}
          {mapRef.current.map && displayIconsWithAShortDelay && localStorage.getItem("show-poi") === "true" && (
            <FloatingButtonPointsOfInterest visiblePois={visiblePois} setVisiblePois={setVisiblePois} />
          )}
          {/* {mapRef.current.map && displayIconsWithAShortDelay && !isCountryViewMode && !listingId && (
        <StyledChip
          onClick={() => dispatch(toggleShowSeenListings())}
          label="Anunțuri văzute"
          icon={<Checkbox color="default" checked={showSeenListings} />}
          mobile={String(isMobile)}
        />
      )} */}
        </>
      </StyledGoogleMap>
    </>
  );
};

const LoadingMapOverlay = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999999,
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.4)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      fontWeight: 500,
    }}
  >
    <ClapSpinner size={60} color="#686769" loading />
    <span style={{ color: "white" }}>Se încarcă anunțurile...</span>
  </div>
);

const StyledGoogleMap = styled("div")(() => ({
  minHeight: "-webkit-fill-available",
  width: "100%",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  overflow: "hidden",
}));

const StyledFiltersFab = styled(Fab)`
  transform: rotate(0deg);
  background-color: #fff;
  z-index: 2;
  box-shadow: ${() => boxShadows.mapButtons};
`;

const StyledHideOrCloseSidebarButton = styled("button")`
  background-color: white;
  padding: 6px 10px;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 34px;
  right: 0px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${() => boxShadows.filters};
  cursor: pointer;

  border: 1px solid rgba(0, 0, 0, 0.12);

  & .show-sidebar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 4px;

    font-size: 16px;
    font-weight: 500;
    color: ${theme.palette.grey[700]};
  }

  &.reversed {
    // transform: rotate(180deg);
    // border-radius: 0 4px 4px 0;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;
