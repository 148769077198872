import { SelectChangeEvent } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { useReportListing } from "api/admin/hooks/report-listing";

export const ReportListingReasons = {
  NOT_AVAILABLE: "Anunțul nu mai este valabil",
  AGENCY: "Anunțul este postat de o agenție imobiliară",
  INAPPROPRIATE: "Anunțul conține elemente inadecvate",
  FAKE_ACCOUNT: "Proprietarul nu este real",
  FAKE: "Anunțul este fals",
  OTHER: "Altul",
};

export const useReportListingFromApp = (props: { onSuccess: () => void; onError: () => void }) => {
  const { reportListing, isReportListingLoading } = useReportListing();
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const queryClient = useQueryClient();

  const [reportedListingId, setReportedListingId] = useState<string>();
  const [reportReason, setReportReason] = useState<keyof typeof ReportListingReasons>();
  const [message, setMessage] = useState<string>("");

  const handleMessageChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setMessage(target.value);
  };

  const handleReportListing = () => {
    if (!reportReason || !reportedListingId) return;

    addLoadingEl("admin");
    queryClient.refetchQueries("bannedUsers");

    reportListing(
      { listingId: reportedListingId, reason: reportReason, message },
      {
        onSuccess: () => {
          toast.success("Anunț raportat cu succes.");
          removeLoadingEl("admin");
          props.onSuccess();
        },
        onError: () => {
          toast.error("Nu am putut raporta anunțul. Încearcă din nou mai târziu.");
          removeLoadingEl("admin");
          props.onError();
        },
      }
    );
  };

  const handleReasonSelection = (event: SelectChangeEvent<keyof typeof ReportListingReasons>) => {
    const target = event.target as HTMLInputElement;
    const key = target.value as keyof typeof ReportListingReasons;

    setReportReason(key);
    setMessage(key === "OTHER" ? "" : ReportListingReasons[key]);
  };

  return {
    onUpdateReportedListingId: setReportedListingId,
    onReportListingReasonSelection: handleReasonSelection,
    onReportListingMessageChange: handleMessageChange,
    onReportListing: handleReportListing,
    isReportListingLoading,
    reportedListingReason: reportReason,
    reportedListingMessage: message,
  };
};
