export const bc = {
  bc_bacau: {
    id: "bc_bacau",
    lat: 46.56380110779703,
    lng: 26.920017193665426,
    zoom: 10,
    countyId: "bc",
    name: "Bacău",
    countyName: "Bacău",
  },
};
