export const sb = {
  sb_sibiu: {
    id: "sb_sibiu",
    lat: 45.79207314276114,
    lng: 24.141896539559422,
    zoom: 10,
    countyId: "sb",
    name: "Sibiu",
    countyName: "Sibiu",
  },
};
