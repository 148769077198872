export const sv = {
  sv_suceava: {
    id: "sv_suceava",
    lat: 47.66407739293809,
    lng: 26.262462967802474,
    zoom: 10,
    countyId: "sv",
    name: "Suceava",
    countyName: "Suceava",
  },
};
