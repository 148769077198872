import { IconButton, Skeleton, Tooltip, styled } from "@mui/material";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { useRef } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import clsx from "clsx";
import { theme } from "theme";
import { getAvailableFrom, getGenericLabel } from "utils/labels";
import { FullListingType } from "api/listings/listings-types";
import { compareDateWithToday } from "utils/helpers";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { setOpenedModal } from "redux/slices/appSlice";
import { useAppDispatch } from "common/hooks";

type Props = {
  activeListing: FullListingType | undefined;
};

export const AddressAndCreateDate = (props: Props) => {
  const { activeListing } = props;
  const { lat, lng, addressFull = "" } = activeListing || {};

  const { isAdminUser } = useIsAdminUser();
  const dispatch = useAppDispatch();

  const handleBanUser = () => {
    if (!activeListing?.ownerId) {
      toast.error("Nu am putut identifica userul.");
      return;
    }

    dispatch(setOpenedModal("ban-user"));
  };

  const handleReportListing = () => {
    dispatch(setOpenedModal("report-listing"));
  };

  const handleCopyToClipboard = async () => {
    if (navigator?.clipboard) {
      try {
        await navigator?.clipboard?.writeText(addressFull);
        toast.info("Adresa a fost copiată în clipboard.");
      } catch (error: any) {
        toast.error(`Adresa nu a putut fi copiată în clipboard.${error?.message}`);
        console.log("Err", error);
      }
    } else if (textRef.current) {
      textRef.current.select();
      document.execCommand("copy");
      toast.info("Adresa a fost copiată în clipboard.");
    }
  };

  const textRef = useRef<HTMLTextAreaElement>(null);

  return activeListing ? (
    <StyledMainDetailsSection>
      <div className="title-section">
        {/* <LocationOnIcon /> */}
        <StyledAliveIconWrapper>
          <div
            className={clsx("circle", { inactive: compareDateWithToday(activeListing?.availableFrom) === "bigger" })}
          />
        </StyledAliveIconWrapper>
        {createListingDetailsTitle(activeListing)}
      </div>
      <div className="address-section">
        <Tooltip title="Vezi pe Google Street View">
          <Link className="address-link" to={`https://www.google.com/maps?layer=c&cbll=${lat},${lng}`} target="_blank">
            {`${addressFull} `}
            {/* <TextTruncate line={1} element="span" truncateText="…" text={`${addressFull} (Google Street View)`} /> */}
          </Link>
        </Tooltip>
        <Tooltip title="Copiază adresa în clipboard">
          <IconButton onClick={handleCopyToClipboard}>
            <ContentCopyIcon className="copy-button" />
          </IconButton>
        </Tooltip>
        <textarea ref={textRef} tabIndex={-1} aria-hidden className="sr-only" value={addressFull} readOnly />
      </div>
      {isAdminUser && (
        <Tooltip title="Blochează userul">
          <StyledReportIconButton onClick={handleBanUser}>
            <BlockIcon color="error" />
            <span>Banează userul</span>
          </StyledReportIconButton>
        </Tooltip>
      )}
      {!isAdminUser && (
        <Tooltip title="Raportează acest anunț">
          <StyledReportIconButton onClick={handleReportListing}>
            <FlagIcon color="warning" />
            <span>Raportează anunțul</span>
          </StyledReportIconButton>
        </Tooltip>
      )}
    </StyledMainDetailsSection>
  ) : (
    <div className="dates">
      <Skeleton width="100px" />
      <Skeleton width="100px" />
    </div>
  );
};

export const createListingDetailsTitle = (
  activeListing: Pick<FullListingType, "rooms" | "availableFrom" | "buildingType"> | undefined
) => {
  if (!activeListing) return <Skeleton width="100%" height="20px" />;

  const title = `${activeListing.buildingType === "block" ? "Apartament" : "Casă"} ${getGenericLabel(
    activeListing.rooms === "1" ? "room" : "rooms",
    activeListing.rooms
  )}, ${getAvailableFrom(activeListing.availableFrom, activeListing.buildingType)}`;

  return title;
  // return isMobile ? title.split(",")[0] : title;
};

const StyledReportIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  span {
    font-size: 10px;
    cursor: pointer;
  }
`;

const StyledMainDetailsSection = styled("div")`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  font-weight: 400;
  font-size: 10px;
  color: ${theme.palette.grey[600]};
  padding: 0;
  border-bottom: 1px solid #e0e0e0;

  .title-section {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 24px;
    color: ${theme.palette.grey[800]};
    margin: 12px 0 0 -12px;

    svg {
      width: 34px;
      height: 34px;
      color: ${theme.palette.secondary.main};
    }
  }

  .address-section {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 12px;
    color: ${theme.palette.grey[600]};
    margin: 0 0 0 8px;
    box-sizing: border-box;
    padding-right: 60px;

    .copy-button {
      font-size: 15px;
      padding: 0;
      margin: 0;
    }

    .address-link {
      color: ${theme.palette.primary.main};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ${theme.breakpoints.down("sm")} {
    .title-section {
      font-size: 18px;
      margin: 10px 0 0 -12px;
      box-sizing: border-box;
      padding-right: 40px;
      font-weight: 600;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .address-section {
      font-size: 12px;
      margin: 0 0 2px 8px;
      text-decoration: underline;
    }
  }
`;

const StyledAliveIconWrapper = styled("div")`
  position: relative;
  width: 34px;

  .circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 16px;
    height: 16px;

    &:before {
      content: "";
      position: relative;
      display: block;
      width: 250%;
      height: 250%;
      box-sizing: border-box;
      margin-left: -75%;
      margin-top: -75%;
      border-radius: 45px;
      background-color: #75daad;
      animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #75daad;
      border-radius: 50px;
      animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }

    // Gray and static when inactive
    &.inactive {
      width: 8px;
      height: 8px;

      &:before {
        background-color: ${theme.palette.grey[300]};
        animation: none;
      }

      &:after {
        background-color: ${theme.palette.grey[300]};
        animation: none;
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes circle {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
