import { useMutation } from "react-query";
import { useAppSelector } from "common/hooks";
import { ListingsApi } from "api/listings/listings-api";

type MutationProps = {
  listingId: string;
  reason: string;
  message: string;
};

export const useReportListing = () => {
  const { token } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    "reportListing",
    ({ listingId, reason, message }: MutationProps) => {
      if (!token) {
        return Promise.reject();
      }

      return ListingsApi.reportListing(listingId, reason, message, token);
    }
  );

  return { reportListing: mutate, isReportListingLoading: isLoading, isSuccess, isError };
};
