export const gj = {
  gj_targu_jiu: {
    id: "gj_targu_jiu",
    lat: 45.03594334153332,
    lng: 23.27179198663702,
    zoom: 10,
    countyId: "gj",
    name: "Târgu Jiu",
    countyName: "Gorj",
  },
};
