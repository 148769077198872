import { useMutation } from "react-query";
import { UserApi } from "../user-api";

interface MutationProps {
  message: string;
  email: string;
  name: string;
}

export const useContactUs = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation("contactUs", (mutationProps: MutationProps) => {
    const { name, email, message } = mutationProps;

    return UserApi.contactUs({
      name,
      email,
      message,
    });
  });

  return { mutate, isLoading, isSuccess, isError };
};
