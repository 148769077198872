import { useMutation } from "react-query";
import { UserApi } from "api/user/user-api";

interface MutationProps {
  token: string;
  phoneNumber: string;
}

export const useSendPhoneNumberVerificationCode = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    "sendPhoneNumberVerificationCode",
    (mutationProps: MutationProps) => {
      const { token, phoneNumber } = mutationProps;

      return UserApi.sendPhoneNumberVerificationCode(token, phoneNumber);
    }
  );

  return { sendPhoneNumberVerificationCode: mutate, isLoading, isSuccess, isError };
};
