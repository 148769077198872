export const hd = {
  hd_deva: {
    id: "hd_deva",
    lat: 45.872726282103756,
    lng: 22.908185470225266,
    zoom: 10,
    countyId: "hd",
    name: "Deva",
    countyName: "Hunedoara",
  },
};
