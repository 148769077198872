import { z } from "zod";
import { SavedSearchObjType } from "api/listings/listings-types";

export const savedSearchBESchema = z.array(
  z.object({
    id: z.string().optional(),
    notifications_enabled: z.boolean(),
    city_code: z.string(),
    county_code: z.string(),
    nw_lat: z.number(),
    nw_lng: z.number(),
    se_lat: z.number(),
    se_lng: z.number(),
    min_price: z.number(),
    max_price: z.number(),
    min_surface: z.number(),
    building_type_filters: z.array(z.string()),
    partitioning_type_filters: z.array(z.string()),
    floor_filters: z.array(z.string()),
    room_filters: z.array(z.string()),
    has_air_conditioning: z.boolean(),
    has_elevator: z.boolean(),
    has_balcony: z.boolean(),
    has_terrace: z.boolean(),
    has_garden: z.boolean(),
    has_cable_tv: z.boolean(),
    has_clothes_dryer: z.boolean(),
    has_dishwasher: z.boolean(),
    has_dryer: z.boolean(),
    has_fireplace: z.boolean(),
    has_fridge: z.boolean(),
    has_furniture: z.boolean(),
    has_garage: z.boolean(),
    has_heating: z.boolean(),
    has_internet: z.boolean(),
    has_oven: z.boolean(),
    has_pool: z.boolean(),
    has_private_parking: z.boolean(),
    has_security_system: z.boolean(),
    has_storage: z.boolean(),
    has_street_parking: z.boolean(),
    has_tv: z.boolean(),
    has_video_surveillance: z.boolean(),
    has_washer: z.boolean(),
  })
);

export type SavedSearchBEResponseType = z.infer<typeof savedSearchBESchema>[number];

export const convertBESavedSearchToFrontendFormat = (
  savedSearchBE: SavedSearchBEResponseType[]
): SavedSearchObjType[] =>
  savedSearchBE.map((item) => ({
    id: item.id,
    notificationsEnabled: item.notifications_enabled,
    cityCode: item.city_code,
    countyCode: item.county_code,
    nwLat: item.nw_lat,
    nwLng: item.nw_lng,
    seLat: item.se_lat,
    seLng: item.se_lng,
    minPrice: item.min_price,
    maxPrice: item.max_price,
    minSurface: item.min_surface,
    buildingTypeFilters: item.building_type_filters,
    partitioningTypeFilters: item.partitioning_type_filters,
    floorFilters: item.floor_filters,
    roomFilters: item.room_filters,
    hasAirConditioning: item.has_air_conditioning,
    hasElevator: item.has_elevator,
    hasBalcony: item.has_balcony,
    hasTerrace: item.has_terrace,
    hasGarden: item.has_garden,
    hasCableTv: item.has_cable_tv,
    hasClothesDryer: item.has_clothes_dryer,
    hasDishwasher: item.has_dishwasher,
    hasDryer: item.has_dryer,
    hasFireplace: item.has_fireplace,
    hasFridge: item.has_fridge,
    hasFurniture: item.has_furniture,
    hasGarage: item.has_garage,
    hasHeating: item.has_heating,
    hasInternet: item.has_internet,
    hasOven: item.has_oven,
    hasPool: item.has_pool,
    hasPrivateParking: item.has_private_parking,
    hasSecuritySystem: item.has_security_system,
    hasStorage: item.has_storage,
    hasStreetParking: item.has_street_parking,
    hasTv: item.has_tv,
    hasVideoSurveillance: item.has_video_surveillance,
    hasWasher: item.has_washer,
  }));
