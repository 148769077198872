export const is = {
  is_iasi: {
    id: "is_iasi",
    lat: 47.154248241685714,
    lng: 27.587998805356598,
    zoom: 10,
    countyId: "is",
    name: "Iași",
    countyName: "Iași",
  },
};
