/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { UserBasicType } from "common/types/user";

export type AuthPages = "login" | "register" | "password-recover" | "password-reset" | "verify-email";

interface AuthState {
  user?: UserBasicType | null;
  isDisplayingAuthModal: boolean;
  token: string | null;
  currentAuthModalPage: AuthPages;
}

const initialState: AuthState = {
  user: null,
  isDisplayingAuthModal: false,
  token: null,
  currentAuthModalPage: "login",
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    initializeUser: (state) => {
      const storedToken = localStorage.getItem("token");
      state.token = storedToken;
    },
    // 👇 Logout the user by returning the initial state
    logout: (_) => {
      localStorage.removeItem("token");
      localStorage.removeItem("favoritesListings");
      localStorage.removeItem("blockedListings");
      // state.isAuthenticated = false;
      toast.warning("Ai fost deconectat cu succes!");
      return initialState;
    },
    // Save the user's info
    saveUserInfo: (state, action) => {
      state.user = action.payload;
    },
    setDisplayAuthModal: (state, action) => {
      const { newValue, newPage } = action.payload;
      state.isDisplayingAuthModal = newValue;
      if (newPage) {
        state.currentAuthModalPage = newPage;
      }
    },
    setToken: (state, action) => {
      localStorage.removeItem("token");

      state.token = action.payload.token;
      if (action.payload.rememberMe) {
        localStorage.setItem("token", action.payload.token);
      }
    },
    updateCurrentAuthModalPage: (state, action) => {
      state.currentAuthModalPage = action.payload;
    },
  },
});

export const { initializeUser, logout, saveUserInfo, setDisplayAuthModal, setToken, updateCurrentAuthModalPage } =
  authSlice.actions;

export default authSlice.reducer;
