import { Chip, Fab } from "@mui/material";
import { styled } from "@mui/system";
import { BasicListingType } from "api/listings/listings-types";
import { Pois } from "./FloatingButtonPointsOfInterest";

export const createClusterPoints = (listings: BasicListingType[] | undefined, seenListings: Record<string, string>) => {
  if (!listings) return [];

  return listings.map((listing) => {
    return {
      type: "Feature",
      id: listing.id,
      properties: {
        cluster: false,
        cluster_id: listing.id,
        listing,
        seen: !!seenListings[listing.id],
      },
      geometry: {
        type: "Point",
        coordinates: [listing.lng, listing.lat],
      },
    };
  });
};

export const StyledFab = styled(Fab)`
  margin: 20px auto 0;
  background-color: #fff;
  position: absolute;
  left: 150px;
  top: -10px;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  }
`;

export const StyledChip = styled(Chip)<{ mobile: string }>(
  ({ theme, mobile }) => `
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: ${mobile === "true" ? "60px" : "5px"};
    transform: translate(-50%, -50%);
    width: 160px;
    background-color: ${theme.palette.background.paper};
    padding: 1px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    z-index: 9;
  
    opacity: 0.4;
  
    &.MuiChip-root {
      color: ${theme.palette.text.secondary};
    }
  
    &.MuiChip-root {
      &:hover, &:active, &:focus {
        cursor: pointer;
        opacity: 1;
        background-color: ${theme.palette.background.paper};
        color: ${theme.palette.text.primary};
      }
    }
  
    .MuiButtonBase-root {
      color: ${theme.palette.text.secondary};
      padding: 2px;
      &:hover {
        color: ${theme.palette.text.primary};
      }
    }
  `
);

export const darkStyles = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#193341",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#2c5a71",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#29768a",
      },
      {
        lightness: -37,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#406d80",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#406d80",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#3e606f",
      },
      {
        weight: 2,
      },
      {
        gamma: 0.84,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        weight: 0.6,
      },
      {
        color: "#1a3541",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#2c5a71",
      },
    ],
  },
  {
    featureType: "landscape.man_made.building",
    elementType: "geometry.fill",
    stylers: [{ color: "#03445D" }],
  },
];

export const mapPointsOfInterestVisibility = (pois: Pois[]) => {
  const displayPointOfInterest = (poi: Pois) => (pois.includes(poi) ? "on" : "off");

  return [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: displayPointOfInterest("transit"),
        },
      ],
    },
    {
      featureType: "poi.attraction",
      stylers: [
        {
          visibility: displayPointOfInterest("attraction"),
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: displayPointOfInterest("business"),
        },
      ],
    },
    {
      featureType: "poi.government",
      stylers: [
        {
          visibility: displayPointOfInterest("government"),
        },
      ],
    },
    {
      featureType: "poi.medical",
      stylers: [
        {
          visibility: displayPointOfInterest("medical"),
        },
      ],
    },
    {
      featureType: "poi.park",
      stylers: [
        {
          visibility: displayPointOfInterest("park"),
        },
      ],
    },
    {
      featureType: "poi.place_of_worship",
      stylers: [
        {
          visibility: displayPointOfInterest("place_of_worship"),
        },
      ],
    },
    {
      featureType: "poi.school",
      stylers: [
        {
          visibility: displayPointOfInterest("school"),
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      stylers: [
        {
          visibility: displayPointOfInterest("sports_complex"),
        },
      ],
    },
  ];
};
