import { Divider } from "@mui/material";
import { theme } from "theme";

export const DividerComponent = () => {
  return (
    <Divider
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        width: "100%",
      }}
    />
  );
};
