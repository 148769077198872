// Order matters here
export const stepperFields = {
  buildingType: {
    key: "buildingType",
    label: "Tip clădire",
  },
  partitioning: {
    key: "partitioning",
    label: "Comparti\u200Bmentare",
  },
  general: {
    key: "general",
    label: "General",
  },
  price: {
    key: "price",
    label: "Preț",
  },
  contact: {
    key: "contact",
    label: "Contact",
  },
  extra: {
    key: "extra",
    label: "Facilități",
  },
  description: {
    key: "description",
    label: "Descriere",
  },
  location: {
    key: "location",
    label: "Localizare",
  },
  images: {
    key: "images",
    label: "Imagini",
  },
  summary: {
    key: "summary",
    label: "Sumar",
  },
};

export const stepperFieldsKeys = Object.keys(stepperFields);
export type StepperFieldsType = keyof typeof stepperFields;

export const buttons = {
  back: "Pasul anterior",
  next: "Următorul pas",
  reset: "Resetează",
  submit: "Adaugă proprietate",
  cancel: "Renunță și ieși",
};

export const errors = {
  choseValue: "Alege o valoare.",
  wrongDate: "Data incorectă.",
  fillAll: "Completează toate câmpurile",
};
