export const ct = {
  ct_constanta: {
    id: "ct_constanta",
    lat: 44.167977191887786,
    lng: 28.634018045133583,
    zoom: 10,
    countyId: "ct",
    name: "Constanța",
    countyName: "Constanța",
  },
};
