import { Button, styled } from "@mui/material";
import clsx from "clsx";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useScrollToTop } from "common/hooks/helpers";
import {
  AddListingFieldsType,
  BuildingTypeType,
  PartitioningType,
} from "pages/AddListing/add-listing-sections/sections-types";
import {
  buildingTypeFields,
  partitioningFields,
  contactFields,
  extraFields,
  generalFields,
  priceFields,
} from "pages/AddListing/add-listing-sections/sections-data";
import { stepperFields } from "pages/AddListing/utils/addListingData";
import { getAvailableFrom, getGenericLabel } from "utils/labels";
import { FullListingType } from "api/listings/listings-types";
import { theme } from "theme";
import { useAppSelector } from "common/hooks";
import { displayDescription } from "utils/helpers";
import { AddListingTitle } from "../pages/AddListing/components/AddListingTitle";
import { StyledAddListingWrapper } from "../pages/AddListing/add-listing-sections/styles";
import { WarningBox } from "./warning-box";

type ListingType = Record<AddListingFieldsType, string>;

interface Props {
  listingObj: ListingType | FullListingType;
  hideTitle?: boolean;
  adminView?: boolean;
}

export const ListingSummary = (props: Props) => {
  const { listingObj, hideTitle = false, adminView = false } = props;
  const { user } = useAppSelector((state) => state.authSlice);

  useScrollToTop();

  const buildingType =
    getMultipleOptionsChoice(buildingTypeFields, listingObj) ||
    getGenericLabel(listingObj.buildingType as BuildingTypeType);

  const partitioningType =
    getMultipleOptionsChoice(partitioningFields, listingObj) ||
    getGenericLabel(listingObj.partitioningType as PartitioningType);

  return (
    <>
      {!hideTitle && (
        <AddListingTitle title="Sumar anunț" subtitle="Verifică corectitudinea datelor introduse și publică anunțul." />
      )}
      <StyledAddListingWrapper>
        <StyledSummaryBox className={clsx(adminView && "admin-view-mode")}>
          {(!user || !user.hasVerifiedEmail) && !adminView && (
            <WarningBox type="warning">
              Doar utilizatorii autentificați și cu o adresă de email verificată pot adăuga anunțuri.
            </WarningBox>
          )}
          <div className="listing-summary-item">
            <h3>{stepperFields.buildingType.label}</h3>
            <p>{buildingType}</p>
          </div>
          <div className="listing-summary-item">
            <h3>{stepperFields.partitioning.label}</h3>
            <p>{partitioningType}</p>
          </div>
          <div className="listing-summary-item">
            <h3>{stepperFields.general.label}</h3>
            <p>{getNormalFieldsValues(generalFields, listingObj)}</p>
          </div>
          <div className="listing-summary-item">
            <h3>{stepperFields.price.label}</h3>
            <p>{getNormalFieldsValues(priceFields, listingObj)}</p>
          </div>
          <div className="listing-summary-item">
            <h3>{stepperFields.contact.label}</h3>
            <p>{getNormalFieldsValues(contactFields, listingObj)}</p>
          </div>
          <div className="listing-summary-item">
            <h3>{stepperFields.extra.label}</h3>
            <p>{getMultipleOptionsChoice(extraFields, listingObj)}</p>
          </div>
          {adminView && (
            <div className="listing-summary-item">
              <h3>{`${stepperFields.description.label} - raw`}</h3>
              <p>{listingObj.description}</p>
            </div>
          )}
          <div className="listing-summary-item">
            <h3>{stepperFields.description.label}</h3>
            <p>{displayDescription(listingObj.description)}</p>
          </div>
          <div className="listing-summary-item">
            <h3>{stepperFields.location.label}</h3>
            <p>{listingObj.addressFull}</p>
            <Button
              variant="text"
              color="primary"
              href={`https://maps.google.com/?q=${listingObj.lat},${listingObj.lng}`}
              target="_blank"
              endIcon={<OpenInNewIcon />}
            >
              Vezi poziția pe hartă
            </Button>
          </div>
        </StyledSummaryBox>
      </StyledAddListingWrapper>
    </>
  );
};

const getMultipleOptionsChoice = (fields: any, listingObj: any) => {
  return Object.entries(fields).reduce((acc, [key, value]: any) => {
    return `${acc}${listingObj[key as any] === "true" ? `${value.label}\n` : ""}`;
  }, "");
};

const getNormalFieldsValues = (fields: any, listingObj: any) => {
  return Object.entries(fields).reduce((acc, [key, value]: any) => {
    const availableFromField = key === "availableFrom" && getAvailableFrom(listingObj[key as any], "block");

    return `${acc}${
      listingObj[key as any]
        ? availableFromField
          ? `${availableFromField.charAt(0).toUpperCase()}${availableFromField.substring(1)}`
          : `${value.label}: ${listingObj[key as any]}\n`
        : ""
    }`;
  }, "");
};

const StyledSummaryBox = styled("div")`
  box-sizing: border-box;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 100px;

  &.admin-view-mode {
    margin-bottom: 0;
  }

  ${theme.breakpoints.down("md")} {
    width: 100%;
  }

  .listing-summary-item {
    > h3 {
      margin: 0;
      padding: 5px 5px 5px 0;
      border-bottom: 1px solid #e0e0e0;
    }

    > p {
      margin: 0;
      line-height: 1.5;
      white-space: pre-line;
      padding: 5px;
      color: ${({ theme }) => theme.palette.grey[700]};
    }

    > a {
      font-size: 12px;
      font-weight: 400;
      padding: 0 10px;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }

      > svg {
        margin: 0;
        padding: 0;
      }
    }
  }
`;
