import axios from "axios";

export async function uploadToS3(images: File[], presignedUrls: { url: string; filename: string }[]) {
  const promises = [];
  // Loop through the images and upload them to AWS
  for (let index = 0; index < images.length; index += 1) {
    const imageFile = images[index];
    const { url } = presignedUrls[index];

    // TODO: Add to Promise.all and await all of them
    const uploadImagePromise = axios.put(url, imageFile, {
      headers: { "Content-Type": "image/jpeg" },
    });
    promises.push(uploadImagePromise);
  }

  const response = await Promise.all(promises);

  return response.map((el) => el.config.url?.substring(0, el.config.url?.indexOf("?")));
}
