import * as React from "react";
import { Helmet } from "react-helmet";
import { PageWrapper } from "components/PageWrapper";

export const Privacy = () => {
  return (
    <PageWrapper title="Politica de confidențialitate">
      <Helmet>
        <title>Politica de confidentialitate</title>
        <meta
          name="description"
          content="Politica de confidentialitate a utilizarii platformei de inchirieri imobiliare direct de la proprietari."
        />
      </Helmet>
      <p>
        Platforma noastră se ocupă cu închirierea și vânzarea de proprietăți imobiliare și ne preocupă protejarea
        informațiilor personale ale utilizatorilor noștri. Această politică de confidențialitate descrie modul în care
        colectăm, utilizăm și protejăm informațiile personale ale utilizatorilor platformei noastre.
      </p>
      <h4>Colectarea informațiilor personale</h4>
      <p>
        Colectăm informații personale de la utilizatorii platformei noastre atunci când aceștia se înregistrează,
        completează formulare sau utilizează alte funcții ale platformei noastre. Informațiile personale colectate pot
        include nume, adresă de email, număr de telefon și alte detalii de contact. De asemenea, colectăm informații
        despre proprietățile pe care utilizatorii le listează sau le caută pe platforma noastră.
      </p>
      <p>
        Informațiile despre anunțurile imobiliare ale utilizatorilor cuprind adresa imobilului, coordonatele geografice
        și alte detalii relevante pentru închiriere. Aceste informații sunt colectate cu scopul de a permite
        utilizatorilor noștri să listeze și să caute proprietăți imobiliare pe platforma noastră. Utilizatorii au
        dreptul să refuze furnizarea acestor informații, dar acest lucru ar putea limita capacitatea lor de a folosi
        platforma în mod eficient.
      </p>
      <h4>Utilizarea informațiilor personale</h4>
      <p>
        Utilizăm informațiile personale colectate pentru a oferi utilizatorilor acces la funcțiile platformei noastre,
        pentru a le permite să listeze și să caute proprietăți imobiliare, pentru a răspunde la întrebări și pentru a le
        oferi alte servicii. De asemenea, utilizăm informațiile personale pentru a ne contacta cu utilizatorii în
        legătură cu actualizări ale platformei sau pentru a le oferi oferte speciale.
      </p>
      <h4>Partajarea informațiilor personale</h4>
      <p>
        Informațiile personale colectate nu vor fi vândute sau distribuite către terți fără consimțământul
        utilizatorului. Totuși, informațiile pot fi partajate cu parteneri ai platformei noastre pentru a oferi
        utilizatorilor servicii adiționale sau pentru a le permite să listeze sau să caute proprietăți imobiliare.
      </p>
      <h4>Protejarea informațiilor personale</h4>
      <p>
        Luăm toate măsurile raționabile pentru a proteja informațiile personale ale utilizatorilor platformei noastre
        împotriva accesului neautorizat, utilizării neautorizate sau pierderii.
      </p>
      <h4>Modificări ale acestei politici de confidențialitate</h4>
      <p>
        Platforma noastră poate modifica această politică de confidențialitate în orice moment. Orice modificare va fi
        publicată pe această pagină și va avea efect imediat. Utilizatorii platformei noastre sunt rugați să verifice
        această pagină periodic pentru orice modificări.
      </p>
      <h4>Accesul și controlul asupra informațiilor personale</h4>
      <p>
        În conformitate cu GDPR, utilizatorii noștri au dreptul de a solicita o copie a tuturor datelor personale pe
        care le deținem despre ei. Ei au, de asemenea, dreptul de a obiecta la prelucrarea datelor lor personale, de a
        solicita restricționarea prelucrării sau portabilitatea datelor. Pentru a exercita aceste drepturi, utilizatorii
        pot folosi informațiile de contact furnizate mai jos.
      </p>
      <h4>Contact</h4>
      <p>
        Dacă aveți întrebări sau nelămuriri cu privire la această politică de confidențialitate, vă rugăm să ne
        contactați la dev@kastel.io sau prin intermediul informațiilor de contact furnizate pe platforma noastră.
      </p>
    </PageWrapper>
  );
};
