import React, { ComponentProps } from "react";

export const PriceIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_725_101094)">
        <path
          d="M22.83 12.99L11.83 2H2V11.83L12.99 22.82L22.83 12.99ZM5.5 7C4.67 7 4 6.33 4 5.5C4 4.67 4.67 4 5.5 4C6.33 4 7 4.67 7 5.5C7 6.33 6.33 7 5.5 7Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_725_101094">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
