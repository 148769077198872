import { useMutation } from "react-query";
import { UserApi } from "../user-api";

interface MutationProps {
  token: string;
  userId: string;
  password: string;
}

export const usePasswordReset = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation("passwordReset", (mutationProps: MutationProps) => {
    const { token, userId, password } = mutationProps;

    return UserApi.passwordReset({
      token,
      userId,
      password,
    });
  });

  return { mutate, isLoading, isSuccess, isError };
};
