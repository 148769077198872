import { useCallback, useEffect, useRef } from "react";
import { useUpdateAppLoadingState } from "./useUpdateAppLoadingState";

function useIsMounted() {
  const isMounted = useRef(false);
  const { removeLoadingEl } = useUpdateAppLoadingState();

  useEffect(() => {
    isMounted.current = true;
    // After the component is mounted, set the global loading state to false
    removeLoadingEl("app-initial");

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export default useIsMounted;
