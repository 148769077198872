import { z } from "zod";
import { PublicUserProfileType } from "common/types/user";
import { basicListingBEResponseSchema, convertBEBasicListingsToFrontendFormat } from "./process-basic-listings";

export const publicUserProfileBEResponseSchema = z.object({
  first_name: z.string(),
  id: z.string(),
  last_name: z.string(),
  phone_number: z.string().nullable(),
  updatedAt: z.string().optional(),
  createdAt: z.string(),
  profile_picture_url: z.string().nullable(),
  listings: basicListingBEResponseSchema,
});

export type PublicUserProfileBEResponseType = z.infer<typeof publicUserProfileBEResponseSchema>;

export const convertBEPublicUserProfileToFrontendFormat = (
  userProfile: PublicUserProfileBEResponseType
): PublicUserProfileType => ({
  firstName: userProfile.first_name,
  id: userProfile.id,
  lastName: userProfile.last_name,
  phoneNumber: userProfile.phone_number,
  createdAt: userProfile.createdAt,
  updatedAt: userProfile.updatedAt,
  profilePictureUrl: userProfile.profile_picture_url,
  listings: convertBEBasicListingsToFrontendFormat(userProfile.listings),
});
