import { Navigate, useParams } from "react-router-dom";

// This is a fallback route for the app. Whenever a user tries to access a route without
// the full path (e.g. kastel.io/terms instead of kastel.io/map/ro/45.69404431014104/24.666355900467778/7/terms),
// the app will redirect the user to the default route for that page.
export const routes = (baseUrl: string) => {
  return {
    admin: {
      path: "/admin",
      element: <Navigate replace to={`${baseUrl}/admin`} />,
    },
    defaultContact: {
      path: "/contact",
      element: <Navigate replace to={`${baseUrl}/contact`} />,
    },
    defaultTerms: {
      path: "/terms",
      element: <Navigate replace to={`${baseUrl}/terms`} />,
    },
    defaultPrivacy: {
      path: "/privacy",
      element: <Navigate replace to={`${baseUrl}/privacy`} />,
    },

    defaultListingAdd: {
      path: "/add",
      element: <Navigate replace to={`${baseUrl}/add`} />,
    },
    userProfile: {
      path: "/profile/:userProfileId",
      element: <Navigate replace to={`${baseUrl}/profile/:userProfileId`} />,
    },
    recoverPassword: {
      path: "/passwordReset/:token/:userId",
      element: <PasswordResetRedirect baseUrl={baseUrl} />,
    },
    verifyEmail1: {
      path: "/email-verification/:emailToken/:userId",
      element: <VerifyEmailRedirect1 baseUrl={baseUrl} />,
    },
    verifyEmail2: {
      path: "/email-verification/:emailToken1/:emailToken2/:userId",
      element: <VerifyEmailRedirect2 baseUrl={baseUrl} />,
    },
    verifyEmail3: {
      path: "/email-verification/:emailToken1/:emailToken2/:emailToken3/:userId",
      element: <VerifyEmailRedirect3 baseUrl={baseUrl} />,
    },
    components: {
      path: "/components",
      element: <Navigate replace to={`${baseUrl}/components`} />,
    },
    defaultMain: {
      path: "/",
      element: <Navigate replace to={`${baseUrl}/`} />,
    },
    notFound: {
      path: "*",
      element: <Navigate replace to="404" />,
    },
  };
};

const PasswordResetRedirect = ({ baseUrl }: { baseUrl: string }) => {
  const { token, userId } = useParams();

  return <Navigate replace to={`${baseUrl}/password-reset/${token}/${userId}`} />;
};

const VerifyEmailRedirect1 = ({ baseUrl }: { baseUrl: string }) => {
  const { emailToken, userId } = useParams();

  return <Navigate replace to={`${baseUrl}/verify-email/${emailToken}/${userId}`} />;
};

const VerifyEmailRedirect2 = ({ baseUrl }: { baseUrl: string }) => {
  const { emailToken1, emailToken2, userId } = useParams();

  return <Navigate replace to={`${baseUrl}/verify-email/${emailToken1}_${emailToken2}/${userId}`} />;
};

const VerifyEmailRedirect3 = ({ baseUrl }: { baseUrl: string }) => {
  const { emailToken1, emailToken2, emailToken3, userId } = useParams();

  return <Navigate replace to={`${baseUrl}/verify-email/${emailToken1}_${emailToken2}_${emailToken3}/${userId}`} />;
};
