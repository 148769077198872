export const tl = {
  tl_tulcea: {
    id: "tl_tulcea",
    lat: 45.177980081697505,
    lng: 28.790408798599728,
    zoom: 10,
    countyId: "tl",
    name: "Tulcea",
    countyName: "Tulcea",
  },
};
