export const nt = {
  nt_piatra_neamt: {
    id: "nt_piatra_neamt",
    lat: 46.92528363091041,
    lng: 26.374847479171247,
    zoom: 10,
    countyId: "nt",
    name: "Piatra Neamț",
    countyName: "Neamț",
  },
};
