import { useAppDispatch, useAppSelector } from "common/hooks";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { setOpenedModal } from "redux/slices/appSlice";

export const AuthSuccessModal = () => {
  const dispatch = useAppDispatch();
  const { openedModal } = useAppSelector((state) => state.appSlice);

  const handleCloseConfirmModal = () => {
    dispatch(setOpenedModal(null));
  };

  return (
    <ConfirmationDialog
      open={openedModal === "auth-success"}
      onCancel={handleCloseConfirmModal}
      onPrimary={handleCloseConfirmModal}
      labelPrimary="Am înțeles"
      title="Succes!"
      variant="success"
      onSecondary={handleCloseConfirmModal}
    >
      Contul a fost creat cu succes! Am trimis un email de confirmare la adresa folosită.
    </ConfirmationDialog>
  );
};
