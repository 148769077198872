export const MAX = Number.MAX_SAFE_INTEGER;

export const SLIDER_DELAY_MS = 1000;
export const DEBOUNCE_DELAY = 1000;
export const PHONE_PREFIX_RO = "+40";

export const ICON_BTN_PADDING_MOBILE = "4px";
export const ICON_BTN_PADDING_DESKTOP = "6px";

export const ICON_BTN_FONT_SIZE_DESKTOP = "40px";

export const WAITING_TIME = 15;

export const WARNING_MESSAGE_REGISTER =
  "Trebuie să accepți Termenii și Condițiile și Politica de Confidențialitate și GDPR pentru a putea continua cu autentificarea.";

export const NEUTRAL_RED = "#e53e3e";
export const NEUTRAL_BLUE = "#9fbded";

export const FILTERS_WIDTH = "300";

export const NAVBAR_HEIGHT = {
  MOBILE: 65,
  DESKTOP: 68,
};

export const TICK_SYMBOL = "✓";

export const MAX_FILTER_VALUE = 999999;
