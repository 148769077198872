import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { styled } from "@mui/material";

interface Props {
  label: string;
  href?: string;
  hrefText?: string;
}

export const CheckboxEl = (props: Props) => {
  const { label, href, hrefText } = props;

  return (
    <StyledCheckboxEl>
      {label}
      {href && (
        <Link className="checkbox-link" to={href} target="_blank">
          {hrefText}
          <LaunchIcon className="launch-icon" />
        </Link>
      )}
    </StyledCheckboxEl>
  );
};

const StyledCheckboxEl = styled("div")`

  font-size: 13px;

  .launch-icon {
    font-size: inherit;
  }
}`;
