import { useQuery } from "react-query";
import { z } from "zod";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { QueriesKeys } from "common/types/general";
import { ListingsApi } from "../../listings-api";
import { INFINITE_CACHING, logZodError } from "../../../utils";
import { useHandleApiError } from "../../../useHandleErrors";

interface ListingOwnerPhoneProps {
  listingId: string | undefined;
  enabled: boolean;
}

export const phoneNumberBEResponseSchema = z.string();

export type OwnerPhoneBEResponseType = z.infer<typeof phoneNumberBEResponseSchema>;

export const useGetListingOwnerPhone = (props: ListingOwnerPhoneProps) => {
  const { listingId, enabled = false } = props;
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["listingOwnerPhone" as QueriesKeys, listingId, enabled],
    async () => {
      addLoadingEl("listing-owner-phone");
      const res = await ListingsApi.getPhoneNumberOfListingOwner(listingId!);
      removeLoadingEl("listing-owner-phone");

      const { data } = res.data;
      const parsed = phoneNumberBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return data;
      }

      return parsed.data;
    },
    {
      ...INFINITE_CACHING,
      enabled: !!listingId && enabled,
    }
  );

  // Handle errors
  useHandleApiError({
    isError,
    message: "Nu s-a putut încărca numărul de telefon al proprietarului. Reîncarcă pagina și încearcă din nou.",
  });

  return { ownerPhoneNumber: data, isLoading, isSuccess, isError };
};
