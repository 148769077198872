/* eslint-disable camelcase */
import { useQuery } from "react-query";
import { z } from "zod";
import { useAppParams } from "common/hooks/useAppParams";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { useAppSelector } from "common/hooks";
import { QueriesKeys } from "common/types/general";
import { ListingsApi } from "../listings-api";
import { LIMITED_CACHING, logZodError } from "../../utils";
import { useHandleApiError } from "../../useHandleErrors";
import { FullListingType } from "../listings-types";

const activeListingBEResponseSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  price: z.number(),
  deposit: z.number().nullable(),
  construction_year: z.number(),
  floor: z.number().nullable().optional(),
  total_floors: z.number(),
  available_parking_lots: z.number().nullable(),
  available_from: z.string().optional(), // TODO: Should not be optional
  garden_surface: z.number().nullable(),
  number_of_rooms: z.number(),
  number_of_bathrooms: z.number(),
  surface: z.number(),
  has_air_conditioning: z.boolean(),
  has_elevator: z.boolean(),
  has_balcony: z.boolean(),
  has_terrace: z.boolean(),
  has_garden: z.boolean(),
  has_cable_tv: z.boolean(),
  has_clothes_dryer: z.boolean(),
  has_dishwasher: z.boolean(),
  has_dryer: z.boolean(),
  has_fireplace: z.boolean(),
  has_fridge: z.boolean(),
  has_furniture: z.boolean(),
  has_garage: z.boolean(),
  has_heating: z.boolean(),
  has_internet: z.boolean(),
  has_oven: z.boolean(),
  has_pool: z.boolean(),
  has_private_parking: z.boolean(),
  has_security_system: z.boolean(),
  has_storage: z.boolean(),
  has_street_parking: z.boolean(),
  has_tv: z.boolean(),
  has_video_surveillance: z.boolean(),
  has_washer: z.boolean(),
  image_urls: z.array(z.string()),
  images_thumbnail_urls: z.array(z.string()),
  main_image_url: z.string(),
  is_active: z.boolean(),
  is_approved: z.boolean(),
  building_type: z.union([z.literal("block"), z.literal("house"), z.literal("villa")]),
  parking_type: z.string().nullable(),
  partitioning_type: z.union([
    z.literal("detached"),
    z.literal("semidetached"),
    z.literal("ordered"),
    z.literal("studio"),
  ]),
  heating_type: z.union([z.literal("municipal"), z.literal("electric"), z.literal("gaz")]).nullable(),
  country_code: z.string(),
  country_name: z.string(),
  county_code: z.string(),
  county_name: z.string(),
  city_code: z.string(),
  city_name: z.string(),
  street_name: z.string(),
  street_number: z.string(),
  user_id: z.string(),
  user: z.object({
    id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email().optional(),
    phone_number: z.string().optional(),
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
  coordinates: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
});

export type FullListingBEResponseType = z.infer<typeof activeListingBEResponseSchema>;

export const useGetActiveListing = () => {
  const { listingId } = useAppParams();
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const { user } = useAppSelector((state) => state.authSlice);
  const { token } = user || {};

  const isOwnListing = !!user?.ownListingsIds?.includes(listingId!);

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["activeListing" as QueriesKeys, listingId, token],
    async () => {
      addLoadingEl("active-listing");
      const res = isOwnListing
        ? await ListingsApi.getActiveOwnListing(listingId!, token)
        : await ListingsApi.getActiveListing(listingId!);

      removeLoadingEl("active-listing");

      const { data } = res.data;

      const parsed = activeListingBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return convertBEListingToFrontendFormat(data);
      }

      return convertBEListingToFrontendFormat(parsed.data);
    },
    {
      ...LIMITED_CACHING,
      enabled: !!listingId,
    }
  );

  // Handle errors
  useHandleApiError({
    isError,
    message: "Nu s-au putut încărca detaliile despre anunț. Reîncarcă pagina și încearcă din nou.",
  });

  return { activeListing: data, isLoading, isSuccess, isError };
};

export const convertBEListingToFrontendFormat = (listing: FullListingBEResponseType): FullListingType => {
  return {
    id: String(listing.id),

    // General
    description: listing.description,
    buildingType: listing.building_type,
    partitioningType: listing.partitioning_type,
    price: String(listing.price),
    deposit: String(listing.deposit || listing.price), // TODO: Remove this when deposit is not nullable
    rooms: String(listing.number_of_rooms),
    surface: String(listing.surface),
    floor: String(listing.floor),
    totalFloors: String(listing.total_floors),
    bathrooms: String(listing.number_of_bathrooms),
    constructionYear: String(listing.construction_year),
    availableFrom: listing.available_from || new Date().toISOString(),

    // Misc
    title: listing.title,
    availableParkingLots: String(listing.available_parking_lots),
    gardenSurface: String(listing.garden_surface),
    isActive: String(listing.is_active),
    isApproved: String(listing.is_approved),
    heatingType: listing.heating_type || "",
    parkingType: listing.parking_type || "",
    createdAt: listing.createdAt,
    updatedAt: listing.updatedAt,

    // Address
    addressFull: `${listing.street_name} nr. ${listing.street_number}, ${listing.city_name}`,
    cityName: listing.city_name,
    countryId: listing.country_code,
    countryName: listing.country_name,
    countyId: listing.county_code,
    cityId: listing.city_code,
    countyName: listing.county_name,
    streetName: listing.street_name,
    streetNumber: listing.street_number,
    lat: String(listing.coordinates.latitude),
    lng: String(listing.coordinates.longitude),

    // TODO: After BE is adapted, we can remove the || "" from the following fields
    // This convertor is re-used in ListingSummary, and there we don't have the user object available
    ownerId: listing.user?.id || "",
    ownerFirstName: listing.user?.first_name || "",
    ownerLastName: listing.user?.last_name || "",
    ownerEmail: listing.user?.email || "",
    ownerPhone: listing.user?.phone_number || "",

    // Extras
    hasAirConditioning: String(listing.has_air_conditioning),
    hasBalcony: String(listing.has_balcony),
    hasCableTv: String(listing.has_cable_tv),
    hasClothesDryer: String(listing.has_clothes_dryer),
    hasDishwasher: String(listing.has_dishwasher),
    hasDryer: String(listing.has_dryer),
    hasElevator: String(listing.has_elevator),
    hasFireplace: String(listing.has_fireplace),
    hasFridge: String(listing.has_fridge),
    hasFurniture: String(listing.has_furniture),
    hasGarage: String(listing.has_garage),
    hasGarden: String(listing.has_garden),
    hasHeating: String(listing.heating_type !== null),
    hasInternet: String(listing.has_internet),
    hasOven: String(listing.has_oven),
    hasPool: String(listing.has_pool),
    hasPrivateParking: String(listing.has_private_parking),
    hasSecuritySystem: String(listing.has_security_system),
    hasStorage: String(listing.has_storage),
    hasStreetParking: String(listing.has_street_parking),
    hasTerrace: String(listing.has_terrace),
    hasTv: String(listing.has_tv),
    hasVideoSurveillance: String(listing.has_video_surveillance),
    hasWasher: String(listing.has_washer),

    // Images
    images: listing.image_urls,
  };
};
