import { useMutation } from "react-query";
import { useAppSelector } from "common/hooks";
import { AdminApi } from "api/admin/admin-api";

type MutationProps = {
  userId: string;
  reason: string;
  adminComment?: string;
};

export const useBanUser = () => {
  const { user } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    "banUser",
    ({ userId, reason, adminComment }: MutationProps) => {
      if (!user) {
        return Promise.reject();
      }

      return AdminApi.banUser(user.token, userId, reason, adminComment);
    }
  );

  return { banUser: mutate, isLoading, isSuccess, isError };
};
