import React, { ComponentProps } from "react";

export const ContactIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M48.2083 5.49984H45.25V0.166504H39.3333V5.49984H15.6667V0.166504H9.75V5.49984H6.79167C3.50792 5.49984 0.875 7.89984 0.875 10.8332V48.1665C0.875 51.0998 3.50792 53.4998 6.79167 53.4998H48.2083C51.4625 53.4998 54.125 51.0998 54.125 48.1665V10.8332C54.125 7.89984 51.4625 5.49984 48.2083 5.49984ZM27.5 13.4998C32.4108 13.4998 36.375 17.0732 36.375 21.4998C36.375 25.9265 32.4108 29.4998 27.5 29.4998C22.5892 29.4998 18.625 25.9265 18.625 21.4998C18.625 17.0732 22.5892 13.4998 27.5 13.4998ZM45.25 45.4998H9.75V42.8332C9.75 37.4998 21.5833 34.5665 27.5 34.5665C33.4167 34.5665 45.25 37.4998 45.25 42.8332V45.4998Z"
        fill="currentColor"
      />
    </svg>
  );
};
