import axios from "axios";
import { FullListingBEResponseType } from "api/listings/hooks";
import { IApproveRejectListingParams, IPendingListingsParams } from "./admin-types";
import { DEFAULT_REQ_TIMEOUT } from "../utils";

const baseUrl =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_SERVER_URL_LOCAL : process.env.REACT_APP_SERVER_URL;

export class AdminApi {
  public static async getPendingListings({ token }: IPendingListingsParams) {
    return axios.get<{ data: FullListingBEResponseType[] }>(
      `${baseUrl}/sdsad-ertwrt-dfpsdfk-dsdvnmkd-wewio/admin/listings/pending`,
      {
        timeout: DEFAULT_REQ_TIMEOUT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public static async approveListing({ listingId, token }: IApproveRejectListingParams) {
    return axios.post(
      `${baseUrl}/sdsad-ertwrt-dfpsdfk-dsdvnmkd-wewio/admin/listing/${listingId}/approve`,
      {},
      {
        timeout: DEFAULT_REQ_TIMEOUT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public static async rejectListing({ listingId, token, reason }: IApproveRejectListingParams & { reason: string }) {
    return axios.post(
      `${baseUrl}/sdsad-ertwrt-dfpsdfk-dsdvnmkd-wewio/admin/listing/${listingId}/reject`,
      { reason },
      {
        timeout: DEFAULT_REQ_TIMEOUT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  public static async banUser(token: string, userId: string, reason: string, adminComment?: string) {
    return axios.post(
      `${baseUrl}/sdsad-ertwrt-dfpsdfk-dsdvnmkd-wewio/admin/user/${userId}/ban`,
      {
        reason,
        admin_comment: adminComment,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: DEFAULT_REQ_TIMEOUT,
      }
    );
  }

  public static async getBannedUsers(token: string) {
    return axios.get(`${baseUrl}/sdsad-ertwrt-dfpsdfk-dsdvnmkd-wewio/admin/banned-users`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static async getReportedListings(token: string) {
    return axios.get(`${baseUrl}/sdsad-ertwrt-dfpsdfk-dsdvnmkd-wewio/admin/listings/reported`, {
      timeout: DEFAULT_REQ_TIMEOUT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
