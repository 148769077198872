import { useEffect, useState } from "react";
import { InputLabel, MenuItem, Select, Stack, TextField, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useGetActiveListing } from "api/listings/hooks";
import { ReportListingReasons, useReportListingFromApp } from "utils/hooks/use-report-listing-from-app";
import { useAppParams } from "common/hooks/useAppParams";
import { setOpenedModal } from "redux/slices/appSlice";

export const ReportListingModal = () => {
  const dispatch = useAppDispatch();

  const { activeListing } = useGetActiveListing();
  const { openedModal } = useAppSelector((state) => state.appSlice);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      setError(false);
      setSuccess(false);
    };
  }, []);

  // Report listing
  const { listingId } = useAppParams();
  const {
    onUpdateReportedListingId,
    onReportListingMessageChange,
    onReportListing,
    onReportListingReasonSelection,
    isReportListingLoading,
    reportedListingMessage,
    reportedListingReason,
  } = useReportListingFromApp({ onSuccess: () => setSuccess(true), onError: () => setError(true) });

  useEffect(() => {
    onUpdateReportedListingId(listingId || "");
  }, [listingId]);

  const handleCloseModal = () => {
    dispatch(setOpenedModal(null));
  };

  if (success) {
    return (
      <ConfirmationDialog
        title="Raportul a fost trimis cu succes!"
        variant="success"
        labelPrimary="Închide"
        open={openedModal === "report-listing"}
        onCancel={handleCloseModal}
        onPrimary={handleCloseModal}
        onSecondary={handleCloseModal}
      >
        Îți mulțumim că ne ajuți să menținem aplicatia curată! Echipa noastră va analiza anunțul și va lua măsurile
        necesare.
      </ConfirmationDialog>
    );
  }

  if (error) {
    return (
      <ConfirmationDialog
        title="Eroare"
        variant="error"
        labelPrimary="Închide"
        open={openedModal === "report-listing"}
        onCancel={handleCloseModal}
        onPrimary={handleCloseModal}
        onSecondary={handleCloseModal}
      >
        A apărut o eroare. Te rugăm să încerci din nou.
      </ConfirmationDialog>
    );
  }

  return (
    <ConfirmationDialog
      title="Raportează acest anunț"
      variant="warning"
      labelSecondary="M-am răzgândit"
      labelPrimary="Trimite raport"
      disabledPrimary={!listingId || isReportListingLoading || !reportedListingReason}
      open={openedModal === "report-listing"}
      onCancel={handleCloseModal}
      onPrimary={onReportListing}
      onSecondary={handleCloseModal}
    >
      Care este motivul pentru care dorești să raportezi anunțul utilizatorului{" "}
      <strong>{activeListing ? `${activeListing.ownerFirstName} ${activeListing.ownerLastName}` : ""}</strong>?
      <StyledSection>
        <Stack>
          <InputLabel id="select-label">Alege din lista de mai jos</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={reportedListingReason || ""}
            // label="Motivul raportării"
            onChange={onReportListingReasonSelection}
            placeholder="Selectează motivul raportării"
          >
            {Object.entries(ReportListingReasons).map(([reason, key]) => (
              <MenuItem key={key} value={reason}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        {reportedListingReason === "OTHER" && (
          <Stack direction="row" spacing={5}>
            <TextField
              multiline
              rows={4}
              label="Introdu mesajul tău"
              variant="outlined"
              fullWidth
              onChange={onReportListingMessageChange}
              value={reportedListingMessage}
            />
          </Stack>
        )}
      </StyledSection>
    </ConfirmationDialog>
  );
};

const StyledSection = styled("section")`
  padding: 24px 0;
  background-color: #f5f5f5;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .MuiStack-root {
    width: 100%;
  }
`;
