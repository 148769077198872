import { useMutation } from "react-query";
import { UserApi } from "../user-api";

interface MutationProps {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
}

export const useRegisterUser = () => {
  const { mutate, isLoading, isSuccess, isError } = useMutation("registerUser", (mutationProps: MutationProps) => {
    const { firstName, lastName, email, phone, password } = mutationProps;

    return UserApi.registerUser({
      firstName,
      lastName,
      email,
      phone,
      password,
    });
  });

  return { mutate, isLoading, isSuccess, isError };
};
