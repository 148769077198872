export const bh = {
  bh_oradea: {
    id: "bh_oradea",
    lat: 47.07176755781394,
    lng: 21.925769711722563,
    zoom: 10,
    countyId: "bh",
    name: "Oradea",
    countyName: "Bihor",
  },
};
