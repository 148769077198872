import { Button } from "@mui/material";
import { styled } from "@mui/system";
import MapIcon from "@mui/icons-material/Map";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { boxShadows, theme } from "theme";
import { useAppDispatch, useAppSelector, useBasePathUrl, useWindowDimensions } from "common/hooks";
import { Views, useAppParams } from "common/hooks/useAppParams";
import { useKeyListener } from "common/hooks/useKeyListener";
import { setSidebarOpen } from "redux/slices/appSlice";

export const SidebarViewsToggleButtons = () => {
  const { isMobile } = useWindowDimensions();
  const { view, listingId } = useAppParams();
  const { imagesFullscreen, lastSeenView, sidebarOpen } = useAppSelector(({ appSlice }) => appSlice);
  const { updateBasePath } = useBasePathUrl();
  const dispatch = useAppDispatch();

  const handleClose = (event: any, newView?: Views) => {
    event?.stopPropagation();

    updateBasePath({ view: newView || "map", goBasePath: true });
  };

  // Not working for some reason; it will still close the listing when pressing Escape
  useKeyListener("Escape", imagesFullscreen ? () => null : handleClose);

  const handleToggle = () => {
    if (listingId) {
      updateBasePath({
        view: view === "details" ? "map" : "details",
      });
      return;
    }

    updateBasePath({
      view: view === "feed" ? "map" : "feed",
    });

    if (isMobile && view === "map") {
      dispatch(setSidebarOpen(true));
    }
  };

  // Desktop close button shown on the details page
  if (!isMobile) {
    if (view === "details" && !imagesFullscreen) {
      return (
        <StyledContainer className="active-listing-toggle-buttons desktop-details">
          <StyledButton
            endIcon={sidebarOpen ? <LocalLibraryIcon /> : <CloseIcon />}
            size="small"
            variant="contained"
            onClick={handleClose}
            sx={{
              width: "100%",
              "&:hover": {
                fontWeight: "bold",
              },
            }}
          >
            {sidebarOpen ? "Înapoi la lista de anunțuri" : "Închide anunțul"}
          </StyledButton>
        </StyledContainer>
      );
    }

    return null;
  }

  // Mobile button shown on the map or feed page
  if (view === "feed") {
    return (
      <StyledContainer className="active-listing-toggle-buttons">
        <StyledButton
          endIcon={<MapIcon />}
          size="small"
          variant="contained"
          onClick={handleToggle}
          sx={{
            width: "100%",
            "&:hover": {
              fontWeight: "bold",
            },
          }}
        >
          Vezi anunțurile pe hartă
        </StyledButton>
      </StyledContainer>
    );
  }

  // Mobile button shown on the map or feed page
  if (view === "map") {
    return (
      <StyledContainer className="active-listing-toggle-buttons">
        <StyledButton
          endIcon={<LocalLibraryIcon />}
          size="small"
          variant="contained"
          onClick={handleToggle}
          sx={{
            width: "100%",
            "&:hover": {
              fontWeight: "bold",
            },
          }}
        >
          Vezi anunțurile în listă
        </StyledButton>
      </StyledContainer>
    );
  }

  // Mobile buttons shown on the details page
  return imagesFullscreen ? null : (
    <StyledContainer className={clsx("active-listing-toggle-buttons", view === "details" && "details-view")}>
      <StyledButton
        endIcon={lastSeenView === "map" ? <MapIcon /> : <LocalLibraryIcon />}
        size="small"
        variant="contained"
        onClick={(event: any) => handleClose(event, lastSeenView)}
        sx={{
          zIndex: 1000,
          width: "100%",
          "&:hover": {
            fontWeight: "bold",
          },
        }}
      >
        {lastSeenView === "map" ? "Înapoi la hartă" : "Înapoi la lista de anunțuri"}
      </StyledButton>
    </StyledContainer>
  );
};

export const StyledContainer = styled("div")`
  // position: fixed; // This is problematic in Safari
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 8px 0;
  box-sizing: border-box;
  z-index: 9;
  gap: 12px;
  bottom: 4px;
  pointer-events: none;

  &.desktop-details {
    z-index: 1000;
  }

  ${theme.breakpoints.down("md")} {
    // position: fixed;
    bottom: 16px;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    z-index: 1000;

    &.details-view,
    &.desktop-details {
      bottom: 16px;
    }
  }
`;

export const StyledButton = styled(Button)`
  pointer-events: all;

  &.MuiButton-root {
    &.MuiButton-contained {
      color: ${theme.palette.grey[50]};
      background-color: rgba(0, 0, 0, 0.9);
      padding: 12px 24px;
      margin: 0;
      font-weight: 500;
      width: auto;
      border-radius: 30px;
      box-shadow: ${boxShadows.mapButtons};
      -webkit-box-shadow: ${boxShadows.mapButtons};
      -moz-box-shadow: ${boxShadows.mapButtons};

      &:hover {
        background-color: rgba(0, 0, 0);
      }
    }
  }

  ${theme.breakpoints.down("md")} {
    &.MuiButton-root {
      &.MuiButton-contained {
        padding: 12px 24px;
      }
    }
  }
`;
