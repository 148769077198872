export const il = {
  il_slobozia: {
    id: "il_slobozia",
    lat: 44.564632708928,
    lng: 27.369303636464085,
    zoom: 10,
    countyId: "il",
    name: "Slobozia",
    countyName: "Ialomița",
  },
};
