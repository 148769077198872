import { Button, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import LivingIcon from "@mui/icons-material/Living";
import ShowerIcon from "@mui/icons-material/Shower";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import PersonIcon from "@mui/icons-material/Person";
import BalconyIcon from "@mui/icons-material/Balcony";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import DryIcon from "@mui/icons-material/Dry";
import WaterIcon from "@mui/icons-material/Water";
import WindPowerIcon from "@mui/icons-material/WindPower";
import ElevatorIcon from "@mui/icons-material/Elevator";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import KitchenIcon from "@mui/icons-material/Kitchen";
import BedIcon from "@mui/icons-material/Bed";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import YardIcon from "@mui/icons-material/Yard";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import WifiIcon from "@mui/icons-material/Wifi";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import PoolIcon from "@mui/icons-material/Pool";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import SecurityIcon from "@mui/icons-material/Security";
import WidgetsIcon from "@mui/icons-material/Widgets";
import StorageIcon from "@mui/icons-material/Storage";
import DeckIcon from "@mui/icons-material/Deck";
import TvIcon from "@mui/icons-material/Tv";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService"; // import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import HeightIcon from "@mui/icons-material/Height";
import Crop54Icon from "@mui/icons-material/Crop54";
import { ReactElement, ReactNode, useState } from "react";
import SellIcon from "@mui/icons-material/Sell";
import clsx from "clsx";
import { theme } from "theme";
import { FullListingType } from "api/listings/listings-types";
import { getAddedAt, getDeposit, getFloors, getGenericLabel, getPrice } from "utils/labels";
import { useGetListingOwnerPhone } from "api/listings/hooks/get-listing-owner-phone";
import { useAppDispatch } from "common/hooks";
import { setOpenedModal } from "redux/slices/appSlice";
import { displayDescription, formatPhoneNumber } from "utils/helpers";

interface ListingDetailsElementProps {
  activeListing: FullListingType | undefined;
  section: "rental-info" | "owner-info" | "place-info" | "extra" | "description";
  title: string;
}

export const ListingDetailsElement = (props: ListingDetailsElementProps) => {
  const { activeListing, section, title } = props;
  const dispatch = useAppDispatch();

  const [askNumber, setAskNumber] = useState(false);
  const { ownerPhoneNumber } = useGetListingOwnerPhone({ listingId: activeListing?.id, enabled: askNumber });

  if (!activeListing) {
    return (
      <div className="main-details">
        <StyledHeader>{title}</StyledHeader>
        <Skeleton width="100%" height="200px" />
      </div>
    );
  }

  let details: { icon: ReactElement; label: string | number | ReactNode; className?: string }[] = [];
  if (section === "rental-info") {
    details = [
      { icon: <SellIcon />, label: getPrice({ amount: activeListing.price }), className: "price" },
      { icon: <SellIcon />, label: getDeposit({ amount: activeListing.deposit }) },
      { icon: <CalendarMonthIcon />, label: getAddedAt(activeListing.createdAt) },
      // { icon: <CalendarMonthIcon />, label: getAvailableFrom(activeListing.availableFrom, activeListing.buildingType) },
      // { icon: <PlaceIcon />, label: labels.getAddress(activeListing.address) },
      // { icon: <PlaceIcon />, label: <GoogleStreetsView activeListing={activeListing} /> },
    ];
  }

  if (section === "owner-info") {
    details = [
      {
        icon: <PersonIcon />,
        label: (
          <div>
            Postat de{" "}
            <Button
              variant="text"
              sx={{
                p: 0,
              }}
              onClick={() => {
                dispatch(setOpenedModal("user-profile"));
                setAskNumber(true);
              }}
            >{`${activeListing.ownerFirstName} ${activeListing.ownerLastName}`}</Button>
          </div>
        ),
      },
      {
        icon: <PhoneIcon />,
        className: "owner-phone-number",
        label: (
          <>
            {ownerPhoneNumber ? (
              <StyledPhoneNumber href={`tel:${ownerPhoneNumber}`}>
                {formatPhoneNumber(ownerPhoneNumber)}
              </StyledPhoneNumber>
            ) : (
              "+0**"
            )}
            {!ownerPhoneNumber && (
              <StyledSeePhoneNumber variant="text" size="small" onClick={() => setAskNumber(true)}>
                Arată numărul
              </StyledSeePhoneNumber>
            )}
          </>
        ),
      },
    ];
  }

  if (section === "place-info") {
    details = [
      { icon: <ApartmentIcon />, label: getGenericLabel(activeListing.buildingType) },
      { icon: <WidgetsIcon />, label: getGenericLabel(activeListing.partitioningType) },
      {
        icon: <LivingIcon />,
        label: getGenericLabel(activeListing.rooms === "1" ? "room" : "rooms", activeListing.rooms),
      },
      {
        icon: <ShowerIcon />,
        label: getGenericLabel(activeListing.bathrooms === "1" ? "bathroom" : "bathrooms", activeListing.bathrooms),
      },
      { icon: <Crop54Icon />, label: getGenericLabel("surface", activeListing.surface) },
      { icon: <HeightIcon />, label: getFloors(activeListing.floor, activeListing.totalFloors) },
      { icon: <CalendarMonthIcon />, label: getGenericLabel("constructionYear", activeListing.constructionYear) },
    ];
  }

  if (section === "extra") {
    details = [
      { icon: <AcUnitIcon />, label: getGenericLabel("hasAirConditioning") },
      { icon: <BalconyIcon />, label: getGenericLabel("hasBalcony") },
      { icon: <ConnectedTvIcon />, label: getGenericLabel("hasCableTv") },
      { icon: <DryIcon />, label: getGenericLabel("hasClothesDryer") },
      { icon: <WaterIcon />, label: getGenericLabel("hasDiswasher") },
      { icon: <WindPowerIcon />, label: getGenericLabel("hasDryer") },
      { icon: <ElevatorIcon />, label: getGenericLabel("hasElevator") },
      { icon: <FireplaceIcon />, label: getGenericLabel("hasFireplace") },
      { icon: <KitchenIcon />, label: getGenericLabel("hasFridge") },
      { icon: <BedIcon />, label: getGenericLabel("hasFurniture") },
      { icon: <WarehouseIcon />, label: getGenericLabel("hasGarage") },
      { icon: <YardIcon />, label: getGenericLabel("hasGarden") },
      { icon: <WhatshotIcon />, label: getGenericLabel("hasHeating") },
      { icon: <WifiIcon />, label: getGenericLabel("hasInternet") },
      { icon: <MicrowaveIcon />, label: getGenericLabel("hasOven") },
      { icon: <PoolIcon />, label: getGenericLabel("hasPool") },
      { icon: <LocalParkingIcon />, label: getGenericLabel("hasPrivateParking") },
      { icon: <LocalParkingIcon />, label: getGenericLabel("hasStreetParking") },
      { icon: <SecurityIcon />, label: getGenericLabel("hasSecuritySystem") },
      { icon: <StorageIcon />, label: getGenericLabel("hasStorage") },
      { icon: <DeckIcon />, label: getGenericLabel("hasTerrace") },
      { icon: <TvIcon />, label: getGenericLabel("hasTv") },
      { icon: <VideoCameraBackIcon />, label: getGenericLabel("hasVideoSurveillance") },
      { icon: <LocalLaundryServiceIcon />, label: getGenericLabel("hasWasher") },
    ];
  }

  if (section === "description") {
    return (
      <>
        {/* <StyledHeader>{activeListing.address.streetName}</StyledHeader> */}
        {activeListing.description ? (
          <StyledMainDetails>
            <StyledHeader>{title}</StyledHeader>
            <div className="entry-text-and-label">{displayDescription(activeListing.description)}</div>
            {/* <div className="entry-text-and-label">{activeListing.description}</div> */}
          </StyledMainDetails>
        ) : null}
      </>
    );
  }

  details = details.filter((el) => el.label);

  return details.length > 0 ? (
    <StyledMainDetails>
      <StyledHeader>{title}</StyledHeader>
      <div className="details-container">
        {details.map((item, index) => (
          <div key={index} className={clsx("entry-text-and-label", { [item?.className || ""]: true })}>
            {item.icon}
            <div className="details-label">{item.label}</div>
          </div>
        ))}
      </div>
    </StyledMainDetails>
  ) : null;
};

const StyledMainDetails = styled(`div`)`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 100px;
  }

  & .details-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;

    & > :last-child {
      width: 100%;
    }

    ${theme.breakpoints.down("sm")} {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }

  & .entry-text-and-label {
    display: flex;
    align-items: center;
    line-height: 130%;
    letter-spacing: 0.5px;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.palette.grey[900]};

    & svg {
      color: ${theme.palette.grey[500]};
    }

    .details-label {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      margin-left: 4px;
    }

    &.price {
      // border: 2px solid ${theme.palette.secondary.main};
      // border-radius: 8px;
      // padding: 2px 4px;
      // color: ${theme.palette.secondary.main};
      // font-weight: 500;

      & svg {
        // color: ${theme.palette.secondary.main};
        // color: white;
      }
    }

    &.owner-phone-number {
      // background-color: green;
    }

    ${theme.breakpoints.down("sm")} {
      font-weight: 400;
      font-size: 13px;
    }
  }
`;

const StyledHeader = styled(`h1`)`
  width: 100%;
  font-weight: 500;
  font-size: 17px;
  color: ${theme.palette.grey[800]};
  margin: 0 0 6px;
  border-left: 3px solid ${theme.palette.primary.main};
  padding: 12px 0 12px 8px;
  background-color: ${theme.palette.grey[200]};

  &:first-of-type {
    margin-top: 0;
  }

  ${theme.breakpoints.down("sm")} {
    // font-size: 14px;
  }
`;

const StyledSeePhoneNumber = styled(Button)`
  color: ${theme.palette.primary.main};
  text-decoration: underline;
  text-transform: none;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
    font-weight: 500;
  }
`;

const StyledPhoneNumber = styled(`a`)`
  color: ${theme.palette.primary.main};
  text-decoration: underline;
`;
