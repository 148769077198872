import { styled } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import SettingsIcon from "@mui/icons-material/Settings";
import ImageGallery from "react-image-gallery";
import { useGetActiveListing } from "api/listings/hooks";
import { useAppParams } from "common/hooks/useAppParams";
import { useMarkListingsAsSeen } from "utils/hooks/useMarkListingsAsSeen";
import { useAppDispatch, useAppSelector, useBasePathUrl } from "common/hooks";
import { theme } from "theme";
import { setImagesFullscreen } from "redux/slices/appSlice";
import { ListingFavoriteAndEditButtons } from "components/listing-favorite-and-edit-buttons";
import { ListingDetailsElement } from "./components/ListingDetailsElement";
import { AddressAndCreateDate } from "./components/AddressAndCreateDate";

export const ListingDetails = () => {
  const { listingId } = useAppParams();
  const { sidebarOpen, imagesFullscreen } = useAppSelector(({ appSlice }) => appSlice);
  const { goToBasePath } = useBasePathUrl();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ authSlice }) => authSlice);
  const detailsRef = useRef<HTMLDivElement>(null);

  const isOwnListing = !!user?.ownListingsIds?.includes(listingId!);

  const { activeListing, isError } = useGetActiveListing();

  const { markListingAsSeen } = useMarkListingsAsSeen({ listingId });
  useEffect(() => {
    markListingAsSeen();
  }, [markListingAsSeen]);

  // Scroll element to top when listingId is changing
  useEffect(() => {
    if (listingId && detailsRef.current) {
      detailsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [listingId]);

  const [debouncedListing, setDebouncedListing] = useState(activeListing);
  const [isOpen, setIsOpen] = useState(!!activeListing);

  useEffect(() => {
    if (listingId) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      // Queue the re-addition of the sidebarOpen styles using requestAnimationFrame
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (listingId) setIsOpen(true);
        });
      });
    }
    return () => {
      setIsOpen(false);
    };
  }, [listingId]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (activeListing) {
      setDebouncedListing(activeListing);
    } else {
      timeoutId = setTimeout(() => {
        setDebouncedListing(undefined);
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeListing]);

  useEffect(() => {
    if (isError) {
      setDebouncedListing(undefined);
      goToBasePath({});
    }
  }, [isError]);

  if (!activeListing) return null;

  return (
    <StyledListingDetails className={clsx(isOpen && "open", sidebarOpen && "sidebar-open")} ref={detailsRef}>
      <div className="listing-details-container">
        <div className="image-gallery">
          <p className={clsx("own-listing-chip", { "own-listing": isOwnListing })}>
            <SettingsIcon />
            Editează anunțul
            {activeListing.isApproved === "false" && <span className="pending">Anunț în curs de aprobare...</span>}
          </p>
          <ImageGallery
            items={debouncedListing?.images?.map((img) => ({ original: img, thumbnail: img })) || []}
            infinite
            showFullscreenButton
            showPlayButton={false}
            showNav
            showBullets={debouncedListing?.images && debouncedListing.images.length > 1}
            showThumbnails={imagesFullscreen}
            onScreenChange={(isFullScreen) => {
              dispatch(setImagesFullscreen(isFullScreen));
            }}
          />
        </div>
        <StyledBottomSection
          className="description-area-container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // height: `calc(45% - 16px)`,
          }}
        >
          <AddressAndCreateDate activeListing={debouncedListing} />
          {!imagesFullscreen && (
            <ListingFavoriteAndEditButtons
              countyId={debouncedListing?.countyId}
              listingId={debouncedListing?.id}
              variant="details-card"
            />
          )}
          <div className="content">
            {[
              { title: "Detalii închiriere", section: "rental-info" as const },
              { title: "Detalii proprietate", section: "place-info" as const },
              { title: "Facilități și dotări suplimentare", section: "extra" as const },
              { title: "Contact", section: "owner-info" as const },
              { title: "Descriere", section: "description" as const },
            ].map((el) => (
              <ListingDetailsElement
                key={el.title}
                title={el.title}
                section={el.section}
                activeListing={activeListing}
              />
            ))}
          </div>
        </StyledBottomSection>{" "}
      </div>
    </StyledListingDetails>
  );
};

const StyledListingDetails = styled("div")`
  position: absolute;
  background-color: ${theme.palette.grey[50]};
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  transform: translateX(100%); // Hide the sidebar by moving it off-screen to the right
  z-index: 999;

  & .own-listing-chip {
    display: none;
    pointer-events: none;

    &.own-listing {
      display: flex;
      align-items: center;
      gap: 4px;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      top: -18px;
      border-radius: 8px 8px 0 0;
      width: 100%;
      padding: 18px 24px;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 500;
      z-index: 10;

      & .pending {
        background-color: ${theme.palette.warning.main};
        color: white;
        padding: 2px 4px;
        border-radius: 4px;
        font-size: 13px;
        margin-left: 8px;
      }

      ${theme.breakpoints.down("sm")} {
        padding: 12px 16px;
      }
    }
  }

  &.open {
    transform: translateX(0); // Bring the sidebar back on-screen
  }

  &.sidebar-open {
    // Do the transition only if the sidebar is open
    transition: transform 0.3s ease-in-out;
  }

  .listing-details-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;

    ${theme.breakpoints.down("sm")} {
      padding: 12px;
    }

    & .image-gallery {
      //   img {
      //     border-radius: 8px;

      //     ${theme.breakpoints.down("md")} {
      //       max-height: 60vh;
      //     }
      //   }

      & .image-gallery-content:not(.fullscreen) {
        //     background-color: ${theme.palette.grey[100]};

        img {
          height: 420px;
          //       object-fit: cover;
          //       object-position: center;
        }
      }

      //   & .image-gallery-thumbnails-wrapper {
      //     img {
      //       object-fit: contain;
      //       object-position: center;
      //       max-height: 55px;
      //     }
      //   }
    }
  }
`;

const StyledBottomSection = styled("div")`
  position: relative;

  & .content {
    position: relative;
    padding: 16px 0;
  }
`;
