import { Box } from "@mui/material";
import { styled } from "@mui/system";
import clsx from "clsx";
import { useAppParams } from "common/hooks/useAppParams";
import { useAppSelector } from "common/hooks";
import { boxShadows, theme } from "theme";
import { SidebarViewsToggleButtons } from "components/SidebarViewsToggleButtons";
import { ListingDetails } from "./ListingDetails/ListingDetails";
import { ListingsFeed } from "./ListingsFeed/ListingsFeed";
import { FiltersSidePanel } from "./FiltersSidePanel";

type SidePanelProps = {
  isMounted: boolean;
  isMobile: boolean;
};

export const SidePanel = (props: SidePanelProps) => {
  const { isMounted, isMobile } = props;
  const { countyId, view, isListingDetailsMode } = useAppParams();
  const { activeMenuView, sidebarOpen } = useAppSelector(({ appSlice }) => appSlice);

  const isFavoritesView = activeMenuView === "favorites";
  const isOwnListingsView = activeMenuView === "own-listings";
  const isFavoritesOrOwnListingsView = isFavoritesView || isOwnListingsView;

  const isOnCountryViewMode = countyId === "ro";
  // const canDisplaySidepanel = isMounted && isOnCountyViewMode;

  const isClosingTheDetailsPageButSidebarIsHiddenByUser = !sidebarOpen && view !== "details";
  const shouldHideSidebarEntirely =
    (!sidebarOpen && view !== "details") ||
    (isOnCountryViewMode && !isFavoritesOrOwnListingsView && !isListingDetailsMode) ||
    (isMobile && view === "map");

  // We used to need this check because of a bug, not sure if it's still needed
  if (!isMounted) return null;

  return (
    <StyledSidePanelContainer
      className={clsx(
        "side-panel-container",
        isClosingTheDetailsPageButSidebarIsHiddenByUser && "no-transition",
        shouldHideSidebarEntirely && "hide-sidebar"
      )}
    >
      {isMobile ? (
        <div className="side-panel-body">
          <ListingsFeed />
          <ListingDetails />
          <SidebarViewsToggleButtons />
        </div>
      ) : (
        <div className="side-panel-body">
          <ListingsFeed />
          <FiltersSidePanel />
          <ListingDetails />
          <SidebarViewsToggleButtons />
        </div>
      )}
    </StyledSidePanelContainer>
  );
};

const StyledSidePanelContainer = styled(Box)`
  position: relative;

  width: ${theme.custom.widths.sidebar.sm}px;
  min-width: ${theme.custom.widths.sidebar.sm}px;

  ${theme.breakpoints.up("md")} {
    width: ${theme.custom.widths.sidebar.md}px;
    min-width: ${theme.custom.widths.sidebar.md}px;
  }

  ${theme.breakpoints.up("lg")} {
    width: ${theme.custom.widths.sidebar.lg}px;
    min-width: ${theme.custom.widths.sidebar.lg}px;
  }

  ${theme.breakpoints.up("xl")} {
    width: ${theme.custom.widths.sidebar.xl}px;
    min-width: ${theme.custom.widths.sidebar.xl}px;
  }

  &.hide-sidebar {
    width: 0;
    min-width: unset;
  }

  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out, visibility 0.3s ease-in-out;

  .side-panel-body {
    position: relative;
    display: flex;
    margin: 0 0 0 auto;
    padding: 0;
    z-index: 2;
    height: 100%;
    background-color: ${theme.palette.background.paper};
    box-shadow: ${boxShadows.filters};
    background-color: white;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
    left: 0;
  }

  ${theme.breakpoints.down("md")} {
    position: absolute;
    width: 100%;
    min-width: unset;
    height: 100%;
    overflow-x: hidden;
    transition: none;

    .side-panel-body {
      position: absolute;
      width: 100%;
    }
  }
`;
