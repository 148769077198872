import { useAppDispatch, useAppSelector } from "common/hooks";
import { ConfirmationDialog } from "components/confirmation-dialog/confirmation-dialog";
import { useAddListingStepper } from "pages/AddListing/utils/useStepper";
import { setOpenedModal } from "redux/slices/appSlice";

export const AddListingSuccessModal = () => {
  const dispatch = useAppDispatch();
  const { onSubmitted } = useAddListingStepper();
  const { openedModal } = useAppSelector((state) => state.appSlice);

  const handleClose = () => {
    dispatch(setOpenedModal(null));
    onSubmitted();
  };

  return (
    <ConfirmationDialog
      title="Am primit anunțul tău!"
      variant="success"
      open={openedModal === "add-listing-success"}
      onPrimary={handleClose}
      onCancel={handleClose}
      onSecondary={handleClose}
      labelPrimary="Închide"
    >
      ...și îl vom publica în cel mai scurt timp. Dorim să ne asigurăm că totul este în regulă cu el. Vei fi anunțat
      prin email când va fi vizibil pe platformă.
    </ConfirmationDialog>
  );
};
