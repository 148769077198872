import TextField from "@mui/material/TextField";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { useRequestPasswordReset } from "api/user/hooks/useRequestPasswordReset";
import { SubmitButton } from "./SubmitButton";
import { useUpdateAuthInput } from "../utils/update-auth-input";
import { AuthFields } from "../utils/auth-fields";

interface Props {
  onCloseModal: () => void;
}

export const PasswordRecoverComponent = (props: Props) => {
  const { onCloseModal } = props;

  const { mutate: requestPasswordReset } = useRequestPasswordReset();
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();

  const { userInput: incomingUserInput, onInputChange, onFocusOut } = useUpdateAuthInput(["email"]);
  const userInput = incomingUserInput as AuthFields;

  const handleSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    addLoadingEl("auth");

    requestPasswordReset(
      { email: userInput.email.value },
      {
        onSuccess: () => {
          removeLoadingEl("auth");
          toast.success(
            `Am trimis un email cu instrucțiuni pentru resetarea parolei pe adresa de email ${userInput.email.value}.`
          );
          onCloseModal();
        },
        onError: (err: any) => {
          let errorMessage = "A apărut o eroare. Încearcă mai târziu.";
          if (err?.response?.status === 404) {
            errorMessage = "Emailul introdus nu există în baza noastră de date.";
          }

          removeLoadingEl("auth");
          toast.error(errorMessage);
        },
      }
    );
  };

  const isDisabled = !userInput.email.touched || userInput.email.error;

  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Adresă de email"
        name="email"
        autoComplete="email"
        autoFocus
        size="small"
        onChange={onInputChange}
        onBlur={onFocusOut}
        value={userInput.email.value}
        error={userInput.email.error}
        helperText={userInput.email.errorMessage}
      />
      <SubmitButton disabled={isDisabled} label="Trimite link de resetare parolă" onSubmit={handleSubmit} />
    </>
  );
};

export default PasswordRecoverComponent;
