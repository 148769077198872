import { useMutation } from "react-query";
import { z } from "zod";
import { useAppSelector } from "common/hooks";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import {
  AddListingFieldsType,
  BuildingTypeType,
  PartitioningType,
} from "pages/AddListing/add-listing-sections/sections-types";
import { ListingsApi } from "../listings-api";

const addListingBERequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  price: z.number(),
  construction_year: z.string(),
  floor: z.number(),
  total_floors: z.number(),
  number_of_rooms: z.number(),
  number_of_bathrooms: z.number(),
  surface: z.number(),
  partitioning_type: z.string(),
  available_from: z.string(),
  building_type: z.string(),
  deposit: z.number(),
  country_code: z.string(),
  country_name: z.string(),
  county_code: z.string(),
  county_name: z.string(),
  city_code: z.string(),
  city_name: z.string(),
  street_name: z.string(),
  street_number: z.string(),
  coordinates: z.object({
    latitude: z.string(),
    longitude: z.string(),
  }),
  owner_email: z.string(),
  owner_first_name: z.string(),
  owner_last_name: z.string(),
  owner_phone: z.string(),
  image_urls: z.array(z.string()),
  images_thumbnail_urls: z.array(z.string()),
  main_image_url: z.string(),
  has_air_conditioning: z.string(),
  has_elevator: z.string(),
  has_balcony: z.string(),
  has_terrace: z.string(),
  has_garden: z.string(),
  has_cable_tv: z.string(),
  has_clothes_dryer: z.string(),
  has_dishwasher: z.string(),
  has_dryer: z.string(),
  has_fireplace: z.string(),
  has_fridge: z.string(),
  has_furniture: z.string(),
  has_garage: z.string(),
  has_heating: z.string(),
  has_internet: z.string(),
  has_oven: z.string(),
  has_pool: z.string(),
  has_private_parking: z.string(),
  has_security_system: z.string(),
  has_storage: z.string(),
  has_street_parking: z.string(),
  has_tv: z.string(),
  has_video_surveillance: z.string(),
  has_washer: z.string(),
});

type FullListingBERequestType = z.infer<typeof addListingBERequestSchema>;

interface MutationProps {
  listing: ListingType;
}

export const useCreateListing = () => {
  const { token } = useAppSelector((state) => state.authSlice);
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();

  const { mutate, isLoading, isSuccess, isError } = useMutation("createListing", (mutationProps: MutationProps) => {
    const { listing } = mutationProps;

    addLoadingEl("create-listing");
    const result = ListingsApi.createListing(convertFEListingToBackendFormat(listing), token || "");

    removeLoadingEl("create-listing");

    return result;
  });

  return { mutate, isLoading, isSuccess, isError };
};

type ListingType = Record<AddListingFieldsType, string> & {
  images: string[];
  availableParkingLots: string;
  gardenSurface: string;
};

export const convertFEListingToBackendFormat = (listing: ListingType): FullListingBERequestType => {
  const buildingType =
    listing.house === "true" ? "house" : listing.villa === "true" ? "villa" : listing.block === "true" ? "block" : "";

  const partitioning =
    listing.detached === "true"
      ? "detached"
      : listing.semidetached === "true"
      ? "semidetached"
      : listing.ordered === "true"
      ? "ordered"
      : listing.studio === "true"
      ? "studio"
      : "";

  return {
    // Basic info
    title: `Anunț nou - ${listing.addressFull}`,
    description: listing.description,
    price: Number(listing.price),
    construction_year: listing.constructionYear,
    floor: Number(listing.floor),
    total_floors: Number(listing.totalFloors),
    number_of_rooms: Number(listing.rooms),
    number_of_bathrooms: Number(listing.bathrooms),
    surface: Number(listing.surface),
    partitioning_type: partitioning as PartitioningType,
    building_type: buildingType as BuildingTypeType,
    deposit: Number(listing.deposit),
    available_from: listing.availableFrom.substring(0, 10), // e.g. 2021-09-01T00:00:00.000Z -> 2021-09-01

    // Address
    country_code: listing.countryId,
    country_name: listing.countryName,
    county_code: listing.countyId,
    county_name: listing.countyName,
    city_code: listing.countyId,
    city_name: listing.cityName,
    street_name: listing.streetName,
    street_number: listing.streetNumber,
    coordinates: {
      latitude: listing.lat,
      longitude: listing.lng,
    },

    // Owner
    owner_email: listing.ownerEmail,
    owner_first_name: listing.ownerFirstName,
    owner_last_name: listing.ownerLastName,
    owner_phone: listing.ownerPhone,

    // Images
    image_urls: listing.images,
    images_thumbnail_urls: [],
    main_image_url: listing.images[0],

    // Extras
    has_air_conditioning: listing.hasAirConditioning,
    has_elevator: listing.hasElevator,
    has_balcony: listing.hasBalcony,
    has_terrace: listing.hasTerrace,
    has_garden: listing.hasGarden,
    has_cable_tv: listing.hasCableTv,
    has_clothes_dryer: listing.hasClothesDryer,
    has_dishwasher: listing.hasDishwasher,
    has_dryer: listing.hasDryer,
    has_fireplace: listing.hasFireplace,
    has_fridge: listing.hasFridge,
    has_furniture: listing.hasFurniture,
    has_garage: listing.hasGarage,
    has_heating: listing.hasHeating,
    has_internet: listing.hasInternet,
    has_oven: listing.hasOven,
    has_pool: listing.hasPool,
    has_private_parking: listing.hasPrivateParking,
    has_security_system: listing.hasSecuritySystem,
    has_storage: listing.hasStorage,
    has_street_parking: listing.hasStreetParking,
    has_tv: listing.hasTv,
    has_video_surveillance: listing.hasVideoSurveillance,
    has_washer: listing.hasWasher,
  };
};
