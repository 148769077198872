import { useMutation } from "react-query";
import { UserApi } from "api/user/user-api";
import { useAppSelector } from "common/hooks";

type MutationProps = {
  listingId: string;
};

export const useDeleteOwnListing = () => {
  const { user } = useAppSelector(({ authSlice }) => authSlice);

  const { mutate, isLoading, isSuccess, isError } = useMutation("deleteOwnListing", ({ listingId }: MutationProps) => {
    if (!user) {
      return Promise.reject();
    }

    return UserApi.deleteOwnListing(user.token, listingId);
  });

  return { mutate, isLoading, isSuccess, isError };
};
