import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import { usePasswordReset } from "api/user/hooks/usePasswordReset";
import { useAppParams } from "common/hooks/useAppParams";
import { PasswordTextField } from "components/TextFields/PasswordTextField";
import { SubmitButton } from "./SubmitButton";
import { useUpdateAuthInput } from "../utils/update-auth-input";
import { AuthFields } from "../utils/auth-fields";

interface Props {
  onCloseModal: () => void;
}

export const PasswordResetComponent = (props: Props) => {
  const { onCloseModal } = props;

  const { mutate: passwordReset } = usePasswordReset();
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();
  const { passwordResetToken, passwordResetUserId } = useAppParams();

  const {
    userInput: incomingUserInput,
    onInputChange,
    onFocusOut,
  } = useUpdateAuthInput(["password", "confirmPassword"]);
  const userInput = incomingUserInput as AuthFields;

  const handleSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!passwordResetToken || !passwordResetUserId) {
      onCloseModal();
      return;
    }

    addLoadingEl("auth");

    passwordReset(
      { password: userInput.password.value, token: passwordResetToken, userId: passwordResetUserId },
      {
        onSuccess: () => {
          removeLoadingEl("auth");
          toast.success("Parola a fost resetată cu succes.");
          onCloseModal();
        },
        onError: (err: any) => {
          let errorMessage = "A apărut o eroare. Încearcă mai târziu.";

          if (err?.response?.status === 404) {
            errorMessage = "Tokenul de resetare a parolei a expirat.";
          }

          toast.error(errorMessage);
          removeLoadingEl("auth");
          onCloseModal();
        },
      }
    );
  };

  const isDisabled = Object.values(userInput).some((input) => input.error || !input.touched);

  return (
    <>
      <PasswordTextField
        id="password"
        name="password"
        label="Parolă"
        onChange={onInputChange}
        onBlur={onFocusOut}
        value={userInput.password.value}
        error={userInput.password.error}
        helperText={userInput.password.errorMessage}
      />
      <PasswordTextField
        id="confirmPassword"
        name="confirmPassword"
        label="Repetă parola"
        onChange={onInputChange}
        onBlur={onFocusOut}
        value={userInput.confirmPassword.value}
        error={userInput.confirmPassword.error}
        helperText={userInput.confirmPassword.errorMessage}
      />
      <SubmitButton disabled={isDisabled} label="Salvează" onSubmit={handleSubmit} />
    </>
  );
};

export default PasswordResetComponent;
