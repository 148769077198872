export const sj = {
  sj_zalau: {
    id: "sj_zalau",
    lat: 47.18655142013071,
    lng: 23.057705966432014,
    zoom: 10,
    countyId: "sj",
    name: "Zalău",
    countyName: "Sălaj",
  },
};
