import { MouseEvent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import clsx from "clsx";
import { useScrollToTop } from "common/hooks/helpers";
import { AddListingPagePropsType } from "common/types/listing";
import { StyledListElWrapper } from "./styles";
import { MyIcon } from "../components/MyIcon";
import { AddListingTitle } from "../components/AddListingTitle";
import { updateLocalStorageWithUserInput, updateUserInputFromLocalStorage } from "../utils/hooks";
import { FieldTooltip } from "../components/FieldTooltip";
import { PartitioningType } from "./sections-types";
import { partitioningFields } from "./sections-data";

type FieldsType = {
  [K in PartitioningType]: {
    value: string;
    label: string;
    icon: string;
    tooltipContent: string;
  };
};

export const Partitioning = (
  props: AddListingPagePropsType & {
    onGoToNextPage: () => void;
  }
) => {
  const { onSetIsValid, onGoToNextPage } = props;

  useScrollToTop();

  const [userInput] = useState<FieldsType>(() => updateUserInputFromLocalStorage({ fields: partitioningFields }));

  useEffect(() => {
    onSetIsValid(Object.values(userInput).some((field) => field.value === "true"));
  }, [userInput, onSetIsValid]);

  const handleSelection = (event: MouseEvent<HTMLDivElement>) => {
    const { id } = event.currentTarget;

    const newInput = Object.entries(userInput).reduce((acc, [key, value]) => {
      const newValue = key === id ? "true" : "false";

      return {
        ...acc,
        [key as keyof FieldsType]: {
          ...value,
          value: newValue,
        },
      };
    }, {} as FieldsType);

    // Update the localStorage here, because there will be no re-render after this
    updateLocalStorageWithUserInput({ userInput: newInput });
    onSetIsValid(true);
    onGoToNextPage();
    return null;
  };

  return (
    <>
      <AddListingTitle
        title="Compartimentare"
        subtitle="Alege tipul apartamentului. Nu ești sigur ce să alegi? Atinge semnul info de lângă fiecare opțiune pentru a afla mai multe detalii."
      />
      {Object.entries(userInput).map(([key, { value, icon, label, tooltipContent }]) => {
        return (
          <StyledListElWrapper key={key}>
            <Box id={key} onClick={handleSelection} className={clsx("type-element", value === "true" ? "active" : "")}>
              <div className="icon-wrapper">
                <MyIcon icon={icon} />
              </div>
              {label}
            </Box>
            <FieldTooltip content={tooltipContent} />
          </StyledListElWrapper>
        );
      })}
    </>
  );
};
