import { useQuery } from "react-query";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import {
  basicListingBEResponseSchema,
  useProcessBasicListings,
} from "api/listings/hooks/helpers/process-basic-listings";
import { QueriesKeys } from "common/types/general";
import { ListingsApi } from "../../listings-api";
import { LIMITED_CACHING, logZodError } from "../../../utils";
import { useHandleApiError } from "../../../useHandleErrors";

export const useGetBasicListingsInCounty = ({ countyId = "", enabled = false }) => {
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["basicListingsInCounty" as QueriesKeys, countyId, enabled],
    async () => {
      addLoadingEl("basic-listings-in-county");
      const res = await ListingsApi.getBasicListingsInCounty({ countyId: countyId! });
      removeLoadingEl("basic-listings-in-county");

      const { data } = res.data;
      const parsed = basicListingBEResponseSchema.safeParse(data);

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return data;
      }

      return parsed.data;
    },
    {
      ...LIMITED_CACHING,
      enabled,
    }
  );

  const { processedListings } = useProcessBasicListings(data);

  // Handle errors
  useHandleApiError({ isError, message: "Nu s-au putut încărca anunțurile. Reîncarcă pagina și încearcă din nou." });

  return { data: processedListings, isLoading, isSuccess, isError };
};
