export const ph = {
  ph_ploiesti: {
    id: "ph_ploiesti",
    lat: 44.93849483129013,
    lng: 26.02190834063308,
    zoom: 10,
    countyId: "ph",
    name: "Ploiești",
    countyName: "Prahova",
  },
};
