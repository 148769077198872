import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks";
import { updateSeenListings } from "redux/slices";
import { updateLastSeenId } from "redux/slices/appSlice";

export const useMarkListingsAsSeen = ({
  isHovered,
  listingId,
  isBasicListing = true,
}: {
  isHovered?: boolean;
  listingId?: string;
  isBasicListing?: boolean;
}) => {
  const { seenListings, favoritesListingsIdsMap } = useAppSelector(({ listingsSlice }) => listingsSlice);

  const dispatch = useAppDispatch();
  const timerRef = useRef<undefined | ReturnType<typeof setTimeout>>(undefined);

  useEffect(() => {
    const seenListings = sessionStorage.getItem("seenListings")?.split(",") || [];
    dispatch(updateSeenListings({ seenListings }));
  }, []);

  // TODO: Wtf is here?
  useEffect(() => {
    if (isHovered && listingId && isBasicListing) {
      timerRef.current = setTimeout(() => {
        markListingAsSeen();
      }, 2000);
    } else {
      clearTimeout(timerRef.current);
    }
    return () => {
      dispatch(updateLastSeenId(listingId));
      clearTimeout(timerRef.current);
    };
  }, [isBasicListing, isHovered, listingId]);

  const markListingAsSeen = useCallback(() => {
    if (!listingId || seenListings[listingId] || favoritesListingsIdsMap[listingId]) return;

    const seenListingsCopy = Object.keys(seenListings);
    seenListingsCopy.push(String(listingId));
    sessionStorage.setItem("seenListings", seenListingsCopy.filter((item) => item).join(","));
    dispatch(updateSeenListings({ seenListings: seenListingsCopy }));
  }, [listingId, seenListings]);

  return { markListingAsSeen };
};
