import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useWindowDimensions } from "common/hooks";
import { theme } from "theme";
import { LoadingBar } from "components/LoadingBar";
import { NAVBAR_HEIGHT } from "utils/constants";
import { StepperComponent } from "./components/Stepper";
import { DividerComponent } from "./components/Divider";

interface Props {
  activeStep: number;
  currentPage: number;
  onStepperReset: () => void;
  onShowConfirmationDialog: () => void;
}

export const AddListingHeader = (props: Props) => {
  const { activeStep, currentPage, onStepperReset, onShowConfirmationDialog } = props;

  const { isMobile } = useWindowDimensions();

  // Close modal on Escape (not needed for now, as it conflicts with other key listeners)
  // useKeyListener( "Escape",handleClose);

  const handleClose = () => {
    // If barely no data added so far, close modal directly
    const localStorageData = JSON.parse(localStorage.getItem("listingObj") || "{}");
    if (Object.keys(localStorageData).length <= 2) {
      onStepperReset();
      return;
    }

    // Otherwise, show confirmation dialog
    onShowConfirmationDialog();
  };

  return (
    <>
      <Stack
        sx={{ width: "100%", overflowX: "auto" }}
        style={{
          height: isMobile ? `${NAVBAR_HEIGHT.MOBILE}px` : `${NAVBAR_HEIGHT.DESKTOP}px`,
          margin: "auto 0",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <StepperComponent
          activeStep={activeStep}
          currentPage={currentPage}
          // handleStepperClick={isValid ? handleStepperClick : () => null}
        />
      </Stack>
      <LoadingBar />
      <DividerComponent />
      <div style={{ width: "100%" }}>
        <div
          style={{
            // padding: "0 20px",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            backgroundColor: theme.palette.grey[100],
          }}
        >
          <IconButton
            style={{
              position: "relative",
              margin: isMobile ? "0 10px 0 auto" : "20px 30px 0 auto",
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: "gray" }} />
          </IconButton>
        </div>
      </div>
    </>
  );
};
