import { createTheme } from "@mui/material/styles";

type WidthsValuesT = {
  xs?: string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
};

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      widths: {
        navbar: WidthsValuesT;
        priceInfo: WidthsValuesT;
        sidebar: WidthsValuesT;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      widths?: {
        navbar?: WidthsValuesT;
        priceInfo?: WidthsValuesT;
        sidebar?: WidthsValuesT;
      };
    };
  }
}

export const colors = {
  brand: {
    blue: "#2C607D",
    blueDark: "#1C607D",
    blueLight: "#86BBD8",
    orange: "#E88117",
    orangeDark: "#ab5f13",
    orangeLight: "#f7a859",
  },
  misc: {
    red: "#FF0000",
    redError: "#960202",
  },
  neutral: {
    gray0: "#000000",
    gray1: "#333333",
    gray2: "#4F4F4F",
    gray3: "#828282",
    gray4: "#BDBDBD",
    gray5: "#E0E0E0",
    gray6: "#F2F2F2",
    gray7: "#FFFFFF",
  },
};

// TODO add these to mixins and type override
export const boxShadows = {
  filters: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
  toolbar: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
  sidebar: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
  cards: "0px 0px 10px 0px rgba(0,0,0,0.2)",
  mapButtons: "0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28)",
  topOnly: "0 -10px 10px -10px rgba(0, 0, 0, 0.2)",
};

export const transitions = {
  default: "all 0.3s ease-in",
};

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.brand.blue,
      // dark: colors.brand.blueDark,
      // light: colors.brand.blueLight,
    },
    secondary: {
      main: colors.brand.orange,
      dark: colors.brand.orangeDark,
    },
    background: {
      default: colors.neutral.gray7,
      paper: colors.neutral.gray6,
    },
    text: {
      primary: colors.neutral.gray2,
      disabled: colors.neutral.gray2,
      secondary: colors.neutral.gray3,
    },
    success: {
      main: colors.brand.blue,
    },
    error: {
      main: colors.misc.red,
      dark: colors.misc.redError,
    },
    // info: {
    //   main: colors.neutral.gray3,
    //   dark: colors.neutral.gray1,
    // },
  },
  typography: {
    // https://codebeautify.org/rem-to-px-converter
    fontFamily: "Roboto, sans-serif",
    body1: {
      fontSize: "0.9375rem", // 15px
      lineHeight: "1.125rem", // 18px
    },
    body2: {
      fontSize: "0.75rem", // 12px
      lineHeight: "0.9375rem", // 15px
      letterSpacing: "0.5px",
      fontWeight: "500",
    },
    button: {
      fontSize: "1.125rem", // 18px
      lineHeight: "1.35rem", // 21.6px
    },
  },
  spacing: 4,
  components: {
    MuiButtonBase: {
      defaultProps: {
        // disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        // This applies to all button variants
        root: {
          textTransform: "none",
          // fontWeight: "normal",
        },
        outlined: {},
        contained: {
          color: colors.neutral.gray7,
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: colors.brand.orange,
          },
        },
      },
      defaultProps: {
        size: "large", // all buttons will have 'large' size by default
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          //   backgroundColor: "rgba(0,0,0,0.7)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // root: {
        //   // Make helper text absolute
        //   "& .MuiFormHelperText-root": {
        //     margin: "0 0 -1.5rem 0",
        //     padding: 0,
        //     lineHeight: "1rem",
        //   },
        // },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          // backgroundColor: bgInputColor,
          "&:hover": {
            // backgroundColor: "red",
          },
          "&.Mui-focused": {
            // backgroundColor: "blue",
          },
          "&.Mui-error": {
            // backgroundColor: "green",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          // backgroundColor: "#f9f9f9",
          "&:hover": {
            // backgroundColor: "red",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          fontWeight: 400,
          color: colors.neutral.gray2,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "16px",
          fontWeight: 400,
          color: colors.neutral.gray7,
          backgroundColor: colors.neutral.gray1,
          padding: "12px 16px",
          lineHeight: "1.5rem",
        },
      },
    },
  },
  breakpoints: {
    // default
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  custom: {
    widths: {
      navbar: {
        xs: "100%",
        sm: 350,
        md: 480,
        lg: 480,
        xl: 720,
      },
      priceInfo: {
        xs: "100%",
        sm: "100%",
        md: 480,
        lg: 480,
        xl: 720,
      },
      sidebar: {
        xs: "0",
        sm: "0",
        md: 650,
        lg: 750, // 850,
        xl: 800, // 900,
      },
    },
  },
});
