import { styled } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import clsx from "clsx";
import { theme } from "theme";
import { useWindowDimensions } from "common/hooks";
import { ListingTypeDropdown } from "./listing-type-dropdown";
import { MobileMenuButton, StyledDesktopMenuItem, StyledText } from "./left-menu";
import { LanguageDropdown } from "./language-dropdown";
import { CurrencyDropdown } from "./currency-dropdown";

type OpenedMenu = "listing-type" | "currency" | "language" | "none";

export const RightMenu = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [openedMenu, setOpenedMenu] = useState<OpenedMenu>("none");

  const { isTablet } = useWindowDimensions();

  const handleOpenMenu = (event: any, type = "none" as OpenedMenu) => {
    if (anchorElNav) {
      // If already open, close it
      handleCloseMenu();
      return;
    }

    // Open dropdown
    setAnchorElNav(event.currentTarget);
    setOpenedMenu(type);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
    setOpenedMenu("none");
  };

  if (isTablet) {
    return (
      <StyledRightMenuMobileContainer style={{ display: "none" }}>
        <MobileMenuButton
          active={String(openedMenu === "listing-type")}
          className={clsx("menu-item-listing-type", { opened: openedMenu === "listing-type" })}
          onMenuButtonClick={(event) => handleOpenMenu(event, "listing-type")}
        >
          <StyledText>
            🔑
            <KeyboardArrowDownIcon />
          </StyledText>
        </MobileMenuButton>
        <MobileMenuButton
          active={String(openedMenu === "language")}
          className={clsx("menu-item-language", { opened: openedMenu === "language" })}
          onMenuButtonClick={(event) => handleOpenMenu(event, "language")}
        >
          <StyledText>
            🇷🇴
            <KeyboardArrowDownIcon />
          </StyledText>
        </MobileMenuButton>
        <MobileMenuButton
          active={String(openedMenu === "currency")}
          className={clsx("menu-item-currency", { opened: openedMenu === "currency" })}
          onMenuButtonClick={(event) => handleOpenMenu(event, "currency")}
        >
          <StyledText>
            💶
            <KeyboardArrowDownIcon />
          </StyledText>
        </MobileMenuButton>
        {openedMenu === "listing-type" && (
          <ListingTypeDropdown
            anchorElNav={anchorElNav}
            selectedItem="rent"
            onCancel={handleCloseMenu}
            onSelectNewType={() => {}}
          />
        )}
        {openedMenu === "language" && (
          <LanguageDropdown
            anchorElNav={anchorElNav}
            selectedItem="ro"
            onCancel={handleCloseMenu}
            onSelectNewType={() => {}}
          />
        )}
        {openedMenu === "currency" && (
          <CurrencyDropdown
            anchorElNav={anchorElNav}
            selectedItem="eur"
            onCancel={handleCloseMenu}
            onSelectNewType={() => {}}
          />
        )}
      </StyledRightMenuMobileContainer>
    );
  }

  return (
    <StyledRightMenuDestkopContainer>
      <StyledDesktopMenuItem
        className="menu-item-listing-type"
        key="right-menu-listing-type"
        onClick={(event) => handleOpenMenu(event, "listing-type")}
        active-btn={String(openedMenu === "listing-type")}
        tabIndex={0}
      >
        <StyledText>
          🔑 Închirieri
          <KeyboardArrowDownIcon />
        </StyledText>
      </StyledDesktopMenuItem>
      <StyledDesktopMenuItem
        className="menu-item-language"
        key="right-menu-language"
        onClick={(event) => handleOpenMenu(event, "language")}
        active-btn={String(openedMenu === "language")}
        tabIndex={0}
      >
        <StyledText>
          🇷🇴 Română <KeyboardArrowDownIcon />
        </StyledText>
      </StyledDesktopMenuItem>
      <StyledDesktopMenuItem
        className="menu-item-currency"
        key="right-menu-currency"
        onClick={(event) => handleOpenMenu(event, "currency")}
        active-btn={String(openedMenu === "currency")}
        tabIndex={0}
      >
        <StyledText>
          💶 EUR <KeyboardArrowDownIcon />
        </StyledText>
      </StyledDesktopMenuItem>
      {openedMenu === "listing-type" && (
        <ListingTypeDropdown
          anchorElNav={anchorElNav}
          selectedItem="rent"
          onCancel={handleCloseMenu}
          onSelectNewType={() => {}}
        />
      )}
      {openedMenu === "language" && (
        <LanguageDropdown
          anchorElNav={anchorElNav}
          selectedItem="ro"
          onCancel={handleCloseMenu}
          onSelectNewType={() => {}}
        />
      )}
      {openedMenu === "currency" && (
        <CurrencyDropdown
          anchorElNav={anchorElNav}
          selectedItem="eur"
          onCancel={handleCloseMenu}
          onSelectNewType={() => {}}
        />
      )}
    </StyledRightMenuDestkopContainer>
  );
};

const StyledRightMenuMobileContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin: 0 4px;
  gap: 4px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    white-space: nowrap;
    color: ${theme.palette.grey[600]};
    background-color: transparent;
    height: 35px;
    width: 42px;

    > p {
      margin: 0;
      padding: 2px;

      svg {
        color: ${theme.palette.grey[600]};
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
      }
    }

    &.opened {
      p {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  ${theme.breakpoints.down("md")} {
    gap: 4px;
    margin: 0 4px;

    > button > p {
    }
  }
`;

const StyledRightMenuDestkopContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin: 0 16px;
  gap: 16px;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    white-space: nowrap;
    color: ${theme.palette.grey[600]};
    background-color: transparent;

    svg {
      color: ${theme.palette.grey[600]};
      transform: rotate(0deg);
      transition: transform 0.2s ease-in-out;
    }

    &[active-btn="true"] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;
