import { ReactNode, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Fab, IconButton, Tooltip, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { toast } from "react-toastify";
import clsx from "clsx";
import { boxShadows, theme } from "theme";
import { useScrollToTop } from "common/hooks/helpers";
import { AddListingTitle } from "pages/AddListing/components/AddListingTitle";
import { AddListingPagePropsType } from "common/types/listing";

import "cropperjs/dist/cropper.css";
import { NEUTRAL_RED } from "utils/constants";
import { ImageFile } from "../sections-types";

const MAX_IMAGES_NUMBER = 10;

const pageTitle = "Fotografii proprietate";
const pageBeforeSubtitle = "Adaugă imagini pentru a-ți face anunțul mai atractiv.";
const pageAfterSubtitle =
  "Pentru imaginea principală, e recomandat să folosești o imagine cu orientare orizontală (landscape).";

export const Images = ({
  images,
  onSetIsValid,
  onSetImages,
}: AddListingPagePropsType & {
  images: ImageFile[];
  onSetIsValid: (isValid: boolean) => void;
  onSetImages: (images: ImageFile[]) => void;
}) => {
  useScrollToTop();

  // Handle image upload with react-dropzone
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true, // Disable click and keydown behavior
    noKeyboard: true,
    accept: {
      "image/*": [],
    },
    maxSize: 10_000_000, // 10MB max size
    onDrop: async (acceptedFiles: File[]) => {
      const filesAllowed = MAX_IMAGES_NUMBER - images.length;

      if (images.length + acceptedFiles.length > MAX_IMAGES_NUMBER) {
        toast.warning(
          `Ai atins numărul maxim de ${MAX_IMAGES_NUMBER} imagini permise. ${
            filesAllowed === 1
              ? "Doar prima imagine poate fi încărcată."
              : filesAllowed > 0
              ? `Doar primele ${filesAllowed} imagini pot fi încărcate.`
              : "Nu mai poți încărca alte imagini."
          }`
        );
      }

      onSetImages(
        [
          ...images,
          ...acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ].slice(0, 10)
      );
    },
  });

  // Set isValid to true if there is at least one image
  useEffect(() => {
    onSetIsValid(images.length > 0);
  }, [images, onSetIsValid]);

  // Handle image deletion
  const handleDelete = (fileIndex: any) => {
    onSetImages(images.filter((file, index) => index !== fileIndex));
  };

  // Handle image promotion (1st image is the main one)
  const handlePromotion = (fileIndex: any) => {
    const newFiles = [...images];
    [newFiles[0], newFiles[fileIndex]] = [newFiles[fileIndex], newFiles[0]];

    onSetImages(newFiles);
  };

  return (
    <>
      <AddListingTitle title={pageTitle} subtitle={images.length === 0 ? pageBeforeSubtitle : pageAfterSubtitle} />

      <StyledContainer>
        {images.length > 0 && <span className="image-label">Imagine principală</span>}
        <StyledUploadBoxWrapper className={clsx("main-image-box-container", images.length > 0 && "has-entries")}>
          {images.length === 0 ? (
            <UploadIconButton
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              onOpen={open}
              className="dropzone"
              more={false}
            >
              <p className="top-text">
                Trage și eliberează fișierele în această zonă sau apasă pe butonul de încărcare de mai jos.
              </p>
            </UploadIconButton>
          ) : (
            <div className="thumb-main-image" key={images[0].path}>
              <Tooltip title="Șterge" placement="top">
                <IconButton aria-label="delete" size="small" className="close-button" onClick={() => handleDelete(0)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <img
                className="thumb-image"
                alt={images[0].preview}
                src={images[0].preview}
                // Revoke data uri after image is loaded
                // (Good to know, but can't do this because it will hide the image on promotion)
                onLoad={() => {
                  // URL.revokeObjectURL(images[0].preview);
                }}
              />
            </div>
          )}
        </StyledUploadBoxWrapper>

        {images.length > 0 && (
          <>
            <span className="image-label">Imagini secundare</span>
            <StyledThumbsContainer className="secondary-images-box-container">
              {images.slice(1).map((file: any, index: number) => (
                <div className="thumb-image-container" key={file.name}>
                  <Tooltip title="Șterge" placement="top" enterTouchDelay={0}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      className="close-button"
                      onClick={() => handleDelete(index + 1)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Alege-o ca imagine principală" placement="top" enterTouchDelay={0}>
                    <IconButton
                      aria-label="promote"
                      size="small"
                      className="promote-button"
                      onClick={() => handlePromotion(index + 1)}
                    >
                      <ArrowCircleUpIcon />
                    </IconButton>
                  </Tooltip>
                  <img alt={file.preview} src={file.preview} />
                </div>
              ))}
              <UploadIconButton
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                onOpen={open}
                className={clsx(
                  "thumb-image-add-new",
                  images.length === 1 && "only-main-image",
                  images.length === MAX_IMAGES_NUMBER && "disabled"
                )}
                disabled={images.length === MAX_IMAGES_NUMBER}
                more
              />
              {/* <div {...getRootProps({ className: "thumb-image-add-new" })}>
                <input {...getInputProps()} />
                <Fab color="primary" aria-label="add" onClick={open} disabled={images.length === MAX_IMAGES_NUMBER}>
                  <FileUploadIcon />
                </Fab>
                {images.length === MAX_IMAGES_NUMBER && <span>{`Maxim ${MAX_IMAGES_NUMBER} imagini`}</span>}
              </div> */}
            </StyledThumbsContainer>
          </>
        )}
      </StyledContainer>
    </>
  );
};

interface UploadIconButtonProps {
  className: string;
  getRootProps: any;
  getInputProps: any;
  onOpen: any;
  children?: ReactNode;
  disabled?: boolean;
  more: boolean;
}

const UploadIconButton = ({
  className,
  getRootProps,
  getInputProps,
  onOpen,
  children,
  disabled,
  more,
}: UploadIconButtonProps) => (
  <div {...getRootProps({ className })}>
    {children}
    <input {...getInputProps()} />
    <Fab color="primary" aria-label="add" onClick={onOpen} disabled={disabled}>
      <FileUploadIcon />
    </Fab>
    <span>{more ? "Adaugă mai multe" : "Încarcă imagini"}</span>
    {disabled && <p>{`Maxim ${MAX_IMAGES_NUMBER} imagini`}</p>}
  </div>
);

const StyledContainer = styled("div")`
  max-width: 800px;
  margin: 0 40px 200px;

  ${theme.breakpoints.down("md")} {
    width: 100%;
  }

  & .image-label {
    font-size: 14px;
    color: ${theme.palette.grey[600]};
  }

  .close-button,
  .promote-button {
    position: absolute;
    right: -14px;
    top: -14px;
    color: ${theme.palette.grey[400]};

    &:hover {
      color: ${theme.palette.secondary.main};
    }
  }

  .promote-button {
    &:hover {
      color: ${theme.palette.secondary.main};
    }
  }
`;

const StyledUploadBoxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  border: none;
  height: auto;
  min-width: 150px;
  margin: 0 24px 16px;

  &.has-entries {
    margin: 0 0 16px;
  }

  & .dropzone {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px 20px 40px;
    border: 1px dashed ${theme.palette.grey[400]};

    > span {
      color: ${theme.palette.grey[600]};
      padding: 6px;
      font-size: 12px;
    }

    & .top-text {
      text-align: center;
      color: ${theme.palette.grey[700]};
      font-size: 14px;
      padding-bottom: 16px;
    }

    & .fab-label {
      text-align: center;
      color: ${theme.palette.grey[700]};
      font-size: 12px;
    }

    button {
      margin-top: 12px;
      background-color: ${theme.palette.primary.main};

      &:hover {
        background-color: ${theme.palette.primary.light};
      }
    }
  }

  & .thumb-main-image {
    display: flex;
    justify-content: center;
    border-radius: 2px;
    margin: 8px;
    flex-grow: 1;
    height: auto;
    width: 100%;
    overflow: visible;

    .close-button {
      right: 0;
      top: 0;
      color: ${NEUTRAL_RED};
      background-color: ${theme.palette.grey[100]};
      border: 1px solid lightgrey;
      padding: 2px;
    }
  }

  & .thumb-image {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    box-shadow: ${boxShadows.cards};
    border: 2px solid white;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
  }
`;

export const StyledThumbsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;

  & .thumb-image-container {
    height: 100px;
    width: auto;
    position: relative;
    margin-top: 10px;
    box-shadow: ${boxShadows.cards};
    border: 2px solid white;
    border-radius: 8px;

    .close-button {
      color: ${NEUTRAL_RED};
      background-color: ${theme.palette.grey[100]};
      border: 1px solid lightgrey;
      padding: 2px;
    }

    & .promote-button {
      right: 18px;
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.grey[100]};
      border: 1px solid lightgrey;
      padding: 2px;
    }

    > img {
      border-radius: 8px;
      display: inline-block;
      width: auto;
      height: 100%;
    }

    margin: 12px 24px 24px 0;

    &:last-child {
      margin-right: 0;
    }
  }

  & .thumb-image-add-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 12px;
    gap: 8px;

    > span {
      color: ${theme.palette.grey[600]};
      padding: 6px;
      font-size: 12px;
    }

    > p {
      color: ${NEUTRAL_RED};
      font-size: 13px;
      padding: 0;
      margin: 0;
    }

    &.only-main-image {
      margin: auto;
      width: 150px;
      height: 150px;

      > span {
        display: block;
      }
    }

    &.disabled {
      > span {
        display: none;
      }
    }
  }
`;
