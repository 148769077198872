import { useState, useEffect } from "react";
import { theme } from "theme";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [{ height, width }, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    height,
    width,
    isXs: width < 450,
    isMobile: width <= theme.breakpoints.values.md,
    isTablet: width <= theme.breakpoints.values.lg,
  };
};
