import { useState } from "react";
import { useScrollToTop } from "common/hooks/helpers";
import ReactQuill from "react-quill";
import { AddListingPagePropsType } from "common/types/listing";
import styled from "@emotion/styled";
import {
  updateUserInputFromLocalStorage,
  useUpdateLocalStorageWithUserInput,
  useValidateUserInput,
} from "../utils/hooks";
import { AddListingTitle } from "../components/AddListingTitle";
import { StyledAddListingWrapper } from "./styles";
import { DescriptionFieldsType } from "./sections-types";
import { descriptionFields } from "./sections-data";

type FieldsType = {
  [K in DescriptionFieldsType]: {
    value: string;
    label: string;
    placeholder: string;
    required: boolean;
    validator: (value: string) => { error: boolean; errorMessage: string };
    options?: { label: string; value: number }[];
    touched?: boolean;
    error?: boolean;
    errorMessage?: string;
    // Type of Mui TextField inputProps.inputMode
    type: "text" | "number";
    skip?: boolean;
  };
};

export const Description = (props: AddListingPagePropsType) => {
  const { onSetIsValid } = props;

  useScrollToTop();

  const [userInput, setUserInput] = useState<FieldsType>(() =>
    updateUserInputFromLocalStorage({ fields: descriptionFields })
  );
  useUpdateLocalStorageWithUserInput({ userInput });
  useValidateUserInput({ userInput, onSetIsValid });

  const handleInputChange = (content: string) => {
    setUserInput((oldInput) => {
      const newInput = {
        ...oldInput,
        description: {
          ...oldInput.description,
          value: content,
          touched: true,
          error: false,
          errorMessage: "",
        },
      };
      return newInput;
    });
  };

  return (
    <>
      <AddListingTitle
        title="Descriere"
        subtitle="Adaugă o descriere a proprietății, dacă sunt lucruri pe care nu le-ai putut menționa în secțiunile anterioare. (opțional)"
      />
      <StyledAddListingWrapper>
        <RichEditorContainer>
          <ReactQuill value={userInput.description.value} onChange={handleInputChange} className="rich-editor" />
        </RichEditorContainer>
      </StyledAddListingWrapper>
    </>
  );
};

const RichEditorContainer = styled.div`
  // width: 100%;
  // min-width: 100%;
  // max-width: 100%;

  & .rich-editor {
    background-color: #fff;
    max-width: 90vw;
    min-width: 60vw;

    .ql-container {
      .ql-editor {
        min-height: 200px;
      }
    }
  }
`;
