import { memo } from "react";
import HouseIcon from "@mui/icons-material/House";
import { styled } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import clsx from "clsx";
import { CountiesMetadataType, ListingsClusterType } from "common/types/listing";
import { useAppSelector, useBasePathUrl } from "common/hooks";
import { BasicListingType } from "api/listings/listings-types";
import { NEUTRAL_BLUE, NEUTRAL_RED } from "utils/constants";
import { theme } from "theme";

// Do this for TS to allow the passing of lat and lng to the Marker div
declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    lat?: number;
    lng?: number;
  }
}

type MarkerProps = {
  address?: string;
  isMetadata?: boolean;
  isCluster?: boolean;
  isClusterSeen?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  lat: number;
  // eslint-disable-next-line react/no-unused-prop-types
  lng: number;
  listing: Partial<BasicListingType & ListingsClusterType & CountiesMetadataType>;
  onClusterClick?: () => void;
};

// Use memo to prevent reloading marker on every render
export const MarkerClusterAndMetadata = memo((props: MarkerProps) => {
  const {
    address: _address,
    listing,
    isCluster = false,
    isClusterSeen = false,
    isMetadata = false,
    onClusterClick,
  } = props;

  const { activeMenuView } = useAppSelector(({ appSlice }) => appSlice);
  const isFavoritesView = activeMenuView === "favorites";
  const isOwnListingsView = activeMenuView === "own-listings";

  const { goToCountyCenter } = useBasePathUrl();

  const handleMarkerClick = (_event: any) => {
    if (isMetadata) {
      goToCountyCenter(listing?.countyId || listing.code);
    }
    if (isCluster) {
      onClusterClick?.();
    }
  };

  const listingCount = listing?.count || 0;
  const shortCount = getShortCount(listingCount);
  const listingsLength = String(shortCount).length;

  return (
    <StyledClusterMarkerWrapper
      className={clsx({ metadata: isMetadata, "large-number": listingsLength > 3, "seen-cluster": isClusterSeen })}
      onClick={handleMarkerClick}
      style={{
        width: `calc(30px + ${listingsLength * 3}px)`,
        height: `calc(30px + ${listingsLength * 3}px)`,
      }}
      data-testid="cluster-marker"
    >
      {listing?.count && (
        <div className="marker-content">
          {isFavoritesView ? (
            <FavoriteIcon
              fontSize={listingsLength > 3 ? "medium" : "small"}
              style={{
                color: NEUTRAL_RED,
              }}
            />
          ) : isOwnListingsView ? (
            <RoomPreferencesIcon
              fontSize={listingsLength > 3 ? "medium" : "small"}
              style={{
                color: theme.palette.secondary.main,
              }}
            />
          ) : (
            <HouseIcon fontSize={listingsLength > 3 ? "medium" : "small"} />
          )}
          {isMetadata && listing.code && <span className="county-name">{listing.code.toLocaleUpperCase()}</span>}
          <span className="count">{shortCount}</span>
        </div>
      )}
    </StyledClusterMarkerWrapper>
  );
});

const getShortCount = (count: number) => {
  // Get 1K for 1000, 1M for 1000000, etc.
  if (count < 1000) {
    return count.toLocaleString();
  }

  return `${(count / 1000).toFixed(0)}K`;
};

const StyledClusterMarkerWrapper = styled("div")`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
  box-shadow: ${theme.shadows[12]};
  color: ${theme.palette.grey[800]};
  background: linear-gradient(to bottom, white, lightgray);

  // border: 1px solid rgba(44, 96, 125, 0.1);
  border-opacity: 0.5;
  padding: 5px;
  text-align: center;
  margin-top: -20px;
  margin-left: -10px;
  font-weight: 600;

  svg {
    color: ${theme.palette.secondary.main};
  }

  &.seen-cluster {
    color: ${NEUTRAL_BLUE};

    svg {
      color: ${NEUTRAL_BLUE};
    }
  }

  .marker-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    font-size: 11px;

    & .county-name {
      color: ${theme.palette.grey[500]};
      font-size: 10px;
    }
  }

  &:hover {
    filter: brightness(1.1);
  }

  &.large-number {
    .marker-content {
      margin-bottom: 10px;
    }
  }

  &.metadata {
    background: linear-gradient(to bottom, white, lightgray);

    padding: 9px;

    &.large-number {
      padding: 15px;
    }
  }
`;
