export const vs = {
  vs_vaslui: {
    id: "vs_vaslui",
    lat: 46.63690647220055,
    lng: 27.72734637128651,
    zoom: 10,
    countyId: "vs",
    name: "Vaslui",
    countyName: "Vaslui",
  },
};
