export const br = {
  br_braila: {
    id: "br_braila",
    lat: 45.27419886019155,
    lng: 27.964407585156906,
    zoom: 10,
    countyId: "br",
    name: "Brăila",
    countyName: "Brăila",
  },
};
