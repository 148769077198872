import { styled } from "@mui/system";
import { theme } from "theme";

// This and StyledListElWrapper are very similar, consider merging them
export const StyledAddListingWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 400px;
  box-sizing: border-box;
  gap: 20px;
  height: 100%;
  overflow: auto;
  padding: 8px 0;

  ${theme.breakpoints.down("md")} {
    width: 100%;
    min-width: 0;

    .type-element {
      .icon-wrapper {
        width: 60px;
        height: 60px;

        img {
          max-width: 25px;
          max-height: 25px;
        }
      }
    }
  }
`;

export const StyledListElWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 400px;
  box-sizing: border-box;
  gap: 10px;

  .type-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 4px 12px 4px 8px;
    border-radius: 5px;
    background-color: white;
    width: 100%;

    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: ${theme.palette.grey[300]};
      cursor: pointer;
    }

    &.active {
      background-color: ${theme.palette.grey[400]};
    }

    .icon-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 70px;

      img {
        max-width: 45px;
        max-height: 45px;
      }
    }
  }

  ${theme.breakpoints.down("md")} {
    width: 100%;
    min-width: 0;

    .type-element {
      .icon-wrapper {
        width: 60px;
        height: 60px;

        img {
          max-width: 25px;
          max-height: 25px;
        }
      }
    }
  }
`;
