import { ComponentProps, ReactElement, ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { toast } from "react-toastify";
import BuildIcon from "@mui/icons-material/Build";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Badge, MenuItem, Skeleton, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector, useBasePathUrl, useWindowDimensions } from "common/hooks";
import { useAuthModal } from "common/hooks/useAuthModal";
import { logout } from "redux/slices/authSlice";
import { theme } from "theme";
import { ICON_BTN_PADDING_MOBILE } from "utils/constants";
import { useBELogout } from "api/user/hooks/useBELogout";
import { useAddListingStepper } from "pages/AddListing/utils/useStepper";
import { useIsAdminUser } from "utils/hooks/useIsAdminUser";
import { setOpenedModal, setSidebarOpen, updateActiveMenuView } from "redux/slices/appSlice";
import { useAppParams } from "common/hooks/useAppParams";
import { QueriesKeys } from "common/types/general";
import { AuthWrapper } from "./components";
import { UserDetailsDropdown } from "./user-details-dropdown";

export const LeftMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { goToBasePath, goToCountyCenter, updateBasePath } = useBasePathUrl();
  const { onDisplayAuthModal } = useAuthModal();
  const { onStepperReset } = useAddListingStepper();
  const { logoutFromBE } = useBELogout();
  const { isMobile } = useWindowDimensions();
  const { isAdminUser } = useIsAdminUser();
  const { isListingDetailsMode } = useAppParams();
  const appSlice = useAppSelector((state) => state.appSlice);
  const authSlice = useAppSelector((state) => state.authSlice);
  const listingsSlice = useAppSelector((state) => state.listingsSlice);
  const queryClient = useQueryClient();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const { user, token } = authSlice;
  const { favoritesListingsIds } = listingsSlice;
  const { activeMenuView } = appSlice;

  const userOwnListings = user?.ownListingsIds || [];
  const userOwnListingsLength = userOwnListings?.length || 0;
  const favoritesCount = Object.values(favoritesListingsIds).length;

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);

    if (activeMenuView === "settings") {
      dispatch(updateActiveMenuView("none"));
    }
  };

  const handleAddListing = () => {
    dispatch(updateActiveMenuView("add-listing"));
    updateBasePath({
      suffix: "add",
    });
  };

  const handleOpenComponents = () => {
    updateBasePath({
      suffix: "components",
    });
  };

  const handleOpenSettingsMenu = (event: any) => {
    if (anchorElNav) {
      // If already open, close it
      handleCloseNavMenu();
      return;
    }

    if (!user) {
      // If not authenticated, open the auth modal
      onDisplayAuthModal(true);
      return;
    }

    // Open dropdown
    setAnchorElNav(event.currentTarget);
    dispatch(updateActiveMenuView("settings"));
  };

  const handleShowFavorites = () => {
    if (favoritesListingsIds.length === 0) {
      toast.warn("Nu ai niciun anunț în lista de favorite", {
        toastId: "no-favorites",
      });
      return;
    }

    if (activeMenuView === "favorites") {
      toast.success("Afișează toate anunțurile", {
        toastId: "all-listings",
      });
    } else {
      toast.success("Afișează doar anunțurile favorite", {
        toastId: "only-favorites",
      });
    }

    if (activeMenuView !== "favorites") {
      goToCountyCenter("ro");
      dispatch(setSidebarOpen(true));
    }
    dispatch(updateActiveMenuView(activeMenuView === "favorites" ? "none" : "favorites"));
  };

  const handleShowUserOwnListings = () => {
    if (userOwnListings.length === 0) {
      // Should never happen, as we hide the button if the user has no listings
      toast.warn("Nu ai niciun anunț postat");
      return;
    }

    if (activeMenuView === "own-listings") {
      toast.success("Afișează toate anunțurile", {
        toastId: "all-listings",
      });
    } else {
      toast.success("Afișează doar anunțurile postate de tine", {
        toastId: "only-own-listings",
      });
    }

    // If we are seeing a listing details page, we need to go back to the base path
    if (isListingDetailsMode) {
      goToBasePath({});
    }

    if (activeMenuView !== "own-listings") {
      goToCountyCenter("ro");
      dispatch(setSidebarOpen(true));
    }
    dispatch(updateActiveMenuView(activeMenuView === "own-listings" ? "none" : "own-listings"));
  };

  const handleSeeAccountDetails = () => {
    dispatch(setOpenedModal("authenticated-user-profile"));
    handleCloseNavMenu();
  };

  const handleViewAdmin = () => {
    dispatch(updateActiveMenuView("settings"));
    handleCloseNavMenu();
    navigate("/admin");
  };

  const handleLogout = () => {
    dispatch(updateActiveMenuView("none"));
    dispatch(logout());
    logoutFromBE({ token: token! });
    onStepperReset();
    handleCloseNavMenu();
    queryClient.invalidateQueries("userDetails" as QueriesKeys);
  };

  if (isMobile) {
    return (
      <StyledMobileMenuBox>
        <MobileMenuButton onMenuButtonClick={handleOpenSettingsMenu} active={String(activeMenuView === "settings")}>
          <AuthWrapper isAuth={!!token}>
            <PermIdentityIcon className="mobile-icon" />
          </AuthWrapper>
        </MobileMenuButton>
        <UserDetailsDropdown
          anchorElNav={anchorElNav}
          onCancel={handleCloseNavMenu}
          onLogout={handleLogout}
          onViewOwnProfilePage={handleSeeAccountDetails}
          onViewAdminPage={handleViewAdmin}
          onOpenComponents={handleOpenComponents}
        />
        {userOwnListingsLength > 0 && (
          <BadgerWrapper count={userOwnListingsLength}>
            <MobileMenuButton
              onMenuButtonClick={handleShowUserOwnListings}
              active={String(activeMenuView === "own-listings")}
            >
              <FormatListBulletedIcon className="mobile-icon" />
            </MobileMenuButton>
          </BadgerWrapper>
        )}
        <BadgerWrapper count={favoritesListingsIds.length || ""}>
          <MobileMenuButton onMenuButtonClick={handleShowFavorites} active={String(activeMenuView === "favorites")}>
            {activeMenuView === "favorites" ? (
              <FavoriteIcon className="mobile-icon" />
            ) : (
              <FavoriteBorderIcon className="mobile-icon" />
            )}
          </MobileMenuButton>
        </BadgerWrapper>

        <MobileMenuButton
          onMenuButtonClick={handleAddListing}
          active={String(activeMenuView === "add-listing")}
          className="add-listing-btn"
        >
          <AddCircleOutlineIcon className="mobile-icon" />
        </MobileMenuButton>
      </StyledMobileMenuBox>
    );
  }

  return (
    <StyledDesktopMenuBox>
      <StyledDesktopMenuItem
        className="left-menu-my-account"
        aria-label="my-account"
        aria-controls="menu-auth"
        aria-haspopup="true"
        onClick={handleOpenSettingsMenu}
        active-btn={String(activeMenuView === "settings")}
        tabIndex={0}
      >
        <StyledText>
          {token && !user ? (
            <Skeleton width={80} height={20} />
          ) : user ? (
            isAdminUser ? (
              `${user.email.split("@")[0]}`
            ) : (
              `${user.firstName} ${user.lastName?.charAt(0)}.`
            )
          ) : (
            "Autentificare"
          )}
          {user && (isAdminUser ? <BuildIcon className="user-icon" /> : <PermIdentityIcon className="user-icon" />)}
        </StyledText>
        <UserDetailsDropdown
          anchorElNav={anchorElNav}
          onCancel={handleCloseNavMenu}
          onLogout={handleLogout}
          onViewOwnProfilePage={handleSeeAccountDetails}
          onViewAdminPage={handleViewAdmin}
          onOpenComponents={handleOpenComponents}
        />
      </StyledDesktopMenuItem>
      {userOwnListingsLength > 0 && (
        <StyledDesktopMenuItem
          key="user-listings"
          onClick={handleShowUserOwnListings}
          active-btn={String(activeMenuView === "own-listings")}
          tabIndex={0}
        >
          <StyledText>
            Anunțurile mele<span>{`(${userOwnListingsLength})`}</span>
          </StyledText>
        </StyledDesktopMenuItem>
      )}
      <StyledDesktopMenuItem
        key="favorites"
        onClick={handleShowFavorites}
        active-btn={String(activeMenuView === "favorites")}
        tabIndex={0}
      >
        <StyledText>
          Favorite<span>{`(${favoritesCount})`}</span>
        </StyledText>
      </StyledDesktopMenuItem>
      <StyledDesktopMenuItem
        key="add-listing"
        onClick={handleAddListing}
        active-btn={String(activeMenuView === "add-listing")}
        tabIndex={0}
        className="add-listing-btn"
      >
        <StyledText>
          Adaugă proprietate
          <AddIcon />
        </StyledText>
      </StyledDesktopMenuItem>
    </StyledDesktopMenuBox>
  );
};

const StyledDesktopMenuBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 8px;
  flex-grow: 1;
`;

export const StyledDesktopMenuItem = styled(MenuItem)`
  color: ${theme.palette.grey[800]};
  border-radius: 20px;
  padding: 0;
  border: 1px solid ${theme.palette.grey[200]};

  & svg {
    color: ${theme.palette.secondary.main};
  }

  &:hover,
  &[active-btn="true"] {
    &.MuiMenuItem-root,
    &.MuiMenuItem-gutters,
    &.MuiButtonBase-root {
      background-color: ${theme.palette.secondary.main};
      color: white;

      span,
      svg {
        color: white;
      }
    }
  }

  &.add-listing-btn {
    color: ${theme.palette.secondary.main};
    font-weight: 500;
    border: 2px solid ${theme.palette.secondary.main};
  }
`;

export const StyledText = styled("p")`
  color: inherit;
  border-radius: 20px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 13px;
  font-weight: 00;
  gap: 0.5ch;

  > span {
    font-weight: 500;
    color: ${theme.palette.secondary.main};
  }

  > svg {
    width: 16px;
    height: 16px;
    color: ${theme.palette.secondary.main};
  }

  &:hover {
    color: white;
  }
`;

const StyledMobileMenuBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-grow: 1;
  margin: 0 4px 0 0;

  & .mobile-icon {
    font-size: 20px;
  }

  & .add-listing-btn {
    color: ${theme.palette.secondary.main};
  }
`;

interface MobileMenuProps extends ComponentProps<typeof IconButton> {
  active: string;
  children: ReactNode;
  onMenuButtonClick: (event: any) => void;
}

export const MobileMenuButton = (props: MobileMenuProps) => {
  const { active, children, onMenuButtonClick, ...rest } = props;

  return (
    <IconButton
      size="large"
      aria-label="my-account"
      aria-controls="menu-auth"
      aria-haspopup="true"
      onClick={onMenuButtonClick}
      sx={{
        display: {
          xs: "flex",
          md: "none",
          color: active === "true" ? theme.palette.secondary.main : theme.palette.grey[800],
          "&:hover": {
            color: theme.palette.secondary.main,
          },
        },
        padding: ICON_BTN_PADDING_MOBILE,
      }}
      {...rest}
    >
      {children}
    </IconButton>
  );
};

type BadgerWrapperProps = {
  count: number | "";
  children: ReactElement;
  forceShow?: boolean;
};

export const BadgerWrapper = (props: BadgerWrapperProps) => {
  const { count, children, forceShow = false } = props;
  const { isMobile } = useWindowDimensions();

  return isMobile || forceShow ? (
    <Badge
      badgeContent={count}
      sx={{
        "& .MuiBadge-badge": {
          color: "green",
          fontSize: 10,
          marginBottom: "-5px",
          padding: 0,
          margin: 0,
          top: 4,
        },
      }}
    >
      {children}
    </Badge>
  ) : (
    children
  );
};
