export const dj = {
  dj_craiova: {
    id: "dj_craiova",
    lat: 44.31922660293122,
    lng: 23.79636475827596,
    zoom: 10,
    countyId: "dj",
    name: "Craiova",
    countyName: "Dolj",
  },
};
