import { useQuery } from "react-query";
import { useUpdateAppLoadingState } from "common/hooks/useUpdateAppLoadingState";
import {
  basicListingBEResponseSchema,
  useProcessBasicListings,
} from "api/listings/hooks/helpers/process-basic-listings";
import { QueriesKeys } from "common/types/general";
import { ListingsApi } from "../../listings-api";
import { LIMITED_CACHING, logZodError } from "../../../utils";
import { useHandleApiError } from "../../../useHandleErrors";

interface BasicListingsProps {
  listingIds: string[];
  enabled?: boolean;
  onMissingIds?: (updatedList: string[]) => void;
}

export const useGetFavoritesBasicListingsByIds = (props: BasicListingsProps) => {
  const { listingIds, enabled = false, onMissingIds } = props;
  const { addLoadingEl, removeLoadingEl } = useUpdateAppLoadingState();

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["favoritesBasicListingsByIds" as QueriesKeys, listingIds, enabled],
    async () => {
      addLoadingEl("favorites-basic-listings-by-ids");
      const res = await ListingsApi.getFavoritesListingsByIds({ listingIds });
      removeLoadingEl("favorites-basic-listings-by-ids");

      const { data } = res.data; // .filter(({ id }: any) => id !== "d27369e4-61c9-46a1-b350-afac64ff74e9");
      const parsed = basicListingBEResponseSchema.safeParse(data);

      // Handle scenario where some of the ids are not found. Remove from the corresponding list
      if (onMissingIds) {
        if (data.length === listingIds.length) {
          const incomingIds = data.map(({ id }: { id: string }) => id);
          const validIds = listingIds.filter((id) => incomingIds.includes(id));

          onMissingIds(validIds);
        }
      }

      if (!parsed.success) {
        logZodError(parsed.error);
        // We continue because it might be an inconsequential parse error
        return data;
      }

      return parsed.data;
    },
    {
      ...LIMITED_CACHING,
      enabled: listingIds.length > 0 && enabled,
    }
  );

  const { processedListings } = useProcessBasicListings(data);

  // Handle errors
  useHandleApiError({
    isError,
    message: "Nu s-au putut încărca anunțurile favorite. Reîncarcă pagina și încearcă din nou.",
  });

  return { data: processedListings, isLoading, isSuccess, isError };
};
