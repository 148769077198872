import { updateLastSeenId, updateLastSeenView } from "redux/slices/appSlice";
import { BasicListingType } from "api/listings/listings-types";
import { useAppDispatch, useBasePathUrl } from ".";
import { useAppParams } from "./useAppParams";

interface Props {
  listing: Pick<BasicListingType, "id" | "lat" | "lng">;
}

export const useOpenListingDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { updateBasePath } = useBasePathUrl();
  const { view } = useAppParams();

  const handleOpenListingDetailsPage = (props: Props) => {
    const { listing } = props;

    dispatch(updateLastSeenId(listing.id));
    dispatch(updateLastSeenView(view));

    updateBasePath({
      suffix: `listing/${listing.id}`,
      view: "details",
      lat: listing.lat,
      lng: listing.lng,
    });

    // THIS ONE WAS FOR ZOOMING IN ON THE LISTING WHEN CLICKING ON IT. NOT SURE IF WE NEED IT ANYMORE IN THE FUTURE

    // Store current url to redirect back to it after closing the details
    // if (!listingId) {
    // const listingZoom = listingId
    // ? currentZoom
    // : currentZoom && !Number.isNaN(parseInt(currentZoom, 10))
    // ? parseInt(currentZoom, 10) + 3
    // : 17;
  };

  return {
    onOpenListingDetailsPage: handleOpenListingDetailsPage,
  };
};
