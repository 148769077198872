export const mh = {
  mh_drobeta_turnu_severin: {
    id: "mh_drobeta_turnu_severin",
    lat: 44.63036343946687,
    lng: 22.65509284144612,
    zoom: 10,
    countyId: "mh",
    name: "Drobeta Turnu Severin",
    countyName: "Mehedinți",
  },
};
