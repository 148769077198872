export const mm = {
  mm_baia_mare: {
    id: "mm_baia_mare",
    lat: 47.65535691533318,
    lng: 23.56914949720437,
    zoom: 10,
    countyId: "mm",
    name: "Baia Mare",
    countyName: "Maramureș",
  },
};
